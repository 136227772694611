// autogenerated

export default class CustomerCode {

    public id: string = "";

    public code: string = "";

    public name: string | null = null;

    public express: boolean = false;

    public ifs: boolean = false;

    public readonly isCustomerCode: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CustomerCode) || ((from as any).isCustomerCode === true));
    }

    public static as(from?: object | null): CustomerCode | null {
        return (CustomerCode.is(from)) ? from as CustomerCode : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}