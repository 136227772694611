import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import ProductAssortmentInheritance from "@/models/server/ProductAssortmentInheritance";
import {
    BorderType,
    CellModel,
    ColumnDefinition,
    Grid,
    GridHoveringType,
    RowModel
} from "@reapptor-apps/reapptor-react-components";
import Product from "@/models/server/Product";
import ProductAssortment from "@/models/server/ProductAssortment";
import {ProductReplacementType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

import styles from "./InheritancesPanel.module.scss";

export interface IInheritancesPanelProps {
    productAssortmentId: string;
}

interface IInheritancesPanelState {
}

export default class InheritancesPanel extends BaseComponent<IInheritancesPanelProps, IInheritancesPanelState> {

    state: IInheritancesPanelState = {
    };

    private readonly _gridRef: React.RefObject<Grid<ProductAssortmentInheritance>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: "2rem",
            maxWidth: "2rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            accessor: nameof.full<ProductAssortmentInheritance>(o => o.fromProductAssortment!.product!.code),
            minWidth: "7.5rem",
            maxWidth: "7.5rem",
            textAlign: TextAlign.Center
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridNameLanguageItemName,
            accessor: (model: ProductAssortmentInheritance) => Product.getFullName(model.fromProductAssortment!.product!),
            minWidth: "23rem",
            init: (cell: CellModel<ProductAssortmentInheritance>) => this.initNameColumn(cell)
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridQuantityLanguageItemName,
            title: Localizer.productPanelGridAmountLanguageItemName,
            textAlign: TextAlign.Center,
            accessor: nameof.full<ProductAssortmentInheritance>(o => o.fromProductAssortment!.product!.packageQuantity),
            minWidth: "4rem",
            maxWidth: "4rem",
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridUsageGroupLanguageItemName,
            header: Localizer.productPanelGridPeriodLanguageItemName,
            title: Localizer.productPanelGridPeriodTitleLanguageItemName,
            accessor: (model: ProductAssortmentInheritance) => "{0:0.00}".format(ProductAssortment.getConsumption(model.fromProductAssortment!)),
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Right,
            settings: {
                infoAccessor: (model: ProductAssortmentInheritance) => Localizer.productPanelGridPeriodInterval.format(model.fromProductAssortment!.customer?.consumptionInterval || 1),
            }
        } as ColumnDefinition,
        {
            group: Localizer.productPanelGridUsageGroupLanguageItemName,
            header: Localizer.productPanelGridUsageAnnualLanguageItemName,
            title: Localizer.productPanelGridUsageAnnualTitleLanguageItemName,
            accessor: (model: ProductAssortmentInheritance) => (model.fromProductAssortment?.annualValue != null) ? "{0}".format(model.fromProductAssortment.annualValue) : "-",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Right,
            init: (cell: CellModel<ProductAssortmentInheritance>) => this.initAnnualColumn(cell),
            settings: {
                infoAccessor: (model: ProductAssortmentInheritance) => (model.fromProductAssortment?.annualDays)
                    ? Localizer.productPanelGridUsageTotalDays.format(model.fromProductAssortment.annualDays)
                    : "",
            }
        } as ColumnDefinition,
        {
            header: Localizer.genericCreatedAtLanguageItemName,
            accessor: nameof.full<ProductAssortmentInheritance>(o => o.productReplacement!.createdAt),
            format: "D",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.genericCreatedByLanguageItemName,
            accessor: nameof.full<ProductAssortmentInheritance>(o => o.productReplacement!.createdBy!),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<ProductAssortmentInheritance>(o => o.productReplacement!.createdBy!.email),
                infoHideEqual: true,
            }
        } as ColumnDefinition
    ];

    private initRow(row: RowModel<ProductAssortmentInheritance>): void {
        const deletedAssortment: boolean = (row.model.fromProductAssortment?.deleted == true);

        row.className = this.cssIf(row.className, deletedAssortment, styles.deletedAssortment);
    }
    
    private initNameColumn(cell: CellModel<ProductAssortmentInheritance>): void {
        const deletedProduct: boolean = (cell.model.fromProductAssortment?.product?.deleted == true);
        
        cell.className = this.cssIf(cell.className, deletedProduct, styles.deletedProduct);
    }
    
    private initAnnualColumn(cell: CellModel<ProductAssortmentInheritance>): void {
        const permanent: boolean = (cell.model.productReplacement?.type == ProductReplacementType.Permanent);

        cell.title = (permanent)
            ? Localizer.productReplacementPageGridTypePermanentLanguageItemName
            : Localizer.productReplacementPageGridTypeTemporaryLanguageItemName;
        
        cell.className = this.cssIf(cell.className, permanent, styles.permanentReplacement);
    }
    
    private get productAssortmentId(): string {
        return this.props.productAssortmentId;
    }

    private async fetchAsync(sender: Grid<ProductAssortmentInheritance>): Promise<ProductAssortmentInheritance[]> {
        return sender.postAsync("/api/productManagement/listProductAssortmentInheritances", this.productAssortmentId);
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.inheritancesPanel)}>

                <Grid id={`inheritancesPanelGrid_${this.productAssortmentId}`}
                      key={`inheritancesPanelGrid_${this.productAssortmentId}`}
                      ref={this._gridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.None}
                      className={styles.inheritancesPanelGrid}
                      noDataText={Localizer.inheritancesPanelGridNoInheritancesLanguageItemName}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      initRow={((row: RowModel<ProductAssortmentInheritance>) => this.initRow(row))}
                      fetchData={(sender: Grid<ProductAssortmentInheritance>) => this.fetchAsync(sender)}
                />

            </div>
        )
    }
}