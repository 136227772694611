import React from "react";
import {ActionType, BaseComponent, ch, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {BorderType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, Grid, GridHoveringType, GridModel, RowModel,} from "@reapptor-apps/reapptor-react-components";
import {FileModel, IPagedList, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import SyncActionItem from "@/models/server/SyncActionItem";
import {SyncActionType} from "@/models/Enums";
import ListSyncActionsRequest from "@/models/server/requests/ListSyncActionsRequest";
import ListSyncActionsResponse from "@/models/server/responses/ListSyncActionsResponse";
import SyncActionAuditPanelToolbar, {SyncActionAuditPanelToolbarModel} from "@/pages/IfsAudit/SyncActionAuditPanelToolbar/SyncActionAuditPanelToolbar";
import Localizer from "@/localization/Localizer";

import styles from "./SyncActionAuditPanel.module.scss";

export interface ISyncActionAuditPanelProps {
    syncActionType: SyncActionType;
}

interface ISyncActionAuditPanelState {
    model: SyncActionAuditPanelToolbarModel;
}

export default class SyncActionAuditPanel extends BaseComponent<ISyncActionAuditPanelProps, ISyncActionAuditPanelState> {

    state: ISyncActionAuditPanelState = {
        model: new SyncActionAuditPanelToolbarModel()
    };

    private readonly _auditGridRef: React.RefObject<Grid<SyncActionItem>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.ifsAuditPageFiletimestamp,
            name: nameof.full<SyncActionItem>(o => o.fileTimestamp),
            format: "D",
            className: styles.timestamp,
            sorting: SortDirection.Desc,
            isDefaultSorting: true,
            accessor: nameof<SyncActionItem>(o => o.fileTimestamp),
            textAlign: TextAlign.Right,
            init: (cell: CellModel<SyncActionItem>) => this.initToggleColumn(cell),
            actions: [
                {
                    name: "toggle",
                    type: ActionType.Blue,
                    icon: {name: ""},
                    callback: (cell: CellModel<SyncActionItem>) => this.toggleDetailsAsync(cell),
                } as ColumnActionDefinition,
            ],
            callback: (cell: CellModel<SyncActionItem>) => this.toggleDetailsAsync(cell),
        } as ColumnDefinition,
        {
            header: Localizer.ifsAuditPageSynctimestamp,
            sorting: true,
            accessor: nameof.full<SyncActionItem>(o => o.syncTimestamp),
            format: "D",
            textAlign: TextAlign.Right,
        } as ColumnDefinition,
        {
            header: Localizer.ifsAuditPageProcessed,
            name: nameof.full<SyncActionItem>(o => o.processed),
            textAlign: TextAlign.Center,
            accessor: (model: SyncActionItem) => model.processed ? "✓" : "",
        } as ColumnDefinition,
        {
            header: Localizer.ifsAuditPageFailed,
            name: nameof.full<SyncActionItem>(o => o.failed),
            textAlign: TextAlign.Center,
            accessor: (model: SyncActionItem) => model.failed ? "✓" : "",
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            removable: false,
            init: (cell: CellModel<SyncActionItem>) => this.initSyncActionOperations(cell),
            actions: [
                {
                    name: "excel",
                    title: Localizer.orderHistoryPanelGridDownloadExcelLanguageItemName,
                    icon: "far fa-file-excel",
                    type: ActionType.Default,
                    callback: (cell: CellModel<SyncActionItem>) => this.exportOrderToXmlAsync(cell.model)
                } as ColumnActionDefinition,
                {
                    name: "xml",
                    title: Localizer.orderHistoryPanelGridDownloadXmlLanguageItemName,
                    icon: "far fa-file-alt",
                    type: ActionType.Default,
                    callback: (cell: CellModel<SyncActionItem>) => this.exportOrderToCsvAsync(cell.model)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
    ];

    private initSyncActionOperations(cell: CellModel<SyncActionItem>): void {
        const downloadXml: CellAction<SyncActionItem> = cell.actions[0];
        const downloadCsv: CellAction<SyncActionItem> = cell.actions[1];

        downloadXml.visible = (this.props.syncActionType == SyncActionType.GetOrderAgentTask);
        downloadCsv.visible = (this.props.syncActionType == SyncActionType.GetSalesReportAgentTask);
    }
    
    private initToggleColumn(cell: CellModel<SyncActionItem>): void {

        const toggleButton: CellAction<SyncActionItem> = cell.actions[0];
        
        toggleButton.visible = cell.model.failed;

        if (toggleButton.action.icon) {
            toggleButton.action.icon.name = (cell.row.expanded) ? "far fa-angle-up" : "far fa-angle-down";
        }
    }
    
    private async exportOrderToCsvAsync(syncAction: SyncActionItem): Promise<void> {
        const file: FileModel = await this.postAsync("/api/audit/exportIfsSyncActionContentToCsv", syncAction.id);

        await ch.download(file);
    }

    private async exportOrderToXmlAsync(syncAction: SyncActionItem): Promise<void> {
        const file: FileModel = await this.postAsync("/api/audit/exportIfsSyncActionContentToXml", syncAction.id);
        
        await ch.download(file);
    }

    private async fetchAsync(sender: Grid<SyncActionItem>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<SyncActionItem>> {
        const request = new ListSyncActionsRequest();
        request.type = this.props.syncActionType;
        request.failedOnly = this.model.onlyFailed;
        request.processedOnly = this.model.onlyProcessed;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.search = this.model.search;

        const response: ListSyncActionsResponse = await sender.postAsync("/api/audit/listSyncActions", request);
        return response.items || [].toPagedList(pageNumber, pageSize)!;
    }

    private async toggleDetailsAsync(cell: CellModel<SyncActionItem>): Promise<void> {
        await cell.row.toggleAsync();
    }

    private async onToolbarSubmitAsync(model: SyncActionAuditPanelToolbarModel): Promise<void> {
        this.state.model = model;
        await this.reloadAsync();
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }
    
    public get model(): SyncActionAuditPanelToolbarModel {
        return this.state.model;
    }

    public get grid(): GridModel<SyncActionItem>{
        return this._auditGridRef.current!.model;
    }

    public renderDetails(row: RowModel<SyncActionItem>): React.ReactNode {
        return (
            <div>
                {row.model.error}
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.syncAudit)}>

                <SyncActionAuditPanelToolbar model={this.model}
                                             syncActionType={this.props.syncActionType}
                                             onChange={(model) => this.onToolbarSubmitAsync(model)}
                />
                
                <Grid optimization responsive pagination 
                      id={"syncActionGrid"}
                      ref={this._auditGridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.Row}
                      className={styles.auditGrid}
                      noDataText={Localizer.genericNoData}
                      headerMinHeight={80}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      renderDetails={(row: RowModel<SyncActionItem>) => this.renderDetails(row)}
                      fetchData={(sender: Grid<SyncActionItem>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null) => this.fetchAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                />

            </div>
        )
    }
}