import React from "react";
import {
    BorderType,
    Button,
    ButtonType, CellAction,
    CellModel,
    Checkbox,
    ColumnActionDefinition,
    ColumnDefinition,
    ColumnType,
    Grid,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ActionType, ch, PageRouteProvider, TextAlign, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import CustomerGroup from "@/models/server/CustomerGroup";
import Customer from "@/models/server/Customer";
import CustomerGroupModal from "@/pages/CustomerManagement/CustomerGroupModal/CustomerGroupModal";
import SaveCustomerGroupRequest from "@/models/server/requests/SaveCustomerGroupRequest";
import PageDefinitions from "@/providers/PageDefinitions";
import {CustomerHandler, CustomerServiceType, ExportEncodingType} from "@/models/Enums";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import DeleteCustomerGroupResponse from "@/models/server/responses/DeleteCustomerGroupResponse";
import CustomerInfoCardModal from "@/pages/CustomerManagement/CustomerInfoCardModal/CustomerInfoCardModal";
import ExportCustomersToCsvRequest from "@/models/server/requests/ExportCustomersToCsvRequest";
import CheckOpenOrdersRequest from "@/models/server/requests/CheckOpenOrdersRequest";
import CheckOpenOrdersResponse from "@/models/server/responses/CheckOpenOrdersResponse";
import AittaConstants from "@/helpers/AittaConstants";
import Localizer from "../../localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./CustomerManagement.module.scss"

interface ICustomerManagementProps {
}

interface ICustomerManagementState {
    loading: boolean,
    customerGroups: CustomerGroup[];
    search: string;
    showDeleted: boolean;
}

export default class CustomerManagement extends AuthorizedPage<ICustomerManagementProps, ICustomerManagementState> {

    state: ICustomerManagementState = {
        loading: false,
        customerGroups: [],
        search: "",
        showDeleted: false
    };

    private readonly _customerGroupsRef: React.RefObject<Grid<CustomerGroup>> = React.createRef();
    private readonly _customerGroupModalRef: React.RefObject<CustomerGroupModal> = React.createRef();
    private readonly _customerInfoCardModalRef: React.RefObject<CustomerInfoCardModal> = React.createRef();

    private readonly _customerGroupsColumns: ColumnDefinition[] = [
        {
            name: "code",
            header: Localizer.customerManagementPageGridCodeLanguageItemName,
            accessor: nameof<CustomerGroup>(o => o.name),
            minWidth: 100,
            maxWidth: 100,
            className: styles.name,
            init: (cell: CellModel<CustomerGroup>) => this.initCustomerGroupNameColumn(cell),
            actions: [
                {
                    name: "toggle",
                    type: ActionType.Blue,
                    icon: {name: ""},
                    callback: (cell: CellModel<CustomerGroup>) => this.toggleDetailsAsync(cell),
                } as ColumnActionDefinition,
            ],
            callback: (cell: CellModel<CustomerGroup>) => this.toggleDetailsAsync(cell),
        } as ColumnDefinition,
        {
            header: Localizer.customerManagementPageGridNameLanguageItemName,
            minWidth: 400,
            maxWidth: 400,
        } as ColumnDefinition,
        {
            header: Localizer.customerManagementPageGridDeliveryAddressLanguageItemName,
            minWidth: 300,
            maxWidth: 300,
        } as ColumnDefinition,
        {
            header: Localizer.customerManagementPageGridContactPersonLanguageItemName,
            minWidth: 180,
            maxWidth: 180,
        } as ColumnDefinition,
        {
            header: Localizer.customerManagementPageGridServiceTypeLanguageItemName,
            minWidth: 150,
            maxWidth: 150,
        } as ColumnDefinition,
        {
            header: Localizer.customerManagementPageGridShelvingTypeLanguageItemName,
            minWidth: 150,
            maxWidth: 150,
        } as ColumnDefinition,
        {
            header: Localizer.customerManagementPageGridCalendarWarningLanguageItemName,
            accessor: (model: CustomerGroup) => model.customers?.some(item => item.calendarWarning) ? "far fa-exclamation-triangle" : null,
            type: ColumnType.Icon,
            textAlign: TextAlign.Center,
            className: styles.calendarWarning,
            minWidth: 110,
            maxWidth: 110,
            removable: false,
        } as ColumnDefinition,
        {
            header: Localizer.genericActions,
            minWidth: 140,
            removable: false,
            init: (cell: CellModel<CustomerGroup>) => this.initCustomerGroupOperations(cell),
            actions: [
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far edit",
                    type: ActionType.Create,
                    callback: (cell, action) => this.processCustomerGroupOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "add",
                    title: Localizer.customerManagementPageGridAddCustomer,
                    icon: "plus",
                    type: ActionType.Info,
                    callback: (cell, action) => this.processCustomerGroupOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericDelete,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: (cell, action) => this.processCustomerGroupOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "restore",
                    title: Localizer.genericRestore,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: (cell, action) => this.processCustomerGroupOperationAsync(cell, action)
                } as ColumnActionDefinition,
            ],
        } as ColumnDefinition
    ];

    private readonly _customersColumns: ColumnDefinition[] = [
        {
            accessor: nameof<Customer>(o => o.codeInfo),
            minWidth: 100,
            maxWidth: 100,
            actions: [
                {
                    type: ActionType.Info,
                    icon: "fa-info-square far",
                    callback: async (cell: CellModel<Customer>) => await this.openCustomerInfoCardModalAsync(cell)
                } as ColumnActionDefinition
            ],
            noWrap: true,
        } as ColumnDefinition,
        {
            accessor: nameof<Customer>(o => o.name),
            minWidth: 400,
            maxWidth: 400,
            noWrap: true,
        } as ColumnDefinition,
        {
            accessor: (model: Customer) => model.location || model.virtualAddress || model.externalAddress,
            minWidth: 300,
            maxWidth: 300,
            noWrap: true,
        } as ColumnDefinition,
        {
            accessor: nameof<Customer>(o => o.contactPerson),
            minWidth: 180,
            maxWidth: 180,
        } as ColumnDefinition,
        {
            accessor: nameof<Customer>(o => o.serviceType),
            format: nameof<CustomerServiceType>(),
            textAlign: TextAlign.Center,
            minWidth: 150,
            maxWidth: 150,
        } as ColumnDefinition,
        {
            accessor: nameof<Customer>(o => o.handler),
            format: nameof<CustomerHandler>(),
            textAlign: TextAlign.Center,
            minWidth: 150,
            maxWidth: 150,
        } as ColumnDefinition,
        {
            accessor: (model: Customer) => model.calendarWarning ? "far fa-exclamation-triangle" : null,
            textAlign: TextAlign.Center,
            type: ColumnType.Icon,
            className: styles.calendarWarning,
            minWidth: 110,
            maxWidth: 110,
        } as ColumnDefinition,
        {
            stretch: true,
            removable: false,
            init: (cell: CellModel<Customer>) => this.initCustomerOperations(cell),
            actions: [
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "far edit",
                    type: ActionType.Create,
                    callback: (cell, action) => this.processCustomerOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericDelete,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<Customer>) => Localizer.customerManagementPageGridAreYouSureDeleteCustomer.format(cell.model.name, cell.model.codeInfo),
                    callback: (cell, action) => this.processCustomerOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "restore",
                    title: Localizer.genericRestore,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: (cell, action) => this.processCustomerOperationAsync(cell, action)
                } as ColumnActionDefinition,
            ],
        } as ColumnDefinition
    ];

    private customerGroupNameUniquenessValidator(customerGroupId: string, name: string): string {
        const existingCustomerGroup: CustomerGroup | undefined = this.state.customerGroups.find(item => item.id !== customerGroupId && item.name === name);
        return (existingCustomerGroup != null)
            ? (existingCustomerGroup.deleted)
                ? Localizer.customerManagementPageNameAlreadyExistsAsDeleted
                : Localizer.customerManagementPageNameAlreadyExists
            : "";
    }

    private initCustomerGroupRow(row: RowModel<CustomerGroup>): void {
        const model: CustomerGroup = row.model;

        row.deleted = model.deleted;
    }

    private initCustomerRow(row: RowModel<Customer>): void {
        const model: Customer = row.model;

        row.deleted = model.deleted;
    }

    private initCustomerGroupNameColumn(cell: CellModel<CustomerGroup>): void {
        cell.columnSpan = cell.grid.columns.length - 2;

        const toggleButton: CellAction<CustomerGroup> = cell.actions[0];

        if (toggleButton.action.icon) {
            toggleButton.action.icon.name = (cell.row.expanded) ? "far fa-angle-up" : "far fa-angle-down";
        }
    }

    private async reloadAsync(): Promise<void> {
        await this.setState({loading: true});

        const customerGroups: CustomerGroup[] = await this.postAsync("/api/productManagement/listCustomerGroups");

        await this.setState({customerGroups, loading: false});
    }

    private async newCustomerGroupAsync(): Promise<void> {
        await this._customerGroupModalRef.current?.openAsync(new CustomerGroup());
    }

    private async newCustomerAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.editCustomerRoute);
    }

    private async saveCustomerGroupAsync(customerGroup: CustomerGroup): Promise<void> {
        const request = new SaveCustomerGroupRequest();
        request.id = customerGroup.id;
        request.name = customerGroup.name;

        await this.postAsync("/api/customerManagement/saveCustomerGroup", request);

        await ch.flyoutMessageAsync(Localizer.customerManagementPageGroupHasBeenSaved.format(customerGroup.name));

        await this.reloadAsync();
    }

    private initCustomerGroupOperations(cell: CellModel<CustomerGroup>): void {
        const deleted: boolean = cell.row.deleted;

        const editAction: CellAction<CustomerGroup> = cell.actions[0];
        const addAction: CellAction<CustomerGroup> = cell.actions[1];
        const deleteAction: CellAction<CustomerGroup> = cell.actions[2];
        const restoreAction: CellAction<CustomerGroup> = cell.actions[3];

        editAction.visible = (!deleted) && (this.userHasFullWriteAccess);
        addAction.visible = (!deleted) && (this.userHasFullWriteAccess);
        deleteAction.visible = (!deleted) && (this.userHasFullWriteAccess);
        restoreAction.visible = (deleted) && (this.userHasFullWriteAccess);
    }

    private initCustomerOperations(cell: CellModel<Customer>): void {
        const model: Customer = cell.model;
        const customerGroup: CustomerGroup = this.state.customerGroups.find(item => item.id == model.customerGroupId)!;

        const deleted: boolean = cell.row.deleted;
        const customerGroupDeleted: boolean = customerGroup.deleted;

        const editAction: CellAction<Customer> = cell.actions[0];
        const deleteAction: CellAction<Customer> = cell.actions[1];
        const restoreAction: CellAction<Customer> = cell.actions[2];

        editAction.visible = (!deleted);
        deleteAction.visible = (!deleted) && (this.userHasFullWriteAccess);
        restoreAction.visible = (deleted) && (!customerGroupDeleted) && (this.userHasFullWriteAccess);
    }

    private async openCustomerInfoCardModalAsync(cell: CellModel<Customer>): Promise<void> {
        const model: Customer = cell.model;

        if (this._customerInfoCardModalRef.current) {
            await this._customerInfoCardModalRef.current.openAsync(model.id);
        }
    }

    private async processCustomerGroupOperationAsync(cell: CellModel<CustomerGroup>, action: CellAction<CustomerGroup>): Promise<void> {

        const model: CustomerGroup = cell.model;

        if (action.action.name === "edit") {

            await this._customerGroupModalRef.current?.openAsync(model);

        }
        if (action.action.name === "add") {

            await PageRouteProvider.redirectAsync(PageDefinitions.editCustomer(null, model.id));

        } else if (action.action.name === "delete") {

            const needConfirmation: boolean = (this.state.customerGroups.some(item => item.id == model.id));

            const confirmed: boolean = (needConfirmation)
                ? await this.confirmAsync(Localizer.customerManagementPageGridAreYouSureDeleteCustomerGroup.format(model.name))
                : false;

            if (confirmed) {

                const request = new CheckOpenOrdersRequest();
                request.customerGroupId = model.id;

                const response: CheckOpenOrdersResponse = await cell.grid.postAsync("/api/customerManagement/checkOpenOrders", request);

                const maxNumberOfCustomerCodesFitInTheModal = 5;
                const customerCodes: string | null = (response.customerCodes.length > 0)
                    ? response.customerCodes.length > maxNumberOfCustomerCodesFitInTheModal
                        ? response.customerCodes.slice(0, maxNumberOfCustomerCodesFitInTheModal).join(", ") + " ..."
                        : response.customerCodes.join(", ")
                    : null;

                const canBeDeleted: boolean = (
                    (!response.hasOpenOrders) ||
                    (
                        (response.hasOpenOrders) &&
                        (customerCodes != null) &&
                        (await this.confirmAsync(Localizer.customerManagementPageConfirmDeleteGroupWithOngoingOrders.format(customerCodes)))
                    )
                );

                if (canBeDeleted) {

                    const response: DeleteCustomerGroupResponse = await cell.grid.postAsync("/api/customerManagement/deleteCustomerGroup", model.id);

                    const message: string = (response.removedPermanently)
                        ? Localizer.customerManagementPageGridCustomerGroupDeleted.format(model.name)
                        : "Customer group <b>{0}</b> has been deleted permanently!".format(model.name);

                    await ch.flyoutMessageAsync(message);

                    await this.reloadAsync();
                }
            }

        } else if (action.action.name === "restore") {

            await cell.grid.postAsync("/api/customerManagement/restoreCustomerGroup", model.id);

            model.deleted = false;

            await cell.row.setDeletedAsync(false);

            await ch.flyoutMessageAsync(Localizer.customerManagementPageCustomerRestored.format(model.name));
        }
    }

    private async processCustomerOperationAsync(cell: CellModel<Customer>, action: CellAction<Customer>): Promise<void> {

        const model: Customer = cell.model;

        if (action.action.name === "edit") {

            await PageRouteProvider.redirectAsync(PageDefinitions.editCustomer(model.id))

        } else if (action.action.name === "delete") {

            const request = new CheckOpenOrdersRequest();
            request.customerId = model.id;

            const response: CheckOpenOrdersResponse = await cell.grid.postAsync("/api/customerManagement/checkOpenOrders", request);

            const canBeDeleted: boolean = (
                (!response.hasOpenOrders) ||
                (
                    (response.hasOpenOrders) &&
                    (await this.confirmAsync(Localizer.customerManagementPageConfirmDeleteCustomerWithOngoingOrders.format(model.name)))
                )
            );

            if (canBeDeleted) {
                await cell.grid.postAsync("/api/customerManagement/deleteCustomer", model.id);

                model.deleted = true;

                await cell.row.setDeletedAsync(true);

                await ch.flyoutMessageAsync(Localizer.customerManagementPageCustomerDeleted.format(model.name));
            }
        } else if (action.action.name === "restore") {

            await cell.grid.postAsync("/api/customerManagement/restoreCustomer", model.id);

            model.deleted = false;

            await cell.row.setDeletedAsync(false);

            await ch.flyoutMessageAsync(Localizer.customerManagementPageCustomerGroupRestored.format(model.name));
        }
    }

    private searchCustomerGroup(customerGroup: CustomerGroup): boolean {
        let visible: boolean = (this.state.showDeleted) || (!customerGroup.deleted);
        if (visible && this.state.search) {
            const search: string = this.state.search.toLowerCase();
            visible = (
                (customerGroup.name.toLowerCase().includes(search)) ||
                (!!customerGroup.customers && customerGroup.customers.some(item => this.searchCustomer(item, customerGroup)))
            );
        }
        return visible;
    }

    private getCustomerGroups(): CustomerGroup[] {
        const customerGroups: CustomerGroup[] = this.state.customerGroups;
        return customerGroups.filter(item => this.searchCustomerGroup(item));
    }

    private searchCustomer(customer: Customer, customerGroup: CustomerGroup): boolean {
        let visible: boolean = (this.state.showDeleted) || (!customer.deleted);
        visible = visible && (customer.customerGroupId == customerGroup.id);
        if ((visible) && (this.state.search)) {
            const search: string = this.state.search.toLowerCase();
            visible = (
                ((!!customer.codeInfo) && (customer.codeInfo.toLowerCase().includes(search))) ||
                ((!!customer.code) && (customer.code.toLowerCase().includes(search))) ||
                ((!!customer.name) && (customer.name.toLowerCase().includes(search))) ||
                ((!!customerGroup.name) && (customerGroup.name.toLowerCase().includes(search)))
            );
        }
        return visible;
    }

    private getCustomers(customerGroup: CustomerGroup): Customer[] { 
        const customers: Customer[] | null = this.state.customerGroups.selectMany(item => item.customers ?? []);
        return customers.where(item => this.searchCustomer(item, customerGroup));
    }

    private async toggleDetailsAsync(cell: CellModel<CustomerGroup>): Promise<void> {
        await cell.row.toggleAsync();
    }

    private async setSearchAsync(search: string): Promise<void> {
        UserInteractionDataStorage.set(nameof(this.state.search), search);

        await this.setState({search});
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        UserInteractionDataStorage.set(nameof(this.state.showDeleted), showDeleted);

        await this.setState({showDeleted});
    }

    private async exportToCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {
        const request = new ExportCustomersToCsvRequest();
        request.encodingType = encodingType;
        
        const file: FileModel = await this.postAsync("/api/customerManagement/exportCustomersToCsv", request);

        ch.download(file);
    }

    private get userHasFullWriteAccess(): boolean {
        return (AittaController.user.isAdmin) ||(AittaController.user.isSiteAdmin);
    }
    
    private get loading(): boolean {
        return this.state.loading;
    }

    public getTitle(): string {
        return Localizer.customerManagementPageTitle;
    }

    public getSubtitle(): string {
        return "";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        this.state.search = UserInteractionDataStorage.get(nameof(this.state.search));
        this.state.showDeleted = UserInteractionDataStorage.get(nameof(this.state.showDeleted));

        await this.reloadAsync();
    }

    public renderDetails(row: RowModel<CustomerGroup>): React.ReactNode {
        const customerGroup: CustomerGroup = row.model;

        return (
            <Grid noHeader
                  id={`grid_customers_${row.index}`}
                  noDataText={Localizer.customerManagementPageGridNoCustomers}
                  className={styles.customersGrid}
                  columns={this._customersColumns}
                  borderType={BorderType.NoSeparators}
                  minWidth={"auto"}
                  data={this.getCustomers(customerGroup)}
                  initRow={(row: RowModel<Customer>) => this.initCustomerRow(row)}
            />
        );
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }
        
        return (
            <PageContainer className={styles.customerManagement} fullWidth fullHeight>

                <PageHeader title={this.getTitle()}
                            subtitle={this.getSubtitle()}
                >

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   route={PageDefinitions.adminRoute}
                    />

                </PageHeader>

                <PageRow>

                    <div className={this.css("col", styles.container)}>

                        <ToolbarContainer className={this.css(styles.toolbar)}>

                            <ToolbarRow justify={JustifyContent.SpaceBetween}>

                                <Inline>

                                    <TextInput inline clearButton
                                               id={"customerNameOrCode"}
                                               maxLength={AittaConstants.keyLength}
                                               readonly={this.loading}
                                               width={"25rem"}
                                               placeholder={Localizer.customerManagementPageGridNameOrCode}
                                               value={this.state.search}
                                               onChange={(_, value) => this.setSearchAsync(value)}
                                    />

                                    <Button id={"search"}
                                            disabled={this.loading}
                                            label={Localizer.genericSearch}
                                            icon={{name: "fas search"}}
                                            type={ButtonType.Primary}
                                            onClick={() => this.reRenderAsync()}
                                    />

                                </Inline>

                                {
                                    (this.userHasFullWriteAccess) &&
                                    (
                                        <Inline>

                                            <ToolbarButton right
                                                           label={Localizer.customerManagementPageGridAddGroup}
                                                           icon={{name: "plus", size: IconSize.Large}}
                                                           type={ButtonType.Orange}
                                                           disabled={this.loading}
                                                           onClick={() => this.newCustomerGroupAsync()}
                                            />

                                            <ToolbarButton right
                                                           label={Localizer.customerManagementPageGridAddCustomer}
                                                           icon={{name: "plus", size: IconSize.Large}}
                                                           type={ButtonType.Orange}
                                                           disabled={this.loading}
                                                           onClick={() => this.newCustomerAsync()}
                                            />

                                        </Inline>
                                    )
                                }

                                <Inline>

                                    <Checkbox inline
                                              id="showDeleted"
                                              label={Localizer.customerManagementPageGridShowDeleted}
                                              value={this.state.showDeleted}
                                              readonly={this.loading}
                                              onChange={(_, value) => this.setShowDeletedAsync(value)}
                                    />

                                    <Button id={"exportToCsv"} right
                                            className={styles.button}
                                            label={Localizer.labelsToolbarExcel}
                                            icon={{name: "fa-file-download fas", size: IconSize.Large}}
                                            type={ButtonType.Primary}
                                            onClick={() => this.exportToCsvAsync()}
                                    >

                                        <Button.Action id={"utf8"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                                                       onClick={() => this.exportToCsvAsync(ExportEncodingType.Utf8)}
                                        />

                                        <Button.Action id={"unicode"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                                                       onClick={() => this.exportToCsvAsync(ExportEncodingType.Unicode)}
                                        />

                                        <Button.Action id={"latin1"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                                                       onClick={() => this.exportToCsvAsync(ExportEncodingType.Latin1)}
                                        />

                                        <Button.Action id={"openXml"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                                                       onClick={() => this.exportToCsvAsync(ExportEncodingType.OpenXml)}
                                        />

                                    </Button>

                                </Inline>

                            </ToolbarRow>

                        </ToolbarContainer>

                        <Grid responsive autoToggle
                              id={"customerGroupsGrid"}
                              ref={this._customerGroupsRef}
                              minWidth={"auto"}
                              className={styles.customerGroupsGrid}
                              borderType={BorderType.NoSeparators}
                              columns={this._customerGroupsColumns}
                              noDataText={Localizer.customerManagementPageGridNoCustomersFound}
                              data={this.getCustomerGroups()}
                              initRow={(row: RowModel<CustomerGroup>) => this.initCustomerGroupRow(row)}
                              renderDetails={(row: RowModel<CustomerGroup>) => this.renderDetails(row)}
                              detailsColEnd={this._customersColumns.length - 1}
                        />

                    </div>

                </PageRow>

                <CustomerGroupModal ref={this._customerGroupModalRef}
                                    nameUniquenessValidator={(customerGroupId: string, name: string) => this.customerGroupNameUniquenessValidator(customerGroupId, name)}
                                    submit={(_, customerGroup) => this.saveCustomerGroupAsync(customerGroup)}
                />

                <CustomerInfoCardModal ref={this._customerInfoCardModalRef}
                />

            </PageContainer>
        );
    }
}