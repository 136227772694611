import React from "react";
import {IManualProps, PageCacheTtl, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {Icon, IIconProps, PageContainer, Spinner, TextInput} from "@reapptor-apps/reapptor-react-components";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import OrderProduct from "@/models/server/OrderProduct";
import ProductAssortmentCard, {ProductAssortmentCardBorder} from "@/components/ProductAssortmentCard/ProductAssortmentCard";
import Product from "@/models/server/Product";
import ListPostDeliveryProductOrdersRequest from "@/models/server/requests/ListPostDeliveryProductOrdersRequest";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import OrderDelivery from "@/models/server/OrderDelivery";
import OrderProductDelivery from "@/models/server/OrderProductDelivery";
import ListPostDeliveryOrderProductsResponse from "@/models/server/responses/ListPostDeliveryOrderProductsResponse";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./PostDelivery.module.scss";

export interface IPostDeliveryProps {
}

interface IPostDeliveryState {
    orderProducts: OrderProduct[],
    displayedOrderProducts: OrderProduct[],
    orderDeliveries: OrderDelivery[],
    search: string | null;
    loading: boolean;
}

export default class PostDelivery extends MobileAuthorizedPage<IPostDeliveryProps, IPostDeliveryState> {

    state: IPostDeliveryState = {
        orderProducts: [],
        displayedOrderProducts: [],
        orderDeliveries: [],
        search: null,
        loading: true
    };

    private async listPostDeliveryOrderProductsAsync(): Promise<ListPostDeliveryOrderProductsResponse> {
        const request = new ListPostDeliveryProductOrdersRequest();
        request.customerId = this.customer!.id;

        return this.postAsync("/api/mobileApp/listPostDeliveryProductOrders", request, PageCacheTtl._5m);
    }

    public getManualProps(): IManualProps {
        return ((this.customer))
            ? {
                icon: "info-circle",
                title: Localizer.mobilePostDeliveryPageTitle,
                render: () => this.renderManual()
            }
            : {};
    }

    public get orderProducts(): OrderProduct[] {
        return this.state.orderProducts;
    }

    public get displayedOrderProducts(): OrderProduct[] {
        return this.state.displayedOrderProducts;
    }

    public get loading(): boolean {
        return this.state.loading;
    }

    private get search(): string | null {
        return this.state.search;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: ListPostDeliveryOrderProductsResponse = await this.listPostDeliveryOrderProductsAsync();

        await this.setState({loading: false, orderProducts: response.orderProducts ?? [], displayedOrderProducts: response.orderProducts ?? [], orderDeliveries: response.deliveries ?? []});
    }

    private async onSearchValueChangedAsync(value: string): Promise<void> {
        value = value.toLowerCase();

        const displayedOrderProducts: OrderProduct[] = (value.length == 0)
            ? this.orderProducts
            : this.orderProducts.where(item => Product.searchPredicate(item.productAssortment!.product, value));

        await this.setState({search: value, displayedOrderProducts});
    }

    public hasSpinner(): boolean {
        return true;
    }

    public renderManual(): React.ReactNode {
        const selectedCustomer: CustomerMobileInfo = this.customer!;

        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(selectedCustomer.codeInfo)} </mark> - {selectedCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{selectedCustomer.customerGroupName}</b></span>

                <span>{ReactUtility.toMultiLines(Localizer.mobilePostDeliveryPageInPostDelivery.format(this.orderProducts.length))}</span>

                {
                    (this.state.orderDeliveries.length > 0) &&
                    (
                        this.renderDeliveryTracking()
                    )
                }

            </div>
        )
    }

    private renderDeliveryTracking(): React.ReactElement {

        return (
            <div className={styles.delivery}>

                <h5>{Localizer.mobileOrderDetailsPageDeliveryTracking.toUpperCase()}</h5>

                {
                    this.state.orderDeliveries.map((orderDelivery: OrderDelivery) => (

                        <div key={`orderProductDelivery_${orderDelivery.id}`} className={styles.content}>


                            <div className={styles.deliveryOrder}>

                                <span>{orderDelivery.date!.format("D")}</span>
                                
                                <span>#{orderDelivery.orderNumber}</span>

                                <a href={orderDelivery.trackingLink!} target={"_blank"}>{Localizer.mobileOrderingDetailsPageViewAllLinks} <Icon name={"fas fa-link"}/> </a>

                            </div>

                            {
                                orderDelivery.deliveries?.map((orderProductDelivery: OrderProductDelivery, index: number) => (
                                    <div key={`orderProductDelivery_${orderProductDelivery.id}`} className={styles.deliveryOrderProduct}>

                                        {
                                            (orderProductDelivery.trackingLink !== "") &&
                                            (
                                                <div>

                                                    {index + 1}.<a href={orderProductDelivery.trackingLink!} target={"_blank"}><small>({orderProductDelivery.productCode})</small> <span>{orderProductDelivery.productName}</span></a>

                                                    <div>
                                                        
                                                        <small>({orderProductDelivery.deliveredQuantity}/{orderProductDelivery.orderedQuantity})</small>
                                                        
                                                    </div>

                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                            }

                        </div>
                    ))
                }

            </div>

        );
    }
    
    public renderSearch(): React.ReactNode {
        return (
            <div className={this.css(styles.searchBar)}>
                <TextInput clearButton noAutoComplete
                           id={"post_delivery_search_product"}
                           className={this.css(styles.searchBarInput)}
                           placeholder={Localizer.mobileOrderDetailsPageSearchCriteria}
                           append={<Icon name="far search"/>}
                           value={this.search}
                           onChange={(sender, value) => this.onSearchValueChangedAsync(value)}
                />
            </div>
        );
    }

    public renderFooter(item: OrderProduct): React.ReactNode {
        const iconProps: IIconProps = OrderProduct.getStatusIcon(item);
        const status: string = Localizer.get(EnumProvider.getOrderProductStatusItem(item.status).text);
        const deliveryDate: string = (item.deliveryDate)
            ? item.deliveryDate.format("D")
            : (item.order!.plannedDeliveryDate)
                ? item.order!.plannedDeliveryDate.format("D")
                : "";

        return (
            <div className={styles.productCardFooter}>

                <div>

                    <span> <Icon name={iconProps.name} className={iconProps.className} tooltip={iconProps.tooltip}/> {status} </span>

                    {
                        (item.order?.plannedDeliveryDate) && (
                            <div>{Localizer.mobilePostDeliveryPageDelivery.format(deliveryDate)} </div>
                        )
                    }

                </div>

                <div>

                    {
                        (item.order) && (
                            <div>{Localizer.mobilePostDeliveryPageOrderNumber.format(item.order.number)}</div>
                        )
                    }

                    <div>{Localizer.mobilePostDeliveryPageDelivered.format(item.deliveredQuantity, item.quantity)}</div>

                </div>

            </div>
        );
    }

    public render(): React.ReactNode {
        const hasPostDeliveries: boolean = (this.displayedOrderProducts.length > 0);

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.postDelivery, this.mobile && styles.mobile)}
            >

                <BannerMessageAlert className={styles.banner}/>

                {this.renderSearch()}

                {
                    (hasPostDeliveries)
                        ? (
                            <React.Fragment>

                                {
                                    this.displayedOrderProducts.map((item: OrderProduct, index: number) => (
                                            <ProductAssortmentCard id={`postDeliveryProduct_${index}`}
                                                                   className={styles.productCard}
                                                                   borderType={ProductAssortmentCardBorder.Selected}
                                                                   express={item.order?.express}
                                                                   detailsProductId={item.id}
                                                                   productReplacementId={item.productReplacementId}
                                                                   productAssortment={item.productAssortment!}
                                                                   renderFooter={() => this.renderFooter(item)}
                                            />
                                        )
                                    )
                                }

                            </React.Fragment>

                        )
                        : (this.loading)
                            ? (
                                <Spinner/>
                            )
                            : (
                                <div className={this.css(styles.noProducts)}>
                                    {Localizer.mobileCatalogNoProducts}
                                </div>
                            )

                }

            </PageContainer>
        );
    }
}