import React from "react";
import {BaseComponent, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Form,
    JustifyContent,
    Spinner,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import AittaConstants from "@/helpers/AittaConstants";
import MyCalendarToolbarModel from "@/pages/MyCalendar/MyCalendarToolbar/MyCalendarToolbarModel";
import AittaController from "@/pages/AittaController";

import styles from "./MyCalendarToolbar.module.scss";

export interface IMyCalendarToolbarProps {
    className?: string;
    model?: MyCalendarToolbarModel;
    hideCustomersInSearch?: boolean;
    
    onChange?(model: MyCalendarToolbarModel): Promise<void>;
}

interface IMyCalendarToolbarState {
    model: MyCalendarToolbarModel;
    isLoading: boolean;
    customers: Customer[];
}

export default class MyCalendarToolbar extends BaseComponent<IMyCalendarToolbarProps, IMyCalendarToolbarState> {

    state: IMyCalendarToolbarState = {
        model: this.props.model ?? new MyCalendarToolbarModel(),
        isLoading: true,
        customers: []
    };

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/myCalendar/listCustomers"));
    }

    private async selectCustomerAsync(customer: Customer, userInteraction: boolean): Promise<void> {

        this.model.customer = customer;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customer);
        }

        await this.processAsync(true);
    }

    private async setCustomerFavoriteAsync(customer: Customer, favorite: boolean): Promise<void> {
        customer.favorite = favorite;
        await AittaController.setFavoriteAsync(customer.id, favorite);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});

        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }

    private get customers(): Customer[] {
        return this.state.customers;
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get model(): MyCalendarToolbarModel {
        return this.state.model;
    }

    public get customer(): Customer | null {
        return this.model.customer;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        this.state.customers = await this.fetchCustomersAsync();
        
        this.model.customer = AittaController.getDefaultCustomer(this.state.customers);

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.myCalendarToolbar, this.props.className)}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Form inline onSubmit={() => this.processAsync(true)}>

                        <Dropdown id="customers" noWrap inline noGrouping clearButton required favorite filterFavorite
                                  className={styles.customerGroupsOrCustomers}
                                  align={DropdownAlign.Left}
                                  filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                  disabled={this.isLoading}
                                  width={AittaConstants.customersDropdownMinWidth}
                                  items={this.customers}
                                  selectedItem={this.customer}
                                  orderBy={DropdownOrderBy.None}
                                  onChange={(sender, item: Customer, userInteraction: boolean) => this.selectCustomerAsync(item, userInteraction)}
                                  onFavoriteChange={(sender, item: Customer, favorite: boolean) => this.setCustomerFavoriteAsync(item, favorite)}
                        />

                    </Form>

                </ToolbarRow>

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}