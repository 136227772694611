// autogenerated

export default class SaveInventoryProductRequest {

    public inventoryProductId: string = "";

    public quantity: number = 0;

    public inputPackages: number = 0;

    public inputPieces: number = 0;

    public readonly isSaveInventoryProductRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveInventoryProductRequest) || ((from as any).isSaveInventoryProductRequest === true));
    }

    public static as(from?: object | null): SaveInventoryProductRequest | null {
        return (SaveInventoryProductRequest.is(from)) ? from as SaveInventoryProductRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}