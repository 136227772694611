import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {ButtonType, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import RevenueReportPanel from "@/pages/ReportManagement/RevenueReportPanel/RevenueReportPanel";
import ProductReportPanel from "@/pages/ReportManagement/ProductReportPanel/ProductReportPanel";
import Localizer from "@/localization/Localizer";

import styles from "./ReportManagement.module.scss"

interface IReportManagementProps {

}

interface IReportManagementState {
}

export default class ReportManagement extends AuthorizedPage<IReportManagementProps, IReportManagementState> {

    state: IReportManagementState = {
    };

    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    
    public getTitle(): string {
        return Localizer.reportsManagementPageTitle;
    }
    
    public render(): React.ReactNode {

        return (
            <PageContainer fullWidth fullHeight className={styles.reportManagement}>

                <PageHeader withTabs
                            title={this.getTitle()}
                >

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>

                <PageRow>

                    <div className="col">

                        <TabContainer id="labelsManagementTabs" ref={this._tabContainerRef} renderType={TabRenderType.ActiveOnly}>

                            <Tab id={"Products"} title={Localizer.reportManagementPageProductsTab}>

                                <ProductReportPanel/>

                            </Tab>

                            <Tab id={"Revenue"} title={Localizer.reportManagementPageRevenueTab} >

                                <RevenueReportPanel/> 

                            </Tab>

                        </TabContainer>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}