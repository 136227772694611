import React from "react";
import {AlertModel, AlertType, ApiProvider, BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";
import BannerMessage from "@/models/server/BannerMessage";
import {Alert} from "@reapptor-apps/reapptor-react-components";
import AittaController from "@/pages/AittaController";

import styles from "./BannerMessageAlert.module.scss";

interface IBannerMessageAlertProps extends IBaseComponentProps {
}

interface IBannerMessageAlertState {
    banner: BannerMessage | null;
    closed: boolean;
}

export default class BannerMessageAlert extends BaseComponent<IBannerMessageAlertProps, IBannerMessageAlertState> {

    public state: IBannerMessageAlertState = {
        banner: null,
        closed: true,
    }

    private getAlertModel(banner: BannerMessage): AlertModel {
        const model = new AlertModel();
        model.alertType = AlertType.Info;
        model.dismissible = banner.dismissible;
        model.message = BannerMessage.getMessage(banner);
        //model.flyout = true;
        return model;
    }
    
    private async onCloseAsync(): Promise<void> {

        if (this.banner) {
            await ApiProvider.backgroundPostAsync("/api/banner/bannerClosed", this.banner.id);
        }

        await this.setState({ closed: true });
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const banner: BannerMessage | null = AittaController.findBannerMessage();
        
        if (banner) {
            await ApiProvider.backgroundPostAsync("/api/banner/bannerShown", banner.id);
        }

        await this.setState({banner, closed: false});
    }

    public get banner(): BannerMessage | null {
        return this.state.banner;
    }

    public render(): React.ReactNode {
        const banner: BannerMessage | null = this.banner;
        const alertModel: AlertModel | null = (banner) ? this.getAlertModel(banner) : null;
        const visible: boolean = (banner != null) && (!this.state.closed);
        const visibleStyle = (visible) && styles.visible;
        const inline = (alertModel && !alertModel.flyout) && styles.inline;

        return (
            <div id={this.id}
                 className={this.css(this.props.className, styles.bannerMessageAlert, visibleStyle, this.mobile && styles.mobile, inline)}>
                {
                    (alertModel) &&
                    (
                        <Alert model={alertModel}
                               className={styles.alert}
                               onClose={() => this.onCloseAsync()}
                        />
                    )
                }

            </div>
        )
    }
}