import React from "react";
import {DataStorageType, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {ButtonType, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import SyncActionAuditPanel from "./SyncActionAuditPanel";
import Localizer from "../../localization/Localizer";
import {SyncActionType} from "@/models/Enums";

interface IIfsAuditProps {
}

interface IIfsAuditState {
}

export default class IfsAudit extends AuthorizedPage<IIfsAuditProps, IIfsAuditState> {

    state: IIfsAuditState = {
    };
    
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    
    public getTitle(): string {
        return Localizer.ifsAuditPageTitle;
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth fullHeight>
                
                <PageHeader title={this.getTitle()}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                    
                </PageHeader>
                
                <PageRow>

                    <div className="col">

                        <TabContainer id="IfsAuditTabs" ref={this._tabContainerRef} renderType={TabRenderType.ActiveOnly} dataStorageType={DataStorageType.Page}>

                            <Tab
                                 id={"getOrderAgentTask"}
                                 title={Localizer.ifsAuditPageOrderAgentTasks}
                            >
                                
                                <SyncActionAuditPanel syncActionType={SyncActionType.GetOrderAgentTask}/>
                                
                            </Tab>

                            <Tab
                                 id={"getSalesReportAgentTask"} 
                                 title={Localizer.ifsAuditPageSalesReportaAgentTask}
                            >

                                <SyncActionAuditPanel syncActionType={SyncActionType.GetSalesReportAgentTask}/>

                            </Tab>

                        </TabContainer>

                    </div>
                    
                </PageRow>

            </PageContainer>
        );
    }
}