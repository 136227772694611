// autogenerated
import {OrderProductStatus} from "@/models/Enums";
import ProductAssortmentMobileInfo from "@/models/server/ProductAssortmentMobileInfo";

export default class OrderProductMobileInfo {

    public productAssortmentId: string = "";

    public id: string = "";

    public orderId: string = "";

    public quantity: number = 0;

    public productAssortment: ProductAssortmentMobileInfo | null = null;

    public productReplacementId: string | null = null;

    public fromProductReplacementId: string | null = null;

    public toProductReplacementIds: string[] | null = null;

    public productId: string = "";

    public orderQuantity: number | null = null;

    public status: OrderProductStatus = OrderProductStatus.Open;

    public deliveryDate: Date | null = null;

    public readonly isOrderProductMobileInfo: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof OrderProductMobileInfo) || ((from as any).isOrderProductMobileInfo === true));
    }

    public static as(from?: object | null): OrderProductMobileInfo | null {
        return (OrderProductMobileInfo.is(from)) ? from as OrderProductMobileInfo : null;
    }

    // #region Placeholder

    public static getReplacementId(product: OrderProductMobileInfo): string | null {
        return ((product.status == OrderProductStatus.Open) || (product.status == OrderProductStatus.Closed))
            ? product.productReplacementId ?? product.fromProductReplacementId
            : product.productReplacementId;
    }

    // #endregion
}