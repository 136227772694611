import React from "react";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, NumberInput, NumberInputBehaviour} from "@reapptor-apps/reapptor-react-components";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import ProductAssortmentCard, {ProductAssortmentCardBorder} from "@/components/ProductAssortmentCard/ProductAssortmentCard";
import ProductAssortmentMobileInfo from "@/models/server/ProductAssortmentMobileInfo";

import styles from "./OrderProductItem.module.scss";

export interface IOrderProductItemProps {
    orderProduct: OrderProductMobileInfo;
    express: boolean;
    className?: string;
    editing?: boolean;
    shoppingCart?: boolean;

    onChange?(sender: OrderProductItem, orderProduct: OrderProductMobileInfo): Promise<void>;
    onFavoriteChange?(sender: OrderProductItem, productId: string, favorite: boolean): Promise<void>;
}

interface IOrderProductItemState {
    initialAmount: number;
}

export default class OrderProductItem extends BaseComponent<IOrderProductItemProps, IOrderProductItemState> {

    // Inherited / implemented

    public state: IOrderProductItemState = {
        initialAmount: OrderProductItem.getInitialAmount(this.orderProduct),
    }

    // Fields

    private readonly _inputRef: React.RefObject<NumberInput> = React.createRef();


    // Getters

    private get orderProduct(): OrderProductMobileInfo {
        return this.props.orderProduct;
    }

    private get express(): boolean {
        return this.props.express;
    }

    private get delta(): number {
        return (this.orderProduct.quantity - this.state.initialAmount);
    }

    private get productAssortment(): ProductAssortmentMobileInfo | null {
        return this.orderProduct.productAssortment;
    }

    private get modified(): boolean {
        return (this.delta !== 0);
    }

    private get assumedLabel(): string {
        const quantity: number = (this.productAssortment)
            ? this.productAssortment.orderQuantity < 0
                ? 0
                : this.productAssortment.orderQuantity
            : 0;

        return "{0}".format(quantity)
    }


    // Sync-methods

    private async setAssumedOrderQuantityAsync(): Promise<void> {
        await this.onChangeAmountAsync(this.productAssortment!.orderQuantity);
    }
    
    private static getInitialAmount(orderProduct: OrderProductMobileInfo): number {
        const tag: string = "__initialAmount";
        const instance = orderProduct as any;
        return (typeof instance[tag] === "number")
            ? instance[tag]
            : (instance[tag] = orderProduct.quantity);
    }


    // Async-methods
    
    private async onChangeAmountAsync(quantity: number): Promise<void> {
        if (this.orderProduct.quantity !== quantity) {
            this.orderProduct.quantity = quantity;
            
            await this.reRenderAsync();

            if (this.props.onChange) {
                await this.props.onChange(this, this.orderProduct);
            }
        }
    }

    private async increaseAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this._inputRef.current.increaseAsync();
        }
    }

    private async decreaseAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this._inputRef.current.decreaseAsync();
        }
    }

    private async deleteAsync(): Promise<void> {
        if (this._inputRef.current) {
            await this.onChangeAmountAsync(0);
        }
    }

    // Renders

    public renderFooter(): React.ReactNode {
        const deltaSign: string = (this.delta > 0)
            ? "+"
            : "";

        return (
            <div className={styles.content}>

                <div className={styles.actions}>

                    <div>

                        <Button block
                                id={"delete"}
                                type={ButtonType.Primary}
                                icon={{name: "fas trash"}}
                                disabled={this.orderProduct.quantity == 0}
                                onClick={() => this.deleteAsync()}
                        />

                    </div>

                </div>

                <div className={styles.actions}>

                    <div>

                        <Button block
                                id={"decrease"}
                                type={ButtonType.Orange}
                                icon={{name: "fas minus"}}
                                onClick={() => this.decreaseAsync()}
                        />

                    </div>

                </div>

                <div className={styles.data}>

                    <div className={styles.input}>

                        <NumberInput hideArrows clickToEdit
                                     ref={this._inputRef}
                                     behaviour={NumberInputBehaviour.Restricted}
                                     min={0}
                                     max={999}
                                     step={1}
                                     value={this.orderProduct.quantity}
                                     onChange={(sender, quantity) => this.onChangeAmountAsync(quantity)}
                        />

                    </div>

                </div>

                <div className={styles.actions}>

                    <div>

                        <Button block
                                id={"increase"}
                                type={ButtonType.Orange}
                                icon={{name: "fas plus"}}
                                onClick={() => this.increaseAsync()}
                        />

                        {
                            (this.modified) &&
                            (
                                <span className={styles.delta}>
                                        {deltaSign}{Utility.formatValue(this.delta)}
                                    </span>
                            )
                        }

                    </div>

                </div>

                {
                    ((!this.express) && (this.productAssortment)) &&
                    (
                        <div className={styles.actions}>

                            <div>

                                <Button block
                                        id={"setAssumed"}
                                        label={this.assumedLabel}
                                        type={ButtonType.Primary}
                                        disabled={(this.productAssortment.orderQuantity <= 0) || (this.orderProduct.quantity == this.productAssortment.orderQuantity)}
                                        onClick={() => this.setAssumedOrderQuantityAsync()}
                                />

                            </div>

                        </div>
                    )
                }

            </div>

        );
    }

    public render(): React.ReactNode {
        const orderProduct: OrderProductMobileInfo = this.orderProduct;

        const isActive: boolean = (orderProduct.quantity > 0);

        const borderType: ProductAssortmentCardBorder = (isActive)
            ? ProductAssortmentCardBorder.Selected
            : ProductAssortmentCardBorder.Unselected;

        return (
            (this.productAssortment) &&
            (
                <ProductAssortmentCard showOrderQuantity
                                       className={styles.orderProductItem}
                                       borderType={borderType}
                                       express={this.props.express}
                                       productReplacementId={OrderProductMobileInfo.getReplacementId(orderProduct)}
                                       toProductReplacementIds={orderProduct.toProductReplacementIds}
                                       productAssortment={this.productAssortment}
                                       renderFooter={() => this.renderFooter()}
                />
            )
        );
    }
};