// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListCustomersRequest extends ETagRequest {

    public productSelectionOnly: boolean = false;

    public productSelectionIncludingSubscription: boolean = false;

    public mobileAppOnly: boolean = false;

    public orderingOnly: boolean = false;

    public reportsOnly: boolean = false;

    public calendarOnly: boolean = false;

    public inventoryOnly: boolean = false;

    public labelsOnly: boolean = false;

    public includeDeleted: boolean = false;

    public readonly isListCustomersRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListCustomersRequest) || ((from as any).isListCustomersRequest === true));
    }

    public static as(from?: object | null): ListCustomersRequest | null {
        return (ListCustomersRequest.is(from)) ? from as ListCustomersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}