import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Checkbox, Form, InlineType, Panel, ThreeColumns} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";
import AittaConstants from "@/helpers/AittaConstants";
import User from "@/models/server/User";

import styles from "./NotificationsPanel.module.scss";

interface INotificationsPanelProps {
    primaryRole: string;
    
    user: User;

    onChangeDailyNotifications(sender: NotificationsPanel, user: User): Promise<void>
}

interface INotificationsPanelState {
}

export default class NotificationsPanel extends BaseComponent<INotificationsPanelProps, INotificationsPanelState> {

    state: INotificationsPanelState = {
    };

    private get isAdminRole(): boolean {
        return AittaConstants.adminRoles.includes(this.props.primaryRole);
    }

    private get user(): User {
        return this.props.user;
    }
    
    private get hasDailyNotifications(): boolean {
        return this.user.hasDailyNotifications;
    }

    private async onChangeDailyNotificationAsync(value: boolean): Promise<void> {
        this.props.user.hasDailyNotifications = value;
        
        await this.props.onChangeDailyNotifications(this, this.user);
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }
    
    public render(): React.ReactNode {
        const showNotification: boolean = this.isAdminRole;
        const hideNotificationsStyles = (!showNotification) && styles.hideNotifications;
        return (
            <Panel title={Localizer.userRolesPanelNotificationPanelTitle} className={this.css(styles.notificationsPanel, hideNotificationsStyles)}>

                <div className="col-12">
                    
                    <div>

                        <Form>

                            <ThreeColumns>

                                <Checkbox inline
                                          inlineType={InlineType.Right}
                                          className={styles.daily}
                                          label={Localizer.userRolesPanelNotificationPanelAdminDailyNotifications}
                                          title={Localizer.userRolesPanelNotificationPanelAdminDailyNotifications}
                                          value={this.hasDailyNotifications}
                                          onChange={(sender, item) => this.onChangeDailyNotificationAsync(item)}
                                />

                            </ThreeColumns>

                        </Form>

                    </div>
                    
                </div>

            </Panel>
        );
    }
};