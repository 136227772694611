import React from "react";
import AnonymousPage from "../../models/base/AnonymousPage";
import {PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import Localizer from "../../localization/Localizer";

const ONLINE_CHECK_INTERVAL: number = 5000;

export default class OfflinePage extends AnonymousPage {
    
    private async checkOnlineAsync(): Promise<void> {
        if (ch.online) {
            const route: PageRoute = await this.postAsync("/api/account/getHomePage");

            await PageRouteProvider.changeUrlWithoutReload();

            await PageRouteProvider.redirectAsync(route, true, true);

            return;
        }
        
        await Utility.wait(ONLINE_CHECK_INTERVAL);
        
        await this.checkOnlineAsync();
    }

    public getTitle(): string {
        return Localizer.offlineTitle;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        setTimeout(() => this.checkOnlineAsync(), ONLINE_CHECK_INTERVAL);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.offlineTitle}/>
                <PageRow>
                    <div className="col">
                        <p>{Localizer.offlineSubtitle}</p>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}