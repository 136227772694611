import React from "react";
import {BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {Icon, IconSize, Modal, ModalSize, Spinner} from "@reapptor-apps/reapptor-react-components";
import GetCustomerInfoCardRequest from "@/models/server/requests/GetCustomerInfoCardRequest";
import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerInfoCardModal.module.scss";

interface ICustomerInfoCardModalProps {
}

interface ICustomerInfoCardModalState {
    infoCard: FileModel | null;
    customerId: string | null;
    isLoading: boolean;
}

export default class CustomerInfoCardModal extends BaseComponent<ICustomerInfoCardModalProps, ICustomerInfoCardModalState> {

    state: ICustomerInfoCardModalState = {
        infoCard: null,
        customerId: null,
        isLoading: false
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get title(): string {
        return Localizer.customerInfoCardModalTitle;
    }

    private get isLoading(): boolean {
        return this.state.isLoading;
    }

    private get infoCard(): FileModel | null {
        return this.state.infoCard;
    }

    public async openAsync(customerId: string, animation: boolean = true): Promise<void> {
        const request = new GetCustomerInfoCardRequest();
        request.customerId = customerId;

        const file: FileModel = await this.postAsync("/api/customerManagement/getCustomerInfoCardFileModel", request);

        await this.setState({infoCard: file, customerId});

        if (this.modal) {
            await this.modal.openAsync(animation);
        }
    }

    public async printInfoCardAsync(): Promise<void> {
        if (this.infoCard) {
            const objectUrl: string = Utility.toObjectUrl(this.infoCard);

            const newWindow: Window | null = window.open(objectUrl);
            newWindow!.print();
        }
    }
    
    public async downloadAsync(): Promise<void> {
        if (this.state.customerId) {
            await this.setState({isLoading: true})
            
            const request = new GetCustomerInfoCardRequest();
            request.customerId = this.state.customerId;
            request.pdf = true;

            const file: FileModel = await this.postAsync("/api/customerManagement/getCustomerInfoCardFileModel", request);
            
            ch.download(file);

            await this.setState({isLoading: false})
        }
    }
    
    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }

        await this.reRenderAsync();
    }

    public isSpinning(): boolean {
        return (this.isLoading || super.isSpinning());
    }
    
    private renderToolbar(): React.ReactNode {
        return (
            <React.Fragment>

                <Icon name="far fa-print"
                      className="blue"
                      size={IconSize.X2}
                      onClick={() => this.printInfoCardAsync()}
                      tooltip={Localizer.customerInfoCardModalSendToPrinter}
                />
                <Icon name="far download"
                      className="blue"
                      size={IconSize.X2}
                      onClick={() => this.downloadAsync()}
                      tooltip={Localizer.customerInfoCardModalDownloadPdf}
                />

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal info
                   id={this.id}
                   ref={this._modalRef}
                   className={styles.customerInfoCardModal}
                   bodyClassName={styles.body}
                   title={this.title}
                   size={ModalSize.Large}
                   toolbar={() => this.renderToolbar()}
            >
                {
                    (this.infoCard) &&
                    (
                        <div className={styles.contentContainer}>

                            <iframe allowFullScreen
                                    className={styles.content}
                                    src={this.infoCard.src}
                                    width={780}
                                    height={700}
                            />

                            {
                                (this.isSpinning()) && (<Spinner noShading/>)
                            }

                        </div>
                    )
                }
                

            </Modal>
        )
    }
}