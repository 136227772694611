import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {ButtonType, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import FaqPanel from "@/pages/FaqManagement/FaqPanel/FaqPanel";
import {LocalizationLanguage} from "@/models/Enums";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import Localizer from "@/localization/Localizer";

interface IFAQProps {
}

interface IFaqState {
    faq: Faq[];
}

export default class Faq extends AuthorizedPage<IFAQProps> {

    state: IFaqState = {
        faq: []
    };

    public getTitle(): string {
        return Localizer.faqManagementPageTitle;
    }

    public get faq(): Faq[] {
        return this.state.faq;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const faq: Faq[] = await this.postAsync("/api/faq/listFaq");

        await this.setState({faq});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight>

                <PageHeader title={this.getTitle()}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>
                
                <PageRow>

                    <TabContainer id={`${this.id}_tabContainer`} renderType={TabRenderType.ActiveOnly}>

                        <Tab id="finnish"
                             title={Localizer.enumLocalizationLanguageFinnish}
                        >

                            <FaqPanel language={LocalizationLanguage.Finnish}/>

                        </Tab>

                        <Tab id="english"
                             title={Localizer.enumLocalizationLanguageEnglish}
                        >

                            <FaqPanel language={LocalizationLanguage.English}/>

                        </Tab>

                        <Tab id="norwegian"
                             title={Localizer.enumLocalizationLanguageNorwegian}
                        >

                            <FaqPanel language={LocalizationLanguage.Norwegian}/>

                        </Tab>

                        <Tab id="swedish"
                             title={Localizer.enumLocalizationLanguageSwedish}
                        >

                            <FaqPanel language={LocalizationLanguage.Swedish}/>

                        </Tab>

                    </TabContainer>

                </PageRow>

            </PageContainer>
        );
    }
}