import React from "react";
import {BaseComponent, ch, IBaseComponentProps, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {CustomerServiceType, DayOfWeek, ExportEncodingType, OrderStatus, ProductOrderFilter} from "@/models/Enums";
import {
    Button,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Form,
    IconSize,
    Inline,
    InlineType,
    JustifyContent,
    SelectListItem,
    Spinner,
    TextInput,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import OrdersToolbarModel, {OrderPanelType} from "@/pages/OrderManagement/OrdersToolbar/OrdersToolbarModel";
import OrderProduct from "@/models/server/OrderProduct";
import Order from "@/models/server/Order";
import GetOpenOrderRequest from "@/models/server/requests/GetOpenOrderRequest";
import User from "@/models/server/User";
import AittaConstants from "@/helpers/AittaConstants";
import AittaController from "@/pages/AittaController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";

import styles from "./OrdersToolbar.module.scss";

export interface IOrdersToolbarProps extends IBaseComponentProps {
    type: OrderPanelType;
    model: OrdersToolbarModel;
    hideCustomersInSearch?: boolean;
    onChange?(sender: OrdersToolbar, model: OrdersToolbarModel): Promise<void>;
    onSubmit?(sender: OrdersToolbar, products: OrderProduct[], express: boolean): Promise<void>;
    onClear?(sender: OrdersToolbar): Promise<void>;
    onAssume?(sender: OrdersToolbar): Promise<void>;
    downloadOrderCsv?(sender: OrdersToolbar, order: Order, encodingType: ExportEncodingType): Promise<void>;
    downloadProductsCsv?(sender: OrdersToolbar, encodingType: ExportEncodingType): Promise<void>;
    send?(sender: OrdersToolbar, order: Order, close: boolean): Promise<void>;
}

interface IOrdersToolbarState {
    model: OrdersToolbarModel;
    isLoading: boolean;
    customers: Customer[];
    products: OrderProduct[];
    order: Order | null;
}

export default class OrdersToolbar extends BaseComponent<IOrdersToolbarProps, IOrdersToolbarState> {

    state: IOrdersToolbarState = {
        model: this.props.model,
        isLoading: true,
        customers: [],
        products: [],
        order: null,
    };

    private readonly _customersDropdownRef: React.RefObject<Dropdown<Customer>> = React.createRef();

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/orderManagement/listCustomers"));
    }

    private async setCustomerFavoriteAsync(customer: Customer, favorite: boolean): Promise<void> {
        customer.favorite = favorite;
        await AittaController.setFavoriteAsync(customer.id, favorite);
    }

    private async selectOrderStatusFilterAsync(item: SelectListItem | null): Promise<void> {
        this.model.orderStatusFilter = (item) ? parseInt(item.value) : null;
        await this.processAsync(true);
    }

    private async selectProductOrderFilterAsync(productOrderFilter: ProductOrderFilter): Promise<void> {
        this.model.productOrderFilter = productOrderFilter;
        await this.processAsync(true);
    }

    private async reLoadOrderAsync(): Promise<void> {
        if ((this.model.customer) && ((this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder))) {
            const request = new GetOpenOrderRequest();
            request.customerId = this.model.customer.id;
            request.express = (this.type == OrderPanelType.ExpressOrder);
            request.includeProductsDetails = true;

            const order: Order = await this.postAsync("/api/orderManagement/getOpenOrder", request);

            await this.setState({order});
        }
    }

    private async selectCustomerAsync(customer: Customer, userInteraction: boolean): Promise<void> {
        this.model.customer = customer;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customer);
        }

        await this.reLoadOrderAsync();

        await this.processAsync(true);
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        
        await this.setState({model: this.state.model});
        
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this, this.state.model);
        }
    }

    private async invokeSubmitOrderAsync(express: boolean): Promise<void> {
        if (this.props.onSubmit) {
            await this.props.onSubmit(this, this.products, express);
        }
    }

    private async clearAsync(): Promise<void> {
        if (this.props.onClear) {
            await this.props.onClear(this);
        }
    }

    private async sendAsync(close: boolean): Promise<void> {
        if ((this.props.send) && (this.order)) {
            await this.props.send(this, this.order, close);
        }
    }

    public async downloadCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {
        if ((this.props.downloadOrderCsv) && (this.order)) {
            await this.props.downloadOrderCsv(this, this.order, encodingType);
        } else if (this.props.downloadProductsCsv) {
            await this.props.downloadProductsCsv(this, encodingType);
        }
    }

    private async assumeAsync(): Promise<void> {
        if (this.props.onAssume) {
            await this.props.onAssume(this);
        }
    }

    private get hasMasterAccess(): boolean {
        const user: User = ch.getUser();
        return (user.isMaster || user.isAdmin || user.isSiteAdmin);
    }

    private get isShelverPartner(): boolean {
        const user: User = ch.getUser();
        return user.isShelverPartner;
    }

    private get canCloseOrSendOrder(): boolean {
        return (
            (!this.isLoading) && (this.productOrderFilter == ProductOrderFilter.Order) &&
            (!this.search) && (this.products.length > 0)
        );
    }
    
    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }

    private get customers(): Customer[] {
        return this.state.customers;
    }

    public get type(): OrderPanelType {
        return this.props.type;
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get model(): OrdersToolbarModel {
        return this.state.model;
    }

    public get products(): OrderProduct[] {
        return this.state.products;
    }

    public get customer(): Customer | null {
        return this.model.customer;
    }

    public get order(): Order | null {
        return this.state.order;
    }

    public get orderStatusFilter(): OrderStatus | null {
        return this.model.orderStatusFilter;
    }

    public get productOrderFilter(): ProductOrderFilter {
        return OrdersToolbarModel.getProductOrderFilter(this.model, this.type);
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public get isSubscription(): boolean {
        return this.customer?.serviceType == CustomerServiceType.Subscription;
    }

    public hasSpinner(): boolean {
        return true;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const customers: Customer[] = await this.fetchCustomersAsync();

        this.state.customers = customers;
        this.state.model.customer = AittaController.getDefaultCustomer(customers);

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public async setProductsAsync(products: OrderProduct[]): Promise<void> {
        await this.setState({products});
    }
    
    public async reloadAsync(): Promise<void> {
        await this.reLoadOrderAsync();
    }
    
    public renderOrderTitle(order: Order): React.ReactNode {
        const weekend: DayOfWeek[] = [DayOfWeek.Saturday, DayOfWeek.Sunday];
        const express: boolean = (order.express);
        const hasCloseDate: boolean = (!express) && (order.plannedCloseDate != null);
        const hasCloseDateHoliday: boolean = (hasCloseDate) && (order.plannedCloseDate != null) && ((!!order.plannedCloseMark) || (weekend.includes(order.plannedCloseDate.getDay())));
        const hasDeliveryDate: boolean = (!express) && (order.plannedDeliveryDate != null);
        const hasDeliveryDateHoliday: boolean = (hasCloseDate) && (order.plannedDeliveryDate != null) && ((!!order.plannedDeliveryMark) || (weekend.includes(order.plannedDeliveryDate.getDay())));
        const hasDates: boolean = ((hasCloseDate) || (hasDeliveryDate));
        const separator: boolean = (hasCloseDate) && (hasDeliveryDate);
        
        return (
            <div className={styles.orderTitle}>

                <span>{Localizer.ordersToolbarOrderNumber.format(order.number)}</span>

                {
                    (hasDates)
                        ? (
                            <React.Fragment>

                                <span> (</span>

                                {
                                    (hasCloseDate) &&
                                    (
                                        <span className={this.css(hasCloseDateHoliday && styles.warning)}>{"{0:D} {0:ddd}".format(order.plannedCloseDate).toUpperCase()}</span>
                                    )
                                }

                                {
                                    (separator) &&
                                    (
                                        <span>/</span>
                                    )
                                }

                                {
                                    (hasDeliveryDate) &&
                                    (
                                        <span className={this.css(hasDeliveryDateHoliday && styles.warning)}>{"{0:D} {0:ddd}".format(order.plannedDeliveryDate).toUpperCase()}</span>
                                    )
                                }

                                <span>)</span>

                            </React.Fragment>
                        )
                        : (!express) && (
                            <span>
                               <span> / </span>  {Localizer.productsToolbarOrderClosesAtMidnight}
                            </span>
                        )
                }

            </div>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.ordersToolbar, this.props.className)}>

                <Form onSubmit={() => this.processAsync(true)}>

                    <ToolbarRow justify={JustifyContent.Start}>

                        <Dropdown id="customers" noWrap inline favorite filterFavorite clearButton noSubtext
                                  required={false}
                                  ref={this._customersDropdownRef}
                                  className={styles.customers}
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                  disabled={this.isLoading}
                                  width={AittaConstants.customersDropdownMinWidth}
                                  items={this.customers}
                                  selectedItem={this.customer}
                                  transform={(item: Customer) => TransformProvider.toCustomerListItem(item)}
                                  onChange={(sender, item: Customer, userInteraction: boolean) => this.selectCustomerAsync(item, userInteraction)}
                                  onFavoriteChange={(sender, item: Customer, favorite: boolean) => this.setCustomerFavoriteAsync(item, favorite)}
                        />

                        {
                            (this.type == OrderPanelType.OrdersHistory) &&
                            (
                                <Dropdown id="orderStatusFilter" noWrap inline
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          width={"25rem"}
                                          disabled={this.isLoading}
                                          items={EnumProvider.getOrderStatusItems()}
                                          selectedItem={this.orderStatusFilter}
                                          onChange={(sender, item: SelectListItem | null) => this.selectOrderStatusFilterAsync(item)}
                                />
                            )
                        }

                        <TextInput id="productSearch" inline clearButton
                                   width={"25rem"}
                                   readonly={this.isLoading}
                                   placeholder={Localizer.productsToolbarSearchPlaceholder}
                                   value={this.search}
                                   onChange={(sender, value: string) => this.setSearchAsync(value)}
                        />

                        <Button submit right
                                id={"search"}
                                disabled={this.isLoading}
                                label={Localizer.genericSearch}
                                icon={{name: "fas search"}}
                                type={ButtonType.Primary}
                        />

                    </ToolbarRow>

                    <ToolbarRow justify={JustifyContent.SpaceBetween} className={styles.productOrderFilter}>

                        <Inline className={styles.orderPanelType}>

                            {
                                ((!this.isSubscription) && (this.type == OrderPanelType.ProductsSelection)) &&
                                (
                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"productOrderFilterCustomerSelection"}
                                              label={Localizer.ordersToolbarCustomerLabel}
                                              title={Localizer.ordersToolbarCustomerTitle}
                                              value={this.productOrderFilter == ProductOrderFilter.CustomerSelection}
                                              onChange={() => this.selectProductOrderFilterAsync(ProductOrderFilter.CustomerSelection)}
                                    />
                                )
                            }

                            {
                                (!this.isShelverPartner) && (this.type == OrderPanelType.ProductsSelection) &&
                                (
                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"productOrderFilterCustomerGroupSelection"}
                                              label={Localizer.ordersToolbarContractLabel}
                                              title={Localizer.ordersToolbarContractTitle}
                                              value={this.productOrderFilter == ProductOrderFilter.CustomerGroupSelection}
                                              onChange={() => this.selectProductOrderFilterAsync(ProductOrderFilter.CustomerGroupSelection)}
                                    />
                                )
                            }

                            {
                                ((!this.isSubscription) && (this.type == OrderPanelType.ProductsSelection)) &&
                                (
                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"productOrderFilterAssumed"}
                                              label={Localizer.ordersToolbarAssumedLabel}
                                              title={Localizer.ordersToolbarAssumedTitle}
                                              value={this.productOrderFilter == ProductOrderFilter.Assumed}
                                              onChange={() => this.selectProductOrderFilterAsync(ProductOrderFilter.Assumed)}
                                    />
                                )
                            }

                            {
                                (this.type == OrderPanelType.ProductsSelection) &&
                                (
                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"productOrderFilterTop30"}
                                              label={Localizer.ordersToolbarTop30Label}
                                              title={Localizer.ordersToolbarTop30Title}
                                              value={this.productOrderFilter == ProductOrderFilter.Top30}
                                              onChange={() => this.selectProductOrderFilterAsync(ProductOrderFilter.Top30)}
                                    />
                                )
                            }

                            {
                                (this.type == OrderPanelType.ProductsSelection) &&
                                (
                                    <Checkbox inline
                                              inlineType={InlineType.Right}
                                              id={"productOrderFilterFavorites"}
                                              label={Localizer.ordersToolbarFavoritesLabel}
                                              title={Localizer.ordersToolbarFavoritesTitle}
                                              value={this.productOrderFilter == ProductOrderFilter.Favorites}
                                              onChange={() => this.selectProductOrderFilterAsync(ProductOrderFilter.Favorites)}
                                    />
                                )
                            }

                        </Inline>

                        {
                            (((this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder)) && (this.order)) &&
                            (
                                <Inline>
                                    {this.renderOrderTitle(this.order)}
                                </Inline>
                            )
                        }

                        <Inline className={styles.orderPanelType} justify={JustifyContent.End}>

                            {
                                ((this.productOrderFilter == ProductOrderFilter.Assumed) && (this.type == OrderPanelType.ProductsSelection)) &&
                                (
                                    <Button id={"assumeAll"}
                                            label={Localizer.ordersToolbarAssumedOrderAllLabel}
                                            type={ButtonType.Primary}
                                            icon={{name: "far fa-arrow-alt-right"}}
                                            confirm={Localizer.ordersToolbarAssumedOrderAllConfirmation}
                                            disabled={this.isLoading}
                                            onClick={() => this.assumeAsync()}
                                    />
                                )
                            }

                            {
                                ((this.type == OrderPanelType.Order) || (this.type == OrderPanelType.ExpressOrder) || (this.type == OrderPanelType.ProductsSelection)) &&
                                (
                                    <Button right
                                            id={"exportToCsv"}
                                            className={styles.button}
                                            label="Excel"
                                            icon={{name: "fa-file-download fas"}}
                                            type={ButtonType.Primary}
                                            disabled={(!this.canCloseOrSendOrder) && (this.type != OrderPanelType.ProductsSelection)}
                                            onClick={() => this.downloadCsvAsync()}
                                    >

                                        <Button.Action id={"utf8"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                                                       onClick={() => this.downloadCsvAsync(ExportEncodingType.Utf8)}
                                        />

                                        <Button.Action id={"unicode"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                                                       onClick={() => this.downloadCsvAsync(ExportEncodingType.Unicode)}
                                        />

                                        <Button.Action id={"latin1"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                                                       onClick={() => this.downloadCsvAsync(ExportEncodingType.Latin1)}
                                        />

                                        <Button.Action id={"openXml"}
                                                       title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                                                       onClick={() => this.downloadCsvAsync(ExportEncodingType.OpenXml)}
                                        />

                                    </Button>
                                )
                            }

                            {
                                ((this.type == OrderPanelType.Order) && (this.hasMasterAccess)) &&
                                (
                                    <Button id={"closeRegular"}
                                            label={Localizer.ordersToolbarCloseOrder.format(this.products.length)}
                                            icon={{name: "fal fa-lock", size: IconSize.Large}}
                                            type={ButtonType.Primary}
                                            disabled={!this.canCloseOrSendOrder}
                                            onClick={() => this.sendAsync(true)}
                                    />
                                )
                            }

                            {
                                ((this.type == OrderPanelType.ExpressOrder) || ((this.type == OrderPanelType.Order) && (this.hasMasterAccess))) &&
                                (
                                    <Button id={"closeExpresscloseExpress"}
                                            label={Localizer.ordersToolbarSendOrder.format(this.products.length)}
                                            icon={{name: "fal fa-share-square", size: IconSize.Large}}
                                            type={ButtonType.Primary}
                                            disabled={!this.canCloseOrSendOrder}
                                            onClick={() => this.sendAsync(false)}
                                    />
                                )
                            }

                            {
                                (this.type == OrderPanelType.ProductsSelection) &&
                                (
                                    <Button id={"submitRegular"}
                                            label={Localizer.ordersToolbarOrderAction.format(this.products.length)}
                                            icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                            disabled={this.isLoading || this.products.length == 0}
                                            onClick={() => this.invokeSubmitOrderAsync(false)}
                                    />
                                )
                            }

                            {
                                (this.type == OrderPanelType.ProductsSelection) &&
                                (
                                    <Button id={"submitExpress"}
                                            label={Localizer.ordersToolbarExpressOrderAction.format(this.products.length)}
                                            className={styles.addExpress}
                                            icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Danger}
                                            disabled={this.isLoading || this.products.length == 0}
                                            onClick={() => this.invokeSubmitOrderAsync(true)}
                                    />
                                )
                            }

                            <Button block
                                    id={"clear"}
                                    className={styles.clear}
                                    label={Localizer.ordersToolbarClearOrder.format(this.products.length)}
                                    icon={{name: "far fa-trash-alt", size: IconSize.Large}}
                                    type={ButtonType.Danger}
                                    disabled={this.products.length == 0}
                                    onClick={() => this.clearAsync()}
                            />

                        </Inline>

                    </ToolbarRow>

                </Form>

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}