import React from "react";
import {
    ApiProvider,
    Justify,
    LdData,
    PageRoute,
    PageRouteProvider,
    BasePageParameters,
    ch
} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Form, IconStyle, IStringInputModel, LdDataHeader, PageContainer, PageHeader, PageRow, PasswordInput, TextInput} from "@reapptor-apps/reapptor-react-components";
import LoginRequest from "../../models/server/requests/LoginRequest";
import { LoginResultStatus } from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import AittaController from "@/pages/AittaController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../localization/Localizer";

import styles from "./Login.module.scss";

export interface ILoginProps extends BasePageParameters {
}

interface ILoginState {
    applicationLdData: LdData | null;
}

export default class Login extends AnonymousPage<ILoginProps, ILoginState> {

    state: ILoginState = {
        applicationLdData: null,
    };

    private readonly _formRef: React.RefObject<any> = React.createRef();
    private readonly _usernameInput: IStringInputModel = { value: "" };
    private readonly _passwordInput: IStringInputModel = { value: "" };
     
    private async loginAsync(username: string, password: string): Promise<void> {
        const login = new LoginRequest(username, password);
        const loginResult: LoginResultStatus = await this.postAsync("/api/Application/Login", login);
        
        if (loginResult !== LoginResultStatus.Success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = EnumProvider.getLoginResultStatusName(loginResult);
                await form.setValidationErrorsAsync(validationError);
            }
        }

        GoogleAnalyticsHelper.login();
    }

    private async handleSubmitAsync(): Promise<void> {
        await this.loginAsync(this._usernameInput.value, this._passwordInput.value);
    }
    
    private async redirectToPasswordPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.forgotPasswordRoute);
    }

    private async onAzureClickAsync(): Promise<void> {
        GoogleAnalyticsHelper.login("office365");

        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => this.getAsync<string>("/api/Application/GetAzureSsoLogin"), true);
    }

    public getTitle(): string {
        return Localizer.topNavLogin;
    }

    public async initializeAsync(): Promise<void> {
        if (this.isAuthorized) {
            const route: PageRoute = await AittaController.getHomePageAsync();

            await PageRouteProvider.redirectAsync(route, true, true);

            return;
        }

        await super.initializeAsync();

        const pageUrl: string = ch.applicationUrl;

        const applicationLdData: LdData = await this.postAsync("/api/application/getApplicationLdData", pageUrl);

        await this.setState({applicationLdData});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={"/login"}
                />

                <BannerMessageAlert />
                
                <PageHeader title={Localizer.loginPageTitle} subtitle={Localizer.loginPageSubtitle} className={styles.header}  />
                
                <PageRow>
                    <div className="col-lg-6">
                        
                        <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>
                            
                            <TextInput id="username" label={Localizer.loginPageEmailInput} model={this._usernameInput} required />

                            <PasswordInput id="password" label={Localizer.loginPagePasswordInput} model={this._passwordInput} required />
                            
                            <ButtonContainer>
                                
                                <Button id={"login"} type={ButtonType.Orange} label={Localizer.loginPageLoginButton} submit />
                                
                                {/*{ */}
                                {/*    (!this.mobileApp) && */}
                                {/*    (*/}
                                {/*        <Button id={"azureLogin"}*/}
                                {/*                type={ButtonType.Primary} */}
                                {/*                icon={{ name: "windows", style: IconStyle.Brands }} */}
                                {/*                label={Localizer.loginPageSsoButton} */}
                                {/*                onClick={async () => await this.onAzureClickAsync()} */}
                                {/*        />*/}
                                {/*    )*/}
                                {/*}*/}
                                
                                <Button id={"forgotPassword"}
                                        type={ButtonType.Link} 
                                        label={Localizer.loginPageForgotPasswordButton}
                                        icon={{name:"fas arrow-right"}}
                                        iconPosition={Justify.Right}
                                        onClick={() => this.redirectToPasswordPage()}  
                                />
                                
                            </ButtonContainer>
                            
                        </Form>
                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}