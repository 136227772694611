import React from "react";
import {BaseComponent, UserInteractionDataStorage, DataStorageType, ch} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, IconSize, Modal, QrWidget, QrWidgetHighResolutionType, QrWidgetType} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";

import styles from "./ScanQrCodeModal.module.scss";

const MinZoom: number = 1.0;
const MaxZoom: number = 10.0;
const ZoomStep: number = 0.5;

interface IScanQrCodeModalModalProps {
    title?: string;
    onQr(sender: ScanQrCodeModal, code: string): Promise<void>;
}

interface IScanQrCodeModalModalState {
    zoom: number;
}

export default class ScanQrCodeModal extends BaseComponent<IScanQrCodeModalModalProps, IScanQrCodeModalModalState> {

    state: IScanQrCodeModalModalState = {
        zoom: UserInteractionDataStorage.get("ScanQrCodeModal.zoom", 1.0, DataStorageType.Session),
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _qrWidget: React.RefObject<QrWidget> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get zoom(): number {
        return this.state.zoom;
    }

    private get debug(): boolean {
        return (ch.findUser()?.username == "andrey.popov@weare.fi");
    }

    // private get isAdmin(): boolean {
    //     return AittaController.user.isAdmin;
    // }

    private async onQrAsync(code: string): Promise<void> {
        if ((this.isMounted) && (this.isOpen)) {
            await this.props.onQr(this, code);
        }
    }

    private async setScaleAsync(step: number): Promise<void> {
        let zoom: number = this.state.zoom + step;
        if (zoom < MinZoom) {
            zoom = MinZoom;
        } else if (zoom > MaxZoom) {
            zoom = MaxZoom;
        }
        UserInteractionDataStorage.set("ScanQrCodeModal.zoom", zoom, DataStorageType.Session);
        await this.setState({zoom});
    }

    public async openAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.openAsync();
        }
    }

    public async onOpenModalAsync(): Promise<void> {
        if (this._qrWidget.current) {
            await this._qrWidget.current.showContentAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
        await this.reRenderAsync();
    }
    
    public get isOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current.isOpen));
    }

    public render(): React.ReactNode {
        return (
            <Modal className={this.css(styles.scanQrCodeModal, this.mobile && styles.mobile)}
                   bodyClassName={this.css(styles.body)}
                   id={"qrModal"}
                   mobileIcon={"far fa-arrow-alt-circle-left"}
                   ref={this._modalRef}
                   title={this.props.title ?? Localizer.mobileCatalogScanQrModalTitle}
                   onOpen={() => this.onOpenModalAsync()}
            >

                <div className={styles.container}>

                    <QrWidget wide stretchContent noAutoCollapse
                              ref={this._qrWidget}
                              id={"qr"}
                              className={styles.qrWidget}
                              highResolution={QrWidgetHighResolutionType.FullHD}
                              type={QrWidgetType.QrCode}
                              zoom={this.zoom}
                              debug={this.debug}
                              viewFinder={50}
                              onQr={(_, code: string) => this.onQrAsync(code)}
                    />

                    <div className={styles.buttons}>

                        <Button id={"setScale"}
                                type={ButtonType.Blue}
                                icon={{name: "far fa-search-minus", size: IconSize.X2}}
                                disabled={(this.zoom <= MinZoom)}
                                onClick={() => this.setScaleAsync(-ZoomStep)}
                        />

                        <span className={styles.scale}>X{this.zoom.toFixed(1)}</span>

                        <Button type={ButtonType.Blue}
                                icon={{name: "far fa-search-plus", size: IconSize.X2}}
                                disabled={(this.zoom >= MaxZoom)}
                                onClick={() => this.setScaleAsync(+ZoomStep)}
                        />

                    </div>

                </div>
                
            </Modal>
        )
    }
}