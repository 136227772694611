import React from "react";
import {BasePageParameters, ch, LdData} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import User from "../../models/server/User";
import {Button, ButtonContainer, ButtonType, EmailInput, Form, IStringInputModel, LdDataHeader, PageContainer, PageHeader, PageRow, TextAreaInput, TextInput} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

export interface IContactSupportParameters extends BasePageParameters {
    requestId: string | null;
}

interface IContactSupportState {
    applicationLdData: LdData | null;
}

export default class ContactSupport extends AnonymousPage<IContactSupportParameters, IContactSupportState> {

    state: IContactSupportState = {
        applicationLdData: null,
    };
    
    public email: IStringInputModel = { value: "" };
    public phone: IStringInputModel = { value: "" };
    public firstname: IStringInputModel = { value: "" };
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();
     
    public async initializeAsync(): Promise<void> {
        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstname.value = user.firstname || "";
            this.lastname.value = user.lastName || "";
        }

        const pageUrl: string = ch.applicationUrl;

        const applicationLdData: LdData = await this.postAsync("/api/application/getApplicationLdData", pageUrl);

        await this.setState({applicationLdData});
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const contactSupportRequest = {} as any;
        data.keys().map(key => {
            return contactSupportRequest[key] = data.getValue(key);
        });
        
        await this.postAsync("/api/ContactSupport/ContactSupport", contactSupportRequest);
    }

    public get requestId(): string | null {
        return (this.props.parameters != null) ? this.props.parameters.requestId : null;
    }

    public getTitle(): string {
        return Localizer.topNavContactSupport;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={"/support"}
                />

                <PageHeader title={Localizer.contactSupportPageTitle} />
                
                <PageRow>
                    <div className="col-lg-12">
                        <Form id="form" ref={this.formRef} onSubmit={(_, data) => this.handleSubmitAsync(data)}>
                            
                            <EmailInput id="email" required
                                        autoFocus={!this.email.value}
                                        label={Localizer.formInputEmail}
                                        readonly={!!this.email.value}
                                        model={this.email}
                            />
                            
                            <TextInput id="phone"
                                       autoFocus={!!this.email.value}
                                       label={Localizer.formInputPhone}
                                       model={this.phone}
                            />
                            
                            <TextInput id="firstname"
                                       label={Localizer.formInputFirstname}
                                       model={this.firstname}
                            />
                            
                            <TextInput id="lastname"
                                       label={Localizer.formInputLastname}
                                       model={this.lastname}
                            />
                            
                            <TextAreaInput id="message" required
                                           rows={6}
                                           label={Localizer.formInputMessage}
                                           model={this.message}
                            />
                            
                            <ButtonContainer>
                                <Button id={"submitContactSupport"}
                                        submit
                                        type={ButtonType.Orange}
                                        label={Localizer.formSend}
                                />
                            </ButtonContainer>
                            
                        </Form>
                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}