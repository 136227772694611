// autogenerated

export default class ExportOrderToXmlRequest {

    public orderId: string = "";

    public readonly isExportOrderToXmlRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ExportOrderToXmlRequest) || ((from as any).isExportOrderToXmlRequest === true));
    }

    public static as(from?: object | null): ExportOrderToXmlRequest | null {
        return (ExportOrderToXmlRequest.is(from)) ? from as ExportOrderToXmlRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}