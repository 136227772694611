// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListMobileCustomersRequest extends ETagRequest {

    public readonly isListMobileCustomersRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListMobileCustomersRequest) || ((from as any).isListMobileCustomersRequest === true));
    }

    public static as(from?: object | null): ListMobileCustomersRequest | null {
        return (ListMobileCustomersRequest.is(from)) ? from as ListMobileCustomersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}