// autogenerated
import BaseReport from "@/models/server/BaseReport";
import RevenueChartDataItem from "@/models/server/RevenueChartDataItem";

export default class RevenueReport extends BaseReport {

    public owner: string | null = null;

    public readonly isRevenueReport: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof RevenueReport) || ((from as any).isRevenueReport === true));
    }

    public static as(from?: object | null): RevenueReport | null {
        return (RevenueReport.is(from)) ? from as RevenueReport : null;
    }

    // #region Placeholder

    public static toAnnualRevenueReportChartData(revenueReport: RevenueReport): RevenueChartDataItem[] {
        const chartData: RevenueChartDataItem[] = [];

        const yearMinus3: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getYearMinus3Name, revenueReport.yearMinus3);
        chartData.push(yearMinus3);

        const yearMinus2: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getYearMinus2Name, revenueReport.yearMinus2);
        chartData.push(yearMinus2);

        const yearMinus1: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getYearMinus1Name, revenueReport.yearMinus1);
        chartData.push(yearMinus1);

        const yearMinus0: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getYearMinus0Name, revenueReport.yearMinus0);
        chartData.push(yearMinus0);

        return chartData;
    }

    public static toQuarterlyRevenueReportChartData(revenueReport: RevenueReport): RevenueChartDataItem[] {
        const chartData: RevenueChartDataItem[] = [];

        const quarterMinus3: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getQuarterMinus3Name, revenueReport.quarterMinus3);
        chartData.push(quarterMinus3);

        const quarterMinus2: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getQuarterMinus2Name, revenueReport.quarterMinus2);
        chartData.push(quarterMinus2);

        const quarterMinus1: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getQuarterMinus1Name, revenueReport.quarterMinus1);
        chartData.push(quarterMinus1);

        const quarterMinus0: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getQuarterMinus0Name, revenueReport.quarterMinus0);
        chartData.push(quarterMinus0);

        return chartData;
    }

    public static toMonthlyRevenueReportChartData(revenueReport: RevenueReport): RevenueChartDataItem[] {
        const chartData: RevenueChartDataItem[] = [];

        const monthlyMinus11: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus11Name, revenueReport.monthMinus11);
        chartData.push(monthlyMinus11);

        const monthlyMinus10: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus10Name, revenueReport.monthMinus10);
        chartData.push(monthlyMinus10);

        const monthlyMinus9: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus9Name, revenueReport.monthMinus9);
        chartData.push(monthlyMinus9);

        const monthlyMinus8: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus8Name, revenueReport.monthMinus8);
        chartData.push(monthlyMinus8);

        const monthlyMinus7: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus7Name, revenueReport.monthMinus7);
        chartData.push(monthlyMinus7);

        const monthlyMinus6: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus6Name, revenueReport.monthMinus6);
        chartData.push(monthlyMinus6);

        const monthlyMinus5: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus5Name, revenueReport.monthMinus5);
        chartData.push(monthlyMinus5);

        const monthlyMinus4: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus4Name, revenueReport.monthMinus4);
        chartData.push(monthlyMinus4);

        const monthlyMinus3: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus3Name, revenueReport.monthMinus3);
        chartData.push(monthlyMinus3);

        const monthlyMinus2: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus2Name, revenueReport.monthMinus2);
        chartData.push(monthlyMinus2);

        const monthlyMinus1: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus1Name, revenueReport.monthMinus1);
        chartData.push(monthlyMinus1);

        const monthlyMinus0: RevenueChartDataItem = BaseReport.toRevenueReportChartDataItem(this.getMonthMinus0Name, revenueReport.monthMinus0);
        chartData.push(monthlyMinus0);

        return chartData;
    }

    // #endregion
}