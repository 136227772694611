// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductOrderFilter, ExportEncodingType} from "@/models/Enums";

export default class ExportOrderProductsToCsvRequest {

    public customerGroupId: string = "";

    public customerId: string = "";

    public productOrderFilter: ProductOrderFilter = ProductOrderFilter.CustomerSelection;

    public search: string | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportOrderProductsToCsvRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ExportOrderProductsToCsvRequest) || ((from as any).isExportOrderProductsToCsvRequest === true));
    }

    public static as(from?: object | null): ExportOrderProductsToCsvRequest | null {
        return (ExportOrderProductsToCsvRequest.is(from)) ? from as ExportOrderProductsToCsvRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}