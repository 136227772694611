// autogenerated
import Product from "@/models/server/Product";

export default class ProductAssortmentMobileInfo {

    public id: string = "";

    public productId: string = "";

    public product: Product | null = null;

    public customerId: string | null = null;

    public orderThreshold: number = 0;

    public orderQuantity: number = 0;

    public favorite: boolean = false;

    public hasOpenLabel: boolean = false;

    public deleted: boolean = false;

    public fromProductReplacementId: string | null = null;

    public toProductReplacementIds: string[] | null = null;

    public readonly isProductAssortmentMobileInfo: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ProductAssortmentMobileInfo) || ((from as any).isProductAssortmentMobileInfo === true));
    }

    public static as(from?: object | null): ProductAssortmentMobileInfo | null {
        return (ProductAssortmentMobileInfo.is(from)) ? from as ProductAssortmentMobileInfo : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}