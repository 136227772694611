// autogenerated
import {ProductReplacementType, ProductReplacementLevel} from "@/models/Enums";
import Customer from "@/models/server/Customer";

export default class ProductReplacementInfo {

    public id: string = "";

    public customerGroupName: string | null = null;

    public customer: Customer | null = null;

    public fromProductName: string | null = null;

    public fromProductCode: string | null = null;

    public toProductName: string | null = null;

    public toProductCode: string | null = null;

    public type: ProductReplacementType = ProductReplacementType.Temporary;

    public createdByName: string | null = null;

    public createdAt: Date = new Date();

    public generateLabels: boolean = false;

    public level: ProductReplacementLevel = ProductReplacementLevel.Global;

    public deleted: boolean = false;

    public obsolete: boolean = false;

    public readonly isProductReplacementInfo: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ProductReplacementInfo) || ((from as any).isProductReplacementInfo === true));
    }

    public static as(from?: object | null): ProductReplacementInfo | null {
        return (ProductReplacementInfo.is(from)) ? from as ProductReplacementInfo : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}