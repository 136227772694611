import React from "react";
import CatalogItem from "@/components/Catalog/CatalogItem/CatalogItem";
import ProductGroupMobileInfo from "@/models/server/ProductGroupMobileInfo";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

import styles from "./ProductGroupItem.module.scss";

export interface ICProductGroupItemProps {
    group: ProductGroupMobileInfo;
    className?: string;

    onClick?(): Promise<void>
}

interface IProductGroupItemState {
    productGroup: ProductGroupMobileInfo;
}

export default class ProductGroupItem extends BaseComponent<ICProductGroupItemProps, IProductGroupItemState> {

    public state: IProductGroupItemState = {
        productGroup: ProductGroupItem.cloneProductGroup(this.props.group),
    }

    // Getters

    private get productGroup(): ProductGroupMobileInfo {
        return this.state.productGroup;
    }

    private get imageOrIcon(): FileModel | string | null {
        return (ProductGroupMobileInfo.isFavorite(this.productGroup) || ProductGroupMobileInfo.isAssumed(this.productGroup) || ProductGroupMobileInfo.isTop30(this.productGroup))
            ? this.productGroup.icon
            : this.productGroup.imageId;
    }

    // Sync-methods

    private static cloneProductGroup(group: ProductGroupMobileInfo): ProductGroupMobileInfo {
        const newProductGroup: ProductGroupMobileInfo = new ProductGroupMobileInfo();

        newProductGroup.icon = group.icon;
        newProductGroup.id = group.id;
        newProductGroup.name = group.name;
        newProductGroup.parent = group.parent;  // NOT CLONED!!
        newProductGroup.parentId = group.parentId;
        newProductGroup.imageId = group.imageId;

        return newProductGroup;
    }

    // Async-methods

    private async onClickAsync(): Promise<void> {
        (this.props.onClick) && await this.props.onClick();
    }

    public render(): React.ReactNode {
        const className: string = this.css(styles.productGroupItem, this.props.className);

        return (

            <CatalogItem className={className}
                         imageOrIcon={this.imageOrIcon}
                         onClick={() => this.onClickAsync()}
            >

                <div className={styles.content}>
                    <span>
                        {this.productGroup.name}
                    </span>
                </div>

            </CatalogItem>
        );
    }
};