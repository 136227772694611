import React from "react";
import {ICatalogDataProvider} from "@/providers/CatalogDataProvider";
import Catalog, {CatalogMode} from "../Catalog";
import {Icon, IconSize, Modal} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import OrderProductItem from "@/components/Catalog/OrderProductItem/OrderProductItem";
import Localizer from "@/localization/Localizer";

import styles from "./CatalogModal.module.scss";

interface ICatalogModalProps {
    express: boolean;
    dataProvider: ICatalogDataProvider;

    onChange?(sender: OrderProductItem, item: OrderProductMobileInfo): Promise<void>;
    
    onClose?(sender: CatalogModal, scanNext: boolean): Promise<void>;
    
    onFavoriteChange?(sender: CatalogModal, productId: string, favorite: boolean): Promise<void>;
}

interface ICatalogModalState {
    showQrScanResultButtons: boolean;
}

export default class CatalogModal extends BaseComponent<ICatalogModalProps, ICatalogModalState> {
    state: ICatalogModalState = {
        showQrScanResultButtons: false
    };
    
    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _categoriesOrProductsRef: React.RefObject<Catalog> = React.createRef();

    private async onChangeItemAsync(sender: OrderProductItem, item: OrderProductMobileInfo): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(sender, item);
        }
    }

    private async onResetSearchAsync(): Promise<void> {
        await this.setState({showQrScanResultButtons: false});
    }
    
    private async onFavoriteChangeAsync(productId: string, favorite: boolean): Promise<void> {
        if (this.props.onFavoriteChange) {
            await this.props.onFavoriteChange(this, productId, favorite);
        }
    }

    public async openAsync(animation: boolean = true, showQrScanResultButtons: boolean): Promise<void> {
        await this.setState({showQrScanResultButtons});
        if (this._modalRef.current) {
            await this._modalRef.current.openAsync(animation);
        }
    }

    public async reloadAsync(search: string | null = null, productGroupId: string | null = null, exactMatch: boolean = false, push: boolean = true): Promise<void> {
        if (this._categoriesOrProductsRef.current) {
            await this._categoriesOrProductsRef.current.reloadAsync(search, productGroupId, exactMatch, push);
        }
    }

    public async closeAsync(scanNext: boolean = false): Promise<void> {
        await this._categoriesOrProductsRef.current?.resetSearchAsync();
        
        if (this.props.onClose) {
            await this.props.onClose(this, scanNext);
        }
        
        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }
        await this.reRenderAsync();
    }

    public static get modalId(): string {
        return "categoriesOrProductsModal";
    }

    public get isOpen(): boolean {
        return ((this._modalRef.current != null) && (this._modalRef.current.isOpen));
    }

    public renderQrScanResultButtons(): React.ReactNode {
        return (
            <React.Fragment>

                <div className={styles.expander}/>

                <div className={styles.buttonsContainer}>

                    <div className={styles.add}
                         onClick={() => this.closeAsync(true)}
                    >
                        <Icon name={"far fa-camera"} size={IconSize.Large}/>
                        <span>{Localizer.mobileCatalogModalScanAgain}</span>
                    </div>

                    <div className={styles.add}
                         onClick={() => this.closeAsync()}
                    >

                        <Icon name={"far check"} size={IconSize.Large}/>
                        <span>{Localizer.mobileCatalogModalDone}</span>
                    </div>
                    
                </div>

            </React.Fragment>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <Modal className={this.css(styles.catalogModal, this.mobile && styles.mobile)}
                   id={CatalogModal.modalId}
                   mobileIcon={"far fa-arrow-alt-circle-left"}
                   ref={this._modalRef}
                   title={Localizer.mobileCatalogModalChooseProducts}
            >
                
                <Catalog ref={this._categoriesOrProductsRef}
                         express={this.props.express}
                         dataProvider={this.props.dataProvider}
                         mode={CatalogMode.AddProduct}
                         onResetSearch={(_: Catalog) => this.onResetSearchAsync()}
                         onChange={(sender: OrderProductItem, item) => this.onChangeItemAsync(sender, item)}
                         onFavoriteChange={(sender, item, favorite) => this.onFavoriteChangeAsync(item, favorite)}
                />

                {
                    (this.state.showQrScanResultButtons) &&
                    (
                        this.renderQrScanResultButtons()
                    )
                }

            </Modal>
        )
    }
}