import React from "react";
import CustomerGroup from "@/models/server/CustomerGroup";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Form, IconSize, Modal, OneColumn, TextInput} from "@reapptor-apps/reapptor-react-components";
import AittaConstants from "@/helpers/AittaConstants";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerGroupModal.module.scss";

interface ICustomerGroupModalProps {
    nameUniquenessValidator?(customerGroupId: string, name: string): string;
    submit?(sender: CustomerGroupModal, customerGroup: CustomerGroup): Promise<void>;
}

interface ICustomerGroupModalState {
    customerGroup: CustomerGroup;
    name: string;
}

export default class CustomerGroupModal extends BaseComponent<ICustomerGroupModalProps, ICustomerGroupModalState> {

    state: ICustomerGroupModalState = {
        customerGroup: new CustomerGroup(),
        name: "",
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _nameRef: React.RefObject<TextInput> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get customerGroup(): CustomerGroup {
        return this.state.customerGroup;
    }

    private get name(): string {
        return this.state.name;
    }

    private get isNew(): boolean {
        return !this.customerGroup.id;
    }

    private get title(): string {
        return (this.isNew)
            ? Localizer.customerGroupModalAddNewTitle
            : Localizer.customerGroupModalEditTitle;
    }
    
    private nameUniquenessValidator(): string {
        return (this.props.nameUniquenessValidator)
            ? this.props.nameUniquenessValidator(this.customerGroup.id, this.name)
            : "";
    }

    private async onOpenAsync(): Promise<void> {
        this._nameRef.current?.showEditAsync(true);
    }

    private async onSubmitAsync(): Promise<void> {
        this.customerGroup.name = this.name;

        if (this.props.submit) {
            await this.props.submit(this, this.customerGroup);
        }
        
        await this.closeAsync();
    }

    public async setNameAsync(value: string): Promise<void> {
        await this.setState({ name: value });
    }

    public async onBlurNameAsync(): Promise<void> {
        await this.setState({ name: this.name.trim() });
    }

    public async openAsync(customerGroup: CustomerGroup, animation: boolean = true): Promise<void> {
        
        await this.setState({ customerGroup, name: customerGroup.name });
        
        if (this.modal) {
            await this.modal.openAsync(animation);
        }
    }

    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
        
        await this.reRenderAsync();
    }

    public get isOpen(): boolean {
        return ((this.modal != null) && (this.modal.isOpen));
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   ref={this._modalRef}
                   className={styles.customerGroupModal}
                   title={this.title}
                   onOpen={() => this.onOpenAsync()}
            >

                <div className="row">

                    <div className="col">

                        <Form ref={this._formRef} onSubmit={() => this.onSubmitAsync()}>

                            <OneColumn>

                                <TextInput required autoFocus noAutoComplete
                                           id="name"
                                           ref={this._nameRef}
                                           maxLength={AittaConstants.keyLength}
                                           label={Localizer.genericName}
                                           value={this.name}
                                           validators={[() => this.nameUniquenessValidator()]}
                                           onChange={(sender, value) => this.setNameAsync(value)}
                                           onBlur={(_) => this.onBlurNameAsync()}
                                />

                            </OneColumn>

                            <ButtonContainer>

                                <Button id={"cancel"}
                                        className={styles.buttons}
                                        type={ButtonType.Light}
                                        icon={{name: "far ban", size: IconSize.Large}}
                                        label={Localizer.genericCancel}
                                        onClick={() => this.closeAsync()}
                                />

                                <Button id={"submit"}
                                        submit right
                                        className={this.css(styles.buttons, "float-right")}
                                        type={ButtonType.Orange}
                                        icon={{name: "save", size: IconSize.Large}}
                                        label={Localizer.genericSave}
                                />

                            </ButtonContainer>

                        </Form>

                    </div>

                </div>

            </Modal>
        )
    }
}