// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductReplacementLevel} from "@/models/Enums";

export default class ListProductReplacementsRequest {

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public replacementLevel: ProductReplacementLevel = ProductReplacementLevel.Global;

    public showHistory: boolean = false;

    public showAll: boolean = false;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListProductReplacementsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListProductReplacementsRequest) || ((from as any).isListProductReplacementsRequest === true));
    }

    public static as(from?: object | null): ListProductReplacementsRequest | null {
        return (ListProductReplacementsRequest.is(from)) ? from as ListProductReplacementsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}