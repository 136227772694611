// autogenerated
import {ProductLabelType} from "@/models/Enums";

export default class CreateProductLabelsRequest {

    public productAssortmentIds: string[] | null = null;

    public type: ProductLabelType = ProductLabelType.Manual;

    public comment: string | null = null;

    public readonly isCreateProductLabelsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CreateProductLabelsRequest) || ((from as any).isCreateProductLabelsRequest === true));
    }

    public static as(from?: object | null): CreateProductLabelsRequest | null {
        return (CreateProductLabelsRequest.is(from)) ? from as CreateProductLabelsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}