// autogenerated
import {CalendarDateMark} from "@/models/Enums";

export default class CustomerCalendarMark {

    public mark: CalendarDateMark = CalendarDateMark.None;

    public name: string | null = null;

    public nativeName: string | null = null;

    public orderNumber: number | null = null;

    public expressOrder: boolean | null = null;

    public readonly isCustomerCalendarMark: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CustomerCalendarMark) || ((from as any).isCustomerCalendarMark === true));
    }

    public static as(from?: object | null): CustomerCalendarMark | null {
        return (CustomerCalendarMark.is(from)) ? from as CustomerCalendarMark : null;
    }

    // #region Placeholder

    public static create(mark: CalendarDateMark): CustomerCalendarMark {
        const item = new CustomerCalendarMark();
        item.mark = mark;
        return item;
    }

    // #endregion
}