import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {
    ButtonType,
    Checkbox,
    DropdownOrderBy,
    Form,
    IconSize,
    Inline,
    List,
    OneColumn,
    Panel,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import CustomerCode from "@/models/server/CustomerCode";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerCodesPanel.module.scss";

interface ICustomerCodesPanelProps {
    customer: Customer;

    onChange(): Promise<void>
}

interface ICustomerCodesPanelState {
    selectedCode: CustomerCode | null;
}

export default class CustomerCodesPanel extends BaseComponent<ICustomerCodesPanelProps, ICustomerCodesPanelState> {

    state: ICustomerCodesPanelState = {
        selectedCode: this.codes.firstOrDefault(),
    };
    
    private readonly _codesRef: React.RefObject<List<CustomerCode>> = React.createRef();
    private readonly _codeNameTextInputRef: React.RefObject<TextInput> = React.createRef();

    private get customer(): Customer {
        return this.props.customer;
    }

    private get codes(): CustomerCode[] {
        return this.customer.codes ?? (this.customer.codes = []);
    }

    private async onChangeCodeAsync(selectedCode: CustomerCode): Promise<void> {
        await this.setState({selectedCode});
    }

    private async addCodeAsync(): Promise<void> {
        const newCode = new CustomerCode();
        newCode.code = Localizer.editCustomerPageCustomerCodesPanelNewCode;
        newCode.id = Utility.newGuid();

        Customer.addCode(this.customer, newCode);

        await this.setState({selectedCode: newCode});

        await this._codeNameTextInputRef.current?.showEditAsync(true);

        await this.props.onChange();
    }

    private async deleteCodeAsync(): Promise<void> {

        let selectedCode: CustomerCode | null = this.selectedCode;

        if (selectedCode) {

            const deletedIndex: number = Customer.deleteCode(this.customer, selectedCode);

            selectedCode = (this.codes.length > 0)
                ? (deletedIndex > 0)
                    ? this.codes[deletedIndex - 1]
                    : this.codes[0]
                : null;

            await this.setState({selectedCode});

            await this.props.onChange();
        }
    }

    private get selectedCode(): CustomerCode | null {
        return this.state.selectedCode;
    }

    private async onCodeNameChangeAsync(value: string): Promise<void> {
        if (this.selectedCode) {
            this.selectedCode.name = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    private async onCodeChangeAsync(value: string): Promise<void> {
        if (this.selectedCode) {
            this.selectedCode.code = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    private async onExpressChangeAsync(value: boolean): Promise<void> {
        if (this.selectedCode) {
            this.selectedCode.express = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    private async onIfsChangeAsync(value: boolean): Promise<void> {
        if (this.selectedCode) {
            this.selectedCode.ifs = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    public render(): React.ReactNode {
        return (
            <Panel className={styles.customerCodesPanel} title={Localizer.editCustomerPageCustomerCodesPanelTitle}>

                <div className="col d-flex flex-column">

                    <ToolbarContainer>

                        <Inline>

                            <ToolbarButton label={Localizer.genericAdd}
                                           icon={{name: "plus", size: IconSize.Large}}
                                           type={ButtonType.Primary}
                                           onClick={() => this.addCodeAsync()}
                            />

                            <ToolbarButton label={Localizer.genericDelete}
                                           icon={{name: "trash", size: IconSize.Large}}
                                           type={ButtonType.Primary}
                                           onClick={() => this.deleteCodeAsync()}
                            />

                        </Inline>

                    </ToolbarContainer>

                    <div className="row h-100">

                        <div className="col-md-6 pb-6">

                            <List required noGrouping absoluteListItems trackChanges
                                  id="codes"
                                  ref={this._codesRef}
                                  maxHeight={"auto"}
                                  orderBy={DropdownOrderBy.None}
                                  filterMinLength={10}
                                  noDataText={Localizer.editCustomerPageCustomerCodesPanelNoCodes}
                                  selectedItem={this.selectedCode}
                                  items={this.codes}
                                  onChange={(_, item) => this.onChangeCodeAsync(item!)}
                            />

                        </div>

                        <div className="col-md-6">

                            <Form>

                                <OneColumn>

                                    <TextInput noAutoComplete
                                               ref={this._codeNameTextInputRef}
                                               id="code"
                                               label={Localizer.editCustomerPageCustomerCodesPanelCode}
                                               readonly={!this.selectedCode}
                                               value={this.selectedCode?.code}
                                               onChange={(_, value) => this.onCodeChangeAsync(value)}
                                    />

                                    <TextInput noAutoComplete
                                               id="name"
                                               label={(this.selectedCode?.ifs == true) ? Localizer.editCustomerPageCustomerCodesPanelEanLocation : Localizer.genericName}
                                               readonly={!this.selectedCode}
                                               value={this.selectedCode?.name}
                                               onChange={(_, value) => this.onCodeNameChangeAsync(value)}
                                    />
                                    
                                    <TwoColumns>
                                        
                                        <Checkbox id="express"
                                                  label={Localizer.editCustomerPageCustomerCodesPanelExpress}
                                                  readonly={!this.selectedCode}
                                                  value={this.selectedCode?.express}
                                                  onChange={(_, value) => this.onExpressChangeAsync(value)}
                                        />

                                        <Checkbox id="ifs"
                                                  label={Localizer.editCustomerPageCustomerCodesPanelIfs}
                                                  readonly={!this.selectedCode}
                                                  value={this.selectedCode?.ifs}
                                                  onChange={(_, value) => this.onIfsChangeAsync(value)}
                                        />
                                        
                                    </TwoColumns>
                                    
                                </OneColumn>

                            </Form>

                        </div>

                    </div>

                </div>

            </Panel>
        );
    }
};