import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    ButtonType,
    PageContainer,
    PageHeader,
    PageRow, ToolbarButton
} from "@reapptor-apps/reapptor-react-components";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import MyCalendarToolbar from "@/pages/MyCalendar/MyCalendarToolbar/MyCalendarToolbar";
import MyCalendarToolbarModel from "@/pages/MyCalendar/MyCalendarToolbar/MyCalendarToolbarModel";
import Customer from "@/models/server/Customer";
import OrderCalendarPanel from "@/pages/EditCustomer/OrderCalendarPanel/OrderCalendarPanel";
import Localizer from "../../localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./MyCalendar.module.scss";


interface IMyCalendarProps {
}

interface IMyCalendarState {
    toolbar: MyCalendarToolbarModel;
}

export default class MyCalendar extends AuthorizedPage<IMyCalendarProps, IMyCalendarState> {

    state: IMyCalendarState = {
        toolbar: new MyCalendarToolbarModel(),
    };

    private async onToolbarSubmitAsync(toolbar: MyCalendarToolbarModel): Promise<void> {
        
        toolbar.customer = (toolbar.customer)
            ? await this.postAsync("/api/myCalendar/getCustomer", toolbar.customer.id)
            : null;
        
        await this.reRenderAsync();
    }

    private get toolbar(): MyCalendarToolbarModel {
        return this.state.toolbar;
    }
    
    private get customer(): Customer | null {
        return this.toolbar.customer;
    }

    public getTitle(): string {
        return Localizer.topNavMyCalendar;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.myCalendar} fullWidth fullHeight>
                
                <PageHeader title={this.getTitle()}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                    
                </PageHeader>

                <PageRow className={"mt-0"}>
                    <div className="col">

                        <div className={this.css(styles.panel)}>

                            <MyCalendarToolbar model={this.toolbar}
                                               className={styles.toolbar}
                                               hideCustomersInSearch={(AittaController.user.isMaster)}
                                               onChange={(toolbar) => this.onToolbarSubmitAsync(toolbar)}
                            />

                            {
                                (this.customer) &&
                                (
                                    <OrderCalendarPanel readonly
                                                        customer={this.customer}
                                    />
                                )
                            }

                        </div>

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}