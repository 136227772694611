import React from "react";
import {GeoLocation, ILanguage, TimeSpan, Utility} from "@reapptor-apps/reapptor-toolkit";
import {AlertModel, AlertType, ch, PageRouteProvider, TextAlign, VerticalAlign} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import UserRole from "../../models/server/UserRole";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import Customer from "../../models/server/Customer";
import {AuthType, LoginResultStatus} from "@/models/Enums";
import DeleteUserResponse from "@/models/server/responses/DeleteUserResponse";
import UserInvitation from "@/models/server/UserInvitation";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import GetUsersRequest from "@/models/server/requests/GetUsersRequest";
import {
    AddressDivider,
    Alert,
    Button,
    ButtonContainer,
    ButtonType,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownOrderBy,
    DropdownSelectType,
    EmailInput,
    Form,
    Grid,
    IconSize,
    IIconProps,
    Inline,
    List,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    SelectListItem,
    Tab,
    TabContainer,
    TabModel,
    TabRenderType,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import UserRolesPanel from "@/pages/UserManagement/UserRolesPanel/UserRolesPanel";
import AittaConstants from "@/helpers/AittaConstants";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../localization/Localizer";

import styles from "./UserManagement.module.scss";

interface IUserManagementProps {
    customers: Customer[] | null;
}

interface IUserManagementState {
    showDeleted: boolean;
    showExpired: boolean;
    filterRoleNames: string[];
    user: User | null;
    prevUser: User | null;
    roles: UserRole[];
    showAddButton: boolean;
    isModified: boolean;
    customers: Customer[];
    selectedTabIndex: number;
    readonly: boolean;
}

export default class UserManagement extends AuthorizedPage<IUserManagementProps, IUserManagementState> {

    state: IUserManagementState = {
        showDeleted: false,
        showExpired: false,
        filterRoleNames: [],
        user: null,
        prevUser: null,
        roles: [],
        showAddButton: true,
        isModified: false,
        customers: [],
        selectedTabIndex: 0,
        readonly: false
    };

    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    private readonly _listRef: React.RefObject<List<User>> = React.createRef();
    private readonly _emailRef: React.RefObject<EmailInput> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private _originalUser: User | null = null;

    private readonly _invitationColumns: ColumnDefinition[] = [
        {
            header: Localizer.adminGridCreatedByLanguageItemName,
            accessor: "createdBy",
            minWidth: 100
        } as ColumnDefinition,
        {
            header: Localizer.adminGridTypeLanguageItemName,
            accessor: "type",
            type: ColumnType.Custom,
            format: "InvitationType",
            minWidth: 90
        } as ColumnDefinition,
        {
            header: Localizer.adminGridAuthTypeLanguageItemName,
            accessor: "authType",
            type: ColumnType.Custom,
            format: "AuthType",
            minWidth: 90,
        } as ColumnDefinition,
        {
            header: Localizer.genericCreatedAtLanguageItemName,
            accessor: "createdAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
            verticalAlign: VerticalAlign.Middle
        } as ColumnDefinition,
        {
            header: Localizer.adminGridExpiresAtLanguageItemName,
            accessor: "validTill",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
            init: (cell) => this.initValidTill(cell)
        } as ColumnDefinition,
        {
            header: Localizer.adminGridProcessedAtLanguageItemName,
            accessor: "processedAt",
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90
        } as ColumnDefinition,
        {
            header: Localizer.adminGridReusableLanguageItemName,
            accessor: (model: UserInvitation) => model.reusable ? "✓" : "",
            textAlign: TextAlign.Center,
            minWidth: 90
        } as ColumnDefinition
    ];

    private initValidTill(cell: CellModel<UserInvitation>): void {
        const model: UserInvitation = cell.row.model;
        const diff: TimeSpan = Utility.diff(model.validTill, Utility.utcNow());
        const expired: boolean = (diff.totalMilliseconds < 0);
        if (expired) {
            cell.className = "danger";
        }
    }

    private async cancelModifyingAsync(): Promise<void> {
        Utility.copyTo(this._originalUser, this.user);
        await this.setIsModified(false);
    }

    private async setSelectedUser(selectedUser: User): Promise<void> {
        if (this.user !== selectedUser) {

            selectedUser = await this.postAsync("/api/admin/getUser", selectedUser.id);

            if (this.state.showAddButton) {
                await this.setState({user: selectedUser, prevUser: null, showAddButton: true});
            }

            this._originalUser = Utility.clone(selectedUser);
        }
    }

    private get userFullName(): string {
        return (this.user) ? TransformProvider.userToString(this.user) : "";
    }

    private async saveAsync(): Promise<void> {

        const user: User = this.user!;
        const newUser: boolean = (!user.id);
        const isValid: boolean = User.isValid(user);

        if (!isValid) {

            const isValidRole: boolean = User.isValidRole(user);

            if (!isValidRole) {
                await ch.flyoutErrorAsync(Localizer.adminAlertErrorCustomerRoleIsNeeded);
            }

            return;
        }

        const request = new SaveUserRequest();
        request.id = user.id;
        request.authType = user.authType;
        request.roles = user.roles;
        request.email = user.email;
        request.phone = user.phone;
        request.firstname = user.firstname;
        request.lastName = user.lastName;
        request.middleName = user.middleName;
        request.language = user.language;
        request.formattedAddress = (user.homeLocation) ? user.homeLocation.formattedAddress : "";
        request.agreementAccepted = user.agreementAccepted;
        request.registrationAccepted = user.registrationAccepted;
        request.hasDailyNotifications = user.hasDailyNotifications;
        request.hasGroupLevelReportsAccess = user.hasGroupLevelReportsAccess;

        const response: SaveUserResponse = await this.postAsync("/api/admin/saveUser", request);

        if (response.userAlreadyExists) {
            const message: string = (response.duplicatedPhoneNumber)
                ? Localizer.adminAlertErrorAccountWithSamePhoneExist
                : (response.duplicatedEmail)
                    ? Localizer.adminAlertErrorAccountWithSameEmailExist
                    : Utility.format(Localizer.adminAlertErrorAccountExist, this.userFullName);

            await this.alertErrorAsync(message, true);
            return;
        }

        if (response.invitationSentFailed) {
            const message: string = Utility.format(Localizer.adminAlertErrorPhoneNumberInvalid, this.userFullName);
            await this.alertErrorAsync(message, true);
            return;
        }

        const message: string = Utility.format(Localizer.adminAlertMessageAccountSaved, this.userFullName);
        await this.alertMessageAsync(message, true);

        const responseUser: User = response.user!;

        this._originalUser = responseUser!;

        if (newUser) {

            await this.list.reloadAsync();

            await this.setState({user: responseUser, showAddButton: true, isModified: false});

            this.list.scrollToSelected();

        } else {
            await this.setIsModified(false);

            await this.list.reRenderAsync();
        }
    }

    private async resetPasswordAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync<LoginResultStatus>("/api/admin/ResetPassword", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.list.reloadAsync();
    }

    private async setShowExpiredAsync(showExpired: boolean): Promise<void> {
        await this.setState({showExpired});
        await this.list.reloadAsync();
    }

    private async setFilterRoleNamesAsync(filterRoles: UserRole[]): Promise<void> {
        const filterRoleNames: string[] = filterRoles.map(item => item.roleName);
        await this.setState({filterRoleNames});
        await this.list.reloadAsync();
    }

    private async addUserAsync(): Promise<void> {
        const prevUser: User | null = this.user;

        const user = new User();
        const role = new UserRole(AittaConstants.managerRole);
        user.language = Localizer.language;
        user.role = role;
        user.roles.push(role);

        await this.setState({user, prevUser, showAddButton: false});

        const tabContainer: TabContainer | null = this._tabContainerRef.current;
        if (tabContainer) {
            await tabContainer.activateTabAsync(0);
        }

        if (this._emailRef.current) {
            this._emailRef.current.focus();
        }
    }

    private async resendInvitationAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync("/api/admin/resendInvitation", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    private async setIsModified(isModified = true, forceReRender: boolean = false): Promise<void> {
        isModified = isModified && this.state.showAddButton;
        if ((forceReRender) || (isModified !== this.state.isModified)) {
            await this.setState({isModified});
        }
    }

    private get isModified(): boolean {
        return this.state.isModified;
    }

    private async setPhone(value: string): Promise<void> {
        this.user!.phone = value;
        await this.setIsModified();
    }

    private async setEmail(value: string): Promise<void> {
        this.user!.email = value;
        await this.setIsModified();
    }

    private async setAuthType(item: SelectListItem, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            await this.setIsModified();
        }

        const user: User = this.user!;
        user.authType = parseInt(item.value);
        await this.reRenderAsync();
    }

    public getTitle(): string {
        return Localizer.topNavUserManagement;
    }

    public get alert(): AlertModel {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(Localizer.adminAlertUserPasswordInfo, this.userFullName);
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;
        return alertModel;
    }

    public async deleteUserAsync(): Promise<void> {
        const user: User = this.user!;

        const response: DeleteUserResponse = await this.postAsync("/api/admin/deleteUser", user.id);

        const message: string = (response.removedPermanently)
            ? Utility.format(Localizer.adminUserDeletedPermanently, this.userFullName)
            : Utility.format(Localizer.get(Localizer.adminUserDeleted, this.userFullName));

        await this.alertMessageAsync(message, true);

        if (!this.showDeleted || response.removedPermanently) {
            await this.setState({user: null});

            await this.list.reloadAsync();

            this.list.scrollToSelected();
        } else {
            user.isDeleted = true;

            await this.list.reRenderAsync();

            await this.reRenderAsync();
        }
    }

    private async restoreUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("/api/admin/restoreUser", user.id);

        user.isDeleted = false;

        await this.alertMessageAsync(Localizer.get(Localizer.adminUserRestored, user.email), true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async unlockUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("/api/admin/unlockUser", user.id);

        user.isLocked = false;

        const message: string = Utility.format(Localizer.adminAlertMessageUnlockUser, this.userFullName);

        await this.alertMessageAsync(message, true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async cancelAddUserAsync() {
        await this.setState({user: this.state.prevUser, prevUser: null, showAddButton: true});
    }

    private get canDelete(): boolean {
        const me: boolean = (this.me.id == this.user!.id);
        return !me;
    }

    private get me(): User {
        return ch.getUser();
    }

    private get readOnly(): boolean {
        return (this.user != null) && (!this.me.isSiteAdmin) && (this.user.isSiteAdmin);
    }

    private async getUsersAsync(sender: List<User>): Promise<User[]> {
        const request = new GetUsersRequest();
        request.showDeleted = this.showDeleted;
        request.showExpired = this.showExpired;
        request.roleNames = this.filterRoleNames;
        request.includeRoles = false;

        return await sender.postAsync("/api/admin/getUsers", request);
    }

    private async onFirstNameChangeAsync(item: string) {
        const user = this.user;
        user!.firstname = item;
        await this.setIsModified();
    }

    private async onLastNameChangeAsync(item: string) {
        const user = this.user;
        user!.lastName = item;
        await this.setIsModified();
    }

    private async onMiddleNameChangeAsync(item: string) {
        const user = this.user;
        user!.middleName = item;
        await this.setIsModified();
    }

    private async onAddressChangeAsync(item: GeoLocation) {
        const user = this.user;
        user!.homeLocation = item;
        await this.setIsModified();
    }

    private async setLanguage(language: ILanguage, userInteraction: boolean): Promise<void> {
        this.user!.language = language.code;

        if (userInteraction) {
            await this.setIsModified();
        }
    }

    private async setRolesAsync(): Promise<void> {
        if (this.user != null) {
            await this.setIsModified(true, false);
        }
    }

    private async setNotificationSettingsAsync(): Promise<void> {
        if (this.user != null) {
            await this.setIsModified(true, false);
        }
    }

    private get customers(): Customer[] {
        return this.state.customers;
    }

    private get user(): User | null {
        return this.state.user;
    }

    private get list(): List<User> {
        return this._listRef.current!;
    }

    private get showDeleted(): boolean {
        return this.state.showDeleted;
    }

    private get showExpired(): boolean {
        return this.showDeleted && this.state.showExpired;
    }

    private get filterRoleNames(): string[] {
        return this.state.filterRoleNames;
    }

    private get isEmailRequired(): boolean {
        return ((this.user != null) && ((this.user.authType == AuthType.Email) || (this.user.role?.roleName == "ContactPerson")));
    }

    private get isPhoneRequired(): boolean {
        return ((this.user != null) && ((this.user.authType == AuthType.Phone) || (this.user.role?.roleName == "ContactPerson")));
    }

    private get accountWarningIcon(): IIconProps | undefined {
        const visible: boolean = (this.user != null) && (this.state.selectedTabIndex != 0) && (!User.isValidAccount(this.user));
        return visible ? {name: "far fa-exclamation-triangle", className: styles.warningIcon} : undefined;
    }

    private get rolesWarningIcon(): IIconProps | undefined {
        const visible: boolean = (this.user != null) && (this.state.selectedTabIndex != 1) && (!User.isValidRole(this.user));
        return visible ? {name: "far fa-exclamation-triangle", className: styles.warningIcon} : undefined;
    }

    private async onChangeTabAsync(tab: TabModel): Promise<void> {
        if (this._formRef.current) {
            await this._formRef.current.validateAsync();
        }

        await this.setState({selectedTabIndex: tab.index});
    }

    public async initializeAsync(): Promise<void> {

        const roles: UserRole[] = await this.getAsync("/api/admin/getUserRoles");

        const customers: Customer[] = await this.postCacheAsync("/api/admin/listCustomers", 60000);

        await this.setState({roles, customers});
    }

    private renderFormButtons(system: boolean): React.ReactNode {
        const showAddButton: boolean = this.state.showAddButton;
        const isModified: boolean = this.isModified;
        const user: User = this.user!;

        return (
            <ButtonContainer>

                {
                    ((!showAddButton) && (system)) &&
                    (
                        <Button id={"cancelAdd"}
                                minWidth={90}
                                label={Localizer.genericCancel}
                                type={ButtonType.Primary}
                                icon={{name: "far ban", size: IconSize.Large}}
                                onClick={() => this.cancelAddUserAsync()}
                        />
                    )
                }

                {
                    (isModified) &&
                    (
                        <Button id={"cancelModify"}
                                minWidth={90}
                                label={Localizer.genericCancel}
                                type={ButtonType.Primary}
                                icon={{name: "far ban", size: IconSize.Large}}
                                confirm={Localizer.adminConfirmationButtonRollback}
                                onClick={() => this.cancelModifyingAsync()}
                        />
                    )
                }

                {
                    ((showAddButton) && (!user.isDeleted) && (system)) &&
                    (
                        <Button id={"deleteUser"}
                                minWidth={90}
                                label={Localizer.adminButtonDelete}
                                icon={{name: "trash-alt", size: IconSize.Large}}
                                type={ButtonType.Primary}
                                disabled={!this.canDelete || this.isModified || this.readOnly}
                                onClick={() => this.deleteUserAsync()}
                                confirm={Utility.format(Localizer.adminConfirmationButtonDeleteUser, this.userFullName)}
                        />
                    )
                }

                {
                    ((showAddButton) && (user.isDeleted) && (system)) &&
                    (
                        <Button id={"restoreUser"}
                                minWidth={90}
                                label={Localizer.adminButtonRestore}
                                icon={{name: "trash-restore", size: IconSize.Large}}
                                type={ButtonType.Primary}
                                disabled={(this.readOnly)}
                                confirm={Utility.format(Localizer.adminConfirmationButtonRestoreUser, this.userFullName)}
                                onClick={() => this.restoreUserAsync()}
                        />
                    )
                }

                {
                    ((showAddButton) && (!user.isDeleted) && (user.isLocked) && (system)) &&
                    (
                        <Button minWidth={90}
                                label={Localizer.adminButtonUnlock}
                                icon={{name: "unlock", size: IconSize.Large}}
                                type={ButtonType.Success}
                                disabled={(this.readOnly)}
                                confirm={Utility.format(Localizer.adminConfirmationButtonUnlockUser, this.userFullName)}
                                onClick={() => this.unlockUserAsync()}
                        />
                    )
                }

                <Button submit
                        type={ButtonType.Orange}
                        icon={{name: "far save"}}
                        disabled={(this.readOnly)}
                        label={Localizer.formSave}
                />

            </ButtonContainer>
        )
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.userManagement} fullWidth fullHeight>

                <PageHeader title={Localizer.userManagementPageTitle}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>

                <PageRow>
                    <div className="col d-flex flex-column">

                        <ToolbarContainer>

                            <Form inline className="d-flex">

                                <Dropdown id="roleNameFilter"
                                          inline noWrap multiple autoCollapse clearButton
                                          minWidth={210}
                                          selectType={DropdownSelectType.Checkbox}
                                          nothingSelectedText={Localizer.adminChooseRole}
                                          label={Localizer.formInputRole}
                                          orderBy={DropdownOrderBy.None}
                                          items={this.state.roles}
                                          onChange={(sender) => this.setFilterRoleNamesAsync(sender.selectedItems)}
                                />

                                <Checkbox inline
                                          label={Localizer.adminShowDeleted}
                                          value={this.showDeleted}
                                          onChange={(sender, value) => this.setShowDeletedAsync(value)}
                                />

                                {
                                    (this.showDeleted) &&
                                    (
                                        <Checkbox inline
                                                  label={Localizer.userManagementPageCheckboxShowExpired}
                                                  value={this.showExpired}
                                                  onChange={(sender, value) => this.setShowExpiredAsync(value)}
                                        />
                                    )
                                }

                            </Form>

                            <Inline>

                                <ToolbarButton label={Localizer.adminAddUser}
                                               icon={{name: "plus", size: IconSize.Large}}
                                               type={ButtonType.Primary}
                                               disabled={!this.state.showAddButton || this.isModified}
                                               onClick={() => this.addUserAsync()}
                                />

                            </Inline>

                        </ToolbarContainer>

                        <div className="row h-100">

                            <div className="col-md-4">

                                <List id="users" ref={this._listRef} required noGrouping absoluteListItems
                                      maxHeight={"auto"}
                                      disabled={!this.state.showAddButton || this.isModified}
                                      orderBy={DropdownOrderBy.None}
                                      filterMinLength={10}
                                      selectedItem={this.user || undefined}
                                      fetchItems={(sender) => this.getUsersAsync(sender)}
                                      onChange={(_, item) => this.setSelectedUser(item!)}
                                />

                            </div>

                            <div className="col-md-8">

                                {
                                    (this.user != null) &&
                                    (
                                        <TabContainer ref={this._tabContainerRef} id="userManagementTabs" renderType={TabRenderType.ActiveOnly} onSelect={(tab) => this.onChangeTabAsync(tab)}>

                                            <Tab id="account" title={Localizer.topNavAccount} icon={this.accountWarningIcon}>

                                                <Form ref={this._formRef} className={styles.form} onSubmit={() => this.saveAsync()}>

                                                    <TwoColumns>

                                                        <Dropdown id="authType" required noSubtext
                                                                  label={Localizer.adminLabelAuthenticationType}
                                                                  selectType={DropdownSelectType.Checkbox}
                                                                  disabled={(!!this.user.id) || (this.readOnly)}
                                                                  orderBy={DropdownOrderBy.None}
                                                                  items={EnumProvider.getAuthTypeItems()}
                                                                  selectedItem={EnumProvider.getAuthTypeItem(this.user.authType)}
                                                                  onChange={(sender, item, userInteraction) => this.setAuthType(item!, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <EmailInput id="email" ref={this._emailRef} noAutoComplete
                                                                    label={Localizer.formInputEmail}
                                                                    required={this.isEmailRequired}
                                                                    value={this.user.email}
                                                                    readonly={((this.user.authType == AuthType.Email) && (!!this.user.id)) || (this.readOnly)}
                                                                    onChange={(sender, value) => this.setEmail(value)}
                                                        />

                                                        <PhoneInput id="phone"
                                                                    label={Localizer.formInputPhone}
                                                                    required={this.isPhoneRequired}
                                                                    value={this.user.phone}
                                                                    readonly={((this.user.authType == AuthType.Phone) && (!!this.user.id)) || (this.readOnly)}
                                                                    onChange={(sender, value) => this.setPhone(value)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput id="firstname"
                                                                   label={Localizer.formInputFirstname}
                                                                   value={this.user.firstname} required
                                                                   readonly={(this.readOnly)}
                                                                   onChange={(sender, item) => this.onFirstNameChangeAsync(item!)}
                                                        />

                                                        <TextInput id="lastName"
                                                                   label={Localizer.formInputLastname}
                                                                   value={this.user.lastName} required
                                                                   readonly={(this.readOnly)}
                                                                   onChange={(sender, item) => this.onLastNameChangeAsync(item!)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput id="middleName"
                                                                   label={Localizer.formInputMiddlename}
                                                                   readonly={(this.readOnly)}
                                                                   onChange={async (sender, item) => await this.onMiddleNameChangeAsync(item!)}
                                                                   value={this.user.middleName}
                                                        />

                                                        <Dropdown id="language" required
                                                                  label={Localizer.formInputLanguage}
                                                                  selectType={DropdownSelectType.Checkbox}
                                                                  items={Localizer.supportedLanguages}
                                                                  selectedItem={Localizer.findLanguage(this.user.language)}
                                                                  disabled={(this.readOnly)}
                                                                  onChange={(sender, item, userInteraction) => this.setLanguage(item!, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    <AddressDivider id="formattedAddress"
                                                                    location={this.user.homeLocation || undefined}
                                                                    readonly={(this.readOnly)}
                                                                    onChange={(sender, item) => this.onAddressChangeAsync(item!)}
                                                    />

                                                    <div className={styles.expander}/>

                                                    {this.renderFormButtons(true)}

                                                </Form>

                                            </Tab>

                                            <Tab id="roles" title={Localizer.userManagementPageRoles} icon={this.rolesWarningIcon}>

                                                {
                                                    (this.customers) &&
                                                    (
                                                        <Form ref={this._formRef} onSubmit={() => this.saveAsync()} className={styles.form}>

                                                            <div>

                                                                <UserRolesPanel user={this.user}
                                                                                isModified={this.isModified}
                                                                                customers={this.customers}
                                                                                readonly={this.readOnly}
                                                                                onChange={() => this.setRolesAsync()}
                                                                                onChangeNotificationSettings={() => this.setNotificationSettingsAsync()}
                                                                />

                                                            </div>

                                                            <div className={styles.expander}/>

                                                            {this.renderFormButtons(false)}

                                                        </Form>
                                                    )
                                                }

                                            </Tab>

                                            {
                                                (this.state.showAddButton) &&
                                                (
                                                    <Tab id="invitations" title={Localizer.adminTabInvitations}>

                                                        <div className={styles.passwordInfo}>
                                                            {
                                                                (!this.user.hasPassword) &&
                                                                (
                                                                    <Alert model={this.alert}/>
                                                                )
                                                            }
                                                        </div>

                                                        <div className={styles.invitations}>

                                                            <Grid responsive
                                                                  columns={this._invitationColumns}
                                                                  data={this.user.invitations}
                                                                  noDataText={Localizer.adminGridNoInvitationsText}
                                                            />

                                                        </div>

                                                        <div className={styles.expander}/>

                                                        <ButtonContainer>

                                                            <Button minWidth={90}
                                                                    label={Localizer.adminButtonResendInvitation}
                                                                    icon={{name: "envelope", size: IconSize.Large}}
                                                                    disabled={this.user.isDeleted || this.user.isLocked || this.user.hasPassword}
                                                                    type={ButtonType.Primary}
                                                                    onClick={() => this.resendInvitationAsync()}
                                                                    confirm={Utility.format(Localizer.adminConfirmationButtonResendInvitation, this.userFullName)}
                                                            />

                                                            <Button minWidth={90}
                                                                    label={Localizer.adminButtonResetPassword}
                                                                    icon={{name: "repeat", size: IconSize.Large}}
                                                                    disabled={this.user.isDeleted || this.user.isLocked || !this.user.hasPassword}
                                                                    type={ButtonType.Primary}
                                                                    onClick={() => this.resetPasswordAsync()}
                                                                    confirm={Utility.format(Localizer.adminConfirmationButtonResetPassword, this.userFullName)}
                                                            />

                                                        </ButtonContainer>

                                                    </Tab>
                                                )
                                            }

                                        </TabContainer>
                                    )
                                }

                            </div>

                        </div>

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}