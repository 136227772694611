import React from "react";
import {Modal, ModalSize, Spinner, Tab, TabContainer, TabRenderType, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import Product from "@/models/server/Product";
import ProductAssortmentStatistics from "@/models/server/ProductAssortmentStatistics";
import InventoriesPanel from "@/pages/ProductManagement/StatisticsModal/InventoriesPanel/InventoriesPanel";
import OrdersPanel from "@/pages/ProductManagement/StatisticsModal/OrdersPanel/OrdersPanel";
import InheritancesPanel from "@/pages/ProductManagement/StatisticsModal/InheritancesPanel/InheritancesPanel";
import {StatisticsItem} from "@/pages/ProductManagement/StatisticsModal/StatisticsItem";
import ProductAssortment from "@/models/server/ProductAssortment";
import Localizer from "@/localization/Localizer";

import styles from "./StatisticsModal.module.scss";

interface IStatisticsModalProps {
    id?: string;
}

interface IStatisticsModalState {
    productAssortmentId: string | null;
    statistics: ProductAssortmentStatistics | null;
}

export default class StatisticsModal extends BaseComponent<IStatisticsModalProps, IStatisticsModalState> {
    
    state: IStatisticsModalState = {
        productAssortmentId: null,
        statistics: null
    };
    
    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();

    public async onCloseAsync(): Promise<void> {
        await this.setState({statistics: null});
    }

    private get productAssortmentId(): string | null {
        return this.state.productAssortmentId;
    }

    private get productAssortment(): ProductAssortment | null {
        return this.statistics?.productAssortment || null;
    }
    
    private get statistics(): ProductAssortmentStatistics | null {
        return this.state.statistics;
    }
    
    private get hasData(): boolean {
        return (this.statistics != null);
    }
    
    private get loading(): boolean {
        return (!this.hasData);
    }
    
    private get title(): string {
        return (this.statistics?.productAssortment?.product)
            ? Product.getTitle(this.statistics.productAssortment.product)
            : "..."
    }
    
    private get subtitle(): string {
        return (this.statistics?.productAssortment?.product)
            ? Product.getSubtitle(this.statistics.productAssortment.product)
            : Localizer.genericLoading
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }
    
    private getGenericStatisticsItems(statistics: ProductAssortmentStatistics): StatisticsItem[] {
        return [
            {
                name: Localizer.statisticsModalStatisticsCustomerGroup, wide: true, estimated: true,
                value: statistics.customerGroup?.name || "-"
            },
            {
                name: Localizer.statisticsModalStatisticsCustomer, wide: true, estimated: true,
                value: statistics.customer?.name || "-"
            },
            
            {
                // Added to Adeona at
                name: Localizer.statisticsModalStatisticsAddedToAdeona, wide: false, estimated: false,
                value: statistics.productAssortment?.product?.externalCreatedAt ? "{0:D}".format(statistics.productAssortment?.product?.externalCreatedAt) : "-"
            },       
            {
                // Modified in Adeona at
                name: Localizer.statisticsModalStatisticsModifiedInAdeona, wide: false, estimated: false,
                value: statistics.productAssortment?.product?.externalModifiedAt ? "{0:D}".format(statistics.productAssortment?.product?.externalModifiedAt) : "-"
            },

            {
                // Assortment added at
                name: Localizer.statisticsModalStatisticsAddedToAssortment, wide: false, estimated: false,
                value: statistics.productAssortment?.createdAt ? "{0:D}".format(statistics.productAssortment?.createdAt) : "-"
            },
            {
                // Assortment modified at
                name: Localizer.statisticsModalStatisticsModifiedAssortment, wide: false, estimated: false,
                value: statistics.productAssortment?.modifiedAt ? "{0:D}".format(statistics.productAssortment?.modifiedAt) : "-"
            },
            
            {
                // Total days
                name: Localizer.statisticsModalStatisticsTotalDays, wide: false, estimated: false,
                value: (statistics.productAssortment?.inheritedTotalDays != null)
                    ? Localizer.statisticsModalStatisticsTotalDaysFormat.format(statistics.productAssortment?.inheritedTotalDays)
                    : "-"
            },            
            {
                // Total quantity
                name: Localizer.statisticsModalStatisticsTotalOrderedQuantity, wide: false, estimated: false,
                value: (statistics.productAssortment?.inheritedTotalValue != null)
                    ? statistics.productAssortment.inheritedTotalValue.toString()
                    : "-"
            },

            {
                // Total orders
                name: Localizer.statisticsModalStatisticsTotalOrders, wide: false, estimated: false,
                value: statistics.inheritedTotalOrders.toString()
            },
            {
                // Average quantity per order
                name: Localizer.statisticsModalStatisticsAverageOrderedQuantity, wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedTotalValue != null)
                    ? "{0:0.00}".format((statistics.inheritedTotalOrders > 0) ? statistics.productAssortment.inheritedTotalValue / statistics.inheritedTotalOrders : 0)
                    : "-"
            },

            {
                // Daily Average
                name: "Daily Average", wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedDailyAverage != null)
                    ? statistics.productAssortment.inheritedDailyAverage.toFixed(4)
                    : "-"
            },
            {
                // Weekly Average
                name: "Weekly Average", wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedWeeklyAverage != null)
                    ? statistics.productAssortment.inheritedWeeklyAverage.toFixed(4)
                    : "-"
            },

            {
                // Total inventories
                name: Localizer.statisticsModalStatisticsTotalInventories, wide: false, estimated: false,
                value: statistics.inheritedTotalInventories.toString()
            },
            {
                name: "-", wide: false, estimated: false, value: "",
            },
            
            {
                // First order
                name: Localizer.statisticsModalStatisticsFirstOrder, wide: false, estimated: false,
                value: statistics.inheritedFirstOrderProduct ? "{0:D}".format(statistics.inheritedFirstOrderProduct.createdAt) : "-"
            },
            {
                // First order quantity
                name: Localizer.statisticsModalStatisticsFirstOrderQuantity, wide: false, estimated: false,
                value: statistics.inheritedFirstOrderProduct ? statistics.inheritedFirstOrderProduct.quantity.toString() : "-"
            },
            
            {
                // Last order
                name: Localizer.statisticsModalStatisticsLastOrder, wide: false, estimated: false,
                value: statistics.inheritedLastOrderProduct ? "{0:D}".format(statistics.inheritedLastOrderProduct.createdAt) : "-"
            },
            {
                // Last order quantity
                name: Localizer.statisticsModalStatisticsLastOrderQuantity, wide: false, estimated: false,
                value: statistics.inheritedLastOrderProduct ? statistics.inheritedLastOrderProduct.quantity.toString() : "-"
            },

            {
                // First inventory
                name: Localizer.statisticsModalStatisticsFirstInventory, wide: false, estimated: false,
                value: statistics.inheritedFirstInventoryProduct ? "{0:D}".format(statistics.inheritedFirstInventoryProduct.createdAt) : "-"
            },
            {
                // First inventory quantity
                name: Localizer.statisticsModalStatisticsFirstInventoryQuantity, wide: false, estimated: false,
                value: (statistics.inheritedFirstInventoryProduct?.quantity != null) ? statistics.inheritedFirstInventoryProduct.quantity.toString() : "-"
            },
            
            {
                // Last inventory
                name: Localizer.statisticsModalStatisticsLastInventory, wide: false, estimated: false,
                value: statistics.inheritedLastInventoryProduct ? "{0:D}".format(statistics.inheritedLastInventoryProduct.createdAt) : "-"
            },
            {
                // Last inventory quantity
                name: Localizer.statisticsModalStatisticsLastInventoryQuantity, wide: false, estimated: false,
                value: (statistics.inheritedLastInventoryProduct?.quantity != null) ? statistics.inheritedLastInventoryProduct.quantity.toString() : "-"
            },

            {
                // Days after the last inventory
                name: Localizer.statisticsModalStatisticsDaysAfterTheLastInventory, wide: false, estimated: false,
                value: (statistics.productAssortment?.daysAfterInventory != null) ? statistics.productAssortment.daysAfterInventory.toString() : "-"
            },
            {
                // Statistics calculated at
                name: Localizer.statisticsModalStatisticsCollectedAt, wide: false, estimated: false,
                value: statistics.productAssortment?.totalAt ? "{0:D}".format(statistics.productAssortment.totalAt) : "-"
            },
        ];
    }

    private getAnnualStatisticsItems(statistics: ProductAssortmentStatistics): StatisticsItem[] {
        return [
            {
                name: Localizer.statisticsModalStatisticsCustomerGroup, wide: true, estimated: true,
                value: statistics.customerGroup?.name || "-"
            },            
            {
                name: Localizer.statisticsModalStatisticsCustomer, wide: true, estimated: true,
                value: statistics.customer?.name || "-"
            },
            
            {
                // Total days (12m)
                name: Localizer.statisticsModalStatisticsTotalDays, wide: false, estimated: false,
                value: (statistics.productAssortment?.inheritedAnnualDays != null)
                    ? Localizer.statisticsModalStatisticsTotalDaysFormat.format(statistics.productAssortment?.inheritedAnnualDays)
                    : "-"
            },
            {
                // Total quantity (12m)
                name: Localizer.statisticsModalStatisticsTotalOrderedQuantity, wide: false, estimated: false,
                value: (statistics.productAssortment?.inheritedAnnualValue != null)
                    ? statistics.productAssortment.inheritedAnnualValue.toString()
                    : "-"
            },
            
            {
                // Total orders (12m)
                name: Localizer.statisticsModalStatisticsTotalOrders, wide: false, estimated: false,
                value: statistics.inheritedAnnualOrders.toString()
            },
            {
                // Average quantity per order (12m)
                name: Localizer.statisticsModalStatisticsAverageOrderedQuantity, wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedAnnualValue != null)
                    ? "{0:0.00}".format((statistics.inheritedAnnualOrders > 0) ? statistics.productAssortment.inheritedAnnualValue / statistics.inheritedAnnualOrders : 0)
                    : "-"
            },

            {
                // Total inventories (12m)
                name: Localizer.statisticsModalStatisticsTotalInventories, wide: false, estimated: false,
                value: statistics.inheritedAnnualInventories.toString()
            },
            {
                // Empty
                name: "-", wide: false, estimated: false, value: "",
            },

            {
                // Daily Average (Annual)
                name: Localizer.statisticsModalStatisticsDailyConsumption, wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedAnnualDailyAverage != null)
                    ? statistics.productAssortment.inheritedAnnualDailyAverage.toFixed(4)
                    : "-"
            },            
            {
                // Weekly Average (12m)
                name: Localizer.statisticsModalStatisticsWeeklyConsumption, wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedAnnualWeeklyAverage != null)
                    ? statistics.productAssortment.inheritedAnnualWeeklyAverage.toFixed(4)
                    : "-"
            },

            {
                // Delivery interval
                name: Localizer.statisticsModalStatisticsDeliveryInterval, wide: false, estimated: false,
                value: statistics.customer?.deliveryInterval
                    ? Localizer.statisticsModalStatisticsDeliveryIntervalFormat.format(statistics.customer.deliveryInterval)
                    : "-"
            },
            {
                // Delivery Interval Consumption (Annual)
                name: Localizer.statisticsModalStatisticsDeliveryIntervalConsumption, wide: false, estimated: true,
                value: (statistics.productAssortment?.inheritedAnnualWeeklyAverage != null)
                    ? ((statistics.customer?.deliveryInterval || 1) * statistics.productAssortment.inheritedAnnualWeeklyAverage).toFixed(4)
                    : "-"
            },

            {
                // Order quantity (TM):
                // NewOrderQuantity = Math.Max(1, (int)Math.Ceiling(NewOrderThreshold));
                name: Localizer.statisticsModalStatisticsOrderQuantity, wide: false, estimated: true,
                value: (statistics.productAssortment?.newOrderQuantity != null)
                    ? statistics.productAssortment?.newOrderQuantity.toString()
                    : "-"
            },
            {
                // Order threshold (TP):
                // NewOrderThreshold = DeliveryIntervalConsumption
                name: Localizer.statisticsModalStatisticsOrderThreshold, wide: false, estimated: true,
                value: (statistics.productAssortment?.newOrderThreshold != null)
                    ? statistics.productAssortment?.newOrderThreshold.toFixed(2)
                    : "-"
            },
            
            {
                // Last inventory
                name: Localizer.statisticsModalStatisticsLastInventory, wide: false, estimated: false,
                value: statistics.inheritedLastInventoryProduct
                    ? "{0:D}".format(statistics.inheritedLastInventoryProduct.createdAt)
                    : "-"
            },
            {
                // Last inventory quantity
                name: Localizer.statisticsModalStatisticsLastInventoryQuantity, wide: false, estimated: false,
                value: (statistics.inheritedLastInventoryProduct?.quantity != null)
                    ? statistics.inheritedLastInventoryProduct.quantity.toString()
                    : "-"
            },

            {
                // Ordered quantity after the last inventory
                name: Localizer.statisticsModalStatisticsQuantityOrderedSinceLastInventory, wide: true, estimated: false,
                value: statistics.productAssortment?.totalAt
                    ? "+{0:0.00}".format(statistics.productAssortment.orderedAfterInventory ?? 0)
                    : "-"
            },
            {
                // Estimated current quantity
                name: Localizer.statisticsModalStatisticsCurrentQuantity, wide: false, estimated: true,
                value: "{0:0.00}".format(statistics.productAssortment?.estimatedQuantity || 0)
            }
        ];
    }

    public async openAsync(productAssortmentId: string): Promise<void> {
        await this.modal.openAsync();

        const statistics: ProductAssortmentStatistics = await this.postAsync("/api/productManagement/getProductAssortmentStatistics", productAssortmentId);

        await this.setState({productAssortmentId, statistics});

        await this._tabContainerRef.current?.activateTabAsync(0);
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }
    
    private renderStatisticsItem(index: number, item: StatisticsItem): React.ReactNode {
        const empty: boolean = (!item.name) || (item.name == "-");
        
        const emptyStyle = (empty) && styles.empty;
        const wideStyle = (item.wide) && styles.wide;
        const estimatedStyle = (item.estimated) && styles.estimated;
        
        return (
            <tr key={index} className={this.css(emptyStyle, wideStyle, estimatedStyle)}>
                <td>
                    {item.name}
                </td>
                <td>
                    {item.value}
                </td>
            </tr>
        );
    }
    
    private renderStatisticsColumn(items: StatisticsItem[]): React.ReactNode {
        return (
            <table className={this.css(styles.column, "table table-striped")}>
                <tbody>

                    { items.map((item: StatisticsItem, index: number) => this.renderStatisticsItem(index, item)) }

                </tbody>
            </table>
        );
    }
    
    private renderStatistics(statistics: ProductAssortmentStatistics, generic: boolean): React.ReactNode {
        const items: StatisticsItem[] = (generic)
            ? this.getGenericStatisticsItems(statistics)
            : this.getAnnualStatisticsItems(statistics);
        
        const firstColumn: StatisticsItem[] = items.filter((item, index) => (index % 2) == 0);
        const secondColumn: StatisticsItem[] = items.filter((item, index) => (index % 2) != 0);
        
        return (
            <React.Fragment>
                
                <TwoColumns className={this.css(styles.statistics)}>
    
                    { this.renderStatisticsColumn(firstColumn) }
    
                    { this.renderStatisticsColumn(secondColumn) }
                    
                </TwoColumns>
                
            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <Modal notResponsive
                   id={this.id}
                   ref={this._modalRef}
                   className={styles.statisticsModal}
                   title={this.title}
                   subtitle={this.subtitle}
                   size={ModalSize.Auto}
                   onClose={() => this.onCloseAsync()}
            >
                
                {
                    (this.isOpen) &&
                    (
                        <div className={styles.content}>

                            <TabContainer scale 
                                          id={`${this.id}_tabContainer_${this.productAssortmentId}`}
                                          ref={this._tabContainerRef}
                                          renderType={TabRenderType.Once}
                                          dataStorageType={false}
                            >

                                <Tab id="statistics_generic" title={Localizer.statisticsModalTabStatisticsGenericLanguageItemName}>

                                    {
                                        (this.statistics) &&
                                        (
                                            this.renderStatistics(this.statistics, true)
                                        )
                                    }

                                </Tab>

                                <Tab id="statistics_annual" title={Localizer.statisticsModalTabStatisticsAnnualLanguageItemName}>

                                    {
                                        (this.statistics) &&
                                        (
                                            this.renderStatistics(this.statistics, false)
                                        )
                                    }

                                </Tab>

                                <Tab id="orders" title={Localizer.statisticsModalTabOrdersLanguageItemName}>

                                    {
                                        (this.productAssortmentId) &&
                                        (
                                            <OrdersPanel productAssortmentId={this.productAssortmentId} />
                                        )
                                    }

                                </Tab>

                                <Tab id="inventories" title={Localizer.statisticsModalTabInventoriesLanguageItemName}>

                                    {
                                        (this.productAssortmentId) &&
                                        (
                                            <InventoriesPanel productAssortmentId={this.productAssortmentId} />
                                        )
                                    }
                                    
                                </Tab>

                                <Tab id="inheritances" title={Localizer.statisticsModalTabInheritancesLanguageItemName}>

                                    {
                                        (this.productAssortmentId) &&
                                        (
                                            <InheritancesPanel productAssortmentId={this.productAssortmentId} />
                                        )
                                    }
                                    
                                </Tab>

                            </TabContainer>
                            
                            { (this.loading) && <Spinner /> }

                        </div>
                    )                    
                }
                
            </Modal>
        )
    }
}