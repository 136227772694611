import {OrderStatus} from "@/models/Enums";
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListOrdersHistoryRequest extends ETagRequest{
    public customerGroupId: string | null = null;

    public customerId: string | null = null;
    
    public statuses: OrderStatus[] = [];
    
    public express: boolean | null = null;
    
    public from: Date | null = null;
    
    public to: Date | null = null;
    
    public search: string | null = null;
}