// autogenerated

export default class DeliveryOrderProductRequest {

    public orderProductId: string = "";

    public comment: string | null = null;

    public readonly isDeliveryOrderProductRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof DeliveryOrderProductRequest) || ((from as any).isDeliveryOrderProductRequest === true));
    }

    public static as(from?: object | null): DeliveryOrderProductRequest | null {
        return (DeliveryOrderProductRequest.is(from)) ? from as DeliveryOrderProductRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}