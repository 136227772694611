import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {BorderType, CellModel, ColumnDefinition, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@reapptor-apps/reapptor-react-components";
import {IPagedList, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import ProductModal from "@/components/ProductModal/ProductModal";
import LabelsToolbarModel, {LabelsPanelType} from "@/pages/LabelsManagement/LabelsToolbar/LabelsToolbarModel";
import ProductAssortment from "@/models/server/ProductAssortment";
import LabelsToolbar from "@/pages/LabelsManagement/LabelsToolbar/LabelsToolbar";
import CreateProductLabelsRequest from "@/models/server/requests/CreateProductLabelsRequest";
import ListProductAssortmentsRequest from "@/models/server/requests/ListProductAssortmentsRequest";
import Product from "@/models/server/Product";
import Localizer from "@/localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./ProductsSelectionPanel.module.scss";


export interface IProductsSelectionPanelProps {
    createLabels?(newLabelsCount: number): Promise<void>;
}

interface IProductsSelectionPanelState {
    toolbar: LabelsToolbarModel;
}

export default class ProductsSelectionPanel extends BaseComponent<IProductsSelectionPanelProps, IProductsSelectionPanelState> {

    state: IProductsSelectionPanelState = {
        toolbar: new LabelsToolbarModel(),
    };

    private readonly _labelsOrderPanelGridRef: React.RefObject<Grid<ProductAssortment>> = React.createRef();
    private readonly _labelsToolbarRef: React.RefObject<LabelsToolbar> = React.createRef();
    private readonly _productModalRef: React.RefObject<ProductModal> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            name: "groupName",
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            isDefaultSorting: true,
            sorting: true,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<ProductAssortment>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            name: "productCode",
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductAssortment>(o => o.product!.code),
            minWidth: "7,5rem",
            maxWidth: "7,5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            name: "productName",
            header: Localizer.productPanelGridNameLanguageItemName,
            sorting: true,
            accessor: (model: ProductAssortment) => Product.getFullName(model.product!),
            minWidth: "25rem",
            stretch: true,
            className: styles.hasImage,
            init: (cell: CellModel<ProductAssortment>) => this.initNameCell(cell),
            callback: (cell: CellModel<ProductAssortment>) => this.onNameClickAsync(cell)
        } as ColumnDefinition,
        {
            name: "manufactureCode",
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductAssortment>(o => o.product!.manufactureCode),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition
    ];

    private async createLabelsAsync(): Promise<void> {
        const request = new CreateProductLabelsRequest();
        request.productAssortmentIds = this.grid!.checkedItems.map(item => item.id);

        await this.postAsync("/api/labelsManagement/createProductLabels", request);
        
        if (this.props.createLabels) {
            await this.props.createLabels(request.productAssortmentIds.length);
        }
    }
    
    private async onToolbarSubmitAsync(toolbar: LabelsToolbarModel): Promise<void> {
        await this.setState({toolbar});
        
        await this.reloadAsync();
    }

    private async fetchAsync(sender: Grid<ProductAssortment>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ProductAssortment>> {
        if (!this.initialized) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListProductAssortmentsRequest();
        request.customerGroupId = this.customerGroupId;
        request.customerId = this.customerId;
        request.search = this.search;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return await sender.postAsync("/api/labelsManagement/listProductAssortments", request);
    }

    private initNameCell(cell: CellModel<ProductAssortment>): void {
        cell.descriptionReadonly = true;
    }
    
    private initRow(row: RowModel<ProductAssortment>): void {
        const model: ProductAssortment = row.model;

        row.checkable = !model.hasOpenLabel;
    }
    
    private async onCheckAsync(sender: GridModel<ProductAssortment>): Promise<void> { 
        const checkedProducts: ProductAssortment[] = sender.checkedItems;
        
        await this._labelsToolbarRef.current!.selectProductAssortments(checkedProducts);
    }
    
    private async onNameClickAsync(cell: CellModel<ProductAssortment>): Promise<void> {
        const model: ProductAssortment = cell.row.model;

        if (this._productModalRef.current) {
            await this._productModalRef.current.openAsync(model.product ?? model.productId, null, null, this.customerId, this.customerGroupId);
        }
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get grid(): GridModel<ProductAssortment> {
        return this._labelsOrderPanelGridRef.current!.model;
    }

    public get customerId(): string | null {
        return this.toolbar.customer?.id || null;
    }

    public get customerGroupId(): string | null {
        return this.toolbar.customerGroup?.id || null;
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    public get toolbar(): LabelsToolbarModel {
        return this.state.toolbar;
    }

    public get initialized(): boolean {
        return LabelsToolbarModel.initialized(this.toolbar, LabelsPanelType.ProductsSelection);
    }

    public renderGroupCell(cell: CellModel<ProductAssortment>): React.ReactNode {
        const product: Product = cell.model!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.productsSelectionPanel)}>
                
                <LabelsToolbar ref={this._labelsToolbarRef}
                               model={this.toolbar}
                               type={LabelsPanelType.ProductsSelection}
                               hideCustomersInSearch={(AittaController.user.isMaster)}
                               createLabels={() => this.createLabelsAsync()}
                               onChange={(sender: LabelsToolbar, toolbar: LabelsToolbarModel) => this.onToolbarSubmitAsync(toolbar)}/>
                
                <Grid optimization responsive checkable pagination
                      id={"productSelectionPanelGrid"}
                      ref={this._labelsOrderPanelGridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.Row}
                      className={styles.productsPanelGrid}
                      headerMinHeight={80}
                      odd={GridOddType.None}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      noDataText={Localizer.genericNoData}
                      onCheck={(sender: GridModel<ProductAssortment>) => this.onCheckAsync(sender)}
                      initRow={(row: RowModel<ProductAssortment>) => this.initRow(row)}
                      fetchData={(sender: Grid<ProductAssortment>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                />
                
                <ProductModal id={"productModal"}
                              ref={this._productModalRef}
                />

            </div>
        )
    }
}