import React from "react";
import {GoogleMap, IGoogleMapMarker, ITabContainerClassNames, PageContainer, Tab, TabContainer, TabRenderType} from "@reapptor-apps/reapptor-react-components";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import OrderList from "@/pages/Mobile/OrderHistory/OrderList/OrderList";
import Customer from "@/models/server/Customer";
import {IManualProps, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import Localizer from "@/localization/Localizer";

import styles from "./OrderHistory.module.scss";

export interface IOrderHistoryProps {
}

interface IOrderHistoryState {
}

export default class OrderHistory extends MobileAuthorizedPage<IOrderHistoryProps, IOrderHistoryState> {

    state: IOrderHistoryState = {};

    private get marker(): IGoogleMapMarker[] {
        return [
            {
                position: {
                    lat: this.customer.location!.lat,
                    lng: this.customer.location!.lon
                }
            }
        ];
    }
    
    public get isShelverPartner(): boolean {
        return this.userContext.isShelverPartner;
    }
    
    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: styles.tabContainerNavTabs,
            headerLink: styles.tabHeaderLink,
            navigationContainer: this.css(styles.stickyNavigationContainer, (this.isShelverPartner && styles.hidden))
        };
    }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileOrderHistoryPageInfo.toUpperCase(),
            render: () => this.renderManual(),
        };
    }

    public renderManual(): React.ReactNode {
        const selectedCustomer: CustomerMobileInfo = this.customer;

        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(selectedCustomer.codeInfo)} </mark> - {selectedCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{this.customer?.customerGroupName}</b></span>
                
                <div className={styles.legend}>

                    <h5>{Localizer.mobileOrderHistoryPageLegend.toUpperCase()}</h5>
                    
                    <div>

                        <span className={this.css(styles.circle, styles.openCircle)}/>

                        <span className={styles.status}>{Localizer.enumOrderStatusOpen}</span>

                    </div>
                    
                    <div>

                        <span className={this.css(styles.circle, styles.closedCircle)}/>

                        <span className={styles.status}>{Localizer.enumOrderStatusClosed}</span>

                    </div>

                    <div>

                        <span className={this.css(styles.circle, styles.partiallyDeliveredCircle)}/>

                        <span className={styles.status}>{Localizer.enumOrderStatusPartiallyDelivered}</span>

                    </div>
                    
                    <div>

                        <span className={this.css(styles.circle, styles.deliveredCircle)}/>

                        <span className={styles.status}>{Localizer.enumOrderStatusDelivered}</span>

                    </div>
                    
                </div>

                <div className={styles.deliverAddress}>

                    <h5>{Localizer.orderHistoryPanelGridDeliveryAddress.toUpperCase()}</h5>

                    <div>
                        <span>{Customer.deliveryAddress(this.customer)}</span>
                    </div>

                </div>
                
                {
                    (this.customer.location) &&
                    (
                        <GoogleMap autoCloseInfoWindows 
                                   mapTypeControl={false}
                                   fullscreenControl={false}
                                   className={styles.map}
                                   height={400}
                                   center={{lat: this.customer.location.lat ?? 0, lng: this.customer.location.lon ?? 0}}
                                   zoom={16}
                                   markers={this.marker}
                        />
                    )
                }
                
            </div>
        )
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment />;
        }
        
        const tabContainerHeaderHeight: number = (!this.isShelverPartner) ? 137 : 72;
        const tabContainerHeaderMarginTop: number | null = (!this.isShelverPartner) ? null : -15;
        const tabContainerHeaderPaddingTop: number | null  = (!this.isShelverPartner) ? null : 15;
        
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.orderHistory, this.mobile && styles.mobile)}
            >
                
                <TabContainer id="ordersContainer"
                              renderType={TabRenderType.Once}
                              className={styles.ordersTabs}
                              classNames={this.tabContainerClassNames}
                >

                    <Tab id="scheduledOrders"
                         title={Localizer.mobileOrderHistoryPageScheduled}
                         className={styles.ordersTab}
                    >
                        <OrderList express={false}
                                   top={tabContainerHeaderHeight}
                                   marginTop={tabContainerHeaderMarginTop}
                                   paddingTop={tabContainerHeaderPaddingTop}
                                   title={Localizer.mobileOrderHistoryPageScheduledOrderHistory}
                                   customer={this.customer}
                                   noDataText={Localizer.mobileOrderHistoryPageNoOrderHistory}
                        />

                    </Tab>

                    <Tab id="expressOrders"
                         title={Localizer.mobileOrderHistoryPageExpress}
                         className={styles.ordersTab}
                    >
                        <OrderList express={true}
                                   top={tabContainerHeaderHeight}
                                   marginTop={tabContainerHeaderMarginTop}
                                   paddingTop={tabContainerHeaderPaddingTop}
                                   title={Localizer.mobileOrderHistoryPageExpressOrderHistory}
                                   customer={this.customer}
                                   noDataText={Localizer.mobileOrderHistoryPageNoExpressOrderHistory}
                        />
                    </Tab>

                </TabContainer>

            </PageContainer>
        );
    }
}