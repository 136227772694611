import ProductAssortment from "@/models/server/ProductAssortment";
import {ProductLabelState, ProductLabelType} from "@/models/Enums";
import {IIconProps} from "@reapptor-apps/reapptor-react-components";
import Product from "@/models/server/Product";
import Localizer from "@/localization/Localizer";

export default class ProductLabel {
    public id: string = "";

    public productCode: string = "";
    
    public productAssortmentId: string = "";

    public productAssortment: ProductAssortment | null = null;

    public type: ProductLabelType = ProductLabelType.ProductAdded;

    public state: ProductLabelState = ProductLabelState.Open;

    public assortmentModified: boolean = false;

    public modifiedAt: Date = new Date();

    public createdAt: Date = new Date();

    public printDate: Date | null = null;

    public comment: string | null = null;

    public inPlaceOfProductId: string | null = null;

    public inPlaceOfProduct: Product | null = null;

    public inPlaceOfProductAssortmentId: string | null = null;

    public inPlaceOfProductAssortment: ProductAssortment | null = null;

    public isProductLabel: true = true;

    public static getStateIcon(model: ProductLabel): IIconProps {
        return (model.state == ProductLabelState.Open)
            ? {name: "fas print-slash", className: "text-grey", tooltip: Localizer.labelsManagementPageNotPrinted}
            : {name: "fas print", className: "text-success", tooltip: Localizer.labelsManagementPagePrinted};
    }
}