import React, {ReactElement} from "react";
import OrderFiltersModal, {OrderFilterModel} from "../../OrderFiltersModal/OrderFiltersModal";
import {BaseComponent, PageCacheTtl, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Icon, IconSize, Layout, Scroller, Spinner} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import {OrderStatus} from "@/models/Enums";
import ListOrdersHistoryRequest from "../../../../models/server/requests/ListOrdersHistoryRequest";
import ListMobileOrdersHistoryResponse from "@/models/server/responses/ListMobileOrdersHistoryResponse";
import OrderMobileInfo from "@/models/server/OrderMobileInfo";
import PageDefinitions from "@/providers/PageDefinitions";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./OrderList.module.scss";

const VISIBLE_DEFAULT: number = 50;

interface IOrderListProps {
    title: string;
    noDataText: string;
    express: boolean | null;
    customer: Customer;
    top?: number | string | null;
    paddingTop?: number | string | null;
    marginTop?: number | string | null;
}

export interface IOrderListState {
    filteredOrders: OrderMobileInfo[];
    displayedOrders: OrderMobileInfo[];
    orderFilterModel: OrderFilterModel;
    isLoading: boolean;
    expandedOrderId: number | null;
    visible: number;
}

export default class OrderList extends BaseComponent<IOrderListProps, IOrderListState> {

    state: IOrderListState = {
        filteredOrders: [],
        displayedOrders: [],
        orderFilterModel: new OrderFilterModel(),
        isLoading: false,
        expandedOrderId: null,
        visible: VISIBLE_DEFAULT
    };

    private readonly _orderFiltersModalRef: React.RefObject<OrderFiltersModal> = React.createRef();
    private readonly _qrScrollerRef: React.RefObject<Scroller> = React.createRef();

    private async fetchOrdersAsync(): Promise<OrderMobileInfo[]> {
        const request = new ListOrdersHistoryRequest();
        request.customerId = this.customer.id;
        request.customerGroupId = this.customer.customerGroupId;
        request.statuses = this.state.orderFilterModel.statuses;
        request.express = this.props.express;
        request.from = this.state.orderFilterModel.createdFrom;
        request.to = this.state.orderFilterModel.createdTo;
        request.search = this.state.orderFilterModel.orderNumber;

        await this.setState({isLoading: true});

        const response: ListMobileOrdersHistoryResponse = await this.postAsync("/api/mobileApp/listMobileOrdersHistory", request, PageCacheTtl._1h);

        const orders: OrderMobileInfo[] = Utility.clone(response.orders);

        const displayedOrders: OrderMobileInfo[] = this.getDisplayedOrders(orders, this.state.visible);
        
        await this.setState({filteredOrders: orders, displayedOrders, isLoading: false});

        return orders;
    }

    private getDisplayedOrders(orders: OrderMobileInfo[], visible: number): OrderMobileInfo[] {
        return (orders.length > visible)
            ? orders.take(visible)
            : orders;
    }
    
    private async showMoreAsync(): Promise<void> {
        const visible: number = this.state.visible + VISIBLE_DEFAULT;

        const displayedOrders: OrderMobileInfo[] = this.getDisplayedOrders(this.state.filteredOrders, visible);

        await this.setState({visible, displayedOrders});
    }
    
    private expandOrder(orderNumber: number): boolean {
        return (this.state.expandedOrderId == orderNumber);
    }

    private async toggleAsync(orderNumber: number): Promise<void> {
        const collapse: boolean = (this.state.expandedOrderId == orderNumber);

        const expandedOrderId: number | null = (collapse) ? null : orderNumber;

        await this.setState({expandedOrderId: expandedOrderId});
    }

    private async submitFiltersAsync(model: OrderFilterModel): Promise<void> {
        await this.setState({orderFilterModel: model});

        await this.fetchOrdersAsync();
    }

    private async openFiltersAsync(): Promise<void> {
        await this._orderFiltersModalRef.current!.openAsync();
    }

    private async redirectToOrderDetailsAsync(orderId: string): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.mobileOrderDetails(orderId));
    }

    private getOrderStatusClassName(item: OrderMobileInfo): string {
        switch (item.status) {
            case OrderStatus.Delivered:
                return styles.deliveredCircle;
            case OrderStatus.PartiallyDelivered:
                return styles.partiallyDeliveredCircle;
            case OrderStatus.Submitted:
                return styles.closedCircle;
            case OrderStatus.Closed:
                return styles.closedCircle;
            case OrderStatus.Open:
                return styles.openCircle;
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.fetchOrdersAsync()
    }

    private get top(): number | string | null {
        return this.props.top || null;
    }

    private get paddingTop(): number | string | null {
        return this.props.paddingTop || null;
    }

    private get marginTop(): number | string | null {
        return this.props.marginTop || null;
    }

    private get customer(): Customer {
        return this.props.customer;
    }

    private getIconName(): string {
        return (OrderFilterModel.isEmpty(this.state.orderFilterModel))
            ? "far filter"
            : "fas filter";
    }

    private async onContainerScrollAsync(position: number, height: number): Promise<void> {
        if (position > 0.75 * height) {
            await this.showMoreAsync();
        }
    }
    
    public renderExpandedContent(order: OrderMobileInfo): React.ReactNode {
        const express: boolean = order.express;
        const hasCloseDate: boolean = (!express) && (order.plannedCloseDate != null);
        const hasDeliveryDate: boolean = (!express) && (order.plannedDeliveryDate != null);
        const hasSubmittedDate: boolean = (order.submittedAt != null);

        return (
            <div className={styles.expandableContent}>

                <div className={styles.subtitle}>

                    <table>

                        <tbody>

                        <tr>
                            <td className={styles.key}>
                                {Localizer.mobileOrderListOrderItems}
                            </td>
                            <td className={styles.value}>
                                {"{0}".format(order.productsCount)}
                            </td>
                        </tr>

                        <tr>
                            <td className={styles.key}>
                                {Localizer.genericCreatedAt}
                            </td>
                            <td className={styles.value}>
                                {"{0:D} {0:dddd}".format(order.createdAt).toUpperCase()}
                            </td>
                        </tr>

                        {
                            (hasCloseDate) &&
                            (
                                <tr>
                                    <td className={styles.key}>
                                        {Localizer.mobileOrderListCloseDate}
                                    </td>
                                    <td className={styles.value}>
                                        {"{0:D} {0:dddd}".format(order.plannedCloseDate).toUpperCase()}
                                    </td>
                                </tr>

                            )
                        }

                        {
                            (hasSubmittedDate) &&
                            (
                                <tr>
                                    <td className={styles.key}>
                                        {Localizer.mobileOrderListSubmittedDate}
                                    </td>
                                    <td className={styles.value}>
                                        {"{0:D} {0:dddd}".format(order.submittedAt).toUpperCase()}
                                    </td>
                                </tr>

                            )
                        }

                        {
                            (hasDeliveryDate) &&
                            (
                                <tr>
                                    <td className={styles.key}>
                                        {Localizer.mobileOrderListShelvingDate}
                                    </td>
                                    <td className={styles.value}>
                                        {"{0:D} {0:dddd}".format(order.plannedDeliveryDate).toUpperCase()}
                                    </td>
                                </tr>

                            )
                        }

                        </tbody>

                    </table>

                </div>

            </div>
        );
    }

    public renderOrder(item: OrderMobileInfo, index: number): React.ReactNode {
        const unconfirmed: (ReactElement | string)[] = this.toMultiLines("Delivery: <mark>{0}</mark>".format(Localizer.mobileOrderListUnconfirmed));

        const deliveryInformation: (ReactElement | string)[] = (item.deliveredAt != null)
            ? this.toMultiLines(Localizer.mobileOrderListDeliveredDateTitle.format(item.deliveredAt)) // delivered
            : (this.props.express)
                ? (item.submittedAt != null)
                    ? this.toMultiLines(Localizer.mobileOrderListSubmittedDateTitle.format(item.submittedAt))
                    : this.toMultiLines("Order created: <mark>{0:D} {0:ddd}</mark>".format(item.createdAt))
                : (item.plannedDeliveryDate)
                    ? this.toMultiLines(Localizer.mobileOrderListShelvingDateTitle.format(item.plannedDeliveryDate))
                    : unconfirmed;

        return (
            <div key={index}
                 className={this.css(styles.orderListItem, item.express && styles.express, "cursor-pointer")}
            >

                <div className={styles.title}>

                    <div className={styles.status}>

                        <span className={this.css(styles.circle, this.getOrderStatusClassName(item))}/>

                    </div>

                    <div className={styles.name} onClick={() => this.toggleAsync(item.number)}>

                        <span> {this.toMultiLines(Localizer.mobileOrderListOrderNumber.format(item.number))} </span>

                        <span className={styles.small}> {this.toMultiLines(Localizer.mobileOrderListErpOrderNumber.format(item.erpId))} </span>

                        <span className={styles.small}> {deliveryInformation} </span>

                    </div>

                    <div className={styles.info}>
                        <Icon name="info-circle"
                              size={IconSize.X2}
                              onClick={() => this.redirectToOrderDetailsAsync(item.id)}
                        />
                    </div>

                </div>

                {
                    (this.expandOrder(item.number)) &&
                    (
                        this.renderExpandedContent(item)
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        const orderListHeaderStyle: React.CSSProperties = {};
        
        if (this.top) {
            orderListHeaderStyle.top = this.top;
        }
        if (this.paddingTop) {
            orderListHeaderStyle.paddingTop = this.paddingTop;
        }
        if (this.marginTop) {
            orderListHeaderStyle.marginTop = this.marginTop;
        }
        
        return (
            <div className={this.css(styles.orderList)}>

                <div className={this.css(styles.stickyOrderListHeader)} style={orderListHeaderStyle}>

                    <div className={this.css(styles.header)}>
                        
                        <span>
                            {this.toMultiLines(this.props.title)}
                        </span>

                        <Button id={"openFilter"}
                                icon={{name: this.getIconName(), size: IconSize.Large}}
                                type={ButtonType.Primary}
                                className={this.css(styles.filter)}
                                onClick={() => this.openFiltersAsync()}
                        />

                    </div>

                </div>

                {(this.state.isLoading) &&
                (
                    <div className={styles.spinner}>
                        <Spinner/>
                    </div>)
                }

                {
                    (!this.state.isLoading) && ((this.state.displayedOrders == null) || (this.state.displayedOrders.length == 0)) &&
                    (
                        <div className={this.css(styles.orderListItem, styles.noItems)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.displayedOrders) &&
                    (
                        this.state.displayedOrders.map((item: OrderMobileInfo, index: number) =>
                            this.renderOrder(item, index)
                        )
                    )
                }

                {
                    (this.state.filteredOrders.length > this.state.visible) &&
                    (
                        <div className={this.css(styles.orderListItem, styles.customItem, styles.showMore)} onClick={() => this.showMoreAsync()}>
                            {Localizer.mobileOrderLabelsPageShowMore}
                        </div>
                    )
                }
                
                <OrderFiltersModal ref={this._orderFiltersModalRef}
                                   title={Localizer.mobileOrderListFilterTitle}
                                   model={this.state.orderFilterModel}
                                   onSubmit={(sender: OrderFiltersModal, model: OrderFilterModel) => this.submitFiltersAsync(model)}
                />

                <Layout.Scroller ref={this._qrScrollerRef}
                                 onScroll={(_, position: number, height: number) => this.onContainerScrollAsync(position, height)}
                />
                
            </div>
        );
    }
};