// autogenerated
import {DeliveryDayOfWeek} from "@/models/Enums";

export default class GenerateCustomerCalendarRequest {

    public customerId: string | null = null;

    public closeInterval: number = 0;

    public deliveryInterval: number = 0;

    public deliveryStartDate: Date = new Date();

    public deliveryEndDate: Date | null = null;

    public deliveryDay: DeliveryDayOfWeek = DeliveryDayOfWeek.Monday;

    public customerAwayDays: Date[] | null = null;

    public openOrderCloseDate: Date | null = null;

    public openOrderDeliveryDate: Date | null = null;

    public readonly isGenerateCustomerCalendarRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof GenerateCustomerCalendarRequest) || ((from as any).isGenerateCustomerCalendarRequest === true));
    }

    public static as(from?: object | null): GenerateCustomerCalendarRequest | null {
        return (GenerateCustomerCalendarRequest.is(from)) ? from as GenerateCustomerCalendarRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}