import React from "react";
import {BasePageParameters, LdData, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import {LdDataHeader, PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./PrivacyPolicy.module.scss";

export interface IPrivacyPolicyParameters extends BasePageParameters {
}

interface IPrivacyPolicyState {
    applicationLdData: LdData | null;
}

export default class PrivacyPolicy extends AnonymousPage<IPrivacyPolicyParameters, IPrivacyPolicyState> {

    state: IPrivacyPolicyState = {
        applicationLdData: null,
    };
    
    public getTitle(): string {
        return Localizer.privacyPolicyPageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.privacyPolicy}>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={"/privacy"}
                />

                <PageHeader title={Localizer.privacyPolicyPageTitle} 
                            subtitle={Localizer.myAccountPageAcceptancePrivacyNoticeTitle}
                />

                <PageRow>
                    <div className="col-lg-12">
                        
                        <span className={styles.container}>
                            {ReactUtility.toMultiLines(Localizer.myAccountPageAcceptancePrivacyNoticeContent)}
                        </span>

                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}