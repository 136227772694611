// autogenerated

export default abstract class BaseConcurrencyRequest {

    public lastModifiedAt: Date | null = null;

    
    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}