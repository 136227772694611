import React from "react";
import {PageContainer, RouteWidget, WidgetContainer} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import GetUserInventoryInformationResponse from "@/models/server/responses/GetUserInventoryInformationResponse";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import {IManualProps, PageRoute, PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Localizer from "@/localization/Localizer";

import styles from "./Inventory.module.scss";
import GetUserInventoryInformationRequest from "@/models/server/requests/GetUserInventoryInformationRequest";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";

export interface IInventoryProps {
}

interface IInventoryState {
    ongoingInventoryId: string | null;
    ongoingStartedAt: Date | null;
    ongoingValidUntil: Date | null;
    lastEditableInventoryId: string | null;
    lastEditableStartedAt: Date | null;
    lastEditableValidUntil: Date | null;
    initialized: boolean;
}

export default class Inventory extends MobileAuthorizedPage<IInventoryProps, IInventoryState> {

    state: IInventoryState = {
        ongoingInventoryId: null,
        ongoingStartedAt: null,
        ongoingValidUntil: null,
        lastEditableInventoryId: null,
        lastEditableStartedAt: null,
        lastEditableValidUntil: null,
        initialized: false
    };

    private async getUserInventoriesInformationAsync(): Promise<void> {
        const request = new GetUserInventoryInformationRequest();
        request.customerId = this.customer.id;

        const response: GetUserInventoryInformationResponse = await this.postAsync("/api/mobileApp/getUserInventoryInformation", request);

        await this.setState({
            ongoingInventoryId: response.ongoingInventoryId,
            ongoingStartedAt: response.ongoingStartedAt,
            ongoingValidUntil: response.ongoingValidUntil,
            lastEditableInventoryId: response.lastEditableInventoryId,
            lastEditableStartedAt: response.lastEditableStartedAt,
            lastEditableValidUntil: response.lastEditableValidUntil,
            initialized: true
        });
    }

    private async createInventoryAsync(): Promise<void> {
        if (this.showLastEditableInventoryWidget) {
            const confirmed: boolean = await this.confirmAsync(Localizer.mobileInventoryPageStartNewConfirmation)
            
            if (!confirmed) {
                return;
            }
        }
        
        const inventory: Inventory = await this.postAsync("/api/mobileApp/createInventory", this.customer.id);

        const route: PageRoute = PageDefinitions.mobileInventoryDetailsRoute(inventory.id);

        await PageRouteProvider.redirectAsync(route);
    }

    private get initialized(): boolean {
        return this.state.initialized;
    }

    private get ongoingInventoryId(): string | null {
        return this.state.ongoingInventoryId;
    }

    private get ongoingStartedAt(): Date | null {
        return this.state.ongoingStartedAt;
    }

    private get ongoingValidUntil(): Date | null {
        return this.state.ongoingValidUntil;
    }

    private get lastEditableInventoryId(): string | null {
        return this.state.lastEditableInventoryId;
    }
    
    private get lastEditableStartedAt(): Date | null {
        return this.state.lastEditableStartedAt;
    }
    
    private get lastEditableValidUntil(): Date | null {
        return this.state.lastEditableValidUntil;
    }
    
    private get showNewInventoryWidget(): boolean {
        return (this.initialized) && (this.ongoingInventoryId == null);
    }

    private get showOngoingInventoryWidget(): boolean {
        return (this.initialized) && (this.ongoingInventoryId != null);
    }

    private get showLastEditableInventoryWidget(): boolean {
        return (this.initialized) && (!this.showOngoingInventoryWidget) && (this.lastEditableInventoryId != null);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.getUserInventoriesInformationAsync();
    }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileInventoryPageTitle.toUpperCase(),
            render: () => this.renderManual(),
        };
    }

    public renderManual(): React.ReactNode {
        const orderCustomer: CustomerMobileInfo = this.customer;
        
        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(orderCustomer.codeInfo)} </mark> - {orderCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{this.customer.customerGroupName}</b></span>

            </div>
        )
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.orderLabels, this.mobile && styles.mobile)}
            >
                <WidgetContainer>

                    {
                        (this.showNewInventoryWidget) &&
                        (
                            <RouteWidget icon={{name: "fal fa-play"}}
                                         label={Localizer.mobileInventoryPageStartNew}
                                         onClick={() => this.createInventoryAsync()}
                                         description={Localizer.mobileInventoryPageStartsToday.format(Utility.today())}
                            />
                        )
                    }

                    {
                        (this.showOngoingInventoryWidget) &&
                        (
                            <RouteWidget icon={{name: "fal fa-edit"}}
                                         route={() => PageDefinitions.mobileInventoryDetailsRoute(this.ongoingInventoryId!)}
                                         label={Localizer.mobileInventoryPageContinueOngoing}
                                         description={Localizer.mobileInventoryPageStarted.format(this.ongoingStartedAt, this.ongoingValidUntil)}
                            />
                        )
                    }

                    {
                        (this.showLastEditableInventoryWidget) &&
                        (
                            <RouteWidget icon={{name: "fal fa-edit"}}
                                         route={() => PageDefinitions.mobileInventoryDetailsRoute(this.lastEditableInventoryId!)}
                                         label={Localizer.mobileInventoryPageEditPrevious}
                                         description={Localizer.mobileInventoryPageStarted.format(this.lastEditableStartedAt, this.lastEditableValidUntil)}
                            />
                        )
                    }

                </WidgetContainer>

            </PageContainer>
        );
    }
}