// autogenerated
import {ExportEncodingType} from "@/models/Enums";

export default class ExportInventoryProductsToCsvRequest {

    public inventoryProductIds: string[] | null = null;

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportInventoryProductsToCsvRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ExportInventoryProductsToCsvRequest) || ((from as any).isExportInventoryProductsToCsvRequest === true));
    }

    public static as(from?: object | null): ExportInventoryProductsToCsvRequest | null {
        return (ExportInventoryProductsToCsvRequest.is(from)) ? from as ExportInventoryProductsToCsvRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}