import React from "react";
import {Button, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, IconSize, PageContainer, Spinner} from "@reapptor-apps/reapptor-react-components";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import Inventory from "@/models/server/Inventory";
import ListInventoriesRequest from "@/models/server/requests/ListInventoriesRequest";
import {IManualProps, PageCacheProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import InventoryProduct from "@/models/server/InventoryProduct";
import ListInventoryProductsRequest from "@/models/server/requests/ListInventoryProductsRequest";
import ProductAssortmentCard, {ProductAssortmentCardBorder} from "@/components/ProductAssortmentCard/ProductAssortmentCard";
import InventoryFiltersModal, {InventoryFilterModel} from "@/pages/Mobile/InventoryManagement/InventoryFiltersModal/InventoryFiltersModal";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import Localizer from "@/localization/Localizer";

import styles from "./InventoryManagement.module.scss";

export interface IInventoryManagementProps {
}

interface IInventoryManagementState {
    inventories: Inventory[];
    selectedInventory: Inventory | null;
    inventoryProducts: InventoryProduct[];
    filterModel: InventoryFilterModel;
    loading: boolean;
}

export default class InventoryManagement extends MobileAuthorizedPage<IInventoryManagementProps, IInventoryManagementState> {

    private readonly _inventoryFiltersModalRef: React.RefObject<InventoryFiltersModal> = React.createRef();

    state: IInventoryManagementState = {
        inventories: [],
        selectedInventory: null,
        inventoryProducts: [],
        filterModel: new InventoryFilterModel(),
        loading: false
    };

    private getIconName(): string {
        return (InventoryFilterModel.isEmpty(this.state.filterModel))
            ? "far filter"
            : "fas filter";
    }

    private get model(): InventoryFilterModel {
        return this.state.filterModel;
    }

    public get loading(): boolean {
        return this.state.loading;
    }

    private get selectedInventory(): Inventory | null {
        return this.state.selectedInventory;
    }

    private async submitFiltersAsync(model: InventoryFilterModel): Promise<void> {
        await this.setState({filterModel: model});

        if (this.selectedInventory != null) {
            await this.fetchInventoryProductsAsync(this.selectedInventory.id);
        }
    }

    private async onInventoryChangeAsync(inventory: Inventory): Promise<void> {
        await this.setState({selectedInventory: inventory});

        await this.fetchInventoryProductsAsync(inventory.id);
    }

    private async fetchInventoriesAsync(): Promise<Inventory[]> {
        const request = new ListInventoriesRequest();
        request.customerId = this.customer.id;

        await this.setState({loading: true});
        
        const inventories: Inventory[] = await PageCacheProvider.getAsync(`listInventories?${this.customer.id}`, () => this.postAsync("/api/inventoryManagement/listInventories", request));
        
        await this.setState({loading: false});
        
        return inventories;
    }

    private async fetchInventoryProductsAsync(inventoryId: string): Promise<InventoryProduct[]> {
        const request = new ListInventoryProductsRequest();
        request.search = this.model.search;
        request.inventoried = this.model.inventoried;
        request.inventoryId = inventoryId;
        request.includeProductGroups = false;

        await this.setState({loading: true});
        
        const products: InventoryProduct[] = await this.postAsync("/api/inventoryManagement/listInventoryProducts", request);

        await this.setState({inventoryProducts: products, loading: false});

        return products;
    }

    private async openFiltersAsync(): Promise<void> {
        await this._inventoryFiltersModalRef.current!.openAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const inventories: Inventory[] = await this.fetchInventoriesAsync();

        await this.setState({inventories});

        if (inventories.length > 0) {
            const firstInventoryId: string = inventories[0].id;

            await this.fetchInventoryProductsAsync(firstInventoryId);
        }
    }

    public hasSpinner(): boolean {
        return true;
    }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileInventoryPageTitle.toUpperCase(),
            render: () => this.renderManual(),
        };
    }
    
    public renderManual(): React.ReactNode {
        const orderCustomer: CustomerMobileInfo = this.customer;
        
        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(orderCustomer.codeInfo)} </mark> - {orderCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{this.customer?.customerGroupName}</b></span>

                <span>{this.toMultiLines(Localizer.mobileInventoryDetailsPageInventoryStartedDate.format(this.selectedInventory?.date))}</span>

            </div>
        )
    }
    
    public renderCards(): React.ReactNode {
        const hasData: boolean = (this.state.inventoryProducts.length > 0);

        return (
            <React.Fragment>

                {
                    (hasData)
                        ? <div className={styles.content}>

                            {
                                this.state.inventoryProducts.map((item: InventoryProduct, index: number) =>
                                    this.renderCardItem(item, index)
                                )
                            }

                        </div>
                        : <div className={styles.content}>
                            <div className={this.css(styles.cardContainer, styles.noItems)}>
                                {Localizer.genericNoData}
                            </div>
                        </div>
                }

            </React.Fragment>
        );
    }

    public renderCardItem(item: InventoryProduct, index: number): React.ReactNode {
        const inventoried: boolean = (item.quantity != null);
        const borderType: ProductAssortmentCardBorder = inventoried ? ProductAssortmentCardBorder.Selected : ProductAssortmentCardBorder.Unselected;

        return (
            <div key={index} className={this.css(styles.cardContainer, !inventoried && styles.notInventoried)}>

                <ProductAssortmentCard className={styles.card}
                                       key={index}
                                       productAssortment={item.productAssortment!}
                                       borderType={borderType}
                                       renderFooter={() => this.renderFooter(item, inventoried)}
                />

            </div>
        );
    }

    public renderFooter(item: InventoryProduct, inventoried: boolean): React.ReactNode {
        const quantityPcs: number = ((item.quantity != null) && (item.product != null)) ? item.quantity! * item.product!.packageQuantity : 0;
        
        return (
            <div className={styles.footer}>

                {(inventoried)
                    ? <React.Fragment>

                        <div>{this.toMultiLines(Localizer.mobileInventoryAdministrationQuantityPcs.format(item.quantity?.toFixed(2), quantityPcs.toFixed(2)))}</div>

                    </React.Fragment>
                    : <div className={styles.notInventoriedText}>{Localizer.mobileInventoryAdministrationNotInventoried}</div>
                }
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.inventoryManagement, this.mobile && styles.mobile)}
            >

                <div className={this.css(styles.stickyOrderListHeader)}>

                    <div className={this.css(styles.header)}>

                        <Dropdown id="inventory"
                                  required noSubtext
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  items={this.state.inventories}
                                  selectedItem={this.state.selectedInventory}
                                  onChange={(_, item) => this.onInventoryChangeAsync(item!)}
                        />

                        <Button id={"openFilter"}
                                icon={{name: this.getIconName(), size: IconSize.Large}}
                                type={ButtonType.Primary}
                                className={this.css(styles.filter)}
                                onClick={() => this.openFiltersAsync()}
                        />

                    </div>

                </div>

                {
                    this.renderCards()
                }

                <InventoryFiltersModal ref={this._inventoryFiltersModalRef}
                                       title={Localizer.mobileInventoryAdministrationFilter}
                                       model={this.state.filterModel}
                                       onSubmit={(sender: InventoryFiltersModal, model: InventoryFilterModel) => this.submitFiltersAsync(model)}
                />

                {(this.loading) && <Spinner/>}
                
            </PageContainer>
        );
    }
}