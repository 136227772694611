// autogenerated

export default class UserRole {

    public roleName: string = "";

    public customerId: string | null = null;

    public customerGroupId: string | null = null;

    public readonly isUserRole: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof UserRole) || ((from as any).isUserRole === true));
    }

    public static as(from?: object | null): UserRole | null {
        return (UserRole.is(from)) ? from as UserRole : null;
    }

    // #region Placeholder

    constructor(roleName: string = "", customerId: string | null = null, customerGroupId: string  | null = null) {
        this.roleName = roleName;
        this.customerId = customerId;
        this.customerGroupId = customerGroupId;
    }

    // #endregion
}