import {BasePage, ch, PageRouteProvider, BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import UserContext from "../server/UserContext";
import User from "../server/User";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

export default abstract class AuthorizedPage<TProps extends BasePageParameters = {}, TState = {}>
    extends BasePage<TProps, TState, UserContext> {
    
    public get isAcceptedRegulations(): boolean {
        const user: User | null = ch.findUser();
        return (user != null) && (user.agreementAccepted && user.registrationAccepted);
    }

    public getUser(): User {
        return ch.getUser();
    }

    public getUserId(): string {
        return ch.getUserId();
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get isCustomer(): boolean {
        return this.userContext.isCustomer;
    }

    public async initializeAsync(): Promise<void> {

        if (!this.isAuthorized) {
            await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute, true, true);
        }

        await super.initializeAsync();

        if (!this.isAcceptedRegulations) {
            await this.alertWarningAsync(Localizer.myAccountPageAcceptanceRequired);
        }
    }
}