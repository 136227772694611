import React from "react";
import {BaseComponent, PageRoute, PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {Icon, IconSize, IconStyle} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import Product from "@/models/server/Product";
import ImageProvider from "@/providers/ImageProvider";
import ProductAssortmentMobileInfo from "@/models/server/ProductAssortmentMobileInfo";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./ProductAssortmentCard.module.scss";

import noImage from "../Images/NoImage.png";

export enum ProductAssortmentCardBorder {
    Unselected,

    Selected
}

interface IProductCardProps {
    id?: string;

    className?: string;

    /**
     * Product displayed in the {@link ProductAssortmentCard}.
     */
    productAssortment: ProductAssortmentMobileInfo;

    productReplacementId?: string | null;

    toProductReplacementIds?: string[] | null;

    detailsProductId?: string | null;

    borderType?: ProductAssortmentCardBorder;

    express?: boolean;

    showOrderQuantity?: boolean;

    renderHeader?(sender: ProductAssortmentCard): React.ReactNode;

    renderFooter?(sender: ProductAssortmentCard): React.ReactNode;
}

interface IProductCardState {
    expanded: boolean;
}

export default class ProductAssortmentCard extends BaseComponent<IProductCardProps, IProductCardState> {

    public state: IProductCardState = {
        expanded: false
    }

    private get productTitleWithSalesUnit(): string {
        return (this.product)
            ? Product.getName(this.product)
            : "";
    }

    private get getSalesUnitAndSize(): string {
        return (this.product)
            ? Product.getSalesUnitAndSize(this.product)
            : "";
    }

    private getProductTitleFontSizeStyle(title: string): string {
        const length: number = title.length;

        if (length >= 80) {
            return styles.sizeXl;
        } else if (length >= 70) {
            return styles.sizeL;
        } else if (length >= 50) {
            return styles.sizeM;
        }
        return "";
    }

    private async redirectToProductDetailsAsync(productIdOrAssortmentIdOrOrderProductId: string, toProductReplacementIds: string[] | null): Promise<void> {
        const page: PageRoute = PageDefinitions.mobileProductDetails(productIdOrAssortmentIdOrOrderProductId, this.props.productReplacementId ?? null, toProductReplacementIds);

        await PageRouteProvider.redirectAsync(page);
    }

    private async toggleFavoriteOnDefaultHeaderAsync(e: React.MouseEvent): Promise<void> {
        e.stopPropagation();

        this.productAssortment.favorite = (!this.productAssortment.favorite);

        AittaController.setFavorite(this.productAssortment.id, this.productAssortment.favorite);

        await this.reRenderAsync();
    }

    public async toggleAsync(): Promise<void> {
        await this.setState({expanded: !this.state.expanded});
    }

    public get productAssortment(): ProductAssortmentMobileInfo {
        return this.props.productAssortment;
    }

    public get isAitta(): boolean {
        return (this.productAssortment.customerId != null);
    }

    public get product(): Product | null {
        return this.productAssortment.product;
    }

    public get expanded(): boolean {
        return this.state.expanded;
    }

    public get borderType(): ProductAssortmentCardBorder {
        return (this.props.borderType ?? ProductAssortmentCardBorder.Unselected);
    }

    public get showOrderQuantity(): boolean {
        return (this.props.showOrderQuantity ?? false);
    }

    public get isMediq(): boolean {
        return AittaController.user.isMediq;
    }

    // Renders
    public renderHeader(): React.ReactNode {
        return (this.props.renderHeader)
            ? this.props.renderHeader(this)
            : this.renderDefaultHeader();
    }

    public renderDefaultHeader(): React.ReactNode {
        const favoriteIconStyle: IconStyle = (this.productAssortment.favorite)
            ? IconStyle.Solid
            : IconStyle.Regular;

        const productTitle: string = this.productTitleWithSalesUnit;

        const productTitleFontSizeStyle: any = this.getProductTitleFontSizeStyle(productTitle);

        const replacedStyles: any = ((this.isMediq) && (this.props.toProductReplacementIds) && (this.props.toProductReplacementIds.length > 0) && (styles.hasReplaced))
        return (
            <React.Fragment>

                <div className={styles.title}>

                    <div className={this.css(styles.favorite)}
                         onClick={(e: React.MouseEvent) => this.toggleFavoriteOnDefaultHeaderAsync(e)}
                    >
                        <Icon name="star"
                              style={favoriteIconStyle}
                              size={IconSize.X2}
                        />

                    </div>

                    <div className={this.css(styles.name, productTitleFontSizeStyle)}
                         onClick={() => this.toggleAsync()}
                    >
                        <span>{this.toMultiLines(productTitle)}</span>
                    </div>

                    <div className={this.css(styles.info, this.props.productReplacementId && styles.hasReplacement, replacedStyles)}>
                        <Icon name="info-circle"
                              size={IconSize.X2}
                              onClick={() => this.redirectToProductDetailsAsync(this.props.detailsProductId ?? this.productAssortment.productId, this.props.toProductReplacementIds || null)}
                        />
                    </div>

                </div>

                <span>{this.toMultiLines(this.getSalesUnitAndSize)}</span>

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        const aittaStyle: any = ((this.isAitta) && (styles.aitta));
        const contractStyle: any = ((!this.isAitta) && (styles.contract));
        const deletedStyle: any = this.productAssortment.deleted && (styles.deleted);
        const selectedStyle: any = (this.borderType == ProductAssortmentCardBorder.Selected) && styles.selected;
        const expressStyle: any = (this.props.express) && styles.express;

        return (
            <div id={this.id}
                 className={this.css(this.props.className, styles.productAssortmentCard, selectedStyle, expressStyle, this.mobile && styles.mobile)}>
                {
                    (this.product) &&
                    (
                        <div className={this.css(styles.container, aittaStyle, contractStyle, deletedStyle, expressStyle)}>

                            <div
                                className={this.css(styles.innerContainer, contractStyle && styles.innerContract, selectedStyle, expressStyle)}>

                                <div className={styles.header}>

                                    {this.renderHeader()}

                                </div>

                                {
                                    (this.showOrderQuantity) &&
                                    (
                                        <div className={styles.orderQuantity}>

                                            <span
                                                className={styles.small}> {this.toMultiLines(Localizer.productAssortmentCardOrderThreshold.format(this.productAssortment.orderThreshold))} </span>

                                            <span
                                                className={styles.small}> {this.toMultiLines(Localizer.productAssortmentCardOrderQuantity.format(this.productAssortment.orderQuantity))} </span>

                                        </div>
                                    )
                                }

                                {
                                    (this.expanded) &&
                                    (
                                        <div className={styles.expandableContent}>

                                            <div className={styles.subtitle}>

                                                <table>

                                                    <tbody>

                                                    <tr>
                                                        <td className={styles.key}>
                                                            {Localizer.mobileCatalogOrderProductItemCode}
                                                        </td>
                                                        <td className={styles.value}>
                                                            {ReactUtility.toMultiLines(this.product.code)}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className={styles.key}>
                                                            {Localizer.mobileCatalogOrderProductItemManufacturer}
                                                        </td>
                                                        <td className={styles.value}>
                                                            {this.product.manufactureCode}
                                                        </td>
                                                    </tr>

                                                    {
                                                        (this.product.wholesalePackageQuantity > 1) &&
                                                        (
                                                            <tr>
                                                                <td className={styles.key}>
                                                                    {Localizer.productAttributeKeyWholesalePackageQuantity}
                                                                </td>
                                                                <td className={styles.value}>
                                                                    {this.product.wholesalePackageQuantity}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    {
                                                        (!!this.product.size) &&
                                                        (
                                                            <tr>
                                                                <td className={styles.key}>
                                                                    {Localizer.productAttributeKeySize}
                                                                </td>
                                                                <td className={styles.value}>
                                                                    {this.product.size}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    </tbody>

                                                </table>

                                                {this.children}

                                            </div>

                                            <img className={styles.productImage}
                                                 src={ImageProvider.getRequiredImageSrc(Product.getImage(this.product), noImage)}
                                                 alt={""}
                                            />

                                        </div>
                                    )
                                }

                                <div className={styles.footer}>
                                    {
                                        (this.props.renderFooter && this.props.renderFooter(this))
                                    }
                                </div>

                            </div>

                        </div>
                    )
                }

            </div>
        )
    }
}