import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, IconSize, Inline, JustifyContent, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";

import styles from "./FaqToolbar.module.scss";

export interface IFaqToolbarProps {
    onSearch(sender: FaqToolbar, search: string): Promise<void>;
    onAdd(sender: FaqToolbar): Promise<void>;
}

interface IFaqToolbarState {
    search: string | null
}

export default class FaqToolbar extends BaseComponent<IFaqToolbarProps, IFaqToolbarState> {

    state: IFaqToolbarState = {
        search: null
    };

    private async onSearchChangeAsync(value: string) {
        await this.setState({search: value});
    }

    public get search(): string | null {
        return this.state.search || null;
    }
    
    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.productsToolbar)}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Inline>

                        <TextInput id="productSearch" inline clearButton
                                   width={"25rem"}
                                   placeholder={Localizer.faqToolbarSearchPlaceholder}
                                   value={this.search}
                                   onChange={(sender, value: string) => this.onSearchChangeAsync(value)}
                        />

                        <Button id={"search"}
                                block
                                label={Localizer.genericSearch}
                                icon={{name: "far search", size: IconSize.Large}}
                                type={ButtonType.Primary}
                                onClick={async () => await this.props.onSearch(this, this.state.search || "")}
                        />

                    </Inline>

                    <Button id={"add"}
                            block right
                            label={Localizer.genericAdd}
                            icon={{name: "plus", size: IconSize.Large}}
                            type={ButtonType.Primary}
                            onClick={async () => await this.props.onAdd(this)}
                    />

                </ToolbarRow>

            </ToolbarContainer>
        )
    }
}