// autogenerated

export default class CheckOpenOrdersRequest {

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public readonly isCheckOpenOrdersRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CheckOpenOrdersRequest) || ((from as any).isCheckOpenOrdersRequest === true));
    }

    public static as(from?: object | null): CheckOpenOrdersRequest | null {
        return (CheckOpenOrdersRequest.is(from)) ? from as CheckOpenOrdersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}