import React from "react";
import {BaseComponent, ch, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, Layout} from "@reapptor-apps/reapptor-react-components";
import AittaConstants from "@/helpers/AittaConstants";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import AittaController from "@/pages/AittaController";

import styles from "./mediq.module.scss";
import mobileAppStyles from "./pages/Mobile/mobile.module.scss";

import appLogo from "@/app-logo-white.png";

class App extends BaseComponent {

    public render(): React.ReactNode {
        return (
            <Layout className={() => this.css(styles.layout, AittaController.mobileAppContent && mobileAppStyles.mobile, ch.isDevelopment && styles.development)}
                    topNavClassName={() => this.css((AittaController.mobileAppContent && mobileAppStyles.topNav))}
                    topNavLogo={appLogo}
                    topNavLogoText={AittaConstants.applicationName}
                    leftNav={() => AittaController.getLeftNav()}
                    footerName={AittaConstants.companyName}
                    footerLinks={() => AittaController.footerLinks}
                    onLogoClick={() => AittaController.onLogoClickAsync()}
            />
        );
    }
}

//Register initialize events
ch.registerInitializeCallback(async () => await AddressHelper.loadGoogleLibraryAsync());
ch.registerInitializeCallback(async () => await GoogleAnalyticsHelper.initializeAsync());
ch.registerInitializeCallback(async () => await AittaController.initializeAsync());
//Register authorize events
ch.registerAuthorizeCallback(async () => await UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(async () => await AittaController.authorizeAsync());

export default App;