import React, {ReactElement} from "react";
import {BaseComponent, ch, PageCacheTtl} from "@reapptor-apps/reapptor-react-common";
import ReportsToolbar from "@/pages/ReportManagement/ReportsToolbar/ReportsToolbar";
import ReportsToolbarModel, {ReportPageType} from "@/pages/ReportManagement/ReportsToolbar/ReportsToolbarModel";
import {AittaReportFilter, ExportEncodingType, ReportType} from "@/models/Enums";
import RevenueChartDataItem from "@/models/server/RevenueChartDataItem";
import RevenueReport from "@/models/server/RevenueReport";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import ExportReportToCsvRequest from "@/models/server/requests/ExportReportToCsvRequest";
import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";
import GetRevenueReportRequest from "@/models/server/requests/GetRevenueReportRequest";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./RevenueReportPanel.module.scss";

export interface IRevenueReportPanelProps {
}

interface IRevenueReportPanelState {
    toolbar: ReportsToolbarModel;
    revenueChartData: RevenueChartDataItem[];
    generation: number;
}

export default class RevenueReportPanel extends BaseComponent<IRevenueReportPanelProps, IRevenueReportPanelState> {

    state: IRevenueReportPanelState = {
        toolbar: new ReportsToolbarModel(),
        revenueChartData: [],
        generation: 0
    };

    private async fetchRevenueAsync(): Promise<void> {
        
        const initialized: boolean = ((AittaController.hasMediqAccess) || (!!this.customerId) || (!!this.customerGroupId));

        let revenueChartData: RevenueChartDataItem[] = [];
        
        if (initialized) {
            const request = new GetRevenueReportRequest();
            request.customerGroupId = this.customerGroupId;
            request.customerId = this.customerId;
            request.aittaAppOnly = this.aittaAppOnly;
            request.reportType = this.reportType;
            request.aittaReportFilter = this.aittaReportFilter;

            const revenueReport: RevenueReport = await this.postAsync("/api/reportManagement/getRevenueReport", request, PageCacheTtl._1m, 60);

            revenueChartData = this.getRevenueChartData(revenueReport);
        }

        await this.setState({revenueChartData, generation: this.state.generation + 1});
    }

    private async downloadCsvAsync(encodingType: ExportEncodingType): Promise<void> {
        const request = new ExportReportToCsvRequest();
        request.customerGroupId = this.customerGroupId;
        request.customerId = this.customerId;
        request.reportType = this.reportType;
        request.aittaReportFilter = this.aittaReportFilter;
        request.encodingType = encodingType;
        request.aittaAppOnly = this.aittaAppOnly;

        const file: FileModel = await this.postAsync("/api/reportManagement/exportRevenueReportToCsv", request, false,120);

        await ch.download(file);
    }
    
    private localizeDataSource(data: RevenueChartDataItem[]): string {
        const dataKey: string = Localizer.reportManagementPageRevenueTabLabel;
        
        const length: number = data.length;
        for (let i: number = 0; i < length; i++) {
            const dataItem: RevenueChartDataItem = data[i];
            (dataItem as any)[dataKey] = Utility.round(dataItem.revenue, 2);
        }
        
        return dataKey;
    }

    private getRevenueChartData(report: RevenueReport): RevenueChartDataItem[] {

        const chartData: RevenueChartDataItem[] = [];

        switch (this.reportType) {
            case ReportType.Annual:
                return RevenueReport.toAnnualRevenueReportChartData(report);

            case ReportType.Quarterly:
                return RevenueReport.toQuarterlyRevenueReportChartData(report);

            case ReportType.Monthly:
                return RevenueReport.toMonthlyRevenueReportChartData(report);
        }
        return chartData;
    }

    private renderCustomBarLabel({x, y, width, value}: any): ReactElement<SVGElement> {
        return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{"{0:C} €".format(value)}</text>;
    };

    private async onToolbarSubmitAsync(toolbar: ReportsToolbarModel): Promise<void> {
        await this.setState({toolbar});

        await this.fetchRevenueAsync();
    }

    public get customerId(): string | null {
        return this.toolbar.customer?.id || null;
    }

    public get customerGroupId(): string | null {
        return this.toolbar.customerGroup?.id || null;
    }

    public get reportType(): ReportType {
        return this.toolbar.reportType;
    }

    public get aittaAppOnly(): boolean {
        return this.toolbar.aittaAppOnly;
    }
    
    public get aittaReportFilter(): AittaReportFilter {
        return this.toolbar.aittaReportFilter;
    }

    public get toolbar(): ReportsToolbarModel {
        return this.state.toolbar;
    }

    public render(): React.ReactNode {
        
        const margin = {
            top: 20, right: 0, left: 0, bottom: 5,
        };

        const data: RevenueChartDataItem[] = this.state.revenueChartData;
        const dataKey: string = this.localizeDataSource(data);
        
        return (
            <div id={this.id} className={this.css(styles.plotReportPanel)}>

                <ReportsToolbar model={this.toolbar}
                                type={ReportPageType.Revenue}
                                downloadCsv={(_: ReportsToolbar, encodingType: ExportEncodingType) => this.downloadCsvAsync(encodingType)}
                                onChange={(_: ReportsToolbar, toolbar: ReportsToolbarModel) => this.onToolbarSubmitAsync(toolbar)}
                />

                <ResponsiveContainer width="100%" height={500}>

                    <BarChart key={this.state.generation}
                              data={data}
                              margin={margin}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey={dataKey} fill="#006983" label={({x, y, width, value}: any) => this.renderCustomBarLabel({x, y, width, value})}/>
                    </BarChart>

                </ResponsiveContainer>

            </div>
        )
    }
}