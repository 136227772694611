import React from "react";
import {BaseComponent, IBaseComponentProps, Justify} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Icon, IconSize, NumberInput, NumberInputBehaviour} from "@reapptor-apps/reapptor-react-components";
import InventoryProductMobileInfo from "@/models/server/InventoryProductMobileInfo";
import ProductAssortmentCard, {
    ProductAssortmentCardBorder
} from "@/components/ProductAssortmentCard/ProductAssortmentCard";
import Product from "@/models/server/Product";

import Localizer from "@/localization/Localizer";

import styles from "./InventoryProductItem.module.scss";

interface IInventoryProductItemProps extends IBaseComponentProps {
    inventoryProduct: InventoryProductMobileInfo;
    modified?: boolean;
    
    onDone(sender: InventoryProductItem, product: InventoryProductMobileInfo): Promise<void>;
    onModify(sender: InventoryProductItem, productId: string): Promise<void>;
}

export interface IInventoryProductItemState {
    modified: boolean;
}

export default class InventoryProductItem extends BaseComponent<IInventoryProductItemProps, IInventoryProductItemState> {

    state: IInventoryProductItemState = {
        modified: false
    };

    private _packageQuantity: number | null = null;

    private get inventoryProductMobileInfo(): InventoryProductMobileInfo {
        return this.props.inventoryProduct;
    }

    private get product(): Product | null {
        return this.inventoryProductMobileInfo.productAssortment?.product ?? null;
    }

    private get quantity(): number | null {
        return this.inventoryProductMobileInfo.quantity;
    }

    private get packageQuantity(): number {
        return this._packageQuantity ?? (this._packageQuantity = this.product?.packageQuantity ?? 0);
    }

    private get hasQuantity(): boolean {
        return (this.quantity != null);
    }

    private get doneLabel(): string {
        const quantity: number | null = this.quantity;

        if (quantity != null) {
            const quantityPieces: number = Math.round(quantity * this.packageQuantity);
            return Localizer.inventoryProductItemTotalPieces.format(quantityPieces);
        }

        return Localizer.inventoryProductItemDone;
    }

    private get doneActive(): boolean {
        return (this.state.modified) || (this.props.modified === true);
    }

    private async setPackagesAsync(value: number): Promise<void> {
        this.inventoryProductMobileInfo.inputPackages = value;

        await this.setModifiedAsync();
    }

    private async changeQuantityAsync(packages: boolean, increase: boolean): Promise<void> {
        const product: InventoryProductMobileInfo = this.inventoryProductMobileInfo;

        if (packages) {
            product.inputPackages = (increase)
                ? product.inputPackages + 1
                : product.inputPackages - 1;
        } else {
            product.inputPieces = (increase)
                ? product.inputPieces + 1
                : product.inputPieces - 1;
        }

        await this.setModifiedAsync();
    }

    private async modifyQuantityAsync(): Promise<void> {
        const inventoryProductMobileInfo: InventoryProductMobileInfo = this.inventoryProductMobileInfo;

        if (this.product) {
            inventoryProductMobileInfo.quantity = inventoryProductMobileInfo.inputPackages + (inventoryProductMobileInfo.inputPieces / this.product.packageQuantity);
        }
        
        await this.props.onModify(this, this.inventoryProductMobileInfo.id);
    }

    private async setPiecesAsync(value: number): Promise<void> {
        this.inventoryProductMobileInfo.inputPieces = value;

        await this.setModifiedAsync();
    }

    private async setModifiedAsync(modified: boolean = true, recalculateQuantity = true): Promise<void> {
        if (recalculateQuantity) {
            await this.modifyQuantityAsync();
        }
        
        await this.setState({modified});
    }

    private async doInventoryAsync(): Promise<void> {
        if (this.props.onDone) {
            await this.props.onDone(this, this.inventoryProductMobileInfo);
        }

        await this.setModifiedAsync(false, false);
    }

    public renderFooter(): React.ReactNode {

        const inputPackages: number = this.inventoryProductMobileInfo.inputPackages;
        const inputPieces: number = this.inventoryProductMobileInfo.inputPieces;
        const singlePackage: boolean = (this.packageQuantity == 1);

        const notStartedStyle: any = (!this.hasQuantity) && styles.notStarted;

        return (
            <div className={styles.content}>

                {
                    (!singlePackage) &&
                    (
                        <React.Fragment>

                            <div className={styles.row}>

                                <span>{Localizer.inventoryProductItemPackages}:</span>

                                <div className={styles.action}>

                                    <div>

                                        <Button block
                                                id={"decrease"}
                                                type={ButtonType.Orange}
                                                disabled={(inputPackages == 0)}
                                                icon={{name: "fas minus"}}
                                                onClick={() => this.changeQuantityAsync(true, false)}
                                        />

                                    </div>

                                </div>

                                <div className={styles.data}>

                                    <div className={this.css(styles.input, notStartedStyle)}>

                                        <NumberInput hideArrows clickToEdit
                                                     behaviour={NumberInputBehaviour.Restricted}
                                                     min={0}
                                                     max={999}
                                                     value={inputPackages}
                                                     onChange={(sender, quantity) => this.setPackagesAsync(quantity)}
                                        />

                                    </div>

                                </div>

                                <div className={styles.action}>

                                    <div>

                                        <Button block
                                                id={"increase"}
                                                type={ButtonType.Orange}
                                                icon={{name: "fas plus"}}
                                                onClick={() => this.changeQuantityAsync(true, true)}
                                        />

                                    </div>

                                </div>

                            </div>

                            <div className={styles.plus}>
                                <Icon name={"fal fa-plus"} size={IconSize.Large}/>
                            </div>

                        </React.Fragment>
                    )
                }

                <div className={styles.row}>

                    <span>{Localizer.inventoryProductItemPieces}:</span>

                    <div className={styles.action}>

                        <div>

                            <Button block
                                    id={"decrease"}
                                    type={ButtonType.Orange}
                                    icon={{name: "fas minus"}}
                                    disabled={(inputPieces == 0)}
                                    onClick={() => this.changeQuantityAsync(false, false)}
                            />

                        </div>

                    </div>

                    <div className={styles.data}>

                        <div className={this.css(styles.input, notStartedStyle)}>

                            <NumberInput hideArrows clickToEdit
                                         behaviour={NumberInputBehaviour.Restricted}
                                         min={0}
                                         max={999}
                                         step={1}
                                         value={inputPieces}
                                         onChange={(sender, quantity) => this.setPiecesAsync(quantity)}
                            />

                        </div>

                    </div>

                    <div className={styles.action}>

                        <div>

                            <Button block
                                    id={"increase"}
                                    type={ButtonType.Orange}
                                    icon={{name: "fas plus"}}
                                    onClick={() => this.changeQuantityAsync(false, true)}
                            />

                        </div>

                    </div>

                </div>

                <div className={styles.orderButton}>
                    {
                        <Button id={"submitInventoryItem"}
                                label={this.doneLabel}
                                iconPosition={Justify.Left}
                                type={ButtonType.Primary}
                                disabled={!this.doneActive}
                                onClick={() => this.doInventoryAsync()}
                        />
                    }
                </div>

            </div>
        );
    }

    public render(): React.ReactNode {
        const borderType: ProductAssortmentCardBorder = (this.hasQuantity)
            ? ProductAssortmentCardBorder.Selected
            : ProductAssortmentCardBorder.Unselected;

        return (
            <ProductAssortmentCard id={this.id}
                                   className={this.css(styles.inventoryProductItem, this.props.className)}
                                   borderType={borderType}
                                   productAssortment={this.inventoryProductMobileInfo.productAssortment!}
                                   renderFooter={() => this.renderFooter()}
            />
        );
    }
};