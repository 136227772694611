// autogenerated
import ProductReplacement from "@/models/server/ProductReplacement";
import ProductReplacementInfo from "@/models/server/ProductReplacementInfo";
import Localizer from "@/localization/Localizer";

export default class SaveProductReplacementResponse {

    public productReplacement: ProductReplacement | null = null;

    public existsOnTheCustomerGroup: ProductReplacementInfo[] | null = null;

    public existsOnTheCustomer: ProductReplacementInfo[] | null = null;

    public existsGlobally: ProductReplacementInfo[] | null = null;

    public existsCyclic: boolean = false;

    public replacementHasNoAssortment: boolean = false;

    public readonly isSaveProductReplacementResponse: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveProductReplacementResponse) || ((from as any).isSaveProductReplacementResponse === true));
    }

    public static as(from?: object | null): SaveProductReplacementResponse | null {
        return (SaveProductReplacementResponse.is(from)) ? from as SaveProductReplacementResponse : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    public static getConflictReplacementsMessage(response: SaveProductReplacementResponse): string {
        let result: string = "";
        if (response.existsOnTheCustomerGroup) {
            const replacements: Map<string, ProductReplacementInfo[]> = response.existsOnTheCustomerGroup.toDictionary(item => item.customerGroupName!, item => item);

            for (let i: number = 0; i < replacements.size; i++) {
                const replacement: ProductReplacementInfo = response.existsOnTheCustomerGroup[i];
                const type: string = "{0:ProductReplacementType}".format(replacement.type);
                result += `<small>${replacement.customerGroupName} (${type}) :</small>\n <mark>${replacement.fromProductCode}</mark> -> <mark>${replacement.toProductCode}</mark>\n<small>${ Localizer.genericCreatedBy} ${replacement.createdByName}</small>\n\n`;
            }
        }
        
        if (response.existsOnTheCustomer) {
            const replacements: Map<string, ProductReplacementInfo[]> = response.existsOnTheCustomer.toDictionary(item => item.customer!.name, item => item);

            for (let i: number = 0; i < replacements.size; i++) {
                const replacement: ProductReplacementInfo = response.existsOnTheCustomer[i];
                const customer: string = "{0}".format(replacement.customer);
                const type: string = "{0:ProductReplacementType}".format(replacement.type);
                result += `<small>${customer} (${type}) :</small>\n <mark>${replacement.fromProductCode}</mark> -> <mark>${replacement.toProductCode}</mark>\n<small>${ Localizer.genericCreatedBy} ${replacement.createdByName}</small>\n\n`;
            }
        }

        return result;
    }

    // #endregion
}