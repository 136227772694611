import React from "react";
import {ApiProvider, ch, IManualProps, PageCacheProvider, PageCacheTtl, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {PageContainer} from "@reapptor-apps/reapptor-react-components";
import GetOpenOrderRequest from "@/models/server/requests/GetOpenOrderRequest";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import Catalog, {CatalogMode} from "@/components/Catalog/Catalog";
import CatalogDataProvider from "@/providers/CatalogDataProvider";
import SaveOrderProductRequest from "@/models/server/requests/SaveOrderProductRequest";
import OrderProductSelection from "@/models/server/OrderProductSelection";
import {CustomerServiceType, DayOfWeek} from "@/models/Enums";
import OrderProductItem from "@/components/Catalog/OrderProductItem/OrderProductItem";
import SaveOrderProductResponse from "@/models/server/requests/SaveOrderProductResponse";
import OrderMobileInfo from "@/models/server/OrderMobileInfo";
import Customer from "@/models/server/Customer";
import PageRoute from "@reapptor-apps/reapptor-react-common/src/models/PageRoute";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./RegularOrder.module.scss";

export interface IRegularOrderProps {
}

interface IRegularOrderState {
    order: OrderMobileInfo | null;
    dataProvider: CatalogDataProvider | null;
}

export default class RegularOrder extends MobileAuthorizedPage<IRegularOrderProps, IRegularOrderState> {

    state: IRegularOrderState = {
        order: null,
        dataProvider: null
    };

    private async onSaveOrderProductResponseAsync(response: SaveOrderProductResponse): Promise<void> {
        if ((this.order) && (response.isModified)) {

            this.order.modifiedAt = response.orderModifiedAt!;

            PageCacheProvider.clear("*order*");
        }
    }
    
    private async onChangeProductItem(orderProduct: OrderProductMobileInfo): Promise<void> {
        const productSelection = OrderProductSelection.create(orderProduct.productAssortmentId, orderProduct.quantity);

        const request = new SaveOrderProductRequest();
        request.customerId = this.customer.id;
        request.expressOrder = false;
        request.product = productSelection;

        await ApiProvider.backgroundPostAsync("/api/mobileApp/saveOrderProduct", request, null, true, (response: SaveOrderProductResponse) => this.onSaveOrderProductResponseAsync(response));

        GoogleAnalyticsHelper.onCartChange(this.order, orderProduct);
        
        await this.reRenderAsync();
    }

    private async onScanQrCodeAsync(sender: Catalog, code: string): Promise<void> {
        await AittaController.onScanQrCodeAsync(sender, this.dataProvider, code, this.order!.customerId);
    }

    private async getOrderAsync(): Promise<OrderMobileInfo> {
        const request = new GetOpenOrderRequest();
        request.customerId = this.customer!.id;
        request.express = false;
        request.includeProductsDetails = true;

        return this.postAsync("/api/mobileApp/getMobileOpenOrder", request, PageCacheTtl._5m);
    }

    public getManualProps(): IManualProps {
        const itemsInBasket: number = this.dataProvider?.orderProductsCount ?? 0;

        return ((this.customer) && (this.order))
            ? {
                icon: "info-circle",
                title: Localizer.mobileRegularOrderPageName.format(itemsInBasket),
                render: () => this.renderManual()
            }
            : {};
    }

    public get order(): OrderMobileInfo | null {
        return this.state.order;
    }
    
    public get dataProvider(): CatalogDataProvider | null {
        return this.state.dataProvider;
    }

    public async beforeRedirectAsync(nextRoute: PageRoute, innerRedirect: boolean): Promise<boolean> {
        if (this.order && this.dataProvider && this.dataProvider.orderProducts.any()) {
            GoogleAnalyticsHelper.purchase(this.order, this.dataProvider.orderProducts);
        }
        
        return super.beforeRedirectAsync(nextRoute, innerRedirect);
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const order: OrderMobileInfo = await this.getOrderAsync();

        const includeAssumedGroupCatalogFolder: boolean = (this.customer.serviceType != CustomerServiceType.Subscription);

        const dataProvider = new CatalogDataProvider(this.customer.customerGroupId, this.customer.id, order.products ?? [], true, includeAssumedGroupCatalogFolder, true);
        
        await this.setState({order, dataProvider});
        
        await this.reRenderTopNavAsync();
    }

    public renderManual(): React.ReactNode {
        const selectedCustomer: Customer = this.customer!;

        const order: OrderMobileInfo | null = this.order!;

        const express: boolean = order.express;

        const weekend: DayOfWeek[] = [DayOfWeek.Saturday, DayOfWeek.Sunday];
        const hasCloseDate: boolean = (!express) && (order.plannedCloseDate != null);
        const hasCloseDateHoliday: boolean = (hasCloseDate) && (order.plannedCloseDate != null) && ((!!order.plannedCloseMark) || (weekend.includes(order.plannedCloseDate.getDay())));
        const hasDeliveryDate: boolean = (!express) && (order.plannedDeliveryDate != null);
        const hasDeliveryDateHoliday: boolean = (hasCloseDate) && (order.plannedDeliveryDate != null) && ((!!order.plannedDeliveryMark) || (weekend.includes(order.plannedDeliveryDate.getDay())));

        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(selectedCustomer.codeInfo)} </mark> - {selectedCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{selectedCustomer.customerGroupName}</b></span>

                <span>{Localizer.mobileRegularOrderPageOrder}: <b>#{this.order?.number}</b></span>

                <span>{Localizer.mobileRegularOrderPageCreatedDate}: <b>{"{0:D} {0:dddd}".format(order.createdAt).toUpperCase()}</b></span>

                {
                    (hasCloseDate) &&
                    (
                        <span>{Localizer.mobileRegularOrderPageCloseDate}: <b className={this.css(hasCloseDateHoliday && styles.warning)}>{"{0:D} {0:dddd}".format(order.plannedCloseDate).toUpperCase()}</b></span>
                    )
                }

                {
                    (hasDeliveryDate) &&
                    (
                        <span>{Localizer.mobileRegularOrderPageDeliverDate}: <b className={this.css(hasDeliveryDateHoliday && styles.warning)}>{"{0:D} {0:dddd}".format(order.plannedDeliveryDate).toUpperCase()}</b></span>
                    )
                }

            </div>
        )
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.regularOrder, this.mobile && styles.mobile)}
            >

                <BannerMessageAlert className={styles.banner} />

                {
                    (this.dataProvider) &&
                    (
                        <Catalog className={styles.categoriesProducts}
                                 express={false}
                                 dataProvider={this.dataProvider}
                                 mode={CatalogMode.Order}
                                 onScanQr={(sender, code) => this.onScanQrCodeAsync(sender, code)}
                                 onChange={(sender: OrderProductItem, item: OrderProductMobileInfo) => this.onChangeProductItem(item)}
                        />
                    )
                }

            </PageContainer>
        );
    }
}