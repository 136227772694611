// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListProductGroupAssortmentsRequest extends ETagRequest {

    public productGroupId: string | null = null;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public favorite: boolean = false;

    public assumed: boolean = false;

    public top30: boolean = false;

    public search: string | null = null;

    public exactMatch: boolean = false;

    public readonly isListProductGroupAssortmentsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListProductGroupAssortmentsRequest) || ((from as any).isListProductGroupAssortmentsRequest === true));
    }

    public static as(from?: object | null): ListProductGroupAssortmentsRequest | null {
        return (ListProductGroupAssortmentsRequest.is(from)) ? from as ListProductGroupAssortmentsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}