import React from "react";
import {ActionType, Align, BaseComponent, ch, DataStorageType, DescriptionModel, DialogResult, IBaseComponentProps, IMessageBoxButtons, Justify, PageRouteProvider, TextAlign, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {ExportEncodingType, ProductAssortmentFilter, ProductAssortmentType, ProductTemplateFilter} from "@/models/Enums";
import ProductsToolbar from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbar";
import ProductSelection from "@/models/server/ProductSelection";
import {
    BorderType,
    CellAction,
    CellModel,
    ColumnActionDefinition,
    ColumnActionType,
    ColumnDefinition,
    ColumnSubActionDefinition,
    ColumnType,
    Description,
    Grid,
    GridHoveringType,
    GridModel,
    RowModel,
    Spinner
} from "@reapptor-apps/reapptor-react-components";
import {FileModel, IPagedList, SortDirection, Utility} from "@reapptor-apps/reapptor-toolkit";
import ListProductSelectionsRequest from "@/models/server/requests/ListProductSelectionsRequest";
import ProductsToolbarModel, {ProductPanelType} from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbarModel";
import SetProductSelectionRequest from "@/models/server/requests/SetProductSelectionRequest";
import ProductAssortment from "@/models/server/ProductAssortment";
import SaveOrderingRuleRequest from "@/models/server/requests/SaveOrderingRuleRequest";
import Product from "@/models/server/Product";
import AittaConstants from "@/helpers/AittaConstants";
import ImportProductSelectionsResponse from "@/models/server/responses/ImportProductSelectionsResponse";
import ProductTemplate from "@/models/server/ProductTemplate";
import SetProductSelectionResponse from "@/models/server/responses/SetProductSelectionResponse";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import ProductModal from "@/components/ProductModal/ProductModal";
import ListProductSelectionsResponse from "@/models/server/responses/ListProductSelectionsResponse";
import StatisticsModal from "@/pages/ProductManagement/StatisticsModal/StatisticsModal";
import ImportProductSelectionsRequest from "@/models/server/requests/ImportProductSelectionsRequest";
import SaveOrderingRuleResponse from "@/models/server/responses/SaveOrderingRuleResponse";
import ProductAssortmentModal from "@/pages/ProductManagement/ProductAssortmentModal/ProductAssortmentModal";
import OrderingRule from "@/models/server/OrderingRule";
import PageDefinitions from "@/providers/PageDefinitions";
import SaveProductAssortmentNoteRequest from "@/models/server/requests/SaveProductAssortmentNoteRequest";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./ProductsPanel.module.scss";

export interface IProductsPanelProps extends IBaseComponentProps {
    type: ProductPanelType;
    templateId?: string | null;
    readonly?: boolean;

    exportToCsv?(sender: ProductsToolbar, encodingType: ExportEncodingType, sortColumnDirection: SortDirection | null, sortColumnName: string | null): Promise<void>;
}

interface IProductsPanelState {
    toolbar: ProductsToolbarModel;
    generation: number;
    loading: boolean;
}

export default class ProductsPanel extends BaseComponent<IProductsPanelProps, IProductsPanelState> {

    state: IProductsPanelState = {
        toolbar: new ProductsToolbarModel(),
        generation: 0,
        loading: false
    };

    private readonly _productsPanelGridRef: React.RefObject<Grid<ProductSelection>> = React.createRef();
    private readonly _productsToolbarRef: React.RefObject<ProductsToolbar> = React.createRef();
    private readonly _productModalRef: React.RefObject<ProductModal> = React.createRef();
    private readonly _statisticsModalRef: React.RefObject<StatisticsModal> = React.createRef();
    private readonly _productAssortmentModal: React.RefObject<ProductAssortmentModal> = React.createRef();
    private readonly _descriptionRef: React.RefObject<Description> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.productPanelGridAittaLanguageItemName,
            minWidth: "5rem",
            textAlign: TextAlign.Center,
            visible: (this.type == ProductPanelType.ProductsSelection),
            init: (cell) => this.initSelectionColumn(cell),
            actions: [
                {
                    name: "select",
                    title: "AITTA",
                    type: ActionType.Light,
                    callback: (cell) => this.setSelectionAsync(cell, true)
                } as ColumnActionDefinition,
                {
                    name: "unselect",
                    title: "AITTA",
                    type: ActionType.Light,
                    callback: (cell) => this.setSelectionAsync(cell, false)
                } as ColumnActionDefinition,
                {
                    name: "adeona",
                    title: "ADEONA",
                    type: ActionType.Light,
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridTemplateLanguageItemName,
            minWidth: "7rem",
            textAlign: TextAlign.Center,
            visible: this.templatesTabActive,
            init: (cell) => this.initSelectionColumn(cell),
            actions: [
                {
                    name: "select",
                    title: "TEMPLATE",
                    type: ActionType.Light,
                    callback: (cell) => this.setSelectionAsync(cell, true)
                } as ColumnActionDefinition,
                {
                    name: "unselect",
                    title: "TEMPLATE",
                    type: ActionType.Light,
                    callback: (cell) => this.setSelectionAsync(cell, false)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            header: Localizer.productReportPanelCustomerLanguageItemName,
            name: "customer",
            sorting: true,
            visible: () => this.showCustomerColumn,
            accessor: (model: ProductSelection) => this.getCustomerColumnName(model),
            minWidth: "13rem",
            maxWidth: "13rem",
            noWrap: true,
            settings: {
                infoAccessor: (model: ProductSelection) => this.getCustomerColumnSettings(model),
            }
        } as ColumnDefinition,
        {
            name: "groupName",
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            render: (model: CellModel<ProductSelection>) => this.renderGroupCell(model),
            minWidth: "15rem",
            maxWidth: "15rem"
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductSelection>(o => o.product!.code),
            minWidth: "7.5rem",
            maxWidth: "7.5rem",
            textAlign: TextAlign.Center,
            init: (cell: CellModel<ProductSelection>) => this.initCodeCell(cell),
            settings: {
                descriptionIcon: "far fa-exchange-alt",
                descriptionAccessor: () => "",
            },
        } as ColumnDefinition,
        {
            name: nameof.full<ProductSelection>(o => o.product!.name),
            header: Localizer.productPanelGridNameLanguageItemName,
            accessor: (model: ProductSelection) => Product.getFullName(model.product!),
            minWidth: "25rem",
            className: this.css(styles.productName, styles.hasImage),
            stretch: true,
            sorting: true,
            selectable: false,
            callback: (cell: CellModel<ProductSelection>) => this.onNameClickAsync(cell),
            init: (cell: CellModel<any>) => this.initNameCell(cell),
            settings: {
                descriptionAccessor: nameof.full<ProductSelection>(o => o.productAssortment!.note),
                descriptionMaxLength: AittaConstants.descriptionLength,
                descriptionCallback: (cell) => this.saveCommentAsync(cell),
            },
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            sorting: true,
            accessor: nameof.full<ProductSelection>(o => o.product!.manufactureCode),
            minWidth: "7rem",
            maxWidth: "7rem",
            noWrap: true,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridWholesalePackageQuantityLanguageItemName,
            title: Localizer.productPanelGridWholesalePackageQuantityTitleLanguageItemName,
            accessor: (model: ProductSelection) => (model.product!.wholesalePackageQuantity > 1)
                ? model.product!.wholesalePackageQuantity
                : "-",
            textAlign: TextAlign.Center,
            minWidth: "5rem",
            maxWidth: "5rem",
        } as ColumnDefinition,
        {
            // TP (pcs):
            group: Localizer.productPanelGridThresholdGroupLanguageItemName,
            header: Localizer.productPanelGridThresholdPcsLanguageItemName,
            title: Localizer.productPanelGridThresholdPcsTitleLanguageItemName,
            visible: () => this.showOrderThresholdAndQuantityAsync(),
            className: this.css(styles.threshold, (!this.orderingRulesTabActive) && styles.selections),
            init: (cell: CellModel<ProductSelection>) => this.initThresholdColumn(cell, "OrderThresholdPcs"),
            accessor: (model: ProductSelection) => ProductAssortment.getThresholdPcs(model.productAssortment),
            reRenderRow: true,
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
            type: (this.orderingRulesTabActive) && (!this.readonly) ? ColumnType.Number : ColumnType.Custom,
            editable: (this.orderingRulesTabActive) && (!this.readonly),
            settings: {
                min: 0,
                maxLength: 4,
                step: 1,
                infoAccessor: (model: ProductSelection) => (this.orderingRulesTabActive) && ((model.productAssortment?.newOrderThreshold) && (model.productAssortment.newOrderThreshold >= 0))
                    ? ProductAssortment.getNewThresholdPcs(model.productAssortment)
                    : "",
                infoHideEqual: true,
            },
            callback: (cell: CellModel<ProductSelection>) => this.updatePackagesThresholdPcsAsync(cell),
        } as ColumnDefinition,
        {
            // TP:
            group: Localizer.productPanelGridThresholdGroupLanguageItemName,
            header: Localizer.productPanelGridThresholdLanguageItemName,
            title: Localizer.productPanelGridThresholdTitleLanguageItemName,
            visible: () => this.showOrderThresholdAndQuantityAsync(),
            className: this.css(styles.threshold, (!this.orderingRulesTabActive) && styles.selections),
            init: (cell: CellModel<ProductSelection>) => this.initThresholdColumn(cell, "OrderThreshold"),
            accessor: nameof.full<ProductSelection>(o => o.productAssortment!.orderThreshold),
            reRenderRow: true,
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
            type: (this.orderingRulesTabActive) && (!this.readonly) ? ColumnType.Number : ColumnType.Custom,
            editable: (this.orderingRulesTabActive) && (!this.readonly),
            //format: "0.00",
            settings: {
                min: 0,
                maxLength: 4,
                step: 0.01,
                infoAccessor: (model: ProductSelection) => (this.orderingRulesTabActive) && ((model.productAssortment?.newOrderThreshold) && (model.productAssortment.newOrderThreshold >= 0))
                    ? model.productAssortment!.newOrderThreshold
                    : "",
                infoHideEqual: true,
            },
            callback: () => this.updatePackagesThresholdAsync(),
        } as ColumnDefinition,
        {
            // TM:
            group: Localizer.productPanelGridThresholdGroupLanguageItemName,
            header: Localizer.productPanelGridNewOrderQuantityLanguageItemName,
            title: Localizer.productPanelGridNewOrderQuantityTitleLanguageItemName,
            visible: () => this.showOrderThresholdAndQuantityAsync(),
            className: this.css(styles.threshold, (!this.orderingRulesTabActive) && styles.selections),
            init: (cell: CellModel<ProductSelection>) => this.initThresholdColumn(cell, "OrderQuantity"),
            accessor: nameof.full<ProductSelection>(o => o.productAssortment!.orderQuantity),
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
            type: (this.orderingRulesTabActive) && (!this.readonly) ? ColumnType.Number : ColumnType.Custom,
            editable: (this.orderingRulesTabActive) && (!this.readonly),
            settings: {
                min: 0,
                maxLength: 4,
                step: 1,
                infoAccessor: (model: ProductSelection) => (this.orderingRulesTabActive) && ((model.productAssortment?.newOrderQuantity) && (model.productAssortment.newOrderQuantity >= 0))
                    ? model.productAssortment!.newOrderQuantity
                    : "",
                infoHideEqual: true,
            },
            callback: () => this.updateOrderQuantityAsync(),
        } as ColumnDefinition,
        {
            // Consumption (Period)
            group: Localizer.productPanelGridUsageGroupLanguageItemName,
            header: Localizer.productPanelGridPeriodLanguageItemName,
            title: Localizer.productPanelGridPeriodTitleLanguageItemName,
            visible: this.orderingRulesTabActive,
            accessor: (model: ProductSelection) => "{0:0.00}".format(ProductSelection.getConsumption(model, this.customer?.consumptionInterval)),
            minWidth: "6.5rem",
            maxWidth: "6.5rem",
            textAlign: TextAlign.Right,
            settings: {
                infoAccessor: () => Localizer.productPanelGridPeriodInterval.format(this.customer?.consumptionInterval || 1),
            }
        } as ColumnDefinition,
        {
            // Consumption (12m)
            group: Localizer.productPanelGridUsageGroupLanguageItemName,
            header: Localizer.productPanelGridUsageAnnualLanguageItemName,
            title: Localizer.productPanelGridUsageAnnualTitleLanguageItemName,
            visible: () => this.consumptionPeriodVisible,
            accessor: (model: ProductSelection) => (model.productAssortment?.inheritedAnnualValue != null)
                ? "{0}".format(model.productAssortment?.inheritedAnnualValue)
                : "-",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Right,
            settings: {
                infoAccessor: (model: ProductSelection) => (model.productAssortment?.inheritedAnnualDays)
                    ? Localizer.productPanelGridUsageTotalDays.format(model.productAssortment.inheritedAnnualDays)
                    : "",
            }
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: (this.orderingRulesTabActive) ? 135 : 90,
            removable: false,
            textAlign: TextAlign.Center,
            selectable: false,
            visible: (!this.readonly) && (this.orderingRulesTabActive || this.productSelectionsTabActive),
            init: (cell) => this.initRulesOperations(cell),
            actions: [
                {
                    name: "apply",
                    title: Localizer.productPanelGridActionsApplyLanguageItemName,
                    icon: "far check",
                    type: ActionType.Create,
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>) => this.processOrderingRuleAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    type: ColumnActionType.Save,
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>) => this.processOrderingRuleAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    type: ColumnActionType.Cancel,
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>) => this.processOrderingRuleAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "add",
                    icon: "fas plus",
                    type: ActionType.Info,
                    title: Localizer.productPanelGridActionsAddProductLanguageItemName,
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>) => this.processOrderingRuleAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "history",
                    title: Localizer.productPanelGridActionsAuditAndStatisticsLanguageItemName,
                    icon: "fal fa-info",
                    type: ActionType.Grey,
                    actions: [
                        {
                            name: "statistics",
                            label: Localizer.productPanelGridActionsStatistics,
                            icon: "far fa-chart-line",
                        } as ColumnSubActionDefinition,
                        {
                            name: "modifications",
                            label: Localizer.productPanelGridActionsLastModificationsInfo,
                            icon: "far fa-comment-edit",
                        } as ColumnSubActionDefinition,
                        {
                            name: "audit",
                            label: Localizer.auditPageTitle,
                            icon: "far fa-history",
                        } as ColumnSubActionDefinition,
                    ],
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>, data?: string | null) => this.processOrderingRuleAsync(cell, action, data)
                } as ColumnActionDefinition,
                {
                    type: ColumnActionType.Delete,
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>) => this.processOrderingRuleAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    type: ColumnActionType.Restore,
                    callback: (cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>) => this.processOrderingRuleAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "audit",
                    title: Localizer.auditPageTitleLanguageItemName,
                    icon: "far fa-history",
                    type: ActionType.Blue,
                    right: true,
                    callback: (cell: CellModel<ProductSelection>) => this.onAuditRedirectAsync(cell.model.productId),
                } as ColumnActionDefinition
            ]

        } as ColumnDefinition,
    ];

    public async onAuditRedirectAsync(productId: string): Promise<void> {

        const confirmed: boolean = (!this.hasUnsavedOrderingRulesChanges) || (await ch.confirmAsync(Localizer.customerManagementPageUnsavedChanges));

        if (confirmed) {

            const customerGroupId: string | null = (this.toolbar.productAssortmentType == ProductAssortmentType.CustomerGroup)
                ? this.toolbar.customerGroup?.id || null
                : null;

            const customerId: string | null = (this.toolbar.productAssortmentType == ProductAssortmentType.Customer)
                ? this.toolbar.customer?.id || null
                : null;

            await PageRouteProvider.redirectAsync(PageDefinitions.AuditRouteDetails(productId, customerGroupId, customerId));

        }
    }

    public async descriptionAsync(containerId: string, model: DescriptionModel): Promise<void> {
        const description: Description = this._descriptionRef.current!;
        await description.toggleAsync(containerId, model);
    }

    private initRow(row: RowModel<ProductSelection>): void {
        const model: ProductSelection = row.model;
        const deleted: boolean = (this.orderingRulesTabActive) && (!model.selected);
        const stickyRow: boolean = (this.type == ProductPanelType.OrderingRules) && (this.containsCreatedProductSelection(model.productAssortmentId!));

        row.className = this.cssIf(row.className, deleted, styles.deleted);
        row.className = this.cssIf(row.className, stickyRow, styles.stickyRow);
    }

    private initCodeCell(cell: CellModel<ProductSelection>): void {
        const model: ProductSelection = cell.model;

        const canShowReplacementInfo: boolean = (!AittaController.user.isMaster) && (!AittaController.user.isManager);

        const hasReplacement: boolean = (canShowReplacementInfo) && ((model.fromProductReplacementId != null) || ((model.toProductReplacementIds != null) && (model.toProductReplacementIds.length > 0)));
        const hasToReplacement: boolean = (canShowReplacementInfo) && (model.toProductReplacementIds != null) && (model.toProductReplacementIds.length > 0);

        cell.className = this.cssIf(cell.className, hasReplacement, styles.hasReplacement);
        cell.className = this.cssIf(cell.className, !hasReplacement, styles.noReplacement);
        cell.className = this.cssIf(cell.className, hasToReplacement, styles.hasToReplacement);
    }

    private initNameCell(cell: CellModel<ProductSelection>): void {
        if (cell.descriptionAction) {
            const model: ProductSelection = cell.model;
            const deleted: boolean = (this.orderingRulesTabActive) && (!model.selected);

            cell.descriptionAction.visible = (!deleted) && (!!model.productAssortment);
            cell.descriptionAction.readonly = !AittaController.hasMasterAccess;
        }
    }

    private async processOrderingRuleAsync(cell: CellModel<ProductSelection>, action: CellAction<ProductSelection>, data?: string | null): Promise<void> {

        const model: ProductSelection = cell.model!;
        const assortment: ProductAssortment = model.productAssortment!;
        const actionName: string | null = (data || action.action.name || "").toLowerCase();

        if (actionName === "statistics") {

            await this.showStatisticsAsync(model.productAssortmentId!);

        } else if ((actionName === "save") || (actionName === "apply")) {

            const request = new SaveOrderingRuleRequest();

            const rule = (actionName == "save")
                ? new OrderingRule(assortment.id, assortment.orderThreshold, assortment.orderQuantity)
                : new OrderingRule(assortment.id, assortment.newOrderThreshold, assortment.newOrderQuantity);

            request.orderingRules = [rule];

            const response: SaveOrderingRuleResponse = await cell.grid.postAsync("/api/productManagement/saveOrderingRule", request);

            if (response.productAssortments && response.productAssortments.length > 0) {
                const modifiedAssortment: ProductAssortment = response.productAssortments[0];

                Utility.copyTo(modifiedAssortment, assortment);

                this.removeCreatedProductSelection(assortment.id);

                await ch.alertMessageAsync(Localizer.productPanelOrderRuleSaved, true, true);
            }

            await cell.row.bindAsync(true);

            await this.onOrderingRuleChangeAsync();

        } else if (actionName === "cancel") {

            await cell.row.cancelAsync();

        } else if (actionName === "modifications") {

            const descriptionModel = new DescriptionModel();
            descriptionModel.description = Localizer.productPanelGridModifiedBy.format(model.productAssortment!.modifiedBy, model.productAssortment!.modifiedAt);
            descriptionModel.className = styles.description;
            descriptionModel.align = Align.Bottom;
            descriptionModel.justify = Justify.Left;
            descriptionModel.readonly = true;

            await this.descriptionAsync(cell.instance.id, descriptionModel);

        } else if (actionName === "add") {

            const customer: Customer | null = this._productsToolbarRef.current?.model?.customer || null;

            if (customer) {
                await this.openProductAssortmentModalAsync(customer, cell.rowIndex + 1);
            }

        } else if (actionName === "delete") {

            model.selected = false;

            await cell.row.bindAsync(true);

            this.removeCreatedProductSelection(assortment.id);

            await this.onOrderingRuleChangeAsync();

        } else if (actionName === "restore") {

            model.selected = true;

            await cell.row.bindAsync(true);

            //await cell.row.setDeletedAsync(false);

            await this.onOrderingRuleChangeAsync();

        } else if (actionName === "audit") {

            await this.onAuditRedirectAsync(model.productId);

        }
    }

    private async updatePackagesThresholdPcsAsync(cell: CellModel<ProductSelection>): Promise<void> {
        const assortment: ProductAssortment = cell.model.productAssortment!;

        ProductAssortment.setThresholdPcs(assortment, cell.value);

        await this.onOrderingRuleChangeAsync();
    }

    private async updatePackagesThresholdAsync(): Promise<void> {
        await this.onOrderingRuleChangeAsync();
    }

    private async updateOrderQuantityAsync(): Promise<void> {
        await this.onOrderingRuleChangeAsync();
    }

    private async onOrderingRuleChangeAsync(): Promise<void> {

        if (this._productsToolbarRef.current) {

            const modified: boolean = this.isModified();

            await this._productsToolbarRef.current.setCanSaveAllOrderingRulesAsync(modified);
        }

        this.updateGeneration();
    }

    private isModified(): boolean {
        return (
            (this.type == ProductPanelType.OrderingRules) &&
            (
                (this.grid.modified) ||
                (this.grid.rows.any(item => !item.model.selected))
            )
        );
    }

    private updateGeneration(): void {
        this.state.generation = this.state.generation + 1;
    }

    private initThresholdColumn(cell: CellModel<ProductSelection>, type: "OrderThreshold" | "OrderThresholdPcs" | "OrderQuantity"): void {
        const model: ProductSelection = cell.row.model;
        const productAssortment: ProductAssortment = model.productAssortment!;

        const value: number | null = ProductAssortment.getThresholdItem(productAssortment, type, false);
        const newValue: number | null = ProductAssortment.getThresholdItem(productAssortment, type, true);

        const hasValue: boolean = (value >= 0);
        const hasNewValue: boolean = (newValue != value);

        const isModified: boolean = cell.modified;

        cell.readonly = (!model.selected);

        cell.className = this.cssIf(cell.className, hasValue, styles.hasValue);
        cell.className = this.cssIf(cell.className, !hasValue, styles.hasNoValue);
        cell.className = this.cssIf(cell.className, hasNewValue, styles.hasNewValue);
        cell.className = this.cssIf(cell.className, isModified, styles.modified);
    }

    private initRulesOperations(cell: CellModel<ProductSelection>): void {
        const model: ProductSelection = cell.row.model;

        const applyAction: CellAction<ProductSelection> = cell.actions[0];
        const saveAction: CellAction<ProductSelection> = cell.actions[1];
        const cancelAction: CellAction<ProductSelection> = cell.actions[2];
        const addAction: CellAction<ProductSelection> = cell.actions[3];
        const historyAction: CellAction<ProductSelection> = cell.actions[4];
        const deleteAction: CellAction<ProductSelection> = cell.actions[5];
        const restoreAction: CellAction<ProductSelection> = cell.actions[6];
        const auditAction: CellAction<ProductSelection> = cell.actions[7];

        const deleted: boolean = (this.orderingRulesTabActive) && (!model.selected);
        const modified: boolean = (cell.row.modified);
        const hasValue: boolean = (model.productAssortment != null) && (model.productAssortment.orderQuantity >= 0) && (model.productAssortment.orderThreshold >= 0);

        const canApply: boolean = (
            (hasValue) &&
            (
                (model.productAssortment?.newOrderQuantity != model.productAssortment?.orderQuantity) ||
                (model.productAssortment?.newOrderThreshold != model.productAssortment?.orderThreshold)
            )
        );

        const isMediq: boolean = AittaController.user.isMediq;

        const orderingRulesTabActive: boolean = this.orderingRulesTabActive;
        const productSelectionsTabActive: boolean = this.productSelectionsTabActive;

        applyAction.visible = (!this.showAllCustomers) && (orderingRulesTabActive) && (!modified) && (!deleted);
        applyAction.disabled = (!canApply);
        saveAction.visible = (orderingRulesTabActive) && (modified) && (!deleted);
        saveAction.disabled = (!hasValue);
        cancelAction.visible = (orderingRulesTabActive) && (modified) && (!deleted);
        addAction.visible = (orderingRulesTabActive) && (!this.customerGroupId) && (!this.showAllCustomers) && (!modified) && (!deleted);
        historyAction.visible = (orderingRulesTabActive) && (isMediq) && (!deleted) && (!modified);
        deleteAction.visible = (!this.showAllCustomers) && (orderingRulesTabActive) && (!modified) && (!deleted);
        restoreAction.visible = (!this.showAllCustomers) && (orderingRulesTabActive) && (!modified) && (deleted);
        auditAction.visible = (productSelectionsTabActive);
    }

    private async fetchAsync(sender: Grid<ProductSelection>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ProductSelection>> {
        if (!this.initialized) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListProductSelectionsRequest();

        request.customerGroupId = (!this.customerId) ? this.customerGroupId : null;

        request.assortmentFilter = this.assortmentFilter;

        if (this.orderingRulesTabActive) {
            request.assortmentFilter = (request.customerGroupId) ? ProductAssortmentFilter.Customer : ProductAssortmentFilter.Selected;
        }

        if (this.templatesTabActive) {
            request.assortmentFilter = ProductAssortmentFilter.All;
            request.templateFilter = this.templateFilter;
        }

        request.customerId = this.customerId;
        request.templateId = this.templateId;
        request.search = this.search;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        const response: ListProductSelectionsResponse = await sender.postAsync("/api/productManagement/listProductSelections", request);

        return response.productSelections || [].toPagedList(pageNumber, pageSize)!;
    }

    private async onBeforePaginationChangeAsync(): Promise<boolean> {
        const canPaginate: boolean = (!this.isModified()) || (await ch.confirmAsync(Localizer.customerManagementPageUnsavedChanges));

        if (canPaginate) {
            window.scrollTo({top: 0});
        }

        return canPaginate;
    }

    private async onProcessDataAsync(items: ProductSelection[]): Promise<void> {

        if (this.type == ProductPanelType.OrderingRules) {
            const selections: ProductSelection[] = this.getCreatedProductProductSelections();

            const length: number = selections.length;

            if (length > 0) {

                const duplicates: ProductSelection[] = [];

                for (let i: number = 0; i < length; i++) {
                    const selection: ProductSelection = selections[i];

                    const duplicate: ProductSelection | null = items.firstOrDefault(item => (item.productAssortmentId == selection.productAssortmentId));

                    if (duplicate) {
                        duplicates.push(duplicate);

                        Utility.copyTo(duplicate, selection);
                    }
                }

                items.remove(duplicates);

                items.insert(selections);
            }
        }
    }

    private async initSelectionColumn(cell: CellModel<ProductSelection>): Promise<void> {
        const model: ProductSelection = cell.row.model;

        const disabled: boolean = (!this.aittaSelectionEnabled);
        const bastard: boolean = (model.selected) && (model.productAssortment?.bastard == true) && (model.productAssortment.type == ProductAssortmentType.Customer);
        const hasBastard: boolean = (model.hasBastards);

        const showOnlySelected: boolean = (this.readonly);

        cell.className = styles.aitta;
        cell.className = this.cssIf(cell.className, ((!disabled) && (model.selected || showOnlySelected)), styles.selected);
        cell.className = this.cssIf(cell.className, ((!disabled) && (!model.selected)), styles.unselected);
        cell.className = this.cssIf(cell.className, disabled, styles.disabled);
        cell.className = this.cssIf(cell.className, hasBastard || bastard, styles.bastard);

        const selectAction: CellAction<ProductSelection> = cell.actions[0];
        const unselectAction: CellAction<ProductSelection> = cell.actions[1];

        selectAction.visible = !disabled && !model.selected;
        unselectAction.visible = !disabled && model.selected;

        selectAction.disabled = (this.readonly) || (this.toolbar.assortmentFilter == ProductAssortmentFilter.Customer);
        unselectAction.disabled = (this.readonly) || (this.toolbar.assortmentFilter == ProductAssortmentFilter.Customer);

        if (this.productSelectionsTabActive) {
            const adeonaAction: CellAction<ProductSelection> = cell.actions[2];

            adeonaAction.visible = disabled && !this.templatesTabActive;
        }
    }

    private async onNameClickAsync(cell: CellModel<ProductSelection>): Promise<void> {
        const model: ProductSelection = cell.row.model;

        if (this._productModalRef.current) {
            await this._productModalRef.current.openAsync(model.product ?? model.productId, model.fromProductReplacementId, null, model.customerId, model.customerGroupId, model.toProductReplacementIds, this.state.generation);
        }
    }

    private async showStatisticsAsync(productAssortmentId: string): Promise<void> {
        if (this._statisticsModalRef.current) {
            await this._statisticsModalRef.current.openAsync(productAssortmentId);
        }
    }

    private async saveCommentAsync(cell: CellModel<ProductSelection>): Promise<void> {
        const model: ProductSelection = cell.model;
        const productAssortment: ProductAssortment | null = model.productAssortment;

        if (productAssortment) {
            const request = new SaveProductAssortmentNoteRequest();
            request.productAssortmentId = productAssortment.id;
            request.note = productAssortment.note || "";

            await cell.grid.postAsync("/api/productManagement/saveProductAssortmentNote", request);

            await cell.reRenderAsync();

            await ch.alertMessageAsync(Localizer.productPanelGridCommentSaved, true, true);
        }
    }

    private async setSelectionAsync(cell: CellModel<ProductSelection>, select: boolean): Promise<void> {
        const model: ProductSelection = cell.model;
        const product: Product = model.product!;

        const productAssortmentType: ProductAssortmentType = (this.templateId) ? ProductAssortmentType.Template : this.productAssortmentType;

        const request = new SetProductSelectionRequest();
        request.productId = model.productId;
        request.customerGroupId = model.customerGroupId;
        request.customerId = model.customerId;
        request.templateId = this.templateId;
        request.productAssortmentType = productAssortmentType;
        request.selected = select;
        request.validate = true;

        let response: SetProductSelectionResponse = await cell.grid.postAsync("/api/productManagement/setProductSelection", request);

        if (response.productAssortment == null) {

            const confirmed: boolean = (
                (
                    ((!response.hasChildrenProductAssortments) || ((response.hasChildrenProductAssortments) && (await ch.confirmAsync(Localizer.productPanelProductsSelectConfirm.format(product.code))))) &&
                    ((!response.hasReplacements) || ((response.hasReplacements) && (await ch.confirmAsync(Localizer.productPanelProductsSelectConfirmDeleteReplacement.format(product.code)))))
                )
            );

            if (!confirmed) {
                return;
            }

            if (response.hasNoSuchParentProductAssortment) {
                const buttons: IMessageBoxButtons = {
                    okButton: Localizer.productPanelNonContractMessageBoxCustomerAndGroup,
                    yesButton: Localizer.productPanelNonContractMessageBoxCustomer,
                    cancelButton: Localizer.genericCancel
                }

                const result: DialogResult = await ch.messageBoxAsync(Localizer.productPanelTemplateIncludeProductToGroup.format(product.code, this.customer?.name, this.customerGroup?.name), "", buttons);

                switch (result) {
                    case DialogResult.OK: {
                        break;
                    }
                    case DialogResult.Yes: {
                        request.bastard = true;
                        break;
                    }
                    case DialogResult.Cancel:
                    case DialogResult.None: {
                        return;
                    }
                }
            }

            request.validate = false;

            response = await cell.grid.postAsync("/api/productManagement/setProductSelection", request);
        }

        model.selected = select;
        model.productAssortment = response.productAssortment;
        model.productAssortmentId = (response.productAssortment) ? response.productAssortment.id : null;

        if ((productAssortmentType == ProductAssortmentType.Customer) && (model.productAssortmentId)) {
            if (select) {
                this.addCreatedProductSelection(model);
            } else {
                this.removeCreatedProductSelection(model.productAssortmentId);
            }
        }

        await cell.row.bindAsync();
    }

    private async onToolbarSubmitAsync(toolbar: ProductsToolbarModel): Promise<void> {
        this.state.toolbar = toolbar;
        
        await this.reloadAsync();
    }

    private async onApplyTemplate(sender: ProductsToolbar, template: ProductTemplate): Promise<void> {
        const confirm: boolean = await ch.confirmAsync(Localizer.productPanelTemplateOverrideExisting);
        if (confirm) {
            await this.onImportProductSelections(sender, null, template);
        }
    }

    private async onUploadFileAsync(sender: ProductsToolbar, file: FileModel): Promise<void> {
        const confirmationMessage: string = (this.customerId)
            ? Localizer.productPanelTemplateAssignProductToCustomerFromFile.format(file.name, this.customer?.name, this.customerGroup?.name)
            : Localizer.productPanelTemplateAssignProductToGroupFromFile.format(file.name, this.customerGroup?.name)

        const confirm: boolean = await ch.confirmAsync(confirmationMessage);

        if (confirm) {
            await this.onImportProductSelections(sender, file, null);
        }
    }

    private async onImportProductSelections(sender: ProductsToolbar, file: FileModel | null, template: ProductTemplate | null) {
        const safeImportNeeded = (this.customerGroup?.id != null);

        const request = new ImportProductSelectionsRequest();
        request.file = file;
        request.customerGroupId = this.customerGroup?.id ?? null;
        request.customerId = this.customer?.id ?? null;
        request.templateId = (this.props.templateId || template?.id) ?? null;
        request.toTemplate = (this.type == ProductPanelType.Templates);
        request.safeImportNeeded = safeImportNeeded;

        await this.setState({loading: true});

        let response: ImportProductSelectionsResponse = await sender.postAsync("/api/productManagement/importProductSelections", request);

        if ((safeImportNeeded) && (!response.failedToParseProductSelections) && (response.customerCodesAffected != null) && (response.customerCodesAffected.length > 0)) {
            const confirm: boolean = await ch.confirmAsync(Localizer.productPanelProductsCustomerAssortmentsWillBeRemoved.format(response.customerCodesAffected.join(", ")));

            if (confirm) {
                request.safeImportNeeded = false;
                response = await sender.postAsync("/api/productManagement/importProductSelections", request);
            }
        }

        await this.processImportProductSelectionsResponseAsync(response);

        await this.reloadAsync();

        await this.setState({loading: false});
    }

    private async openProductAssortmentModalAsync(customer: Customer, rowIndex: number = 0): Promise<void> {
        await this._productAssortmentModal.current?.openAsync(customer, rowIndex);
    }

    private async saveAllOrderingRulesAsync(sender: ProductsToolbar): Promise<void> {

        const grid: GridModel<ProductSelection> = this.grid;

        const modifiedOrderingRules: OrderingRule[] = grid.rows
            .where(item => item.modified)
            .map(item => new OrderingRule(item.model.productAssortmentId!, item.model.productAssortment!.orderThreshold, item.model.productAssortment!.orderQuantity));

        const selectionsToDelete: ProductSelection[] = grid.rows
            .where(item => !item.model.selected)
            .map(item => item.model);

        const deletedProductAssortmentIds: string[] = selectionsToDelete.map(item => item.productAssortmentId!);

        const saveRuleRequest = new SaveOrderingRuleRequest();
        saveRuleRequest.orderingRules = modifiedOrderingRules;
        saveRuleRequest.deletedProductAssortmentIds = deletedProductAssortmentIds;

        await sender.postAsync("/api/productManagement/saveOrderingRule", saveRuleRequest);

        await ch.alertMessageAsync(Localizer.productPanelOrderRulesSaved, true, true);

        selectionsToDelete.forEach(item => grid.delete(item));

        modifiedOrderingRules.forEach(item => this.removeCreatedProductSelection(item.productAssortmentId));

        await grid.bindAsync(true);
    }

    private getCreatedProductSelectionsKey(): string {
        return `createdProductSelections:${this.customerId}`;
    }

    private containsCreatedProductSelection(productAssortmentId: string): boolean {
        const selections: ProductSelection[] = this.getCreatedProductProductSelections();
        return selections.any(item => item.productAssortmentId == productAssortmentId);
    }

    private getCreatedProductProductSelections(): ProductSelection[] {
        const key: string = this.getCreatedProductSelectionsKey();
        return UserInteractionDataStorage.get(key, [], DataStorageType.Page);
    }

    private addCreatedProductSelection(selection: ProductSelection): void {
        if (selection.productAssortment) {
            selection.productAssortmentId = selection.productAssortment.id;
            selection.productAssortment.product = selection.productAssortment.product ?? selection.product;

            const selections: ProductSelection[] = this.getCreatedProductProductSelections();

            const existingSelection: ProductSelection | null = selections.firstOrDefault(item => item.productAssortmentId == selection.productAssortmentId);

            if (existingSelection) {
                selections.remove(existingSelection);
            }

            selections.push(selection);

            const key: string = this.getCreatedProductSelectionsKey();
            UserInteractionDataStorage.set(key, selections, DataStorageType.Page);
        }
    }

    public clearCreatedProductSelections(): void {
        const key: string = this.getCreatedProductSelectionsKey();
        UserInteractionDataStorage.set(key, [], DataStorageType.Page);
    }

    private removeCreatedProductSelection(productAssortmentId: string): void {
        const selections: ProductSelection[] = this.getCreatedProductProductSelections();
        const selectionToRemove: ProductSelection | null = selections.firstOrDefault(item => item.productAssortmentId == productAssortmentId);
        if (selectionToRemove) {
            selections.remove(selectionToRemove);
            UserInteractionDataStorage.set("createdProductSelections", selections, DataStorageType.Page);
        }
    }

    private async onAddCustomerProductAssortment(sender: ProductAssortmentModal, assortment: ProductAssortment, rowIndex: number = 0): Promise<void> {
        const request = new SetProductSelectionRequest();
        request.customerId = assortment.customerId!;
        request.productId = assortment.productId;
        request.orderQuantity = assortment.orderQuantity;
        request.orderThreshold = assortment.orderThreshold;
        request.selected = true;
        request.includeProductSelection = true;
        request.productAssortmentType = ProductAssortmentType.Customer;
        request.validate = true;

        let response: SetProductSelectionResponse = await sender.postAsync("/api/productManagement/setProductSelection", request);

        if (response.hasNoSuchParentProductAssortment) {
            const buttons: IMessageBoxButtons = {
                okButton: Localizer.productPanelNonContractMessageBoxCustomerAndGroup,
                yesButton: Localizer.productPanelNonContractMessageBoxCustomer,
                cancelButton: Localizer.genericCancel
            }

            const result: DialogResult = await ch.messageBoxAsync(Localizer.productPanelTemplateIncludeProductToGroup.format(assortment?.product?.code, this.customer?.name, this.customerGroup?.name), "", buttons);

            switch (result) {
                case DialogResult.OK: {
                    break;
                }
                case DialogResult.Yes: {
                    request.bastard = true;
                    break;
                }
                case DialogResult.Cancel:
                case DialogResult.None: {
                    return;
                }
            }
        }

        request.validate = false;

        response = await sender.postAsync("/api/productManagement/setProductSelection", request);

        const productSelection: ProductSelection | null = response.productSelection;

        if (productSelection) {

            this.addCreatedProductSelection(productSelection);

            const newRow: RowModel<ProductSelection> = (await this.grid.insertAsync(rowIndex, productSelection)).first();

            await newRow.selectAsync();

            await ch.flyoutMessageAsync(Localizer.productPanelMessageAssortmentAdded);
        }
    }

    private async onUploadCustomerAssortmentsAsync(sender: ProductsToolbar, file: FileModel): Promise<void> {
        const confirmationMessage: string = Localizer.productPanelProductsUploadAssortmentsConfirm;

        const confirm: boolean = await ch.confirmAsync(confirmationMessage);

        if (!confirm) {
            return;
        }

        const request = new ImportProductSelectionsRequest();
        request.customerId = this.customer!.id;
        request.file = file;

        await this.setState({loading: true});

        const response: ImportProductSelectionsResponse = await sender.postAsync("/api/productManagement/importProductSelections", request);

        await this.processImportProductSelectionsResponseAsync(response);

        await this.reloadAsync();

        await this.setState({loading: false});
    }

    private async processImportProductSelectionsResponseAsync(response: ImportProductSelectionsResponse): Promise<void> {
        const flyoutDelay: number = 20000;

        if (response.failedToParseProductSelections) {
            await ch.flyoutErrorAsync(Localizer.productPanelTemplateFailedToParse, flyoutDelay);
        } else {
            let message: string = "";

            if (response.numberOfAddedCustomerSelections > 0) {
                message += Localizer.productPanelProductsNewCustomerAssortments.format(response.numberOfAddedCustomerSelections);
            }
            if (response.numberOfModifiedCustomerSelections > 0) {
                message += Localizer.productPanelProductsModifiedCustomerAssortments.format(response.numberOfModifiedCustomerSelections);
            }
            if (response.numberOfRestoredCustomerSelections > 0) {
                message += Localizer.productPanelProductsRestoredCustomerAssortments.format(response.numberOfRestoredCustomerSelections);
            }
            if (response.numberOfRemovedCustomerSelections > 0) {
                message += Localizer.productPanelProductsRemovedCustomerAssortments.format(response.numberOfRemovedCustomerSelections);
            }
            if (response.numberOfAddedGroupSelections > 0) {
                message += Localizer.productPanelProductsNewContractAssortments.format(response.numberOfAddedGroupSelections);
            }
            if (response.numberOfRestoredGroupSelections > 0) {
                message += Localizer.productPanelProductsRestoredContractAssortments.format(response.numberOfRestoredGroupSelections);
            }
            if (response.numberOfRemovedGroupSelections > 0) {
                message += Localizer.productPanelProductsRemovedContractAssortments.format(response.numberOfRemovedGroupSelections);
            }
            if (response.numberOfAddedTemplateSelections > 0) {
                message += Localizer.productPanelProductsNewTemplateAssortments.format(response.numberOfAddedTemplateSelections);
            }

            if (message) {
                message += Localizer.productPanelProductsLabelsCreatedWithDelay;
            } else {
                message = Localizer.productPanelProductsNoChanges;
            }

            await ch.flyoutMessageAsync(message, flyoutDelay);
        }
    }

    public async componentWillReceiveProps(nextProps: IProductsPanelProps): Promise<void> {
        const newTemplateId: boolean = (this.props.templateId != nextProps.templateId);
        const newType: boolean = (this.props.type != nextProps.type);

        await super.componentWillReceiveProps(nextProps);

        if (newTemplateId || newType) {
            await this.reloadAsync();
        }
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    private async exportProductToCsvAsync(sender: ProductsToolbar, encodingType: ExportEncodingType): Promise<void> {
        const sortColumnName: string | null = (this.grid.sortColumn)
            ? this.grid.sortColumn.name
                ? this.grid.sortColumn.name
                : (typeof this.grid.sortColumn.accessor === "string")
                    ? this.grid.sortColumn.accessor
                    : null
            : null;

        const sortColumnDirection: SortDirection | null = this.grid.sortDirection;


        if (this.props.exportToCsv) {
            await this.props.exportToCsv(sender, encodingType, sortColumnDirection, sortColumnName);
        }
    }

    public get grid(): GridModel<ProductSelection> {
        return this._productsPanelGridRef.current!.model;
    }

    public get hasUnsavedOrderingRulesChanges(): boolean {
        return ((this.type == ProductPanelType.OrderingRules) && (this.isModified()));
    }

    public get type(): ProductPanelType {
        return this.props.type;
    }

    public get readonly(): boolean {
        return this.props.readonly ?? false;
    }

    public get templatesTabActive(): boolean {
        return (this.type == ProductPanelType.Templates);
    }

    public get orderingRulesTabActive(): boolean {
        return (this.type == ProductPanelType.OrderingRules);
    }

    public get consumptionPeriodVisible(): boolean {
        return (
            (this.orderingRulesTabActive) ||
            (
                (this.productSelectionsTabActive) &&
                (
                    (this.toolbar.assortmentFilter == ProductAssortmentFilter.Customer) ||
                    ((this.toolbar.assortmentFilter == ProductAssortmentFilter.Selected) && (!!this.customerId))
                )
            )
        );
    }
    
    public get productSelectionsTabActive(): boolean {
        return (this.type == ProductPanelType.ProductsSelection);
    }

    public get toolbar(): ProductsToolbarModel {
        return this.state.toolbar;
    }

    public get assortmentFilter(): ProductAssortmentFilter {
        return this.toolbar.assortmentFilter;
    }

    public get templateFilter(): ProductTemplateFilter {
        return this.toolbar.templateFilter;
    }

    public get customerId(): string | null {
        if (this.toolbar.customer) {
            return this.toolbar.customer.id;
        }

        return null;
    }

    public get customer(): Customer | null {
        return this.toolbar.customer ?? null;
    }

    public getCustomerColumnName(selection: ProductSelection): string {
        return (selection.productAssortment!.customer != null)
            ? (this.toolbar.showAllCustomers)
                ? Customer.getNameWithCode(selection.productAssortment!.customer!)
                : selection.productAssortment!.customer!.name
            : "-";
    }

    public getCustomerColumnSettings(selection: ProductSelection): string {
        return (selection.productAssortment!.customer != null)
            ? (this.toolbar.showAllCustomers)
                ? selection.productAssortment!.customer!.customerGroup!.name
                : selection.productAssortment!.customer!.codeInfo
            : "";
    }

    public get customerGroupId(): string | null {
        if (this.toolbar.customerGroup) {
            return this.toolbar.customerGroup.id;
        }

        return null;
    }

    public get showAllCustomers(): boolean {
        return this.toolbar.showAllCustomers;
    }

    public get productAssortmentType(): ProductAssortmentType {
        return this.toolbar.productAssortmentType;
    }

    public get customerGroup(): CustomerGroup | null {
        return this.toolbar.customerGroup ?? this.customer?.customerGroup ?? null;
    }

    public get templateId(): string | null {
        return this.props.templateId || null;
    }

    public get showCustomerColumn(): boolean {
        return (
            ((this.type == ProductPanelType.OrderingRules) && (this.customer == null) && ((this.toolbar.showAllCustomers) || (this.customerGroupId != null))) ||
            ((this.type == ProductPanelType.ProductsSelection) && (this.toolbar.assortmentFilter == ProductAssortmentFilter.Customer))
        );
    }

    public get hasCustomerOrGroup(): boolean {
        return ((!!this.customerId) || (!!this.customerGroupId));
    }

    public get aittaSelectionEnabled(): boolean {
        return (
            ((this.type == ProductPanelType.ProductsSelection) && (this.hasCustomerOrGroup)) ||
            (this.type == ProductPanelType.Templates)
        );
    }

    public showOrderThresholdAndQuantityAsync(): boolean {
        return (
            (this.orderingRulesTabActive) ||
            (
                (this.productSelectionsTabActive) &&
                (
                    ((!!this.customer) && (this.toolbar.assortmentFilter == ProductAssortmentFilter.Selected)) ||
                    ((!!this.customerGroup) && (this.toolbar.assortmentFilter == ProductAssortmentFilter.Customer))
                )
            )
        );
    }

    public get initialized(): boolean {
        return this.toolbar.initialized(this.type);
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    private get loading(): boolean {
        return this.state.loading;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public renderGroupCell(cell: CellModel<ProductSelection>): React.ReactNode {
        const product: Product = cell.model!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        const stickyToolbar: any = (this.type == ProductPanelType.OrderingRules) && styles.stickyToolbar;
        const stickyTableHeader: any = (this.type == ProductPanelType.OrderingRules) && styles.stickyHeader

        return (
            <div id={this.id} className={this.css(styles.productsPanel)}>

                <ProductsToolbar ref={this._productsToolbarRef}
                                 className={stickyToolbar}
                                 type={this.type}
                                 hideCustomersInSearch={(AittaController.user.isMaster)}
                                 grid={() => this._productsPanelGridRef.current?.model}
                                 readonly={this.readonly}
                                 model={this.toolbar}
                                 exportToCsv={(sender: ProductsToolbar, encodingType: ExportEncodingType) => this.exportProductToCsvAsync(sender, encodingType)}
                                 onApplyTemplate={(sender: ProductsToolbar, template: ProductTemplate) => this.onApplyTemplate(sender, template)}
                                 onUploadFile={(sender: ProductsToolbar, file: FileModel) => this.onUploadFileAsync(sender, file)}
                                 onUploadSelectionsRules={(sender: ProductsToolbar, file: FileModel) => this.onUploadCustomerAssortmentsAsync(sender, file)}
                                 onAddAssortmentModalOpen={((sender: ProductsToolbar, customer: Customer) => this.openProductAssortmentModalAsync(customer))}
                                 saveAllOrderingRules={((sender: ProductsToolbar) => this.saveAllOrderingRulesAsync(sender))}
                                 onChange={(toolbar) => this.onToolbarSubmitAsync(toolbar)}
                />

                <Grid autoToggle pagination optimization responsive
                      id={"productsPanelGrid"}
                      ref={this._productsPanelGridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.Row}
                      className={this.css(styles.productsPanelGrid, stickyTableHeader)}
                      noDataText={Localizer.genericNoData}
                      headerMinHeight={80}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      initRow={(row: RowModel<ProductSelection>) => this.initRow(row)}
                      fetchData={(sender: Grid<ProductSelection>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                      onProcessData={(_, items: ProductSelection[]) => this.onProcessDataAsync(items)}
                      onBeforePagination={(_) => this.onBeforePaginationChangeAsync()}
                      onPagination={() => this._productsToolbarRef.current!.reRenderAsync()}
                />

                <ProductModal id={"productModal"}
                              ref={this._productModalRef}
                />

                <StatisticsModal id={"statisticsModal"}
                                 ref={this._statisticsModalRef}
                />

                <ProductAssortmentModal id={"productAssortmentModal"}
                                        ref={this._productAssortmentModal}
                                        onAddProductAssortment={(sender: ProductAssortmentModal, assortment: ProductAssortment, rowIndex: number) => this.onAddCustomerProductAssortment(sender, assortment, rowIndex)}
                />

                <Description ref={this._descriptionRef}/>

                {(this.loading) && (<Spinner/>)}

            </div>
        )
    }
}