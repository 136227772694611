import React from "react";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {ApiProvider, ch, DataStorageType, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {ButtonType, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ProductsPanel from "@/pages/OrderManagement/ProductsPanel/ProductsPanel";
import {OrderPanelType} from "@/pages/OrderManagement/OrdersToolbar/OrdersToolbarModel";
import AddOrderProductsRequest from "@/models/server/requests/AddOrderProductsRequest";
import SaveOrderProductRequest from "@/models/server/requests/SaveOrderProductRequest";
import OrderProductSelection from "@/models/server/OrderProductSelection";
import SendOrderRequest from "@/models/server/requests/SendOrderRequest";
import Order from "@/models/server/Order";
import SendOrderResponse from "@/models/server/responses/SendOrderRequest";
import ExportOrderToCsvRequest from "@/models/server/requests/ExportOrderToCsvRequest";
import OrdersHistoryPanel from "@/pages/OrderManagement/OdersHistory/OrdersHistoryPanel/OrdersHistoryPanel";
import OrderProduct from "@/models/server/OrderProduct";
import {ExportEncodingType} from "@/models/Enums";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import SaveOrderProductResponse from "@/models/server/requests/SaveOrderProductResponse";
import ExportOrderToXmlRequest from "@/models/server/requests/ExportOrderToXmlRequest";
import ClearOrderRequest from "@/models/server/requests/ClearOrderRequest";
import ClearOrderResponse from "@/models/server/responses/ClearOrderResponse";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import AittaController from "@/pages/AittaController";
import Localizer from "../../localization/Localizer";

import styles from "./OrderManagement.module.scss"
import AddOrderProductsResponse from "@/models/server/responses/AddOrderProductsResponse";

interface IOrderManagementProps {
}

interface IOrderManagementState {
}

export default class ProductManagement extends AuthorizedPage<IOrderManagementProps, IOrderManagementState> {

    state: IOrderManagementState = {
    };
    
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    private readonly _activeProductsPanelRef: React.RefObject<ProductsPanel> = React.createRef();
    
    private async addOrderProductsAsync(customerId: string, products: OrderProduct[], expressOrder: boolean): Promise<boolean> {

        const hasExpressOrderedProducts: boolean = (products.some(item => item.expressOrderQuantity));
        const hasOrderedProducts: boolean = (products.some(item => item.orderQuantity));
        const hasSubmittedProducts: boolean = (products.some(item => item.submittedQuantity));
        
        if (hasExpressOrderedProducts) {
            if (!await ch.confirmAsync(Localizer.orderManagementPageHasExpressOrderedProducts)) {
                return false;
            }
        } else if (hasOrderedProducts) {
            if (!await ch.confirmAsync(Localizer.orderManagementPageHasOrderedProducts)) {
                return false;
            }
        } else if (hasSubmittedProducts) {
            if (!await ch.confirmAsync(Localizer.orderManagementPageHasSubmittedProducts)) {
                return false;
            }
        }
        
        const request = new AddOrderProductsRequest();
        request.customerId = customerId;
        request.expressOrder = expressOrder;
        request.products = products.map(product => OrderProductSelection.create(product.productAssortmentId, product.quantity));
        
        const response: AddOrderProductsResponse = await this.postAsync("/api/orderManagement/addOrderProducts", request);

        if (response.order) {
            GoogleAnalyticsHelper.purchase(response.order, products);
        }
        
        if (this._tabContainerRef.current) {
            
            const tabName: string = (expressOrder)
                ? nameof(OrderPanelType.ExpressOrder)
                : nameof(OrderPanelType.Order);

            await this._tabContainerRef.current.model.activateTabAsync(tabName);
        }
        
        return true;
    }
    
    private async exportOrderToCsvAsync(order: Order, encodingType: ExportEncodingType): Promise<void> {
        const request = new ExportOrderToCsvRequest();
        request.orderId = order.id;
        request.encodingType = encodingType;
        
       const file: FileModel = await this.postAsync("/api/orderManagement/exportOrderToCsv", request);
       
       await ch.download(file);
    }
    
    private async exportOrderToXmlAsync(order: Order): Promise<void> {
        const request = new ExportOrderToXmlRequest();
        request.orderId = order.id;
        
       const file: FileModel = await this.postAsync("/api/orderManagement/exportOrderToXml", request);
       
       await ch.download(file);
    }
    
    private async sendOrderAsync(order: Order, close: boolean): Promise<void> {

        const confirmationMessage: string = (close)
            ? Localizer.orderManagementPageCloseOrderConfirmation
            : Localizer.orderManagementPageSendOrderConfirmation;

        if (!await ch.confirmAsync(confirmationMessage)) {
            return;
        }

        const request = new SendOrderRequest();
        request.orderId = order.id;
        request.lastModifiedAt = order.modifiedAt;
        request.close = close;

        const response: SendOrderResponse = await this.postAsync("/api/orderManagement/sendOrder", request);

        if (response.concurrency) {
            await ch.alertErrorAsync(Localizer.orderManagementPageNotPossibleSendOrder.format(order.number, response.concurrencyBy, response.concurrencyAt));
        } else {
            const message: string = (close)
                ? Localizer.orderManagementPageOrderClosedSuccessfully.format(order.number)
                : Localizer.orderManagementPageOrderSentSuccessfully.format(order.number);

            await ch.flyoutMessageAsync(message);
        }

        if (this._activeProductsPanelRef.current) {
            await this._activeProductsPanelRef.current.reloadAsync();
        }

        GoogleAnalyticsHelper.purchase(order, order.products ?? []);
    }

    private async onSaveOrderProductResponseAsync(order: Order, response: SaveOrderProductResponse): Promise<void> {
        if ((response.isModified) && (response.orderModifiedAt)) {
            order.modifiedAt = response.orderModifiedAt;
        }
    }
    
    private async saveOrderProductAsync(order: Order, product: OrderProductSelection, expressOrder: boolean): Promise<void> {
        const request = new SaveOrderProductRequest();
        request.customerId = order.customerId;
        request.expressOrder = expressOrder;
        request.product = product;
        
        await ApiProvider.backgroundPostAsync("/api/orderManagement/saveOrderProduct", request, null, true, (response: SaveOrderProductResponse) => this.onSaveOrderProductResponseAsync(order, response));
    }
    
    private async clearOrderAsync(order: Order): Promise<void> {
        const request = new ClearOrderRequest();
        request.orderId = order.id;
        
        const response: ClearOrderResponse = await this.postAsync("/api/orderManagement/clearOrder", request);

        if (response.concurrency) {
            await ch.alertErrorAsync(Localizer.orderManagementPageNotPossibleSendOrder.format(order.number, response.concurrencyBy, response.concurrencyAt));
        }
    }
    
    public getTitle(): string {
        return Localizer.orderManagementPageTitle;
    }

    public get hasMediqAccess(): boolean {
        return AittaController.hasMediqAccess;
    }
    
    private get showExpressOrderTab(): boolean {
        return !this.userContext.isShelverPartner;
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.orderManagement} fullWidth fullHeight>

                <BannerMessageAlert />
                
                <PageHeader title={this.getTitle()}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                    
                </PageHeader>
                
                <PageRow>

                    <div className="col">

                        <TabContainer id="orderManagementTabs" ref={this._tabContainerRef} renderType={TabRenderType.ActiveOnly} dataStorageType={DataStorageType.Page}>

                            <Tab active={!this.hasMediqAccess}
                                 id={nameof(OrderPanelType.ProductsSelection)}
                                 title={Localizer.orderManagementPageNewProducts}
                            >
                                
                                <ProductsPanel ref={this._activeProductsPanelRef}
                                               type={OrderPanelType.ProductsSelection}
                                               onSubmit={(sender, order, products, expressOrder) => this.addOrderProductsAsync(order, products, expressOrder)}
                                />
                                
                            </Tab>

                            <Tab active={this.hasMediqAccess}
                                 id={nameof(OrderPanelType.Order)} 
                                 title={Localizer.orderManagementPageOrder}
                            >

                                <ProductsPanel ref={this._activeProductsPanelRef}
                                               type={OrderPanelType.Order}
                                               onChange={(sender, order, product, expressOrder) => this.saveOrderProductAsync(order, product, expressOrder)}
                                               onClear={(sender, order) => this.clearOrderAsync(order)}
                                               downloadOrderToCsv={(sender, order, encodingType: ExportEncodingType) => this.exportOrderToCsvAsync(order, encodingType)}
                                               send={(sender, order, close) => this.sendOrderAsync(order, close)}
                                />

                            </Tab>

                            {
                                (this.showExpressOrderTab) &&
                                (
                                    <Tab id={nameof(OrderPanelType.ExpressOrder)} title={Localizer.orderManagementPageExpressOrder}>

                                        <ProductsPanel ref={this._activeProductsPanelRef}
                                                       type={OrderPanelType.ExpressOrder}
                                                       onChange={(sender, order, product, expressOrder) => this.saveOrderProductAsync(order, product, expressOrder)}
                                                       onClear={(sender, order) => this.clearOrderAsync(order)}
                                                       downloadOrderToCsv={(sender, order, encodingType: ExportEncodingType) => this.exportOrderToCsvAsync(order, encodingType)}
                                                       send={(sender, order, close) => this.sendOrderAsync(order, close)}
                                        />

                                    </Tab>
                                )
                            }

                            <Tab id={nameof(OrderPanelType.OrdersHistory)} title={Localizer.orderManagementPageOrdersHistory}>

                                <OrdersHistoryPanel sendOrder={(order, close) => this.sendOrderAsync(order, close)}
                                                    exportOrderToCsv={(order, encodingType: ExportEncodingType) => this.exportOrderToCsvAsync(order, encodingType)}
                                                    exportOrderToXml={(order) => this.exportOrderToXmlAsync(order)}
                                />
                                
                            </Tab>

                        </TabContainer>

                    </div>
                    
                </PageRow>

            </PageContainer>
        );
    }
}