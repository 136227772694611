// autogenerated

export default class ListAvailableCustomerProductsRequest {

    public customerId: string = "";

    public limitByContract: boolean = false;

    public readonly isListAvailableCustomerProductsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListAvailableCustomerProductsRequest) || ((from as any).isListAvailableCustomerProductsRequest === true));
    }

    public static as(from?: object | null): ListAvailableCustomerProductsRequest | null {
        return (ListAvailableCustomerProductsRequest.is(from)) ? from as ListAvailableCustomerProductsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}