// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductAssortmentFilter, ExportEncodingType} from "@/models/Enums";

export default class ExportProductsToCsvRequest {

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public productAssortmentFilter: ProductAssortmentFilter = ProductAssortmentFilter.All;

    public search: string | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportProductsToCsvRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ExportProductsToCsvRequest) || ((from as any).isExportProductsToCsvRequest === true));
    }

    public static as(from?: object | null): ExportProductsToCsvRequest | null {
        return (ExportProductsToCsvRequest.is(from)) ? from as ExportProductsToCsvRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}