import React from "react";
import {Icon, IconSize, PageContainer, Spinner, TextInput} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BasePageParameters, IManualProps, PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Order from "../../../../models/server/Order";
import OrderProduct from "../../../../models/server/OrderProduct";
import ProductAssortment from "@/models/server/ProductAssortment";
import PageDefinitions from "@/providers/PageDefinitions";
import {OrderProductStatus} from "@/models/Enums";
import Product from "@/models/server/Product";
import ProductAssortmentCard, {ProductAssortmentCardBorder} from "@/components/ProductAssortmentCard/ProductAssortmentCard";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import GetOrderResponse from "@/models/server/responses/GetOrderResponse";
import OrderDelivery from "@/models/server/OrderDelivery";
import OrderProductDelivery from "@/models/server/OrderProductDelivery";

import Localizer from "@/localization/Localizer";

import styles from "./OrderDetails.module.scss";

export interface IOrderDetailsProps extends BasePageParameters {
    orderId: string;
}

interface IOrderDetailsState {
    order: Order | null;
    deliveries: OrderDelivery[];
    loading: boolean;
    search: string;
    searchValue: string;
    expandedOrderProductId: string | null;
    expanded: boolean;
    searchResult: OrderProduct[] | null;
}

export default class OrderDetails extends AuthorizedPage<IOrderDetailsProps, IOrderDetailsState> {

    state: IOrderDetailsState = {
        order: null,
        deliveries: [],
        loading: false,
        search: "",
        searchValue: "",
        expandedOrderProductId: "",
        expanded: false,
        searchResult: null
    };

    private get hasData(): boolean {
        return (
            (this.order != null) &&
            (this.order.products != null) &&
            (!this.order.products.some(item => item.productAssortment == null || item.productAssortment.product == null))
        );
    }

    private get searchValue(): string {
        return this.state.searchValue;
    }

    private async onSearchValueChangedAsync(value: string): Promise<void> {
        const search: OrderProduct[] | null = (value.length == 0)
            ? this.order!.products
            : this.order!.products!.where(item => item.product!.name.toLowerCase().includes(value) || item.product!.code.startsWith(value));

        await this.setState({searchValue: value, searchResult: search});
    }

    private async redirectToProductDetailsAsync(productId: string, productReplacementId: string | null, toReplacementProductIds: string[] | null): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.mobileProductDetails(productId, productReplacementId, toReplacementProductIds));
    }

    private getOrderProductStatusClassName(item: OrderProduct): string {
        switch (item.status) {
            case OrderProductStatus.Delivered:
                return styles.deliveredCircle;
            case OrderProductStatus.PartiallyDelivered:
                return styles.partiallyDeliveredCircle;
            case OrderProductStatus.Submitted:
                return styles.submittedCircle;
            case OrderProductStatus.Closed:
                return styles.closedCircle;
            case OrderProductStatus.Open:
                return styles.openCircle;
            case OrderProductStatus.Ignored:
                return styles.ignoredCircle;
        }
    }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileOrderHistoryPageInfo.toUpperCase(),
            render: () => this.renderManual(),
        };
    }

    public get order(): Order | null {
        return this.state.order;
    }

    public get isMediq(): boolean {
        return this.getUser()?.isMediq;
    }

    public get loading(): boolean {
        return this.state.loading;
    }

    public async initializeAsync(): Promise<void> {

        const orderId: string = this.routeId || "";

        if (orderId) {
            const response: GetOrderResponse = await this.postAsync("/api/mobileApp/GetOrder", orderId);

            await this.setState({order: response.order, deliveries: response.deliveries ?? []});
        }

        await super.initializeAsync();
    }

    private renderManual(): React.ReactNode {
        const orderCustomer: CustomerMobileInfo | null = this.order?.customer ?? null;

        return (
            <div className={styles.manual}>

                {
                    (orderCustomer) &&
                    (
                        <React.Fragment>

                            <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(orderCustomer.codeInfo)} </mark> - {orderCustomer.name} </b></span>

                            <span>{Localizer.mobileInfoGroup}: <b>{this.order?.customer?.customerGroup?.name}</b></span>

                            <span>{ReactUtility.toMultiLines(Localizer.mobileOrderDetailsPageOrderNumber.format(this.order?.number))}</span>

                            {
                                (this.state.deliveries.length > 0) &&
                                (
                                    this.renderDeliveryTracking()       
                                )
                            }

                            <div className={styles.legend}>

                                <h5>{Localizer.mobileOrderHistoryPageLegend.toUpperCase()}</h5>

                                <div>

                                    <span className={this.css(styles.circle, styles.openCircle)}/>

                                    <span className={styles.status}>{Localizer.enumOrderStatusOpen}</span>

                                </div>


                                <div>

                                    <span className={this.css(styles.circle, styles.closedCircle)}/>

                                    <span className={styles.status}>{Localizer.enumOrderStatusClosed}</span>

                                </div>

                                <div>

                                    <span className={this.css(styles.circle, styles.partiallyDeliveredCircle)}/>

                                    <span className={styles.status}>{Localizer.enumOrderStatusPartiallyDelivered}</span>

                                </div>

                                <div>

                                    <span className={this.css(styles.circle, styles.deliveredCircle)}/>

                                    <span className={styles.status}>{Localizer.enumOrderStatusDelivered}</span>

                                </div>

                                <div>

                                    <span className={this.css(styles.circle, styles.ignoredCircle)}/>

                                    <span className={styles.status}>{Localizer.enumOrderStatusIgnored}</span>

                                </div>

                            </div>

                        </React.Fragment>
                    )
                }

            </div>
        )
    }

    private renderDeliveryTracking(): React.ReactElement {

        return (
            <div className={styles.delivery}>

                <h5>{Localizer.mobileOrderDetailsPageDeliveryTracking.toUpperCase()}</h5>

                {
                    this.state.deliveries.map((orderDelivery: OrderDelivery) => (

                        <div key={`orderProductDelivery_${orderDelivery.id}`} className={styles.content}>

                           
                                <div className={styles.deliveryOrder}>

                                    <span className={this.css(styles.circle, styles.openCircle)}>{orderDelivery.date!.format("D")}</span>
                                    
                                    <a href={orderDelivery.trackingLink!} target={"_blank"}>{Localizer.mobileOrderingDetailsPageViewAllLinks} <Icon name={"fas fa-link"}/> </a>
                                    
                                </div>
                           
                            {
                                orderDelivery.deliveries?.map((orderProductDelivery: OrderProductDelivery, index: number) => (
                                    <div key={`orderProductDelivery_${orderProductDelivery.id}`} className={styles.deliveryOrderProduct}>

                                        {
                                            (orderProductDelivery.trackingLink !== "") &&
                                            (
                                                <div>

                                                    {index + 1}.<a href={orderProductDelivery.trackingLink!} target={"_blank"}><small>({orderProductDelivery.productCode})</small> {orderProductDelivery.productName}</a>

                                                    <div>
                                                        <small>({orderProductDelivery.deliveredQuantity}/{orderProductDelivery.orderedQuantity})</small>
                                                    </div>

                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                            }

                        </div>
                    ))
                }

            </div>

        );
    }

    private renderHeader(sender: ProductAssortmentCard, orderProduct: OrderProduct, productReplacementId: string | null): React.ReactElement {
        const product: Product = orderProduct.productAssortment!.product!;
        const productName: string = Product.getName(product);
        const unitAndSize: string = Product.getSalesUnitAndSize(product);

        const replacedStyles: any = ((this.isMediq) && (orderProduct.toProductReplacementIds) && (orderProduct.toProductReplacementIds.length > 0) && (styles.hasReplaced))
        
        return (
            <div className={styles.title}>

                <div className={styles.status}>

                    <span className={this.css(styles.circle, this.getOrderProductStatusClassName(orderProduct))}/>

                </div>

                <div className={styles.name}
                     onClick={() => sender.toggleAsync()}
                >

                    <span>{this.toMultiLines(productName)}</span>

                    <span className={styles.unitAndSize}>{this.toMultiLines(unitAndSize)}</span>

                    <span className={styles.small}> {this.toMultiLines(Localizer.mobileOrderDetailsPageOrderQuantity.format(orderProduct.quantity))} </span>

                </div>

                <div className={this.css(styles.info, (productReplacementId && styles.hasReplacement), replacedStyles)}>
                    <Icon name="info-circle"
                          size={IconSize.X2}
                          onClick={() => this.redirectToProductDetailsAsync(orderProduct.id, productReplacementId, orderProduct.toProductReplacementIds)}
                    />
                </div>

            </div>
        );
    }

    private renderProduct(orderProduct: OrderProduct, index: number): React.ReactElement {
        const productAssortment: ProductAssortment = orderProduct.productAssortment!;
        const productReplacementId: string | null = OrderProduct.getReplacementId(orderProduct);

        return (
            <ProductAssortmentCard key={index}
                                   className={styles.card}
                                   borderType={ProductAssortmentCardBorder.Selected}
                                   productAssortment={productAssortment}
                                   productReplacementId={productReplacementId}
                                   renderHeader={(sender: ProductAssortmentCard) => this.renderHeader(sender, orderProduct, productReplacementId)}
            />
        )
    }

    public renderSearch(): React.ReactNode {
        return (
            <div className={this.css(styles.searchBar)}>
                <TextInput clearButton noAutoComplete
                           placeholder={Localizer.mobileOrderDetailsPageSearchCriteria}
                           id={"catalog_search_product"}
                           className={this.css(styles.searchBarInput)}
                           value={this.searchValue}
                           onChange={(sender, value) => this.onSearchValueChangedAsync(value)}
                           append={<Icon name="far search"/>}
                />

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.orderDetails, this.mobile && styles.mobile)}
            >
                {
                    (this.hasData)
                        ?
                        <React.Fragment>

                            {
                                (this.order!.products!.length > 6) &&
                                (
                                    this.renderSearch()
                                )
                            }

                            {
                                (this.state.searchResult != null)
                                    ? this.state.searchResult.map((item: OrderProduct, index: number) => this.renderProduct(item, index))
                                    : this.order!.products!.map((item: OrderProduct, index: number) => this.renderProduct(item, index))
                            }


                        </React.Fragment>
                        :
                        (this.loading) && <Spinner/>
                }

                {
                    (this.order?.products?.length == 0) &&
                    (
                        <div className={this.css(styles.card, styles.noItems, this.order.express && styles.express)}>
                            {Localizer.mobileCatalogNoProducts}
                        </div>
                    )
                }

            </PageContainer>
        );
    }
}