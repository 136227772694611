// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ReportType, AittaReportFilter} from "@/models/Enums";

export default class ListReportProductsRequest {

    public reportType: ReportType = ReportType.Annual;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public aittaReportFilter: AittaReportFilter = AittaReportFilter.All;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public aittaAppOnly: boolean = false;

    public withSalesOnly: boolean = false;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListReportProductsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListReportProductsRequest) || ((from as any).isListReportProductsRequest === true));
    }

    public static as(from?: object | null): ListReportProductsRequest | null {
        return (ListReportProductsRequest.is(from)) ? from as ListReportProductsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}