import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Checkbox, Form, Inline, JustifyContent, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import {SyncActionType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

import styles from "./SyncActionAuditPanelToolbar.module.scss";

export interface ISyncActionAuditPanelToolbarProps {
    model?: SyncActionAuditPanelToolbarModel;
    syncActionType: SyncActionType;

    onChange?(model: SyncActionAuditPanelToolbarModel): Promise<void>;
}

interface ISyncActionAuditPanelToolbarState {
    model: SyncActionAuditPanelToolbarModel;
}

export class SyncActionAuditPanelToolbarModel {
    public onlyFailed: boolean = false;

    public onlyProcessed: boolean = false;

    public search: string | null = null;
}

export default class SyncActionAuditPanelToolbar extends BaseComponent<ISyncActionAuditPanelToolbarProps, ISyncActionAuditPanelToolbarState> {

    state: ISyncActionAuditPanelToolbarState = {
        model: this.props.model ?? new SyncActionAuditPanelToolbarModel()
    };


    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});

        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private async setOnlyFailed(onlyFailed: boolean): Promise<void> {
        this.model.onlyFailed = onlyFailed;

        await this.processAsync(true);
    }

    private async setOnlyProcessed(onlyProcessed: boolean): Promise<void> {
        this.model.onlyProcessed = onlyProcessed;

        await this.processAsync(true);
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }
    
    public get model(): SyncActionAuditPanelToolbarModel {
        return this.state.model;
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.SyncActionAuditPanelToolbar)}>

                <Form inline onSubmit={() => this.processAsync(true)}>

                    <ToolbarRow justify={JustifyContent.SpaceBetween}>

                        <Inline>

                            <Checkbox id="onlyFailed" inline
                                      label={Localizer.ifsAuditPageToolbarFailedOnly}
                                      value={this.model.onlyFailed}
                                      onChange={(_, value: boolean) => this.setOnlyFailed(value)}
                            />

                            <Checkbox id="onlyProcessed" inline
                                      label={Localizer.ifsAuditPageToolbarProcessedOnly}
                                      value={this.model.onlyProcessed}
                                      onChange={(_, value: boolean) => this.setOnlyProcessed(value)}
                            />

                            {
                                (this.props.syncActionType == SyncActionType.GetOrderAgentTask) &&
                                (
                                    <Inline>

                                        <TextInput id="syncActionSearch" inline clearButton
                                                   width={"20rem"}
                                                   placeholder={Localizer.ifsAuditPageSearchOrder}
                                                   value={this.search}
                                                   onChange={(_, value) => this.setSearchAsync(value)}
                                        />

                                        <Button submit right
                                                id={"search"}
                                                label={Localizer.genericSearch}
                                                icon={{name: "fas search"}}
                                                type={ButtonType.Primary}
                                        />

                                    </Inline>
                                )
                            }

                        </Inline>

                    </ToolbarRow>

                </Form>

            </ToolbarContainer>
        )
    }
}