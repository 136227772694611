import React from "react";
import {
    ButtonType,
    PageContainer,
    PageHeader,
    PageRow,
    Tab,
    TabContainer,
    TabRenderType,
    ToolbarButton
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ProductsPanel from "@/pages/ProductManagement/ProductsPanel/ProductsPanel";
import {ch, DataStorageType, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {ProductPanelType} from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbarModel";
import TemplatesPanel from "@/pages/ProductManagement/TemplatesPanel/TemplatesPanel";
import ProductReplacementsPanel from "@/pages/ProductManagement/ProductReplacementsPanel/ProductReplacementsPanel";
import ProductsToolbar from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbar";
import {FileModel, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ExportEncodingType} from "@/models/Enums";
import ExportProductsToCsvRequest from "@/models/server/requests/ExportProductsToCsvRequest";
import PageRoute from "@reapptor-apps/reapptor-react-common/src/models/PageRoute";
import Localizer from "../../localization/Localizer";

import styles from "./ProductManagement.module.scss"
import AittaController from "@/pages/AittaController";

interface IProductManagementProps {
}

interface IProductManagementState {
    templateId: string | null;
}

export default class ProductManagement extends AuthorizedPage<IProductManagementProps, IProductManagementState> {

    state: IProductManagementState = {
        templateId: null
    };

    private readonly _templatesProductsPanelRef: React.RefObject<ProductsPanel> = React.createRef();
    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    private readonly _orderingRulesProductPanelRef: React.RefObject<ProductsPanel> = React.createRef();

    private get readonly(): boolean {
        return (AittaController.user.isManager);
    }

    private async canContinueNavigationAsync(): Promise<boolean> {
        let canContinueNavigation: boolean = true;

        const orderingRulesPanel: ProductsPanel | null = this._orderingRulesProductPanelRef.current;

        if (orderingRulesPanel) {
            const hasUnsavedOrderingRules: boolean = orderingRulesPanel.hasUnsavedOrderingRulesChanges;

            canContinueNavigation = (!hasUnsavedOrderingRules) || (await this.confirmAsync(Localizer.customerManagementPageUnsavedChanges));
        }

        return canContinueNavigation;
    }

    private async onBeforeSelectTab(): Promise<boolean> {
        return await this.canContinueNavigationAsync();
    }

    public async beforeRedirectAsync(nextRoute: PageRoute, innerRedirect: boolean): Promise<boolean> {

        const canRedirect: boolean = await this.canContinueNavigationAsync();

        if (!canRedirect) {
            return false;
        }

        this._orderingRulesProductPanelRef.current?.clearCreatedProductSelections();

        return super.beforeRedirectAsync(nextRoute, innerRedirect);
    }

    public async onChangeTemplateId(templateId: string | null): Promise<void> {
        await this.setState({templateId});
    }

    public async exportToCsvAsync(sender: ProductsToolbar, productPanelType: ProductPanelType, encodingType: ExportEncodingType, sortColumnDirection: SortDirection | null, sortColumnName: string | null): Promise<void> {
        const request = new ExportProductsToCsvRequest();
        request.customerGroupId = sender.customerGroup?.id ?? null;
        request.customerId = sender.customer?.id ?? null;
        request.productAssortmentFilter = sender.assortmentFilter;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortColumnDirection;
        request.encodingType = encodingType;
        request.search = sender.model.search;

        const file: FileModel = (productPanelType == ProductPanelType.ProductsSelection)
            ? await this.postAsync("/api/productManagement/exportProductsToCsv", request, false, 120)
            : await this.postAsync("/api/productManagement/exportProductOrderRulesToCsv", request, false,120);

        ch.download(file);
    }

    public getTitle(): string {
        return Localizer.productManagementPageTitle;
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }

        return (
            <PageContainer className={styles.productManagement} fullWidth fullHeight scrollX scrollY>

                <PageHeader title={this.getTitle()}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>

                <PageRow>

                    <div className="col">

                        <TabContainer id={"ProductsTabs"} ref={this._tabContainerRef}
                                      renderType={TabRenderType.ActiveOnly}
                                      dataStorageType={DataStorageType.Page}
                                      onBeforeSelect={() => this.onBeforeSelectTab()}
                        >

                            <Tab id="ProductsSelection"
                                 title={Localizer.productManagementProductSelection}
                            >

                                <ProductsPanel id={"productSelectionPanel"}
                                               type={ProductPanelType.ProductsSelection}
                                               readonly={this.readonly}
                                               exportToCsv={(sender: ProductsToolbar, encodingType: ExportEncodingType, sortColumnDirection: SortDirection | null, sortColumnName: string | null) => this.exportToCsvAsync(sender, ProductPanelType.ProductsSelection, encodingType, sortColumnDirection, sortColumnName)}/>

                            </Tab>

                            <Tab id="OrderingRules" title={Localizer.productManagementOrderingRules}>

                                <ProductsPanel id={"orderingRulesPanel"}
                                               ref={this._orderingRulesProductPanelRef}
                                               readonly={this.readonly}
                                               type={ProductPanelType.OrderingRules}
                                               exportToCsv={(sender: ProductsToolbar, encodingType: ExportEncodingType, sortColumnDirection: SortDirection | null, sortColumnName: string | null) => this.exportToCsvAsync(sender, ProductPanelType.OrderingRules, encodingType, sortColumnDirection, sortColumnName)}/>

                            </Tab>

                            {
                                (!this.readonly) &&
                                (

                                    <Tab id="Templates" title={Localizer.productManagementTemplates}>

                                        <TemplatesPanel
                                            onChange={(_, templateId) => this.onChangeTemplateId(templateId)}/>

                                        {
                                            (this.state.templateId) &&
                                            (
                                                <ProductsPanel id={"templatesPanel"}
                                                               ref={this._templatesProductsPanelRef}
                                                               type={ProductPanelType.Templates}
                                                               templateId={this.state.templateId}
                                                />
                                            )
                                        }

                                    </Tab>
                                )
                            }
                            
                            {
                                (!this.readonly) &&
                                (
                                    <Tab id="Replacements" title={Localizer.productManagementReplacements}>

                                        <ProductReplacementsPanel id={"replacementsPanel"} />

                                    </Tab>
                                )
                            }

                        </TabContainer>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}