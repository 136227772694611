import {PageRouteProvider, BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import Customer from "@/models/server/Customer";
import AittaController from "@/pages/AittaController";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";

export default abstract class MobileAuthorizedPage<TProps extends BasePageParameters = {}, TState = {}>
    extends AuthorizedPage<TProps, TState> {
    
    protected get customerRequired(): boolean {
        return true;
    }
    
    public get customer(): Customer {
        return AittaController.currentCustomer!;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        if ((this.customerRequired) && (AittaController.currentCustomer == null)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileDashboardRoute, true, true);
        }
    }
}