// autogenerated

export default class GetCustomerInfoCardRequest {

    public customerId: string = "";

    public pdf: boolean = false;

    public readonly isGetCustomerInfoCardRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof GetCustomerInfoCardRequest) || ((from as any).isGetCustomerInfoCardRequest === true));
    }

    public static as(from?: object | null): GetCustomerInfoCardRequest | null {
        return (GetCustomerInfoCardRequest.is(from)) ? from as GetCustomerInfoCardRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}