import React from "react";
import {BaseComponent, PageCacheProvider, PageCacheTtl} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Icon, Layout, Scroller, Spinner, TextInput} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import InventoryProductMobileInfo from "@/models/server/InventoryProductMobileInfo";
import ListInventoryProductsRequest from "@/models/server/requests/ListInventoryProductsRequest";
import SaveInventoryProductRequest from "@/models/server/requests/SaveInventoryProductRequest";
import ScanQrCodeModal from "@/components/Catalog/ScanQrCodeModal/ScanQrCodeModal";
import InventoryProductItem from "@/pages/Mobile/InventoryDetails/InventoryProductItem/InventoryProductItem";
import Product from "@/models/server/Product";
import ListMobileInventoryProductsResponse from "@/models/server/responses/ListMobileInventoryProductsResponse";

import Localizer from "@/localization/Localizer";

import styles from "./InventoryProductList.module.scss";
import {Utility} from "@reapptor-apps/reapptor-toolkit";

const VISIBLE_DEFAULT: number = 50;

interface IInventoryProductListProps {
    title: string;
    noDataText: string;
    customer: Customer;
    showInventoried: boolean;
    inventoryId: string;
    modifiedProductIds: string[];
    
    onDone(sender: InventoryProductItem, product: InventoryProductMobileInfo): Promise<void>;
    onModify(sender: InventoryProductItem, productId: string): Promise<void>;
}

export interface IInventoryProductListState {
    inventoryProducts: InventoryProductMobileInfo[];
    filteredInventoryProducts: InventoryProductMobileInfo[];
    displayedInventoryProducts: InventoryProductMobileInfo[];
    isLoading: boolean;
    search: string | null;
    visible: number;
}

export default class InventoryProductList extends BaseComponent<IInventoryProductListProps, IInventoryProductListState> {

    state: IInventoryProductListState = {
        inventoryProducts: [],
        filteredInventoryProducts: [],
        displayedInventoryProducts: [],
        isLoading: true,
        search: null,
        visible: VISIBLE_DEFAULT
    };

    private readonly _qrModalRef: React.RefObject<ScanQrCodeModal> = React.createRef();
    private readonly _qrScrollerRef: React.RefObject<Scroller> = React.createRef();

    private getDisplayedInventoryProducts(products: InventoryProductMobileInfo[], visible: number): InventoryProductMobileInfo[] {
        return (products.length > visible)
            ? products.take(visible)
            : products;
    }

    private async fetchInventoryProductsAsync(): Promise<void> {
        if (!this.isLoading) {
            await this.setState({isLoading: true});
        }
        
        const request = new ListInventoryProductsRequest();
        request.inventoried = this.props.showInventoried;
        request.inventoryId = this.props.inventoryId;
        request.includeProductGroups = false;
        
        const response: ListMobileInventoryProductsResponse = await this.postAsync("/api/mobileApp/listMobileInventoryProducts", request, PageCacheTtl._1h);

        const products: InventoryProductMobileInfo[] = Utility.clone(response.products);

        const displayedInventoryProducts: InventoryProductMobileInfo[] = this.getDisplayedInventoryProducts(products, this.state.visible);

        await this.setState({inventoryProducts: products, filteredInventoryProducts: products, displayedInventoryProducts, isLoading: false});
    }

    private async doInventoryAsync(sender: InventoryProductItem, model: InventoryProductMobileInfo): Promise<void> {
        const request = new SaveInventoryProductRequest();
        request.inventoryProductId = model.id;
        request.quantity = model.quantity!;
        request.inputPackages = model.inputPackages;
        request.inputPieces = model.inputPieces;

        await this.postAsync("/api/mobileApp/saveInventoryForProduct", request);

        PageCacheProvider.clear("*listMobileInventoryProducts");

        if (!this.props.showInventoried) {

            this.state.inventoryProducts.remove(model);

            this.state.filteredInventoryProducts.remove(model);

            this.state.displayedInventoryProducts = this.getDisplayedInventoryProducts(this.state.filteredInventoryProducts, this.state.visible);
        }

        if (this.props.onDone) {
            await this.props.onDone(sender, model);
        }
        
        await this.reRenderAsync();
    }

    private get qrModal(): ScanQrCodeModal {
        return this._qrModalRef.current!;
    }

    private async showMoreAsync(): Promise<void> {
        const visible: number = this.state.visible + VISIBLE_DEFAULT;

        const displayedInventoryProducts: InventoryProductMobileInfo[] = this.getDisplayedInventoryProducts(this.state.filteredInventoryProducts, visible);

        await this.setState({visible, displayedInventoryProducts});
    }

    private async onQrAsync(code: string): Promise<void> {
        await this.qrModal.closeAsync();

        await this.onSearchValueChangedAsync(code);
    }

    private async onContainerScrollAsync(position: number, height: number): Promise<void> {
        if (position > 0.75 * height) {
            await this.showMoreAsync();
        }
    }

    private get inventoryProducts(): InventoryProductMobileInfo[] {
        return this.state.inventoryProducts;
    }

    private get search(): string | null {
        return this.state.search;
    }

    private get isLoading(): boolean {
        return this.state.isLoading;
    }

    private get searchResult(): InventoryProductMobileInfo[] {
        return this.state.displayedInventoryProducts;
    }

    private async onSearchValueChangedAsync(value: string): Promise<void> {

        this._qrScrollerRef.current!.scrollTop();

        const visible: number = VISIBLE_DEFAULT;

        if (this.state.visible != visible) {
            await this.setState({visible});
        }

        value = value.toLowerCase();

        const products: InventoryProductMobileInfo[] = (value.length == 0)
            ? this.inventoryProducts
            : this.inventoryProducts.where(item => Product.searchPredicate(item.productAssortment!.product, value));

        const displayedInventoryProducts: InventoryProductMobileInfo[] = this.getDisplayedInventoryProducts(products, visible);

        await this.setState({search: value, filteredInventoryProducts: products, displayedInventoryProducts});
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.fetchInventoryProductsAsync();
    }

    public renderSearch(): React.ReactNode {
        return (
            <div className={this.css(styles.searchBar)}>
                <TextInput clearButton noAutoComplete
                           id={"catalog_search_product"}
                           className={this.css(styles.searchBarInput)}
                           placeholder={Localizer.mobileOrderDetailsPageSearchCriteria}
                           append={<Icon name="far search"/>}
                           value={this.search}
                           onChange={(sender, value) => this.onSearchValueChangedAsync(value)}
                />
            </div>
        );
    }

    public renderInventoryProduct(item: InventoryProductMobileInfo): React.ReactNode {
        const modified: boolean = this.props.modifiedProductIds.includes(item.id);
        
        return (
            <div key={item.id} className={this.css(styles.cardContainer)}>
                <InventoryProductItem inventoryProduct={item}
                                      modified={modified}
                                      onModify={(sender: InventoryProductItem, productId: string) => this.props.onModify(sender, productId)}
                                      onDone={(sender: InventoryProductItem) => this.doInventoryAsync(sender, item)}
                />
            </div>
        );
    }

    public render(): React.ReactNode {
        const searchResult: InventoryProductMobileInfo[] = this.searchResult;

        return (
            <div className={this.css(styles.inventoryProductList)}>

                <div className={this.css(styles.stickyDeviceListHeader)}>

                    <div className={this.css(styles.header)}>

                        {this.renderSearch()}

                        <Button type={ButtonType.Primary}
                                label={Localizer.mobileOrderLabelsPageQR}
                                icon={{name: "fas fa-qrcode"}}
                                onClick={() => this.qrModal.openAsync()}
                        />

                    </div>

                </div>

                {
                    searchResult.map((item: InventoryProductMobileInfo) => this.renderInventoryProduct(item))
                }

                {
                    (searchResult.length == 0) &&
                    (
                        <div className={this.css(styles.cardContainer, styles.customItem, styles.noItems)}>
                            
                            {(this.isLoading) ? Localizer.genericLoading : Localizer.inventoryProductListNoProducts}

                            {(this.isLoading) && <Spinner />}

                        </div>
                    )
                }

                {
                    (this.state.filteredInventoryProducts.length > this.state.visible) &&
                    (
                        <div className={this.css(styles.cardContainer, styles.customItem, styles.showMore)} onClick={() => this.showMoreAsync()}>
                            {Localizer.mobileOrderLabelsPageShowMore}
                        </div>
                    )
                }

                <ScanQrCodeModal ref={this._qrModalRef}
                                 onQr={(sender, code: string) => this.onQrAsync(code)}
                />

                <Layout.Scroller ref={this._qrScrollerRef}
                                 onScroll={(_, position: number, height: number) => this.onContainerScrollAsync(position, height)}
                />

            </div>
        );
    }
};