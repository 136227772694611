import React from "react";
import {BaseComponent, IBaseComponentProps, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {ExportEncodingType, ProductAssortmentFilter, ProductAssortmentType, ProductReplacementLevel, ProductTemplateFilter} from "@/models/Enums";
import {
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    FileInput,
    Form,
    GridModel,
    IconSize,
    Inline,
    InlineType,
    JustifyContent,
    SelectListItem,
    Spinner,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import ProductsToolbarModel, {ProductPanelType} from "@/pages/ProductManagement/ProductsToolbar/ProductsToolbarModel";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import ProductTemplate from "@/models/server/ProductTemplate";
import AittaConstants from "@/helpers/AittaConstants";
import ProductSelection from "@/models/server/ProductSelection";
import GridLightPagination from "@/components/GridLightPagination/GridLightPagination";
import ListCustomersRequest from "@/models/server/requests/ListCustomersRequest";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./ProductsToolbar.module.scss";

export interface IProductsToolbarProps extends IBaseComponentProps {
    type: ProductPanelType;
    model?: ProductsToolbarModel | null;
    grid?: () => GridModel<ProductSelection> | null | undefined;
    readonly?: boolean;
    hideCustomersInSearch?: boolean;

    onChange?(model: ProductsToolbarModel): Promise<void>;

    onApplyTemplate?(sender: ProductsToolbar, template: ProductTemplate): Promise<void>;

    onUploadFile?(sender: ProductsToolbar, file: FileModel): Promise<void>;

    onUploadSelectionsRules?(sender: ProductsToolbar, file: FileModel): Promise<void>;

    onAddReplacement?(sender: ProductsToolbar): Promise<void>;

    onAddAssortmentModalOpen?(sender: ProductsToolbar, customer: Customer): Promise<void>;

    exportToCsv?(sender: ProductsToolbar, encodingType: ExportEncodingType): Promise<void>;

    saveAllOrderingRules?(sender: ProductsToolbar): Promise<void>;
}

interface IProductsToolbarState {
    model: ProductsToolbarModel;
    isLoading: boolean;
    customers: Customer[];
    templates: ProductTemplate[];

    canSaveAllOrderingRules: boolean;
}

export default class ProductsToolbar extends BaseComponent<IProductsToolbarProps, IProductsToolbarState> {

    state: IProductsToolbarState = {
        model: this.props.model ?? new ProductsToolbarModel(),
        isLoading: true,
        customers: [],
        templates: [],
        canSaveAllOrderingRules: false
    };

    private readonly _fileInputRef: React.RefObject<FileInput> = React.createRef();
    private readonly _litePaginationRef: React.RefObject<GridLightPagination<ProductSelection>> = React.createRef();

    private async saveAllOrderingRulesAsync(): Promise<void> {
        if (this.state.canSaveAllOrderingRules) {

            await this.setState({canSaveAllOrderingRules: false});

            if (this.props.saveAllOrderingRules) {
                await this.props.saveAllOrderingRules(this);
            }
        }
    }

    private async openFileInputOpenAsync(): Promise<void> {
        if (this._fileInputRef.current) {
            await this._fileInputRef.current.openAsync();
        }
    }

    private getProductAssortmentFilterItems(): SelectListItem[] {
        let items: ProductAssortmentFilter[];

        const hasCustomer: boolean = (!!this.model.customer);

        if (this.readonly) {
            items = (hasCustomer)
                ? [ProductAssortmentFilter.Selected]
                : [ProductAssortmentFilter.Selected, ProductAssortmentFilter.Customer];
        } else {
            const hasCustomerGroup: boolean = (!!this.model.customerGroup);
            items = (hasCustomer)
                ? [ProductAssortmentFilter.All, ProductAssortmentFilter.Contract, ProductAssortmentFilter.NonContract, ProductAssortmentFilter.Selected, ProductAssortmentFilter.NotSelected]
                : (hasCustomerGroup)
                    ? [ProductAssortmentFilter.All, ProductAssortmentFilter.Selected, ProductAssortmentFilter.NotSelected, ProductAssortmentFilter.Customer]
                    : [ProductAssortmentFilter.All];
        }
        
        return items.map((item: ProductAssortmentFilter) => TransformProvider.toProductAssortmentFilterItem(item, hasCustomer));
    }

    private async fetchCustomersAsync(): Promise<Customer[]> {
        const request = new ListCustomersRequest();
        request.productSelectionIncludingSubscription = (this.type == ProductPanelType.ProductsSelection);
        
        return await PageCacheProvider.getAsync(`listCustomers_productSelectionIncludingSubscription_${request.productSelectionIncludingSubscription}`, () => this.postAsync("/api/productManagement/listCustomers", request));
    }

    private async fetchTemplatesAsync(): Promise<ProductTemplate[]> {
        return await this.postAsync("/api/productManagement/ListActiveProductTemplates");
    }

    private async selectAssortmentFilterAsync(assortmentFilter: ProductAssortmentFilter): Promise<void> {
        this.model.assortmentFilter = assortmentFilter;
        await this.processAsync(true);
    }

    private async selectTemplateFilterAsync(templateFilter: ProductTemplateFilter): Promise<void> {
        this.model.templateFilter = templateFilter;
        await this.processAsync(true);
    }

    private async selectReplacementTypeFilterAsync(replacementLevelFilter: ProductReplacementLevel, userInteraction: boolean): Promise<void> {
        this.model.replacementLevelFilter = replacementLevelFilter;

        if (replacementLevelFilter == ProductReplacementLevel.Global) {
            this.model.customerGroup = null;
            this.model.customer = null;

            await this.processAsync(true);
        } else {
            const items: (CustomerGroup | Customer)[] = this.items;

            const defaultCustomerId: string | null = AittaController.getDefaultCustomerId();
            const defaultCustomerGroupId: string | null = AittaController.getDefaultCustomerGroupId();

            const customerGroupOrCustomer: CustomerGroup | Customer | null = items.firstOrDefault(item => (item.id == defaultCustomerId) || (item.id == defaultCustomerGroupId));

            if (customerGroupOrCustomer) {
                await this.selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer, userInteraction);
            } else {
                await this.reRenderAsync();
            }
        }
    }

    private async selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer: CustomerGroup | Customer | null, userInteraction: boolean): Promise<void> {
        this.model.productAssortmentType = CustomerGroup.is(customerGroupOrCustomer) ? ProductAssortmentType.CustomerGroup : ProductAssortmentType.Customer;
        this.model.customerGroup = (this.model.productAssortmentType == ProductAssortmentType.CustomerGroup) ? customerGroupOrCustomer as CustomerGroup : null;
        this.model.customer = (this.model.productAssortmentType == ProductAssortmentType.Customer) ? customerGroupOrCustomer as Customer : null;

        this.model.showAllCustomers = false;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customerGroupOrCustomer);
        }

        if ((this.type == ProductPanelType.OrderingRules) && (!!this.customerGroup)) {
            this.model.assortmentFilter = ProductAssortmentFilter.Customer;
        }

        await this.processAsync(true);
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }

    private async setShowDeletedAsync(value: boolean): Promise<void> {
        this.model.showHistory = value;

        await this.processAsync(true);
    }

    private async applyTemplateAsync(template: ProductTemplate): Promise<void> {
        if (this.props.onApplyTemplate) {
            await this.props.onApplyTemplate(this, template);
        }
    }

    private async onUploadSelectionsAsync(file: FileModel): Promise<void> {
        if (((this.type == ProductPanelType.ProductsSelection) || this.type == ProductPanelType.Templates) && (this.props.onUploadFile)) {
            await this.props.onUploadFile(this, file);
        } else if (this.props.onUploadSelectionsRules) {
            await this.props.onUploadSelectionsRules(this, file);
        }
    }

    private async addReplacementAsync(): Promise<void> {
        if (this.props.onAddReplacement) {
            await this.props.onAddReplacement(this);
        }
    }

    private async onAddAssortmentModalOpenAsync(): Promise<void> {
        if (this.props.onAddAssortmentModalOpen && this.model.customer) {
            await this.props.onAddAssortmentModalOpen(this, this.model.customer);
        }
    }

    private async setShowActiveReplacementsAsync(value: boolean): Promise<void> {
        this.model.showAllReplacements = value;

        await this.processAsync(true);
    }

    private async setShowAllCustomersAsync(value: boolean): Promise<void> {
        if (!this.readonly) {
            this.model.showAllCustomers = value;

            await this.processAsync(true);
        }
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});

        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }

        if (this._litePaginationRef?.current) {
            await this._litePaginationRef.current.reRenderAsync();
        }
    }

    private async exportToCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {
        if (this.props.exportToCsv) {
            await this.props.exportToCsv(this, encodingType);
        }
    }

    private get showImportActions(): boolean {
        return (!this.readonly) && (
            (
                ((this.type == ProductPanelType.OrderingRules) && (this.hasOnlyCustomer)) ||
                ((this.type == ProductPanelType.ProductsSelection) && (this.hasCustomerOrGroup) && (this.assortmentFilter != ProductAssortmentFilter.Customer))
            ) ||
            (this.type == ProductPanelType.Templates)
        );
    }

    private get hasCustomerOrGroup(): boolean {
        return ((!!this.model.customer?.id) || (!!this.model.customerGroup?.id));
    }

    private get hasOnlyCustomer(): boolean {
        return ((!!this.model.customer?.id) && (!this.model.customerGroup));
    }

    private get templates(): ProductTemplate[] {
        return this.state.templates;
    }

    private get readonly(): boolean {
        return this.props.readonly ?? false;
    }

    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }

    private get grid(): GridModel<ProductSelection> | null {
        return (this.props.grid)
            ? this.props.grid() ?? null
            : null;
    }

    private get items(): (CustomerGroup | Customer)[] {
        const includeGroups: boolean = ((this.type == ProductPanelType.ProductsSelection) || (this.type == ProductPanelType.OrderingRules) || (this.type == ProductPanelType.Replacements));
        const excludeSubscriptionCustomers: boolean = (this.type == ProductPanelType.ProductsSelection);
        
        return (this.type == ProductPanelType.Replacements)
            ? (this.replacementLevelFilter == ProductReplacementLevel.Global)
                ? []
                : (this.replacementLevelFilter == ProductReplacementLevel.CustomerGroup)
                    ? Customer.getCustomerGroups(this.state.customers)
                    : this.state.customers
            : (includeGroups)
                ? Customer.group(this.state.customers, excludeSubscriptionCustomers)
                : this.state.customers;
    }

    public async setCanSaveAllOrderingRulesAsync(canSaveAllOrderingRules: boolean) {
        if (canSaveAllOrderingRules != this.state.canSaveAllOrderingRules) {
            await this.setState({canSaveAllOrderingRules});
        }
    }

    public get type(): ProductPanelType {
        return this.props.type;
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get model(): ProductsToolbarModel {
        return this.state.model;
    }

    public get customerGroup(): CustomerGroup | null {
        return this.model.customerGroup;
    }

    public get customer(): Customer | null {
        return this.model.customer;
    }

    public get assortmentFilter(): ProductAssortmentFilter {
        return this.model.assortmentFilter;
    }

    public get replacementLevelFilter(): ProductReplacementLevel {
        return this.model.replacementLevelFilter;
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public get showCustomerAndGroupDropdown(): boolean {
        return ((this.type == ProductPanelType.OrderingRules) || (this.type == ProductPanelType.ProductsSelection)) ||
            (
                (this.type == ProductPanelType.Replacements) &&
                (this.replacementLevelFilter == ProductReplacementLevel.CustomerGroup)
            )
    }

    public get showCustomersDropdown(): boolean {
        return ((this.type == ProductPanelType.Replacements) && (this.replacementLevelFilter == ProductReplacementLevel.Customer));
    }

    public get customerOrGroupRequired(): boolean {
        return (this.showCustomerAndGroupDropdown) && (this.type != ProductPanelType.ProductsSelection) && (this.type != ProductPanelType.OrderingRules);
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const includeCustomers: boolean = (this.type != ProductPanelType.Templates);

        const fetchTemplates: boolean = (this.type == ProductPanelType.ProductsSelection || this.type == ProductPanelType.OrderingRules);

        const customers: Customer[] = (includeCustomers)
            ? await this.fetchCustomersAsync()
            : [];

        const templates: ProductTemplate[] = (fetchTemplates)
            ? await this.fetchTemplatesAsync()
            : [];

        this.state.customers = customers;
        this.state.templates = templates;
        this.state.model.customer = AittaController.getDefaultCustomer(customers);

        const emptyGroupIfCustomerSelected: boolean = (this.type == ProductPanelType.OrderingRules);

        this.state.model.customerGroup = (emptyGroupIfCustomerSelected)
            ? (!!this.state.model.customer)
                ? null
                : AittaController.getDefaultCustomerGroup(customers)
            : AittaController.getDefaultCustomerGroup(customers);

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public renderReplacementsToolbar(): React.ReactNode {
        return (
            <ToolbarRow className={styles.toolbar} justify={JustifyContent.Start}>

                <Inline>

                    <Checkbox inline
                              id="showHistory"
                              label={Localizer.productsToolbarShowHistory}
                              value={this.model.showHistory}
                              onChange={(_, value) => this.setShowDeletedAsync(value)}
                    />

                    <Checkbox inline
                              id="allActive"
                              label={Localizer.productsToolbarShowAll}
                              value={this.model.showAllReplacements}
                              onChange={(_, value) => this.setShowActiveReplacementsAsync(value)}
                    />

                </Inline>

            </ToolbarRow>
        );
    }

    public renderExportButtons(): React.ReactNode {
        return (
            <Button id={"exportToCsv"} right
                    className={styles.button}
                    label={Localizer.labelsToolbarExcel}
                    icon={{name: "fa-file-download fas", size: IconSize.Large}}
                    type={ButtonType.Primary}
                    disabled={(this.type == ProductPanelType.ProductsSelection) && (this.assortmentFilter == ProductAssortmentFilter.All)}
                    onClick={() => this.exportToCsvAsync()}
            >

                <Button.Action id={"utf8"}
                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                               onClick={() => this.exportToCsvAsync(ExportEncodingType.Utf8)}
                />

                <Button.Action id={"unicode"}
                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                               onClick={() => this.exportToCsvAsync(ExportEncodingType.Unicode)}
                />

                <Button.Action id={"latin1"}
                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                               onClick={() => this.exportToCsvAsync(ExportEncodingType.Latin1)}
                />

                <Button.Action id={"openXml"}
                               title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                               onClick={() => this.exportToCsvAsync(ExportEncodingType.OpenXml)}
                />

            </Button>
        );
    }

    public renderImportButtons(): React.ReactNode {
        return (
            <ButtonContainer className={styles.buttons}>

                <FileInput inline hidden
                           ref={this._fileInputRef}
                           fileTypes={[".xlsx", ".csv"]}
                           onChange={(_, file: FileModel) => this.onUploadSelectionsAsync(file)}
                />

                <Button block
                        className={styles.button}
                        label={Localizer.labelsToolbarExcel}
                        icon={{name: "fas fa-file-upload", size: IconSize.Large}}
                        type={ButtonType.Primary}
                        disabled={(this.type == ProductPanelType.ProductsSelection) && (this.assortmentFilter == ProductAssortmentFilter.All)}
                        onClick={() => this.openFileInputOpenAsync()}
                />

                {
                    (this.templates.length > 0) &&
                    (
                        <Button id={"templateActions"}
                                type={ButtonType.Orange}
                                label={Localizer.productsToolbarApplyTemplate}
                                icon={{name: "far clone"}}
                        >

                            {
                                this.templates.map(item => (
                                    <Button.Action key={"template_" + item.name}
                                                   title={item.name}
                                                   onClick={() => this.applyTemplateAsync(item)}
                                    />
                                ))
                            }

                        </Button>
                    )
                }

            </ButtonContainer>
        );
    }

    public render(): React.ReactNode {
        const showAllCustomersCheckBox: boolean = (!this.readonly) && (this.model.dataInitialized) && ((this.type == ProductPanelType.OrderingRules || this.type == ProductPanelType.ProductsSelection) && (!this.hasCustomerOrGroup));

        const showLightPagination: boolean = (this.type != ProductPanelType.Templates) && (this.model.dataInitialized) && (this.grid != null);

        return (
            <ToolbarContainer id={this.id} className={this.css(styles.productsToolbar, this.props.className)}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Form inline onSubmit={() => this.processAsync(true)}>

                        {
                            (this.type == ProductPanelType.Replacements) &&
                            (
                                <Dropdown id="replacementLevelFilter" required noWrap inline
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          width={"13rem"}
                                          disabled={this.isLoading}
                                          items={EnumProvider.getProductReplacementLevelItems()}
                                          selectedItem={this.replacementLevelFilter}
                                          onChange={(sender, item, userInteraction: boolean) => this.selectReplacementTypeFilterAsync(parseInt(item!.value), userInteraction)}
                                />
                            )
                        }

                        {
                            (this.type == ProductPanelType.Templates) &&
                            (
                                <Dropdown id="templateFilter" required noWrap inline
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          width={"25rem"}
                                          disabled={this.isLoading}
                                          items={EnumProvider.getProductTemplateFilterItems()}
                                          selectedItem={this.assortmentFilter}
                                          onChange={(sender, item) => this.selectTemplateFilterAsync(parseInt(item!.value))}
                                />
                            )
                        }

                        {
                            (this.showCustomerAndGroupDropdown) &&
                            (
                                <Dropdown id="customerGroupsOrCustomers" noWrap inline noGrouping clearButton noSubtext
                                          required={this.customerOrGroupRequired}
                                          className={styles.customerGroupsOrCustomers}
                                          align={DropdownAlign.Left}
                                          filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                          disabled={this.isLoading}
                                          width={AittaConstants.customersDropdownMinWidth}
                                          items={this.items}
                                          selectedItem={this.customer || this.customerGroup}
                                          orderBy={DropdownOrderBy.None}
                                          transform={(item: Customer | CustomerGroup) => TransformProvider.toCustomerOrCustomerGroupSelectItem(item, styles.customerGroupItem, styles.customerItem)}
                                          onChange={(sender, item: CustomerGroup | Customer | null, userInteraction: boolean) => this.selectCustomerGroupOrCustomerAsync(item, userInteraction)}
                                />
                            )
                        }

                        {
                            (this.showCustomersDropdown) &&
                            (
                                <Dropdown id="customerGroupsOrCustomers" noWrap inline clearButton noSubtext
                                          required={this.customerOrGroupRequired}
                                          className={styles.customerGroupsOrCustomers}
                                          align={DropdownAlign.Left}
                                          filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                          disabled={this.isLoading}
                                          width={AittaConstants.customersDropdownMinWidth}
                                          items={this.items}
                                          selectedItem={this.customer}
                                          orderBy={DropdownOrderBy.None}
                                          transform={(item: Customer) => TransformProvider.toCustomerListItem(item)}
                                          onChange={(sender, item: CustomerGroup | Customer | null, userInteraction: boolean) => this.selectCustomerGroupOrCustomerAsync(item, userInteraction)}
                                />
                            )
                        }

                        {
                            (this.type == ProductPanelType.ProductsSelection) &&
                            (
                                <Dropdown id="assortmentFilter" required noWrap inline
                                          className={styles.assortmentFilter}
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          disabled={this.isLoading}
                                          width={"20rem"}
                                          items={this.getProductAssortmentFilterItems()}
                                          selectedItem={this.assortmentFilter}
                                          onChange={(sender, item) => this.selectAssortmentFilterAsync(parseInt(item!.value))}
                                />
                            )
                        }

                        <TextInput id="productSearch" inline clearButton
                                   width={"25rem"}
                                   readonly={this.isLoading}
                                   placeholder={Localizer.productsToolbarSearchPlaceholder}
                                   value={this.search}
                                   onChange={(sender, value: string) => this.setSearchAsync(value)}
                        />

                        <Button submit right
                                disabled={this.isLoading}
                                label={Localizer.genericSearch}
                                icon={{name: "fas search"}}
                                type={ButtonType.Primary}
                        />

                    </Form>

                    {
                        (this.type == ProductPanelType.Replacements) &&
                        (
                            <Inline>

                                <ToolbarButton right
                                               id={"newReplacement"}
                                               icon={{name: "plus"}}
                                               label={Localizer.productsToolbarAddNewReplacement}
                                               title={Localizer.genericAdd}
                                               type={ButtonType.Orange}
                                               onClick={() => this.addReplacementAsync()}
                                />

                            </Inline>
                        )
                    }

                    {
                        ((!this.readonly) && (this.type == ProductPanelType.OrderingRules) && (this.customer)) &&
                        (
                            <Inline>

                                <ToolbarButton right
                                               id={"newProductAssortment"}
                                               icon={{name: "plus"}}
                                               label={Localizer.productManagementOrderingRulesAddProduct}
                                               title={Localizer.genericAdd}
                                               type={ButtonType.Orange}
                                               onClick={() => this.onAddAssortmentModalOpenAsync()}
                                />

                            </Inline>
                        )
                    }

                </ToolbarRow>

                {
                    (this.type == ProductPanelType.Replacements) &&
                    (
                        this.renderReplacementsToolbar()
                    )
                }

                {
                    ((this.type == ProductPanelType.ProductsSelection) || (this.type == ProductPanelType.OrderingRules) || this.type == ProductPanelType.Templates) &&
                    (
                        <ToolbarRow
                            justify={(showAllCustomersCheckBox || showLightPagination) ? JustifyContent.SpaceBetween : JustifyContent.End}
                            className={styles.toolbar}>

                            {
                                (showLightPagination || showAllCustomersCheckBox) &&
                                (
                                    <Inline justify={JustifyContent.Start}>

                                        {
                                            (showLightPagination) &&
                                            (
                                                <GridLightPagination ref={this._litePaginationRef}
                                                                     grid={this.grid!}
                                                />
                                            )
                                        }

                                        {
                                            (showAllCustomersCheckBox) &&
                                            (
                                                <Checkbox inline inlineType={InlineType.Left}
                                                          id="allActive"
                                                          label={Localizer.productsToolbarShowAll}
                                                          value={this.model.showAllCustomers}
                                                          onChange={(_, value) => this.setShowAllCustomersAsync(value)}
                                                />
                                            )
                                        }

                                    </Inline>
                                )
                            }

                            {
                                (showAllCustomersCheckBox) &&
                                (
                                    <Inline justify={JustifyContent.Start}>


                                    </Inline>
                                )
                            }

                            <Inline justify={JustifyContent.End}>

                                {
                                    (
                                        ((this.hasCustomerOrGroup) || (!this.hasCustomerOrGroup && this.model.showAllCustomers)) ||
                                        (this.type == ProductPanelType.Templates)
                                    )
                                    &&
                                    (
                                        <React.Fragment>

                                            {
                                                ((this.type == ProductPanelType.ProductsSelection) || (this.type == ProductPanelType.OrderingRules)) &&
                                                (
                                                    this.renderExportButtons()
                                                )
                                            }

                                            {
                                                (this.showImportActions) &&
                                                (
                                                    this.renderImportButtons()
                                                )
                                            }

                                            {
                                                ((!this.readonly) && (this.type == ProductPanelType.OrderingRules)) &&
                                                (
                                                    <Button block
                                                            label={Localizer.productsToolbarSaveAll}
                                                            icon={{name: "far fa-save"}}
                                                            type={ButtonType.Success}
                                                            disabled={!this.state.canSaveAllOrderingRules}
                                                            onClick={() => this.saveAllOrderingRulesAsync()}
                                                    />
                                                )
                                            }

                                        </React.Fragment>
                                    )
                                }

                            </Inline>

                        </ToolbarRow>
                    )
                }

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}