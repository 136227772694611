import React from "react";
import {Button, ButtonType, GridModel} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";

import styles from "./GridLightPagination.module.scss";
import Localizer from "@/localization/Localizer";

interface IGridLightPaginationProps<TItem = {}> extends IBaseComponentProps {
    grid: GridModel<TItem>;

    onChange?(sender: GridLightPagination<TItem>, grid: GridModel<TItem>): Promise<void>;
}


export default class GridLightPagination<TItem = {}> extends BaseComponent<IGridLightPaginationProps<TItem>> {
    private get grid(): GridModel<TItem> {
        return this.props.grid;
    }

    private async prevPageAsync(): Promise<void> {
        await this.grid.prevPageAsync();

        await this.reRenderAsync();
    }

    private async nextPageAsync(): Promise<void> {
        await this.grid.nextPageAsync();

        await this.reRenderAsync();
    }

    public render(): React.ReactNode {
        const totalItemCount: number = this.grid.totalItemCount;

        const numberOfPieces: string = `${totalItemCount}`;

        const totalPages: number = (totalItemCount > 0)
            ? Math.ceil(totalItemCount / this.grid.pageSize)
            : 1;

        return (
            <div id={this.id} className={this.css(this.props.className, styles.gridLightPagination)}>

                <Button submit
                        type={ButtonType.Default}
                        label={"‹"}
                        disabled={!this.grid.canPrevPage}
                        onClick={() => this.prevPageAsync()}
                />
                
                <Button submit
                        type={ButtonType.Default}
                        label={"›"}
                        disabled={!this.grid.canNextPage}
                        onClick={() => this.nextPageAsync()}
                />

                <div className={styles.info}>

                    <span> {numberOfPieces} </span>

                    <span>{Localizer.gridLightPaginationPage} {`${this.grid.pageNumber}/${totalPages}`}</span>

                </div>
                
            </div>
        )
    }
}