// autogenerated
import ProductGroupMobileInfo from "@/models/server/ProductGroupMobileInfo";

export default class ListProductGroupMobileInfoResponse {

    public productGroups: ProductGroupMobileInfo[] | null = null;

    public parents: ProductGroupMobileInfo[] = [];

    public readonly isListProductGroupMobileInfoResponse: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListProductGroupMobileInfoResponse) || ((from as any).isListProductGroupMobileInfoResponse === true));
    }

    public static as(from?: object | null): ListProductGroupMobileInfoResponse | null {
        return (ListProductGroupMobileInfoResponse.is(from)) ? from as ListProductGroupMobileInfoResponse : null;
    }

    // #region Placeholder

    public static restore(response: ListProductGroupMobileInfoResponse): void {
        const parentsDict: Map<string, ProductGroupMobileInfo[]> = response
            .parents
            .toDictionary(item => item.id, item => item);

        const productGroups: ProductGroupMobileInfo[] = response.productGroups ?? [];

        const length: number = productGroups.length;

        for(let i: number = 0; i < length; i++) {
            const productGroup: ProductGroupMobileInfo = productGroups[i];
            const parentId: string | null = productGroup.parentId;

            if (parentId) {
                productGroup.parent = parentsDict.get(parentId)?.first() ?? null;
            }
        }
    }

    // #endregion
}