import {Utility, GeoLocation, GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import {PageRoute} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, SelectListItem, StatusListItem} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import User from "../models/server/User";
import CustomerGroup from "@/models/server/CustomerGroup";
import ProductTemplate from "@/models/server/ProductTemplate";

export default class Comparator {

    private static isEqualGeoCoordinate(x: GeoCoordinate, y: GeoCoordinate): boolean {
        return (x.lat === y.lat) && (x.lon === y.lon);
    }

    private static isEqualGeoLocation(x: GeoLocation, y: GeoLocation): boolean {
        if (x.formattedAddress === y.formattedAddress) {
            return true;
        }
        
        const xCoordinate: GeoCoordinate | null = AddressHelper.getCoordinate(x);
        const yCoordinate: GeoCoordinate | null = AddressHelper.getCoordinate(y);
        
        if (xCoordinate && yCoordinate) {
            return this.isEqualGeoCoordinate(xCoordinate, yCoordinate);
        }
        
        const xAddress: string = AddressHelper.removeLatLon(x.formattedAddress);
        const yAddress: string = AddressHelper.removeLatLon(y.formattedAddress);
        
        return (xAddress === yAddress);
    }

    private static isEqualStatusListItem(x: StatusListItem, y: StatusListItem): boolean {
        return (x.completed === y.completed) && (x.lineThrough === y.lineThrough) && (x.value === y.value);
    }
    
    public static isEqualPageRoute(x: PageRoute | null, y: PageRoute | null): boolean {

        if (x === y) {
            return true;
        }
        if ((x == null) && (y == null)) {
            return true;
        }
        if ((x == null) || (y == null)) {
            return false;
        }
        if (x.name !== y.name) {
            return false;
        }
        if (x.index !== y.index) {
            return false;
        }
        if (x.id !== y.id) {
            return false;
        }
        
        // TODO: compare parameters
        
        return true;
    }
    
    public static isEqual(x: any | string | null | undefined, y: any | string | null | undefined): boolean {
        if (x === y) {
            return true;
        }

        if ((x == null) && (y == null)) {
            return true;
        }

        if ((x == null) || (y == null)) {
            return false;
        }
        
        if (typeof x === "object") {

            if ((x instanceof StatusListItem) || (x.isStatusListItem)) {
                return ((y instanceof StatusListItem) || (y.isStatusListItem)) && (this.isEqualStatusListItem(x, y));
            }

            if ((x instanceof SelectListItem) || (x.isSelectListItem)) {
                return ((y instanceof SelectListItem) || (y.isSelectListItem)) && ((x as SelectListItem).value === (y as SelectListItem).value);
            }
            
            if ((x instanceof PageRoute) || (x.isPageRoute)) {
                return ((y instanceof PageRoute) || (y.isPageRoute)) && (this.isEqualPageRoute(x, y));
            }

            if ((x instanceof CustomerGroup) || (x.isCustomerGroup)) {
                return ((y instanceof CustomerGroup) || (y.isCustomerGroup))
                    ? ((x as CustomerGroup).id == (y as CustomerGroup).id)
                    : ((typeof y === "string") && ((x as CustomerGroup).id === y as string))
            }

            if ((x instanceof Customer) || (x.isCustomer)) {
                return ((y instanceof Customer) || (y.isCustomer))
                    ? ((x as Customer).id == (y as Customer).id)
                    : ((typeof y === "string") && ((x as Customer).id === y as string))
            }

            if ((x instanceof User) || (x.isUser)) {
                return ((y instanceof User) || (y.isUser))
                    ? ((x as User).id == (y as User).id)
                    : ((typeof y === "string") && ((x as User).id === y as string));
            }

            if ((x instanceof ProductTemplate) || (x.isProductTemplate)) {
                return ((y instanceof User) || (y.isProductTemplate))
                    ? ((x as ProductTemplate).id == (y as ProductTemplate).id)
                    : ((typeof y === "string") && ((x as ProductTemplate).id === y as string));
            }

            if ((x instanceof GeoLocation) || (x.isGeoLocation)) {
                return ((y instanceof GeoLocation) || (y.isGeoLocation)) && (this.isEqualGeoLocation(x, y));
            }

            if ((x instanceof GeoCoordinate) || (x.isGeoCoordinate)) {
                return ((y instanceof GeoCoordinate) || (y.isGeoCoordinate)) && (this.isEqualGeoCoordinate(x, y));
            }

            if ((Utility.isDateType(x)) || (Utility.isDateType(y))) {
                const xDate: Date = new Date(x);
                const yDate: Date = new Date(y);
                return (xDate.valueOf() === yDate.valueOf());
            }
        }
        
        if ((typeof x === "string") && (typeof y === "object")) {
            return Comparator.isEqual(y as any, x as string);
        }
        
        if ((Array.isArray(x)) && (Array.isArray(y))) {
            
            if (x.length !== y.length) {
                return false;
            }
            
            for (let i: number = 0; i < x.length; i++) {
                if (!Comparator.isEqual(x[i], y[i])) {
                    return false;
                }
            }
            
            return true;
        }

        return (x == y);
    }

    public static isNonNullObject(value: any): boolean {
        return (typeof value === "object") && (value !== null);
    }
    
    public static assertIsNonNullObject<T extends object>(value: any): T {
        if (this.isNonNullObject(value)) {
            return value as T;
        }
        throw new TypeError("value is not an object or is null");
    }
}