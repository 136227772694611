// autogenerated

export default class LoginRequest {

    public username: string | null = null;

    public password: string | null = null;

    public readonly isLoginRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof LoginRequest) || ((from as any).isLoginRequest === true));
    }

    public static as(from?: object | null): LoginRequest | null {
        return (LoginRequest.is(from)) ? from as LoginRequest : null;
    }

    // #region Placeholder

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }

    // #endregion
}