import React from "react";
import {ApiProvider, ch, IManualProps, PageCacheProvider, PageCacheTtl, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {PageContainer} from "@reapptor-apps/reapptor-react-components";
import GetOpenOrderRequest from "@/models/server/requests/GetOpenOrderRequest";
import OrderMobileInfo from "@/models/server/OrderMobileInfo";
import Catalog, {CatalogMode} from "@/components/Catalog/Catalog";
import CatalogDataProvider from "@/providers/CatalogDataProvider";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import OrderProductSelection from "@/models/server/OrderProductSelection";
import SaveOrderProductRequest from "@/models/server/requests/SaveOrderProductRequest";
import SendOrderRequest from "@/models/server/requests/SendOrderRequest";
import SendOrderResponse from "@/models/server/responses/SendOrderRequest";
import OrderProductItem from "@/components/Catalog/OrderProductItem/OrderProductItem";
import SaveOrderProductResponse from "@/models/server/requests/SaveOrderProductResponse";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./ExpressOrder.module.scss";
import HasProductOrderOverlapResponse from "@/models/server/responses/HasProductOrderOverlapResponse";
import HasProductOrderOverlapRequest from "@/models/server/requests/HasProductOrderOverlapRequest";

export interface IExpressOrderProps {
}

interface IExpressOrderState {
    order: OrderMobileInfo | null;
    dataProvider: CatalogDataProvider | null;
}

export default class ExpressOrder extends MobileAuthorizedPage<IExpressOrderProps, IExpressOrderState> {

    state: IExpressOrderState = {
        order: null,
        dataProvider: null,
    };


    private readonly _catalogRef: React.RefObject<Catalog> = React.createRef();
    
    private getOrderAsync(): Promise<OrderMobileInfo> {
        const request = new GetOpenOrderRequest();
        request.customerId = this.customer!.id;
        request.express = true;
        request.includeProductsDetails = true;

        return this.postAsync("/api/mobileApp/getMobileOpenOrder", request, PageCacheTtl._5m);
    }

    private async onChangeProductItem(orderProduct: OrderProductMobileInfo): Promise<void> {
        const productSelection = OrderProductSelection.create(orderProduct.productAssortmentId, orderProduct.quantity);

        const request = new SaveOrderProductRequest();
        request.customerId = this.customer.id;
        request.expressOrder = true;
        request.product = productSelection;

        await ApiProvider.backgroundPostAsync<SaveOrderProductResponse>("/api/mobileApp/saveOrderProduct", request, null, true, (response: SaveOrderProductResponse) => this.onSaveOrderProductResponseAsync(response));

        await this.reRenderAsync();
    }

    private async onSaveOrderProductResponseAsync(response: SaveOrderProductResponse): Promise<void> {
        if ((this.order) && (response.isModified)) {

            this.order.modifiedAt = response.orderModifiedAt!;

            PageCacheProvider.clear("*order*");
        }
    }

    private async sendExpressOrderAsync(sender: Catalog): Promise<boolean> {
        let orderSent: boolean = false;

        if (this.order) {
            const order: OrderMobileInfo = this.order;

            let confirmed: boolean = await this.confirmAsync(Localizer.mobileExpressOrderPageConfirmExtraCharges.format(this.order.number));

            if (!confirmed) {
                return orderSent;
            }
            
            // const hasProductOrderOverlap: boolean = order.products.some(item => (item.orderQuantity != null) && (item.orderQuantity > 0));

            const hasProductOrderOverlapRequest = new HasProductOrderOverlapRequest();
            hasProductOrderOverlapRequest.orderId = order.id;
            
            const hasProductOrderOverlapResponse: HasProductOrderOverlapResponse = await sender.postAsync("api/mobileApp/hasProductOrderOverlap", hasProductOrderOverlapRequest);

            if (hasProductOrderOverlapResponse.hasOverlap && hasProductOrderOverlapResponse.codes) {
                const codes:string = (hasProductOrderOverlapResponse.codes.length > 5)
                ? hasProductOrderOverlapResponse.codes.take(5).join(",")
                : hasProductOrderOverlapResponse.codes.join(",");
                
                confirmed = await this.confirmAsync(Localizer.mobileExpressOrderPageConfirmDuplicateProduct.format(codes));

                if (!confirmed) {
                    return orderSent;
                }
            }

            const request = new SendOrderRequest();
            request.orderId = order.id;
            request.lastModifiedAt = order.modifiedAt;

            const response: SendOrderResponse = await sender.postAsync("/api/mobileApp/sendOrder", request);

            if (response.concurrency) {
                await ch.alertErrorAsync(Localizer.orderManagementPageNotPossibleSendOrder.format(order.number, response.concurrencyBy, response.concurrencyAt));
                return orderSent;
            } else {
                const message: string = Localizer.orderManagementPageOrderSentSuccessfully.format(order.number);

                await ch.flyoutMessageAsync(message);

                const newOrder: OrderMobileInfo = await this.getOrderAsync();

                await this.setState({order: newOrder});

                orderSent = true;
                
                GoogleAnalyticsHelper.purchase(this.order, this._catalogRef.current!.state!.displayedProducts);
            }
        }

        return orderSent;
    }

    private async onScanQrCodeAsync(sender: Catalog, code: string): Promise<void> {
        await AittaController.onScanQrCodeAsync(sender, this.dataProvider, code, this.order!.customerId);
    }

    public getManualProps(): IManualProps {
        const itemsInBasket: number = this.dataProvider?.orderProductsCount ?? 0;

        return ((this.customer) && (this.order))
            ? {
                icon: "info-circle",
                title: Localizer.mobileExpressOrderPageName.format(itemsInBasket),
                render: () => this.renderManual(),
            }
            : {};
    }

    public get order(): OrderMobileInfo | null {
        return this.state.order;
    }

    public get dataProvider(): CatalogDataProvider | null {
        return this.state.dataProvider;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const order: OrderMobileInfo = await this.getOrderAsync();

        const dataProvider = new CatalogDataProvider(this.customer.customerGroupId, this.customer.id, order.products ?? [], true, false, true)

        await this.setState({order, dataProvider});

        await this.reRenderTopNavAsync();
    }

    public renderManual(): React.ReactNode {
        const selectedCustomer: CustomerMobileInfo = this.customer;
        
        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(selectedCustomer.codeInfo)} </mark> - {selectedCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{this.customer?.customerGroupName}</b></span>

                <span>{Localizer.mobileExpressOrderPageOrder}: <b>#{this.order?.number}</b></span>

                <span>{Localizer.mobileExpressOrderPageCreatedDate}: <b>{"{0:D} {0:dddd}".format(this.order?.createdAt).toUpperCase()}</b></span>

            </div>
        )
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.expressOrder, this.mobile && styles.mobile)}
            >
                
                <BannerMessageAlert className={styles.banner} />
                
                {
                    (this.dataProvider) &&
                    (
                        <Catalog ref={this._catalogRef}
                                 className={styles.categoriesProducts}
                                 express={true}
                                 dataProvider={this.dataProvider}
                                 mode={CatalogMode.Order}
                                 sendExpressOrder={(sender: Catalog) => this.sendExpressOrderAsync(sender)}
                                 onScanQr={(sender: Catalog, code: string) => this.onScanQrCodeAsync(sender, code)}
                                 onChange={(sender: OrderProductItem, item: OrderProductMobileInfo) => this.onChangeProductItem(item)}
                        />
                    )
                }

            </PageContainer>
        );
    }
}