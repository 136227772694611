// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListPostDeliveryProductOrdersRequest extends ETagRequest {

    public customerId: string = "";

    public readonly isListPostDeliveryProductOrdersRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListPostDeliveryProductOrdersRequest) || ((from as any).isListPostDeliveryProductOrdersRequest === true));
    }

    public static as(from?: object | null): ListPostDeliveryProductOrdersRequest | null {
        return (ListPostDeliveryProductOrdersRequest.is(from)) ? from as ListPostDeliveryProductOrdersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}