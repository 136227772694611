import React from "react";
import {BaseComponent, PageCacheProvider, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, Form, Inline, JustifyContent, Spinner, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import InventoriesToolbarModel from "@/pages/InventoryManagement/InventoriesToolbar/InventoriesToolbarModel";
import Inventory from "@/models/server/Inventory";
import ListInventoriesRequest from "@/models/server/requests/ListInventoriesRequest";
import InventoryProduct from "@/models/server/InventoryProduct";
import AittaConstants from "@/helpers/AittaConstants";
import {ExportEncodingType} from "@/models/Enums";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./InventoriesToolbar.module.scss";

export interface IInventoriesToolbarProps {
    model: InventoriesToolbarModel;
    hideCustomersInSearch?: boolean;
    onChange?(sender: InventoriesToolbar, model: InventoriesToolbarModel): Promise<void>;
    exportToCsv?(sender: InventoriesToolbar, model: InventoriesToolbarModel, encodingType: ExportEncodingType): Promise<void>;
}

interface IInventoriesToolbarState {
    model: InventoriesToolbarModel;
    isLoading: boolean;
    customers: Customer[];
    inventories: Inventory[];
    products: InventoryProduct[];
}

export default class InventoriesToolbar extends BaseComponent<IInventoriesToolbarProps, IInventoriesToolbarState> {

    state: IInventoriesToolbarState = {
        model: this.props.model,
        isLoading: true,
        customers: [],
        inventories: [],
        products: [],
    };

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/inventoryManagement/listCustomers"));
    }

    private async fetchInventoriesAsync(customerId: string): Promise<Inventory[]> {
        const request = new ListInventoriesRequest();
        request.customerId = customerId;

        const inventories: Inventory[] = await PageCacheProvider.getAsync(`listInventories?${customerId}`, () => this.postAsync("/api/inventoryManagement/listInventories", request));

        // Validate inventoryId
        if (this.inventoryId) {
            let inventory: Inventory | null = inventories.firstOrDefault(item => item.id == this.inventoryId) ?? inventories.firstOrDefault();

            await this.selectInventoryAsync(inventory);
        }

        return inventories;
    }

    private async setCustomerFavoriteAsync(customer: Customer, favorite: boolean): Promise<void> {
        customer.favorite = favorite;

        await AittaController.setFavoriteAsync(customer.id, favorite);
    }

    private async reLoadInventoriesAsync(): Promise<void> {
        if (this.model.customerId) {

            const inventories: Inventory[] = await this.fetchInventoriesAsync(this.model.customerId);

            await this.setState({inventories});

            if (inventories.length == 0) {
                this.state.products = [];

                await this.selectInventoryAsync(null);
            }
        }
    }

    private async selectCustomerAsync(customer: Customer, userInteraction: boolean): Promise<void> {
        if (this.model.customerId !== customer.id) {
            this.model.customerId = customer.id;
            this.model.inventoryId = null;

            if (userInteraction) {
                AittaController.setDefaultCustomerGroupOrCustomer(customer);
            }

            UserInteractionDataStorage.set("inventoryId", null);

            await this.reLoadInventoriesAsync();

            await this.processAsync(true);
        }
    }

    private async selectInventoryAsync(inventory: Inventory | null): Promise<void> {
        const inventoryId: string | null = inventory?.id || null;

        if (this.model.inventoryId != inventoryId) {
            this.model.inventoryId = inventoryId;

            UserInteractionDataStorage.set("inventoryId", inventoryId);

            await this.processAsync(true);
        }
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});

        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this, this.state.model);
        }
    }

    private async exportToCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {
        if (this.props.exportToCsv) {
            await this.props.exportToCsv(this, this.model, encodingType);
        }
    }

    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }

    private get customers(): Customer[] {
        return this.state.customers;
    }

    private get inventories(): Inventory[] {
        return this.state.inventories;
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get model(): InventoriesToolbarModel {
        return this.state.model;
    }

    public get customerId(): string | null {
        return this.model.customerId;
    }

    public get inventoryId(): string | null {
        return this.model.inventoryId || UserInteractionDataStorage.get("inventoryId");
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public get products(): InventoryProduct[] {
        return this.state.products;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        this.state.customers = await this.fetchCustomersAsync();

        this.model.customerId = AittaController.getDefaultCustomer(this.state.customers)?.id ?? null;

        await this.reLoadInventoriesAsync();

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public async reloadAsync(): Promise<void> {
        await this.reLoadInventoriesAsync()
    }

    public async setProductsAsync(products: InventoryProduct[]): Promise<void> {
        await this.setState({products});
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.inventoriesToolbar)}>

                <Form onSubmit={() => this.processAsync(true)}>

                    <ToolbarRow justify={JustifyContent.SpaceBetween}>

                        <Inline>

                            <Dropdown id="customers" required noWrap inline favorite filterFavorite
                                      className={styles.customers}
                                      align={DropdownAlign.Left}
                                      filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                      disabled={this.isLoading}
                                      width={AittaConstants.customersDropdownMinWidth}
                                      items={this.customers}
                                      selectedItem={this.customerId}
                                      onChange={(sender, item: Customer, userInteraction: boolean) => this.selectCustomerAsync(item, userInteraction)}
                                      onFavoriteChange={(sender, item: Customer, favorite: boolean) => this.setCustomerFavoriteAsync(item, favorite)}
                            />

                            <Dropdown id="inventories" required noWrap inline noSubtext
                                      nothingSelectedText={Localizer.inventoriesToolbarNoInventories}
                                      className={styles.inventories}
                                      width={"15rem"}
                                      align={DropdownAlign.Left}
                                      orderBy={DropdownOrderBy.None}
                                      disabled={this.isLoading || this.inventories.length == 0}
                                      items={this.inventories}
                                      selectedItem={this.inventoryId}
                                      onChange={(sender, item: Inventory | null) => this.selectInventoryAsync(item)}
                            />

                            <TextInput id="productSearch" inline clearButton
                                       width={"25rem"}
                                       readonly={this.isLoading}
                                       placeholder={Localizer.inventoriesToolbarSearchPlaceholder}
                                       value={this.search}
                                       onChange={(sender, value: string) => this.setSearchAsync(value)}
                            />

                            <Button id={"search"}
                                    submit right
                                    disabled={this.isLoading}
                                    label={Localizer.genericSearch}
                                    icon={{name: "fas search"}}
                                    type={ButtonType.Primary}
                            />

                        </Inline>

                        <Inline>

                            <Button id={"exportToCsv"} right
                                    className={styles.button}
                                    label={"Excel <small>{0}</small>".format(this.products.length)}
                                    icon={{name: "fa-file-download fas"}}
                                    type={ButtonType.Primary}
                                    disabled={this.isLoading || this.products.length == 0}
                                    onClick={() => this.exportToCsvAsync()}
                            >

                                <Button.Action id={"utf8"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                                               onClick={() => this.exportToCsvAsync(ExportEncodingType.Utf8)}
                                />

                                <Button.Action id={"unicode"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                                               onClick={() => this.exportToCsvAsync(ExportEncodingType.Unicode)}
                                />

                                <Button.Action id={"latin1"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                                               onClick={() => this.exportToCsvAsync(ExportEncodingType.Latin1)}
                                />

                                <Button.Action id={"openXml"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                                               onClick={() => this.exportToCsvAsync(ExportEncodingType.OpenXml)}
                                />

                            </Button>

                        </Inline>

                    </ToolbarRow>

                </Form>

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}