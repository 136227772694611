import {OrderStatus, ProductOrderFilter} from "@/models/Enums";

export default class InventoriesToolbarModel {
    public orderStatusFilter: OrderStatus | null = null;
    
    public productOrderFilter: ProductOrderFilter = ProductOrderFilter.CustomerSelection;

    public customerId: string | null = null;

    public inventoryId: string | null = null;

    public search: string | null = null;

    public dataInitialized: boolean = false;

    public static initialized(toolbar: InventoriesToolbarModel): boolean {
        return ((toolbar.dataInitialized) && (!!toolbar.customerId) && (!!toolbar.inventoryId));
    }
}