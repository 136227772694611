import React from "react";
import {BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, Form, IconSize, Inline, TextAreaInput, TextInput, ThreeColumns} from "@reapptor-apps/reapptor-react-components";
import ProductTemplate from "@/models/server/ProductTemplate";
import SaveProductTemplateRequest from "@/models/server/requests/SaveProductTemplateRequest";
import DeleteProductTemplateRequest from "@/models/server/requests/DeleteProductTemplateRequest";
import SaveProductTemplateResponse from "@/models/server/responses/SaveProductTemplateResponse";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import AittaConstants from "@/helpers/AittaConstants";

import Localizer from "@/localization/Localizer";

import styles from "./TemplatesPanel.module.scss";

export interface ITemplatesPanelProps {
    onChange(sender: TemplatesPanel, templateId: string | null): Promise<void>;
}

interface ITemplatesPanelState {
    templates: ProductTemplate[];
    template: ProductTemplate | null;
    isLoading: boolean;
    edit: boolean;
}

export default class TemplatesPanel extends BaseComponent<ITemplatesPanelProps, ITemplatesPanelState> {

    state: ITemplatesPanelState = {
        templates: [],
        template: null,
        edit: false,
        isLoading: true
    };

    private async fetchTemplatesAsync(): Promise<void> {
        const templates: ProductTemplate[] = await this.postAsync("/api/productManagement/ListProductTemplates");

        await this.setState({templates});
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get template(): ProductTemplate | null {
        return this.state.template;
    }

    public get templates(): ProductTemplate[] {
        return this.state.templates;
    }

    public get isNew(): boolean {
        return (this.template != null) && (!this.template.id);
    }

    public async addNewTemplateAsync(): Promise<void> {
        await this.setState({template: new ProductTemplate(), edit: true});

        await this.props.onChange(this, null);
    }

    public async editTemplateAsync(): Promise<void> {
        const edit: boolean = !this.state.edit;

        await this.setState({edit});
    }

    public async saveTemplateAsync(): Promise<void> {

        const template: ProductTemplate = this.template!;
        const isNew: boolean = (!template.id);

        const request = new SaveProductTemplateRequest();
        request.id = template.id;
        request.name = template.name;
        request.description = template.description;

        const response: SaveProductTemplateResponse = await this.postAsync("/api/productManagement/saveProductTemplates", request);

        if (response.alreadyExists) {
            await ch.flyoutErrorAsync(Localizer.templatesPanelTemplateExists.format(template.name));
            return;
        }

        await ch.flyoutMessageAsync(Localizer.templatesPanelSaved);

        if (isNew) {
            this.templates.push(response.productTemplate!);
        } else {
            const modifiedItem: ProductTemplate | undefined = this.templates.find(item => item.id == template.id);

            const index: number = this.templates.indexOf(modifiedItem!);

            this.templates[index] = response.productTemplate!;
        }

        this.state.templates = Utility.clone(this.templates);

        await this.setState({edit: false, template: response.productTemplate!});

        await this.props.onChange(this, response.productTemplate!.id);
    }

    public async setTemplateName(value: string): Promise<void> {
        if (this.template) {
            this.template.name = value;

            await this.setState({template: this.template});
        }
    }

    public async setTemplateDescription(value: string): Promise<void> {
        if (this.template) {
            this.template.description = value;

            await this.setState({template: this.template});
        }
    }

    public async setTemplateAsync(template: ProductTemplate | null): Promise<void> {
        if (!this.isNew) {
            await this.setState({template});
        }

        const templateId: string | null = (template != null) ? template.id : null;

        await this.props.onChange(this, templateId);
    }

    public async cancelAsync(): Promise<void> {
        await this.setState({edit: false, template: null});
    }

    public async deleteTemplateAsync(): Promise<void> {

        const template: ProductTemplate | undefined = this.templates.find(template => template.id == this.template!.id);

        const request = new DeleteProductTemplateRequest();
        request.id = template!.id;

        await this.postAsync("/api/productManagement/deleteProductTemplate", request);

        this.templates.remove(template!);

        await this.setState({template: null});

        if (this.templates.length > 0) {
            const lastTemplate: ProductTemplate = this.templates[this.templates.length - 1];
            await this.props.onChange(this, lastTemplate.id)
        } else {
            await this.props.onChange(this, null);
        }

        await ch.flyoutMessageAsync(Localizer.templatesPanelDelete.format(template!.name));
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.fetchTemplatesAsync();

        await this.setState({isLoading: false});
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.templatesPanel)}>

                <div className={styles.content}>

                    <Form className={styles.form} onSubmit={() => this.saveTemplateAsync()}>

                        <Inline>

                            <Dropdown id="templates" noSubtext
                                      required={this.templates.length > 0 && !this.isNew}
                                      label={Localizer.templatesPanelTemplates}
                                      align={DropdownAlign.Left}
                                      orderBy={DropdownOrderBy.None}
                                      width={"17rem"}
                                      nothingSelectedText={Localizer.templatesPanelNoTemplates}
                                      selectedTextTransform={() => (this.isNew) ? Localizer.templatesPanelNewTemplate : ""}
                                      disabled={this.state.edit || this.isLoading || this.templates.length == 0}
                                      items={this.templates}
                                      selectedItem={!this.isNew ? this.template : null}
                                      onChange={(sender, item) => this.setTemplateAsync(item)}
                            />

                            <Button id={"addTemplate"}
                                    className={styles.actions}
                                    icon={{name: "plus", size: IconSize.Large}}
                                    title={Localizer.genericAdd}
                                    type={ButtonType.Orange}
                                    disabled={this.state.edit}
                                    onClick={() => this.addNewTemplateAsync()}
                            />
                            
                            {
                                (this.template != null) &&
                                (
                                    <Button id={"editTemplate"}
                                            className={styles.actions}
                                            icon={{name: "edit", size: IconSize.Large}}
                                            title={Localizer.genericEdit}
                                            type={ButtonType.Primary}
                                            disabled={this.state.edit}
                                            onClick={() => this.editTemplateAsync()}
                                    />
                                )
                            }

                            {
                                ((this.template != null) && (this.template.id)) &&
                                (
                                    <Button type={ButtonType.Blue}
                                            className={styles.actions}
                                            title={Localizer.genericDelete}
                                            icon={{name: "far trash"}}
                                            disabled={this.state.edit}
                                            confirm={Localizer.templatesPanelAreYouSureDelete.format(this.template.name)}
                                            onClick={() => this.deleteTemplateAsync()}
                                    />
                                )
                            }

                        </Inline>

                        {
                            ((this.template) && (this.state.edit)) &&
                            (
                                <React.Fragment>

                                    <ThreeColumns>

                                        <TextInput required autoFocus noAutoComplete trim
                                                   id="name"
                                                   maxLength={AittaConstants.keyLength}
                                                   label={Localizer.templatesPanelName}
                                                   readonly={this.isLoading}
                                                   value={this.template.name || ""}
                                                   onChange={(sender, value: string) => this.setTemplateName(value)}
                                        />

                                    </ThreeColumns>

                                    <ThreeColumns>

                                        <TextAreaInput id="description"
                                                       label={Localizer.templatesPanelDescription}
                                                       readonly={this.isLoading}
                                                       value={this.template.description}
                                                       maxLength={AittaConstants.descriptionLength}
                                                       onChange={(sender, value: string) => this.setTemplateDescription(value)}
                                        />

                                    </ThreeColumns>

                                    <ThreeColumns>
                                        
                                        <ButtonContainer className={styles.buttonContainer}>

                                            <Button type={ButtonType.Blue}
                                                    icon={{name: "far ban", size: IconSize.Large}}
                                                    label={Localizer.genericCancel}
                                                    onClick={() => this.cancelAsync()}
                                            />

                                            <Button submit right
                                                    type={ButtonType.Orange}
                                                    icon={{name: "far save", size: IconSize.Large}}
                                                    label={Localizer.formSave}
                                            />

                                        </ButtonContainer>
                                        
                                    </ThreeColumns>

                                </React.Fragment>
                            )
                        }
                    </Form>

                </div>

            </div>
        )
    }
}