// autogenerated
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ProductAssortmentType} from "@/models/Enums";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import InventoryProduct from "@/models/server/InventoryProduct";
import User from "@/models/server/User";
import ProductAssortmentMobileInfo from "@/models/server/ProductAssortmentMobileInfo";
import AittaConstants from "@/helpers/AittaConstants";

export default class ProductAssortment extends ProductAssortmentMobileInfo {

    public customer: Customer | null = null;

    public customerGroupId: string | null = null;

    public customerGroup: CustomerGroup | null = null;

    public type: ProductAssortmentType = ProductAssortmentType.Aitta;

    public newOrderThreshold: number | null = null;

    public newOrderQuantity: number | null = null;

    public note: string | null = null;

    public firstInventoryProductId: string | null = null;

    public firstInventoryProduct: InventoryProduct | null = null;

    public lastInventoryProductId: string | null = null;

    public lastInventoryProduct: InventoryProduct | null = null;

    public bastard: boolean = false;

    public createdAt: Date = new Date();

    public createdBy: User | null = null;

    public modifiedAt: Date = new Date();

    public modifiedBy: User | null = null;

    public totalValue: number | null = null;

    public totalDays: number | null = null;

    public totalAt: Date | null = null;

    public dailyAverage: number | null = null;

    public weeklyAverage: number | null = null;

    public annualValue: number | null = null;

    public annualDays: number | null = null;

    public annualAverage: number | null = null;

    public annualDailyAverage: number | null = null;

    public annualWeeklyAverage: number | null = null;

    public inheritedTotalValue: number | null = null;

    public inheritedTotalDays: number | null = null;

    public inheritedDailyAverage: number | null = null;

    public inheritedWeeklyAverage: number | null = null;

    public inheritedAnnualValue: number | null = null;

    public inheritedAnnualDays: number | null = null;

    public inheritedAnnualAverage: number | null = null;

    public inheritedAnnualDailyAverage: number | null = null;

    public inheritedAnnualWeeklyAverage: number | null = null;

    public daysAfterInventory: number | null = null;

    public orderedAfterInventory: number | null = null;

    public consumedAfterInventory: number | null = null;

    public estimatedQuantity: number | null = null;

    public readonly isProductAssortment: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ProductAssortment) || ((from as any).isProductAssortment === true));
    }

    public static as(from?: object | null): ProductAssortment | null {
        return (ProductAssortment.is(from)) ? from as ProductAssortment : null;
    }

    // #region Placeholder

    public static getNewThresholdPcs(model: ProductAssortment | null): number {
        return ((model) && (model.newOrderThreshold != null) && (model.newOrderThreshold >= 0))
            ? (model.product)
                ? Math.round(model.product.packageQuantity * model.newOrderThreshold)
                : 0
            : AittaConstants.orderThresholdNoValue;
    }

    public static setNewThresholdPcs(model: ProductAssortment, value: number): void {
        if (model.product) {
            model.newOrderThreshold = value / model.product.packageQuantity;
        }
    }

    public static setThresholdPcs(model: ProductAssortment, value: number): void {
        if (model.product) {
            model.orderThreshold = Utility.round(value / model.product.packageQuantity, 4);
        }
    }

    public static getThresholdPcs(model: ProductAssortment | null): number {
        return ((model) && (model.orderThreshold >= 0))
            ? (model.product)
                ? Math.round(model.product.packageQuantity * model.orderThreshold)
                : 0
            : AittaConstants.orderThresholdNoValue;
    }

    public static getThresholdItem(model: ProductAssortment | null, type: "OrderThreshold" | "OrderThresholdPcs" | "OrderQuantity", isNew: boolean): number {
        if (model != null) {
            switch (type) {
                case "OrderQuantity":
                    return (isNew)
                        ? model.newOrderQuantity ?? AittaConstants.orderThresholdNoValue
                        : model.orderQuantity;
                case "OrderThreshold":
                    return (isNew)
                        ? model.newOrderThreshold ?? AittaConstants.orderThresholdNoValue
                        : model.orderThreshold;
                case "OrderThresholdPcs":
                    return (isNew) ? ProductAssortment.getNewThresholdPcs(model) : ProductAssortment.getThresholdPcs(model);
            }
        }

        return 0;
    }

    public static getConsumption(assortment: ProductAssortment, consumptionInterval?: number | null): number {
        return ((consumptionInterval ?? assortment?.customer?.consumptionInterval) || 1) * (assortment.inheritedAnnualWeeklyAverage ?? 0);
    }

    public static calcQuantity(model?: ProductAssortment | null, pcs?: number | null, digits: number = 2): number {
        return ((pcs != null) && (model) && (model.product))
            ? Utility.round(pcs / model.product.packageQuantity, digits)
            : 0;
    }

    public static calcPcs(model?: ProductAssortment | null, quantity?: number | null): number {
        return ((quantity != null) && (model) && (model.product))
            ? Math.round(model.product.packageQuantity * quantity)
            : 0;
    }

    // #endregion
}