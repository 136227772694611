import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Icon, IconSize, IconStyle} from "@reapptor-apps/reapptor-react-components";

import styles from "./BreadCrumb.module.scss";

export interface IBreadCrumbItem {
    readonly id: string;

    readonly name: string;
}

export interface IBreadCrumbProps<TBreadCrumbItem extends IBreadCrumbItem> {
    className?: string;
    items: TBreadCrumbItem[];

    onClick?(sender: BreadCrumb<TBreadCrumbItem>, item: TBreadCrumbItem, index: number): Promise<void>;

    onHome?(sender: BreadCrumb<TBreadCrumbItem>): Promise<void>;
}

export default class BreadCrumb<TBreadCrumbItem extends IBreadCrumbItem> extends BaseComponent<IBreadCrumbProps<TBreadCrumbItem>> {

    private async onHomeAsync(): Promise<void> {
        if (this.props.onHome) {
            await this.props.onHome(this);
        }
    }

    private async onClickAsync(item: TBreadCrumbItem, index: number): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item, index);
        }
    }

    private renderItem(item: TBreadCrumbItem | null = null, index: number = -1): React.ReactNode {
        return (
            <div key={this.baseKey + index}
                 className={styles.bcItem}
            >
                {
                    (item) &&
                        (
                            <a onClick={async () => await this.onClickAsync(item!, index)}>
                                <span>{item!.name}</span>
                            </a>
                        ) 
                }
                {
                    (index != this.props.items.length - 1) && (
                        <div className={styles.divider}>
                            /
                        </div>
                    )
                }


            </div>
        );
    }

    private get baseKey(): string {
        return `BreadCrumbs_${this.id}`;
    }

    public render(): React.ReactNode {
        return (
            <div key={this.baseKey}
                 id={"breadCrumb-container"}
                 className={this.css(styles.breadcrumbs, "not-small-device")}
                 style={{display: "flex"}}
            >
                <div className={styles.bcItems}
                     id={"bcItems"}
                >

                    <div className={styles.bcItem}>
                        <a onClick={() => this.onHomeAsync()}>
                            <Icon name={"home"}
                                  className={styles.homeIcon}
                                  size={IconSize.X2}
                                  style={IconStyle.Solid}
                            />
                        </a>
                    </div>

                    {this.renderItem()}

                    {
                        this.props.items.map((item, index) => this.renderItem(item, index))
                    }

                </div>

            </div>
        );
    }
}