import {OrderStatus, ProductOrderFilter} from "@/models/Enums";
import Customer from "@/models/server/Customer";

export enum OrderPanelType {
    ProductsSelection,

    Order,

    ExpressOrder,

    OrdersHistory
}

export default class OrdersToolbarModel {
    public orderStatusFilter: OrderStatus | null = null;
    
    public productOrderFilter: ProductOrderFilter = ProductOrderFilter.CustomerSelection;

    public customer: Customer | null = null;

    public search: string | null = null;

    public dataInitialized: boolean = false;

    public static initialized(toolbar: OrdersToolbarModel): boolean {
        return ((toolbar.dataInitialized) && (!!toolbar.customer?.id));
    }
    
    public static getProductOrderFilter(toolbar: OrdersToolbarModel, type: OrderPanelType) {
        return ((type == OrderPanelType.Order) || (type == OrderPanelType.ExpressOrder))
            ? ProductOrderFilter.Order
            : toolbar.productOrderFilter;
    }
}