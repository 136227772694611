import React from "react";
import Customer from "@/models/server/Customer";
import {BaseComponent, ch, IBaseComponentProps, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Checkbox, Dropdown, DropdownAlign, DropdownOrderBy, Form, IconSize, Inline, InlineType, JustifyContent, SelectListItem, Spinner, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import CustomerGroup from "@/models/server/CustomerGroup";
import ProductAssortment from "@/models/server/ProductAssortment";
import ProductLabel from "@/models/server/ProductLabel";
import ReportsToolbarModel, {ReportPageType} from "@/pages/ReportManagement/ReportsToolbar/ReportsToolbarModel";
import {AittaReportFilter, ExportEncodingType, ReportType} from "@/models/Enums";
import AittaConstants from "@/helpers/AittaConstants";
import User from "@/models/server/User";
import AittaController from "@/pages/AittaController";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";
import EnumProvider from "@/providers/EnumProvider";

import styles from "./ReportsToolbar.module.scss";

export interface IReportsToolbarProps extends IBaseComponentProps {
    model: ReportsToolbarModel;
    type: ReportPageType;
    hideCustomersInSearch?: boolean;

    onChange?(sender: ReportsToolbar, model: ReportsToolbarModel): Promise<void>;

    downloadCsv?(sender: ReportsToolbar, encodingType: ExportEncodingType): Promise<void>;
}

interface IReportsToolbarState {
    model: ReportsToolbarModel;
    isLoading: boolean;
    customers: Customer[];
    assortments: ProductAssortment[];
    selectedLabels: ProductLabel[];
    type: ReportType;
}

export default class ReportsToolbar extends BaseComponent<IReportsToolbarProps, IReportsToolbarState> {

    state: IReportsToolbarState = {
        model: this.props.model,
        customers: [],
        isLoading: true,
        assortments: [],
        selectedLabels: [],
        type: ReportType.Annual
    };

    private readonly _customerDropdownRef: React.RefObject<Dropdown<any>> = React.createRef();

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/orderManagement/listCustomers"));
    }

    private async selectReportTypeFilterAsync(item: SelectListItem | null): Promise<void> {
        this.model.reportType = parseInt(item!.value);
        await this.processAsync(true);
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }

    private async setAittaReportFilterAsync(aittaReportFilter: AittaReportFilter): Promise<void> {
        this.model.aittaReportFilter = aittaReportFilter;

        await this.resetWithSalesOnlyToDefaultAsync();

        await this.processAsync(true);
    }

    private async resetWithSalesOnlyToDefaultAsync(): Promise<void> {
        this.model.productsWithSalesOnly = !this.showWithSalesOnlyCheckbox;
    }

    private async setAittaAppOnlyAsync(aittaAppOnly: boolean): Promise<void> {
        this.model.aittaAppOnly = aittaAppOnly;

        await this.processAsync(true);
    }

    private async setWithSalesOnlyAsync(productsWithSalesOnly: boolean): Promise<void> {
        this.model.productsWithSalesOnly = productsWithSalesOnly;

        await this.processAsync(true);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this, this.state.model);
        }
    }

    private async resetReportTypeFilter(): Promise<void> {
        this.model.aittaReportFilter = AittaReportFilter.All;
    }

    private async selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer: CustomerGroup | Customer | null, userInteraction: boolean): Promise<void> {
        if ((!Customer.is(customerGroupOrCustomer)) && (this.model.aittaReportFilter == AittaReportFilter.NotAittaAndNotActive)) {
            await this.resetReportTypeFilter();
        }

        this.model.customerGroup = CustomerGroup.is(customerGroupOrCustomer) ? customerGroupOrCustomer as CustomerGroup : null;
        this.model.customer = Customer.is(customerGroupOrCustomer) ? customerGroupOrCustomer as Customer : null;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customerGroupOrCustomer);
        }

        await this.resetWithSalesOnlyToDefaultAsync();

        await this.processAsync(true);
    }

    public async downloadCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {
        if (this.props.downloadCsv) {
            await this.props.downloadCsv(this, encodingType);
        }
    }

    public getAittaReportFilterItems(): SelectListItem[] {
        const items: SelectListItem[] = EnumProvider.getAittaReportFilterItems();

        return (this.customer != null)
            ? items
            : items.where(item => item.value != AittaReportFilter.NotAittaAndNotActive.toString());

    }

    public get items(): (CustomerGroup | Customer)[] {
        return Customer.group(this.state.customers);
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get type(): ReportType {
        return this.state.type;
    }

    public get model(): ReportsToolbarModel {
        return this.state.model;
    }

    public get user(): User {
        return ch.getUser();
    }

    public get isAdmin(): boolean {
        return this.user.isAdmin;
    }

    public get customerGroup(): CustomerGroup | null {
        return this.model.customerGroup;
    }

    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }

    public get customer(): Customer | null {
        return this.model.customer;
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public get hasMediqAccess(): boolean {
        return AittaController.hasMediqAccess;
    }

    public get showWithSalesOnlyCheckbox(): boolean {
        return (this.props.type == ReportPageType.Products) && (
            (
                ((!!this.customer) || (!!this.customerGroup)) &&
                (this.model.aittaReportFilter != AittaReportFilter.NotAittaAndNotActive) &&
                (this.model.aittaReportFilter != AittaReportFilter.NotAittaOnly)
            )
        );
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const customers: Customer[] = await this.fetchCustomersAsync();

        this.state.customers = customers;
        this.state.model.customerGroup = AittaController.getDefaultCustomerGroup(customers);
        this.state.model.customer = AittaController.getDefaultCustomer(customers);

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(this.props.className, styles.reportsToolbar)}>

                <Form onSubmit={() => this.processAsync(true)}>

                    <ToolbarRow justify={JustifyContent.SpaceBetween}>

                        <Inline>

                            <Dropdown id="customerGroupsOrCustomers" noWrap inline noGrouping clearButton
                                      ref={this._customerDropdownRef}
                                      required={!this.hasMediqAccess}
                                      className={styles.customerGroupsOrCustomers}
                                      align={DropdownAlign.Left}
                                      filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                      disabled={this.isLoading}
                                      width={AittaConstants.customersDropdownMinWidth}
                                      items={this.items}
                                      selectedItem={this.customer || this.customerGroup}
                                      nothingSelectedText={Localizer.labelsToolbarAllCustomers}
                                      orderBy={DropdownOrderBy.None}
                                      transform={(item: Customer | CustomerGroup) => TransformProvider.toCustomerOrCustomerGroupSelectItem(item, styles.customerGroupItem, styles.customerItem)}
                                      onChange={(sender, item: CustomerGroup | Customer | null, userInteraction: boolean) => this.selectCustomerGroupOrCustomerAsync(item, userInteraction)}
                            />

                            <Dropdown id="reportTypeFilter" noWrap inline clearButton required
                                      align={DropdownAlign.Left}
                                      orderBy={DropdownOrderBy.None}
                                      width={"20rem"}
                                      disabled={this.isLoading}
                                      items={EnumProvider.getReportTypeItems()}
                                      selectedItem={this.model.reportType}
                                      onChange={(sender, item: SelectListItem) => this.selectReportTypeFilterAsync(item)}
                            />

                            <Dropdown required inline noWrap noSubtext
                                      id={"aittaReportFilter"}
                                      className={styles.reportTypeFilter}
                                      align={DropdownAlign.Left}
                                      orderBy={DropdownOrderBy.None}
                                      width={"16rem"}
                                      items={this.getAittaReportFilterItems()}
                                      selectedItem={this.model.aittaReportFilter}
                                      onChange={(_, item) => this.setAittaReportFilterAsync(parseInt(item!.value))}
                            />

                            {
                                (!this.user.isManager) &&
                                (
                                    <Inline>
                                        
                                        <Checkbox id={"aittaAppOnly"} inline
                                                  inlineType={InlineType.Right}
                                                  label={Localizer.reportsToolbarAittaApp}
                                                  value={this.model.aittaAppOnly}
                                                  onChange={(_, value) => this.setAittaAppOnlyAsync(value)}
                                        />

                                        {
                                            (this.showWithSalesOnlyCheckbox) &&
                                            (
                                                <Checkbox id={"productsWithSalesOnly"} inline
                                                          inlineType={InlineType.Right}
                                                          label={Localizer.reportsToolbarWithSalesOnly}
                                                          value={this.model.productsWithSalesOnly}
                                                          onChange={(_, value) => this.setWithSalesOnlyAsync(value)}
                                                />
                                            )
                                        }
                                        
                                    </Inline>
                                )
                            }

                        </Inline>

                        <Inline>

                            <Button id={"exportToCsv"} right
                                    className={styles.button}
                                    label={Localizer.labelsToolbarExcel}
                                    icon={{name: "fa-file-download fas", size: IconSize.Large}}
                                    type={ButtonType.Primary}
                                    disabled={this.isLoading}
                                    onClick={() => this.downloadCsvAsync()}
                            >

                                <Button.Action id={"utf8"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Utf8)}
                                />

                                <Button.Action id={"unicode"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Unicode)}
                                />

                                <Button.Action id={"latin1"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Latin1)}
                                />

                                <Button.Action id={"openXml"}
                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.OpenXml)}
                                />

                            </Button>

                        </Inline>

                    </ToolbarRow>

                    {
                        (this.props.type == ReportPageType.Products) &&
                        (

                            <ToolbarRow>

                                <TextInput id="productSearch" inline clearButton
                                           width={AittaConstants.customersDropdownMinWidth}
                                           readonly={this.isLoading}
                                           placeholder={Localizer.labelsToolbarSearch}
                                           value={this.search}
                                           onChange={(sender, value: string) => this.setSearchAsync(value)}
                                />

                                <Button submit right
                                        id={"search"}
                                        disabled={this.isLoading}
                                        label={Localizer.genericSearch}
                                        icon={{name: "fas search"}}
                                        type={ButtonType.Primary}
                                />

                            </ToolbarRow>

                        )
                    }
                </Form>

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}