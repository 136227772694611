//Autogenerated

import {BaseEnumProvider, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {WebApplicationType, PasswordValidationError, TokenType} from "@reapptor-apps/reapptor-react-common";
import {SyncActionType, AittaReportFilter, ErpType, ProductAuditAction, InventoryStateFilter, ReportType, ExportEncodingType, ProductLabelType, ProductLabelState, DeliveryDayOfWeek, ProductAccessoryType, ProductReplacementLevel, ProductReplacementType, CustomerLabelHandler, CustomerHandler, CustomerServiceType, CalendarDateMark, ProductType, ProductAssortmentType, ProductAssortmentFilter, ProductTemplateFilter, ProductOrderFilter, OrderStatus, OrderProductStatus, OrderProductErpAction, LoginResultStatus, SavePasswordResultStatus, InvitationType, NotificationType, AuditTimestamp, AuthType, ScheduleAction, LocalizationLanguage, DayOfWeek} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["SyncActionType", "AittaReportFilter", "ErpType", "ProductAuditAction", "InventoryStateFilter", "ReportType", "ExportEncodingType", "ProductLabelType", "ProductLabelState", "DeliveryDayOfWeek", "ProductAccessoryType", "ProductReplacementLevel", "ProductReplacementType", "CustomerLabelHandler", "CustomerHandler", "CustomerServiceType", "CalendarDateMark", "ProductType", "ProductAssortmentType", "ProductAssortmentFilter", "ProductTemplateFilter", "ProductOrderFilter", "OrderStatus", "OrderProductStatus", "OrderProductErpAction", "LoginResultStatus", "SavePasswordResultStatus", "InvitationType", "NotificationType", "AuditTimestamp", "AuthType", "SortDirection", "ScheduleAction", "LocalizationLanguage", "WebApplicationType", "PasswordValidationError", "TokenType", "DayOfWeek"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region SyncActionType

    public getSyncActionTypeItem(value: SyncActionType): SelectListItem {
        return this.transform(SyncActionType, "SyncActionType", value);
    }

    public getSyncActionTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SyncActionType, "SyncActionType", reverse);
    }

    public getSyncActionTypeName(value: SyncActionType): string {
        return this.getSyncActionTypeItem(value).text;
    }

    public getSyncActionTypeText(value: SyncActionType): string {
        return this.localizer.get(this.getSyncActionTypeName(value));
    }

    public getSyncActionTypeDescription(value: SyncActionType): string {
        return this.getSyncActionTypeItem(value).subtext;
    }

    // #endregion

    // #region AittaReportFilter

    public getAittaReportFilterItem(value: AittaReportFilter): SelectListItem {
        return this.transform(AittaReportFilter, "AittaReportFilter", value);
    }

    public getAittaReportFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AittaReportFilter, "AittaReportFilter", reverse);
    }

    public getAittaReportFilterName(value: AittaReportFilter): string {
        return this.getAittaReportFilterItem(value).text;
    }

    public getAittaReportFilterText(value: AittaReportFilter): string {
        return this.localizer.get(this.getAittaReportFilterName(value));
    }

    public getAittaReportFilterDescription(value: AittaReportFilter): string {
        return this.getAittaReportFilterItem(value).subtext;
    }

    // #endregion

    // #region ErpType

    public getErpTypeItem(value: ErpType): SelectListItem {
        return this.transform(ErpType, "ErpType", value);
    }

    public getErpTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ErpType, "ErpType", reverse);
    }

    public getErpTypeName(value: ErpType): string {
        return this.getErpTypeItem(value).text;
    }

    public getErpTypeText(value: ErpType): string {
        return this.localizer.get(this.getErpTypeName(value));
    }

    public getErpTypeDescription(value: ErpType): string {
        return this.getErpTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductAuditAction

    public getProductAuditActionItem(value: ProductAuditAction): SelectListItem {
        return this.transform(ProductAuditAction, "ProductAuditAction", value);
    }

    public getProductAuditActionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductAuditAction, "ProductAuditAction", reverse);
    }

    public getProductAuditActionName(value: ProductAuditAction): string {
        return this.getProductAuditActionItem(value).text;
    }

    public getProductAuditActionText(value: ProductAuditAction): string {
        return this.localizer.get(this.getProductAuditActionName(value));
    }

    public getProductAuditActionDescription(value: ProductAuditAction): string {
        return this.getProductAuditActionItem(value).subtext;
    }

    // #endregion

    // #region InventoryStateFilter

    public getInventoryStateFilterItem(value: InventoryStateFilter): SelectListItem {
        return this.transform(InventoryStateFilter, "InventoryStateFilter", value);
    }

    public getInventoryStateFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InventoryStateFilter, "InventoryStateFilter", reverse);
    }

    public getInventoryStateFilterName(value: InventoryStateFilter): string {
        return this.getInventoryStateFilterItem(value).text;
    }

    public getInventoryStateFilterText(value: InventoryStateFilter): string {
        return this.localizer.get(this.getInventoryStateFilterName(value));
    }

    public getInventoryStateFilterDescription(value: InventoryStateFilter): string {
        return this.getInventoryStateFilterItem(value).subtext;
    }

    // #endregion

    // #region ReportType

    public getReportTypeItem(value: ReportType): SelectListItem {
        return this.transform(ReportType, "ReportType", value);
    }

    public getReportTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ReportType, "ReportType", reverse);
    }

    public getReportTypeName(value: ReportType): string {
        return this.getReportTypeItem(value).text;
    }

    public getReportTypeText(value: ReportType): string {
        return this.localizer.get(this.getReportTypeName(value));
    }

    public getReportTypeDescription(value: ReportType): string {
        return this.getReportTypeItem(value).subtext;
    }

    // #endregion

    // #region ExportEncodingType

    public getExportEncodingTypeItem(value: ExportEncodingType): SelectListItem {
        return this.transform(ExportEncodingType, "ExportEncodingType", value);
    }

    public getExportEncodingTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ExportEncodingType, "ExportEncodingType", reverse);
    }

    public getExportEncodingTypeName(value: ExportEncodingType): string {
        return this.getExportEncodingTypeItem(value).text;
    }

    public getExportEncodingTypeText(value: ExportEncodingType): string {
        return this.localizer.get(this.getExportEncodingTypeName(value));
    }

    public getExportEncodingTypeDescription(value: ExportEncodingType): string {
        return this.getExportEncodingTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductLabelType

    public getProductLabelTypeItem(value: ProductLabelType): SelectListItem {
        return this.transform(ProductLabelType, "ProductLabelType", value);
    }

    public getProductLabelTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductLabelType, "ProductLabelType", reverse);
    }

    public getProductLabelTypeName(value: ProductLabelType): string {
        return this.getProductLabelTypeItem(value).text;
    }

    public getProductLabelTypeText(value: ProductLabelType): string {
        return this.localizer.get(this.getProductLabelTypeName(value));
    }

    public getProductLabelTypeDescription(value: ProductLabelType): string {
        return this.getProductLabelTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductLabelState

    public getProductLabelStateItem(value: ProductLabelState): SelectListItem {
        return this.transform(ProductLabelState, "ProductLabelState", value);
    }

    public getProductLabelStateItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductLabelState, "ProductLabelState", reverse);
    }

    public getProductLabelStateName(value: ProductLabelState): string {
        return this.getProductLabelStateItem(value).text;
    }

    public getProductLabelStateText(value: ProductLabelState): string {
        return this.localizer.get(this.getProductLabelStateName(value));
    }

    public getProductLabelStateDescription(value: ProductLabelState): string {
        return this.getProductLabelStateItem(value).subtext;
    }

    // #endregion

    // #region DeliveryDayOfWeek

    public getDeliveryDayOfWeekItem(value: DeliveryDayOfWeek): SelectListItem {
        return this.transform(DeliveryDayOfWeek, "DeliveryDayOfWeek", value);
    }

    public getDeliveryDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeliveryDayOfWeek, "DeliveryDayOfWeek", reverse);
    }

    public getDeliveryDayOfWeekName(value: DeliveryDayOfWeek): string {
        return this.getDeliveryDayOfWeekItem(value).text;
    }

    public getDeliveryDayOfWeekText(value: DeliveryDayOfWeek): string {
        return this.localizer.get(this.getDeliveryDayOfWeekName(value));
    }

    public getDeliveryDayOfWeekDescription(value: DeliveryDayOfWeek): string {
        return this.getDeliveryDayOfWeekItem(value).subtext;
    }

    // #endregion

    // #region ProductAccessoryType

    public getProductAccessoryTypeItem(value: ProductAccessoryType): SelectListItem {
        return this.transform(ProductAccessoryType, "ProductAccessoryType", value);
    }

    public getProductAccessoryTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductAccessoryType, "ProductAccessoryType", reverse);
    }

    public getProductAccessoryTypeName(value: ProductAccessoryType): string {
        return this.getProductAccessoryTypeItem(value).text;
    }

    public getProductAccessoryTypeText(value: ProductAccessoryType): string {
        return this.localizer.get(this.getProductAccessoryTypeName(value));
    }

    public getProductAccessoryTypeDescription(value: ProductAccessoryType): string {
        return this.getProductAccessoryTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductReplacementLevel

    public getProductReplacementLevelItem(value: ProductReplacementLevel): SelectListItem {
        return this.transform(ProductReplacementLevel, "ProductReplacementLevel", value);
    }

    public getProductReplacementLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductReplacementLevel, "ProductReplacementLevel", reverse);
    }

    public getProductReplacementLevelName(value: ProductReplacementLevel): string {
        return this.getProductReplacementLevelItem(value).text;
    }

    public getProductReplacementLevelText(value: ProductReplacementLevel): string {
        return this.localizer.get(this.getProductReplacementLevelName(value));
    }

    public getProductReplacementLevelDescription(value: ProductReplacementLevel): string {
        return this.getProductReplacementLevelItem(value).subtext;
    }

    // #endregion

    // #region ProductReplacementType

    public getProductReplacementTypeItem(value: ProductReplacementType): SelectListItem {
        return this.transform(ProductReplacementType, "ProductReplacementType", value);
    }

    public getProductReplacementTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductReplacementType, "ProductReplacementType", reverse);
    }

    public getProductReplacementTypeName(value: ProductReplacementType): string {
        return this.getProductReplacementTypeItem(value).text;
    }

    public getProductReplacementTypeText(value: ProductReplacementType): string {
        return this.localizer.get(this.getProductReplacementTypeName(value));
    }

    public getProductReplacementTypeDescription(value: ProductReplacementType): string {
        return this.getProductReplacementTypeItem(value).subtext;
    }

    // #endregion

    // #region CustomerLabelHandler

    public getCustomerLabelHandlerItem(value: CustomerLabelHandler): SelectListItem {
        return this.transform(CustomerLabelHandler, "CustomerLabelHandler", value);
    }

    public getCustomerLabelHandlerItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CustomerLabelHandler, "CustomerLabelHandler", reverse);
    }

    public getCustomerLabelHandlerName(value: CustomerLabelHandler): string {
        return this.getCustomerLabelHandlerItem(value).text;
    }

    public getCustomerLabelHandlerText(value: CustomerLabelHandler): string {
        return this.localizer.get(this.getCustomerLabelHandlerName(value));
    }

    public getCustomerLabelHandlerDescription(value: CustomerLabelHandler): string {
        return this.getCustomerLabelHandlerItem(value).subtext;
    }

    // #endregion

    // #region CustomerHandler

    public getCustomerHandlerItem(value: CustomerHandler): SelectListItem {
        return this.transform(CustomerHandler, "CustomerHandler", value);
    }

    public getCustomerHandlerItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CustomerHandler, "CustomerHandler", reverse);
    }

    public getCustomerHandlerName(value: CustomerHandler): string {
        return this.getCustomerHandlerItem(value).text;
    }

    public getCustomerHandlerText(value: CustomerHandler): string {
        return this.localizer.get(this.getCustomerHandlerName(value));
    }

    public getCustomerHandlerDescription(value: CustomerHandler): string {
        return this.getCustomerHandlerItem(value).subtext;
    }

    // #endregion

    // #region CustomerServiceType

    public getCustomerServiceTypeItem(value: CustomerServiceType): SelectListItem {
        return this.transform(CustomerServiceType, "CustomerServiceType", value);
    }

    public getCustomerServiceTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CustomerServiceType, "CustomerServiceType", reverse);
    }

    public getCustomerServiceTypeName(value: CustomerServiceType): string {
        return this.getCustomerServiceTypeItem(value).text;
    }

    public getCustomerServiceTypeText(value: CustomerServiceType): string {
        return this.localizer.get(this.getCustomerServiceTypeName(value));
    }

    public getCustomerServiceTypeDescription(value: CustomerServiceType): string {
        return this.getCustomerServiceTypeItem(value).subtext;
    }

    // #endregion

    // #region CalendarDateMark

    public getCalendarDateMarkItem(value: CalendarDateMark): SelectListItem {
        return this.transform(CalendarDateMark, "CalendarDateMark", value);
    }

    public getCalendarDateMarkItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(CalendarDateMark, "CalendarDateMark", reverse);
    }

    public getCalendarDateMarkName(value: CalendarDateMark): string {
        return this.getCalendarDateMarkItem(value).text;
    }

    public getCalendarDateMarkText(value: CalendarDateMark): string {
        return this.localizer.get(this.getCalendarDateMarkName(value));
    }

    public getCalendarDateMarkDescription(value: CalendarDateMark): string {
        return this.getCalendarDateMarkItem(value).subtext;
    }

    // #endregion

    // #region ProductType

    public getProductTypeItem(value: ProductType): SelectListItem {
        return this.transform(ProductType, "ProductType", value);
    }

    public getProductTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductType, "ProductType", reverse);
    }

    public getProductTypeName(value: ProductType): string {
        return this.getProductTypeItem(value).text;
    }

    public getProductTypeText(value: ProductType): string {
        return this.localizer.get(this.getProductTypeName(value));
    }

    public getProductTypeDescription(value: ProductType): string {
        return this.getProductTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductAssortmentType

    public getProductAssortmentTypeItem(value: ProductAssortmentType): SelectListItem {
        return this.transform(ProductAssortmentType, "ProductAssortmentType", value);
    }

    public getProductAssortmentTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductAssortmentType, "ProductAssortmentType", reverse);
    }

    public getProductAssortmentTypeName(value: ProductAssortmentType): string {
        return this.getProductAssortmentTypeItem(value).text;
    }

    public getProductAssortmentTypeText(value: ProductAssortmentType): string {
        return this.localizer.get(this.getProductAssortmentTypeName(value));
    }

    public getProductAssortmentTypeDescription(value: ProductAssortmentType): string {
        return this.getProductAssortmentTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductAssortmentFilter

    public getProductAssortmentFilterItem(value: ProductAssortmentFilter): SelectListItem {
        return this.transform(ProductAssortmentFilter, "ProductAssortmentFilter", value);
    }

    public getProductAssortmentFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductAssortmentFilter, "ProductAssortmentFilter", reverse);
    }

    public getProductAssortmentFilterName(value: ProductAssortmentFilter): string {
        return this.getProductAssortmentFilterItem(value).text;
    }

    public getProductAssortmentFilterText(value: ProductAssortmentFilter): string {
        return this.localizer.get(this.getProductAssortmentFilterName(value));
    }

    public getProductAssortmentFilterDescription(value: ProductAssortmentFilter): string {
        return this.getProductAssortmentFilterItem(value).subtext;
    }

    // #endregion

    // #region ProductTemplateFilter

    public getProductTemplateFilterItem(value: ProductTemplateFilter): SelectListItem {
        return this.transform(ProductTemplateFilter, "ProductTemplateFilter", value);
    }

    public getProductTemplateFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductTemplateFilter, "ProductTemplateFilter", reverse);
    }

    public getProductTemplateFilterName(value: ProductTemplateFilter): string {
        return this.getProductTemplateFilterItem(value).text;
    }

    public getProductTemplateFilterText(value: ProductTemplateFilter): string {
        return this.localizer.get(this.getProductTemplateFilterName(value));
    }

    public getProductTemplateFilterDescription(value: ProductTemplateFilter): string {
        return this.getProductTemplateFilterItem(value).subtext;
    }

    // #endregion

    // #region ProductOrderFilter

    public getProductOrderFilterItem(value: ProductOrderFilter): SelectListItem {
        return this.transform(ProductOrderFilter, "ProductOrderFilter", value);
    }

    public getProductOrderFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductOrderFilter, "ProductOrderFilter", reverse);
    }

    public getProductOrderFilterName(value: ProductOrderFilter): string {
        return this.getProductOrderFilterItem(value).text;
    }

    public getProductOrderFilterText(value: ProductOrderFilter): string {
        return this.localizer.get(this.getProductOrderFilterName(value));
    }

    public getProductOrderFilterDescription(value: ProductOrderFilter): string {
        return this.getProductOrderFilterItem(value).subtext;
    }

    // #endregion

    // #region OrderStatus

    public getOrderStatusItem(value: OrderStatus): SelectListItem {
        return this.transform(OrderStatus, "OrderStatus", value);
    }

    public getOrderStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrderStatus, "OrderStatus", reverse);
    }

    public getOrderStatusName(value: OrderStatus): string {
        return this.getOrderStatusItem(value).text;
    }

    public getOrderStatusText(value: OrderStatus): string {
        return this.localizer.get(this.getOrderStatusName(value));
    }

    public getOrderStatusDescription(value: OrderStatus): string {
        return this.getOrderStatusItem(value).subtext;
    }

    // #endregion

    // #region OrderProductStatus

    public getOrderProductStatusItem(value: OrderProductStatus): SelectListItem {
        return this.transform(OrderProductStatus, "OrderProductStatus", value);
    }

    public getOrderProductStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrderProductStatus, "OrderProductStatus", reverse);
    }

    public getOrderProductStatusName(value: OrderProductStatus): string {
        return this.getOrderProductStatusItem(value).text;
    }

    public getOrderProductStatusText(value: OrderProductStatus): string {
        return this.localizer.get(this.getOrderProductStatusName(value));
    }

    public getOrderProductStatusDescription(value: OrderProductStatus): string {
        return this.getOrderProductStatusItem(value).subtext;
    }

    // #endregion

    // #region OrderProductErpAction

    public getOrderProductErpActionItem(value: OrderProductErpAction): SelectListItem {
        return this.transform(OrderProductErpAction, "OrderProductErpAction", value);
    }

    public getOrderProductErpActionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrderProductErpAction, "OrderProductErpAction", reverse);
    }

    public getOrderProductErpActionName(value: OrderProductErpAction): string {
        return this.getOrderProductErpActionItem(value).text;
    }

    public getOrderProductErpActionText(value: OrderProductErpAction): string {
        return this.localizer.get(this.getOrderProductErpActionName(value));
    }

    public getOrderProductErpActionDescription(value: OrderProductErpAction): string {
        return this.getOrderProductErpActionItem(value).subtext;
    }

    // #endregion

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationType

    public getNotificationTypeItem(value: NotificationType): SelectListItem {
        return this.transform(NotificationType, "NotificationType", value);
    }

    public getNotificationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationType, "NotificationType", reverse);
    }

    public getNotificationTypeName(value: NotificationType): string {
        return this.getNotificationTypeItem(value).text;
    }

    public getNotificationTypeText(value: NotificationType): string {
        return this.localizer.get(this.getNotificationTypeName(value));
    }

    public getNotificationTypeDescription(value: NotificationType): string {
        return this.getNotificationTypeItem(value).subtext;
    }

    // #endregion

    // #region AuditTimestamp

    public getAuditTimestampItem(value: AuditTimestamp): SelectListItem {
        return this.transform(AuditTimestamp, "AuditTimestamp", value);
    }

    public getAuditTimestampItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuditTimestamp, "AuditTimestamp", reverse);
    }

    public getAuditTimestampName(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).text;
    }

    public getAuditTimestampText(value: AuditTimestamp): string {
        return this.localizer.get(this.getAuditTimestampName(value));
    }

    public getAuditTimestampDescription(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region ScheduleAction

    public getScheduleActionItem(value: ScheduleAction): SelectListItem {
        return this.transform(ScheduleAction, "ScheduleAction", value);
    }

    public getScheduleActionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ScheduleAction, "ScheduleAction", reverse);
    }

    public getScheduleActionName(value: ScheduleAction): string {
        return this.getScheduleActionItem(value).text;
    }

    public getScheduleActionText(value: ScheduleAction): string {
        return this.localizer.get(this.getScheduleActionName(value));
    }

    public getScheduleActionDescription(value: ScheduleAction): string {
        return this.getScheduleActionItem(value).subtext;
    }

    // #endregion

    // #region LocalizationLanguage

    public getLocalizationLanguageItem(value: LocalizationLanguage): SelectListItem {
        return this.transform(LocalizationLanguage, "LocalizationLanguage", value);
    }

    public getLocalizationLanguageItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LocalizationLanguage, "LocalizationLanguage", reverse);
    }

    public getLocalizationLanguageName(value: LocalizationLanguage): string {
        return this.getLocalizationLanguageItem(value).text;
    }

    public getLocalizationLanguageText(value: LocalizationLanguage): string {
        return this.localizer.get(this.getLocalizationLanguageName(value));
    }

    public getLocalizationLanguageDescription(value: LocalizationLanguage): string {
        return this.getLocalizationLanguageItem(value).subtext;
    }

    // #endregion

    // #region WebApplicationType

    public getWebApplicationTypeItem(value: WebApplicationType): SelectListItem {
        return this.transform(WebApplicationType, "WebApplicationType", value);
    }

    public getWebApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WebApplicationType, "WebApplicationType", reverse);
    }

    public getWebApplicationTypeName(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).text;
    }

    public getWebApplicationTypeText(value: WebApplicationType): string {
        return this.localizer.get(this.getWebApplicationTypeName(value));
    }

    public getWebApplicationTypeDescription(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region PasswordValidationError

    public getPasswordValidationErrorItem(value: PasswordValidationError): SelectListItem {
        return this.transform(PasswordValidationError, "PasswordValidationError", value);
    }

    public getPasswordValidationErrorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PasswordValidationError, "PasswordValidationError", reverse);
    }

    public getPasswordValidationErrorName(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).text;
    }

    public getPasswordValidationErrorText(value: PasswordValidationError): string {
        return this.localizer.get(this.getPasswordValidationErrorName(value));
    }

    public getPasswordValidationErrorDescription(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).subtext;
    }

    // #endregion

    // #region TokenType

    public getTokenTypeItem(value: TokenType): SelectListItem {
        return this.transform(TokenType, "TokenType", value);
    }

    public getTokenTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TokenType, "TokenType", reverse);
    }

    public getTokenTypeName(value: TokenType): string {
        return this.getTokenTypeItem(value).text;
    }

    public getTokenTypeText(value: TokenType): string {
        return this.localizer.get(this.getTokenTypeName(value));
    }

    public getTokenTypeDescription(value: TokenType): string {
        return this.getTokenTypeItem(value).subtext;
    }

    // #endregion

    // #region DayOfWeek

    public getDayOfWeekItem(value: DayOfWeek): SelectListItem {
        return this.transform(DayOfWeek, "DayOfWeek", value);
    }

    public getDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DayOfWeek, "DayOfWeek", reverse);
    }

    public getDayOfWeekName(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).text;
    }

    public getDayOfWeekText(value: DayOfWeek): string {
        return this.localizer.get(this.getDayOfWeekName(value));
    }

    public getDayOfWeekDescription(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();