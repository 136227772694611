// autogenerated
import ProductAssortment from "@/models/server/ProductAssortment";
import BaseReport from "@/models/server/BaseReport";

export default class ReportProduct extends BaseReport {

    public productAssortmentId: string = "";

    public productAssortment: ProductAssortment | null = null;

    public groupName: string | null = null;

    public mainGroup: string | null = null;

    public subGroup: string | null = null;

    public productCode: string | null = null;

    public productName: string | null = null;

    public productDeleted: boolean = false;

    public assortmentDeleted: boolean = false;

    public manufactureCode: string | null = null;

    public customerCode: string | null = null;

    public customerName: string | null = null;

    public aitta: boolean = false;

    public readonly isReportProduct: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ReportProduct) || ((from as any).isReportProduct === true));
    }

    public static as(from?: object | null): ReportProduct | null {
        return (ReportProduct.is(from)) ? from as ReportProduct : null;
    }

    // #region Placeholder

    public productReplacementId: string | null = null;

    // #endregion
}