import React from "react";
import {Button, ButtonContainer, ButtonType, Dropdown, DropdownRequiredType, DropdownVerticalAlign, Form, IconSize, Modal, ModalSize, NumberInput, OneColumn, Spinner, TextInput, TwoColumns} from "@reapptor-apps/reapptor-react-components";
import {ApiProvider, BaseComponent, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import Customer from "@/models/server/Customer";
import Product from "@/models/server/Product";
import ProductAssortment from "@/models/server/ProductAssortment";
import ListAvailableCustomerProductsRequest from "@/models/server/requests/ListAvailableCustomerProductsRequest";
import Localizer from "@/localization/Localizer";

import styles from "./ProductAssortmentModal.module.scss";

interface IProductAssortmentModalProps {
    id?: string;
    onAddProductAssortment(sender: ProductAssortmentModal, assortment: ProductAssortment, rowIndex: number): Promise<void>;
}

interface IProductAssortmentModalState {
    loading: boolean;
    customer: Customer | null;
    availableProducts: Product[];
    assortment: ProductAssortment;
    rowIndex: number;
    orderThresholdModified: boolean;
    orderQuantityModified: boolean;
}

export default class ProductAssortmentModal extends BaseComponent<IProductAssortmentModalProps, IProductAssortmentModalState> {

    state: IProductAssortmentModalState = {
        loading: false,
        customer: null,
        availableProducts: [],
        assortment: new ProductAssortment(),
        rowIndex: 0,
        orderThresholdModified: false,
        orderQuantityModified: false,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _orderPointRef: React.RefObject<NumberInput> = React.createRef();

    public async onCloseAsync(): Promise<void> {
        await this.setState({customer: null, availableProducts: [], assortment: new ProductAssortment()});
    }

    private get assortment(): ProductAssortment {
        return this.state.assortment;
    }   

    private get loading(): boolean {
        return this.state.loading;
    }

    private get products(): Product[] {
        return this.state.availableProducts;
    }

    private get hasData(): boolean {
        return (this.products.length > 0);
    }

    private get title(): string {
        return this.customer?.name;
    }

    private get subtitle(): string {
        return (this.loading || !this.state.customer)
            ? Localizer.genericLoading
            : this.state.customer.codeInfo
    }

    private get customer(): Customer {
        return this.state.customer!;
    }

    private get modal(): Modal {
        return this._modalRef.current!;
    }
    
    private async onChangeSelectedProductAsync(product: Product): Promise<void> {
        this.state.assortment = new ProductAssortment();
        this.state.assortment.customerId = this.customer.id;
        this.state.assortment.product = product;
        this.state.assortment.productId = product.id;
        
        this.state.orderThresholdModified = false;
        this.state.orderQuantityModified = false;
        
        await this.reRenderAsync();
        
        this._orderPointRef.current?.focus();
    }
    
    private async onChangeOrderPointAsync(sender: NumberInput, value: number, userInteraction: boolean): Promise<void> {
        this.assortment.orderThreshold = value;
        
        if (userInteraction) {
            this.state.orderThresholdModified = (!sender.empty);
        }
        
        await this.reRenderAsync();
    }

    private async onChangeOrderPointPscAsync(sender: NumberInput, value: number, userInteraction: boolean): Promise<void> {
        ProductAssortment.setThresholdPcs(this.assortment, value);

        if (userInteraction) {
            this.state.orderThresholdModified = (!sender.empty);
        }

        await this.reRenderAsync();
    }
    
    private async onChangeOrderQuantityAsync(sender: NumberInput, value: number, userInteraction: boolean): Promise<void> {
        this.assortment.orderQuantity = value;

        if (userInteraction) {
            this.state.orderQuantityModified = (!sender.empty);
        }

        await this.reRenderAsync();
    }
    
    private async submitAsync(): Promise<void> {
        
        await this.setState({loading: true});

        try {
            await this.props.onAddProductAssortment(this, this.state.assortment, this.state.rowIndex);
        } finally {
            await this.setState({loading: false});
        }

        await this._modalRef.current?.closeAsync();
    }

    private validateOrderPoint(): string | null {
        return (!this.state.orderThresholdModified)
            ? `${Localizer.newProductModalOrderPoint} *`
            : null;
    }
    
    private validateOrderPointPcs(): string | null {
        return (!this.state.orderThresholdModified)
            ? `${Localizer.newProductModalOrderPointPcs} *`
            : null;
    }
    
    private validateOrderQuantity(): string | null {
        return (!this.state.orderQuantityModified)
            ? `${Localizer.newProductModalOrderQuantity} *`
            : null;
    }

    public async openAsync(customer: Customer, rowIndex: number = 0): Promise<void> {
        
        await this.modal.openAsync();

        await this.setState({loading: true});
        
        const request = new ListAvailableCustomerProductsRequest();
        request.customerId = customer.id;
        request.limitByContract = false;
        
        const products: Product[] = await PageCacheProvider.getAsync("listAvailableCustomerProducts", () => ApiProvider.postAsync("/api/productManagement/listAvailableCustomerProducts", request))

        await this.setState({customer, availableProducts: products, loading: false, rowIndex});
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    public get isOpen(): boolean {
        return (this._modalRef.current != null) && (this._modalRef.current.isOpen);
    }
    
    public get isValid(): boolean {
        return (
            (!!this.state.assortment.productId) &&
            (!this.validateOrderPoint()) &&
            (!this.validateOrderPointPcs()) &&
            (!this.validateOrderQuantity())
        )
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        
        return (
            <Modal notResponsive
                   id={this.id}
                   ref={this._modalRef}
                   className={styles.productAssortmentModal}
                   title={this.title}
                   subtitle={this.subtitle}
                   size={ModalSize.Default}
                   onClose={() => this.onCloseAsync()}
            >

                {(this.loading) && (<Spinner/>)}
                
                {
                    (this.isOpen) &&
                    (
                        <div className={styles.content}>

                            <Form className={styles.form}
                                  onSubmit={() => this.submitAsync()}
                            >

                                <OneColumn>

                                    <Dropdown required
                                              requiredType={DropdownRequiredType.Manual}
                                              id={"products"}
                                              label={Localizer.newProductModalProducts}
                                              noDataText={Localizer.newProductModalNoProducts}
                                              verticalAlign={DropdownVerticalAlign.Bottom}
                                              disabled={this.loading}
                                              selectedItem={this.assortment.product}
                                              minWidth={"13rem"}
                                              items={this.state.availableProducts}
                                              onChange={(_, item) => this.onChangeSelectedProductAsync(item!)}
                                    />

                                </OneColumn>

                                <TwoColumns>

                                    <NumberInput id="orderPoint" noAutoComplete
                                                 ref={this._orderPointRef}
                                                 hideZero={!this.state.orderThresholdModified}
                                                 label={Localizer.newProductModalOrderPoint}
                                                 format={"0.00"}
                                                 min={0}
                                                 max={99999}
                                                 readonly={this.assortment.product == null}
                                                 value={this.assortment.orderThreshold}
                                                 validators={[() => this.validateOrderPoint()]}
                                                 onChange={(sender, value, userInteraction) => this.onChangeOrderPointAsync(sender, value, userInteraction)}
                                    />

                                    <NumberInput id="orderQuantity" noAutoComplete
                                                 label={Localizer.newProductModalOrderQuantity}
                                                 hideZero={!this.state.orderQuantityModified}
                                                 min={0}
                                                 step={1}
                                                 max={9999}
                                                 readonly={this.assortment.product == null}
                                                 value={this.assortment.orderQuantity}
                                                 validators={[() => this.validateOrderQuantity()]}
                                                 onChange={(sender, value, userInteraction) => this.onChangeOrderQuantityAsync(sender, value, userInteraction)}
                                    />
                                    
                                </TwoColumns>
                                
                                <TwoColumns>

                                    <NumberInput id="orderPointPcs" noAutoComplete
                                                 hideZero={!this.state.orderThresholdModified}
                                                 min={0}
                                                 step={1}
                                                 max={99999}
                                                 label={Localizer.newProductModalOrderPointPcs}
                                                 readonly={this.assortment.product == null}
                                                 value={ProductAssortment.getThresholdPcs(this.assortment)}
                                                 validators={[() => this.validateOrderPointPcs()]}
                                                 onChange={(sender, value, userInteraction) => this.onChangeOrderPointPscAsync(sender, value, userInteraction)}
                                    />

                                    <TextInput id="packageQuantity" readonly
                                               label={Localizer.productAttributeKeyPackageQuantity}
                                               value={this.state.assortment.product?.packageQuantity.toString()}
                                    />

                                </TwoColumns>

                                <ButtonContainer className={this.css(styles.buttons, this.mobile && styles.mobile)}>

                                    <Button id={"cancel"}
                                            type={ButtonType.Blue}
                                            icon={{name: "far ban", size: IconSize.Large}}
                                            label={Localizer.genericCancel}
                                            disabled={this.loading}
                                            onClick={() => this.closeAsync()}
                                    />

                                    <Button submit
                                            id={"submitAssortment"}
                                            label={Localizer.genericSave}
                                            type={ButtonType.Orange}
                                            icon={{name: "save"}}
                                            disabled={!this.hasData || this.loading || !this.isValid}
                                    />

                                </ButtonContainer>

                            </Form>

                        </div>
                    )
                }

            </Modal>
        )
    }
}