import React from "react";
import {BaseComponent, ch, IBaseComponentProps, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {OrderStatus} from "@/models/Enums";
import {Button, ButtonType, Checkbox, Dropdown, DropdownAlign, DropdownOrderBy, Form, InlineType, JustifyContent, SelectListItem, Spinner, SwitchNullable, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import OrdersHistoryToolbarModel from "@/pages/OrderManagement/OdersHistory/OrdersHistoryToolbar/OrdersHistoryToolbarModel";
import AittaConstants from "@/helpers/AittaConstants";
import AittaController from "@/pages/AittaController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import TransformProvider from "@/providers/TransformProvider";

import styles from "./OrdersHistoryToolbar.module.scss";

export interface IOrdersHistoryToolbarProps extends IBaseComponentProps{
    model: OrdersHistoryToolbarModel;
    showOrderTypeFilter?: boolean;
    showWithReturnsFilter?: boolean;
    hideCustomersInSearch?: boolean;
    
    onChange?(sender: OrdersHistoryToolbar, model: OrdersHistoryToolbarModel): Promise<void>;
}

interface IOrdersHistoryToolbarState {
    model: OrdersHistoryToolbarModel;
    customers: Customer[];
    isLoading: boolean;
    selectedGroupOrCustomer: CustomerGroup | Customer | null;
}

export default class OrdersHistoryToolbar extends BaseComponent<IOrdersHistoryToolbarProps, IOrdersHistoryToolbarState> {

    state: IOrdersHistoryToolbarState = {
        model: this.props.model,
        customers: [],
        isLoading: true,
        selectedGroupOrCustomer: null,
    };

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/mobileApp/listCustomers"));
    }

    private async selectOrderStatusFilterAsync(items: SelectListItem[]): Promise<void> {
        this.model.orderStatusFilter = items.map(item => parseInt(item.value));
        await this.processAsync(true);
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }

    private async setExpress(express: boolean | null): Promise<void> {
        this.model.express = express;

        await this.processAsync(true);
    }

    private async setWithReturns(withReturns: boolean): Promise<void> {
        this.model.withReturns = withReturns;

        await this.processAsync(true);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this, this.state.model);
        }
    }

    private async selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer: CustomerGroup | Customer, userInteraction: boolean): Promise<void> {
        this.model.customerGroup = CustomerGroup.is(customerGroupOrCustomer) ? customerGroupOrCustomer as CustomerGroup : null;
        this.model.customer = Customer.is(customerGroupOrCustomer) ? customerGroupOrCustomer as Customer : null;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customerGroupOrCustomer);
        }

        await this.processAsync(true);
    }

    public get items(): (CustomerGroup | Customer)[] {
        return Customer.group(this.state.customers);
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get model(): OrdersHistoryToolbarModel {
        return this.state.model;
    }

    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }
    
    public get customerGroup(): CustomerGroup | null {
        return this.model.customerGroup;
    }

    public get customer(): Customer | null {
        return this.model.customer;
    }

    public get orderStatusFilter(): OrderStatus[] {
        return this.model.orderStatusFilter;
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public get showOrderTypeFilter(): boolean {
        return this.props.showOrderTypeFilter || false;
    }

    public get showWithReturnsFilter(): boolean {
        return this.props.showWithReturnsFilter || false;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const customers: Customer[] = await this.fetchCustomersAsync(); 

        this.state.customers = customers;
        this.state.model.customerGroup = AittaController.getDefaultCustomerGroup(customers);
        this.state.model.customer = AittaController.getDefaultCustomer(customers);

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.ordersHistoryToolbar, this.props.className)}>

                <Form onSubmit={() => this.processAsync(true)}>

                    <ToolbarRow justify={JustifyContent.Start}>

                        <Dropdown id="customerGroupsOrCustomers" noWrap inline noGrouping
                                  required={(!AittaController.user.isAdmin && !AittaController.user.isSiteAdmin)}
                                  clearButton={(AittaController.user.isAdmin || AittaController.user.isSiteAdmin)}
                                  className={styles.customers}
                                  align={DropdownAlign.Left}
                                  filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                  disabled={this.isLoading}
                                  width={AittaConstants.customersDropdownMinWidth}
                                  items={this.items}
                                  selectedItem={this.customer || this.customerGroup}
                                  orderBy={DropdownOrderBy.None}
                                  transform={(item: Customer | CustomerGroup) => TransformProvider.toCustomerOrCustomerGroupSelectItem(item, styles.customerGroupItem, styles.customerItem)}
                                  onChange={(sender, item: CustomerGroup | Customer, userInteraction: boolean) => this.selectCustomerGroupOrCustomerAsync(item, userInteraction)}
                        />

                        <Dropdown id="orderStatusFilter" noWrap inline clearButton multiple
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  width={"25rem"}
                                  disabled={this.isLoading}
                                  nothingSelectedText={Localizer.orderHistoryToolbarStatusAll}
                                  items={EnumProvider.getOrderStatusItems()}
                                  selectedItems={this.orderStatusFilter}
                                  onChange={(sender) => this.selectOrderStatusFilterAsync(sender.selectedItems)}
                        />

                        {
                            (this.showOrderTypeFilter) && 
                            (
                                <SwitchNullable inline
                                                label={Localizer.orderHistoryToolbarScheduled}
                                                rightLabel={Localizer.orderHistoryToolbarExpress}
                                                onChange={(_, value) => this.setExpress(value)}
                                                value={this.model.express}

                                />
                            )
                        }

                        {
                            (this.showWithReturnsFilter) &&
                            (
                                <Checkbox inline
                                          inlineType={InlineType.Right}
                                          className={styles.daily}
                                          label={Localizer.orderManagementPageOrdersHistoryWithReturns}
                                          title={Localizer.orderManagementPageOrdersHistoryWithReturns}
                                          value={this.model.withReturns}
                                          onChange={(sender, item) => this.setWithReturns(item)}
                                />
                            )
                        }

                    </ToolbarRow>

                    <ToolbarRow>

                        <TextInput id="orderSearch" inline clearButton
                                   width={AittaConstants.customersDropdownMinWidth}
                                   readonly={this.isLoading}
                                   placeholder={Localizer.orderHistoryToolbarSearch}
                                   value={this.search}
                                   onChange={(sender, value: string) => this.setSearchAsync(value)}
                        />

                        <Button submit right
                                id={"search"}
                                disabled={this.isLoading}
                                label={Localizer.genericSearch}
                                icon={{name: "fas search"}}
                                type={ButtonType.Primary}
                        />

                    </ToolbarRow>

                </Form>

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}