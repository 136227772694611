// autogenerated

export default abstract class BaseProductAssortmentConsumptionsRequest {

    public productAssortmentId: string | null = null;

    public productId: string | null = null;

    public customerId: string | null = null;

    public annualOnly: boolean = false;

    public aittaOnly: boolean = false;

    
    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}