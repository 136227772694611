import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageContainer, RouteWidget, WidgetContainer} from "@reapptor-apps/reapptor-react-components";
import GlobalInformationModal from "@/pages/Dashboard/GlobalInformationModal/GlobalInformationModal";
import {ch, DataStorageType, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import AittaConstants from "@/helpers/AittaConstants";
import User from "@/models/server/User";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import Localizer from "../../localization/Localizer";

import styles from "../../mediq.module.scss";

interface IDashboardProps {
    name: string | null;
}

interface IDashboardState {
    globalMessageHashCode: number;
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {

    state: IDashboardState = {
        globalMessageHashCode: -1
    }

    private readonly _globalInformationModal: React.RefObject<GlobalInformationModal> = React.createRef()

    public get globalInformationModal(): GlobalInformationModal | null {
        return this._globalInformationModal.current;
    }

    public get user(): User {
        return this.getUser();
    }
    
    public get isAdmin(): boolean {
        return this.user.isAdmin;
    }

    public get isCustomer(): boolean {
        return this.user.isCustomer;
    }

    public get isManager(): boolean {
        return this.user.isManager;
    }

    public get supportsCalendar(): boolean {
        return this.user.supportsCalendar;
    }

    public get supportsOrdering(): boolean {
        return this.user.supportsOrdering;
    }

    public getManual(): string {
        return Localizer.dashboardGetManual;
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }

    public async openGlobalMessageModal(globalMessage: string): Promise<void> {
        if (this.globalInformationModal) {
            await this.globalInformationModal.openAsync(globalMessage);
        }
    }

    public async onCloseGlobalInformationModal(showAgain: boolean): Promise<void> {
        const globalMessageHashCode: number | null = (showAgain) ? null : this.state.globalMessageHashCode;

        UserInteractionDataStorage.set(AittaConstants.globalMessageHashCode, globalMessageHashCode, DataStorageType.Session);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const needToShowGlobalMessage: boolean = UserInteractionDataStorage.get(AittaConstants.needToShowGlobalMessage, false, DataStorageType.Session);

        if (needToShowGlobalMessage) {
            UserInteractionDataStorage.set(AittaConstants.needToShowGlobalMessage, false, DataStorageType.Session);

            const globalMessage: string = await this.getAsync("/api/account/getGlobalMessage");

            this.state.globalMessageHashCode = globalMessage.getHashCode();

            const globalMessagePreviousHashCode: number | null = UserInteractionDataStorage.get(AittaConstants.globalMessageHashCode, null, DataStorageType.Session);

            const showGlobalMessage: boolean = (!!globalMessage) && (this.state.globalMessageHashCode != globalMessagePreviousHashCode);

            if (showGlobalMessage) {
                await this.openGlobalMessageModal(globalMessage);
            }
        }
        
        if (this.user.hasNoCustomers) {
            await ch.alertWarningAsync(Localizer.dashboardNoAccessToCustomersLanguageItemName);
        }
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }

        return (
            <PageContainer transparent className={styles.pageContainer} alertClassName={styles.alert}>

                <WidgetContainer controller="Dashboard">

                    <BannerMessageAlert />
                    
                    {
                        (this.isCustomer) && (this.supportsOrdering) &&
                        (
                            <RouteWidget icon={{name: "far fa-cart-plus"}}
                                         route={PageDefinitions.orderManagementRoute}
                                         label={Localizer.topNavOrderManagement}
                                         description={Localizer.dashboardPageOrderManagementDescription}
                            />
                        )
                    }
                    
                    {
                        (this.isManager) &&
                        (
                            <RouteWidget icon={{name: "far fa-monitor-heart-rate"}}
                                         route={PageDefinitions.productManagementRoute}
                                         label={Localizer.topNavProductManagement}
                                         description={Localizer.dashboardPageProductsManagementDescription}
                            />
                        )
                    }

                    {
                        (this.isCustomer) && (this.supportsCalendar) &&
                        (
                            <RouteWidget icon={{name: "faк fa-calendar-alt"}}
                                         route={PageDefinitions.myCalendarRoute}
                                         label={Localizer.topNavMyCalendar}
                                         description={Localizer.dashboardPageMyCalendarDescription}
                            />
                        )
                    }

                    {
                        (this.isAdmin) &&
                        (
                            <RouteWidget wide
                                         icon={{name: "users-cog"}}
                                         route={PageDefinitions.adminRoute}
                                         label={Localizer.topNavAdmin}
                                         description={Localizer.dashboardPageAdminDescription}
                            />
                        )
                    }

                    <RouteWidget icon={{name: "user"}}
                                 route={PageDefinitions.accountRoute}
                                 label={Localizer.topNavAccount}
                                 description={Localizer.dashboardPageAccountDescription}
                    />

                    <RouteWidget icon={{name: "users"}}
                                 route={PageDefinitions.contactSupportRoute}
                                 label={Localizer.topNavContactSupport}
                                 description={Localizer.dashboardPageContactSupportDescription}
                    />
                    
                </WidgetContainer>

                <GlobalInformationModal ref={this._globalInformationModal}
                                        onClose={(_, showAgain: boolean) => this.onCloseGlobalInformationModal(showAgain)}

                />

            </PageContainer>
        );
    }
}