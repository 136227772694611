import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize} from "@reapptor-apps/reapptor-react-components";

import styles from "./GlobalInformationModal.module.scss";
import Localizer from "@/localization/Localizer";

export interface IGlobalInformationModalProps {
    onClose?: (sender: GlobalInformationModal, showAgain: boolean) => Promise<void>;
}

interface IGlobalInformationModalState {
    text: string | null;
}

export default class GlobalInformationModal extends BaseComponent<IGlobalInformationModalProps, IGlobalInformationModalState> {

    state: IGlobalInformationModalState = {
        text: null
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private async onCloseAsync(showAgain: boolean): Promise<void> {
        if (this.props.onClose) {
            await this.props.onClose(this, showAgain);
        }
        
        await this.closeAsync();
    }

    private get text(): string | null {
        return this.state.text;
    }

    private get modal(): Modal | null {
        return this._modalRef.current;
    }

    public async openAsync(text: string): Promise<void> {
        await this.setState({text});
        
        if (this.modal) {
            await this.modal.openAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        if (this.modal) {
            await this.modal.closeAsync();
        }
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   title={"{0:D} {0:dddd}".format(new Date())}
                   size={ModalSize.Default}
                   ref={this._modalRef}
                   className={this.css(styles.bookingRateModal)}
            >

                <div className={styles.content}>
                    
                    <div className={styles.text}>
                        {this.text}
                    </div>
                    
                    <ButtonContainer className={styles.buttons}>
                        
                        <Button id={"globalInfoHide"}
                                label={Localizer.globalInformationModalDontShowAgain}
                                type={ButtonType.Primary}
                                onClick={() => this.onCloseAsync(false)}
                        />
                        
                        <Button id={"globalInfoShow"}
                                label={Localizer.genericAccept}
                                type={ButtonType.Orange}
                                onClick={() => this.onCloseAsync(true)}
                        />
                        
                    </ButtonContainer>

                </div>

            </Modal>
        );
    }
}