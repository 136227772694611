import React from "react";
import {BaseComponent, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Dropdown, Form, Modal, OneColumn, SwitchNullable, TextInput} from "@reapptor-apps/reapptor-react-components";
import Localizer from "@/localization/Localizer";

import styles from "./InventoryFiltersModal.module.scss";
import EnumProvider from "@/providers/EnumProvider";
import {InventoryStateFilter} from "@/models/Enums";

export class InventoryFilterModel {
    public search: string | null = null;
    public inventoried: boolean | null = null;

    public clear(): void {
        this.search = null;
        this.inventoried = null;
    }

    public static isEmpty(model: InventoryFilterModel): boolean {
        return (
            (model.search == null || model.search == "") &&
            (model.inventoried == null)
        );
    }
}

interface IInventoryFiltersModalProps {
    model: InventoryFilterModel;
    title: string;

    onSubmit(sender: IBaseComponent, model: InventoryFilterModel): Promise<void>;
}

interface IInventoryFiltersModalState {
    model: InventoryFilterModel;
}

export default class InventoryFiltersModal extends BaseComponent<IInventoryFiltersModalProps, IInventoryFiltersModalState> {
    state: IInventoryFiltersModalState = {
        model: this.props.model
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private async clearAsync(): Promise<void> {
        this.state.model.clear();

        await this.reRenderAsync();
    }

    private async onSearchChangeAsync(value: string): Promise<void> {
        this.state.model.search = value;
        await this.setState({model: this.state.model});
    }

    private async onInventoriedChangeAsync(value: InventoryStateFilter, userInteraction: boolean): Promise<void> {
        this.state.model.inventoried = (value == InventoryStateFilter.All)
            ? null
            : (value == InventoryStateFilter.Completed);
        
        if (userInteraction) {
            await this.setState({model: this.state.model});
        }
    }

    private async submitAsync(): Promise<void> {
        await this.props.onSubmit(this, this.state.model);

        await this._modalRef.current!.closeAsync();
    }

    private get model(): InventoryFilterModel {
        return this.state.model;
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   title={this.props.title}
                   ref={this._modalRef}
                   className={this.css(styles.inventoryFiltersModal, this.mobile && styles.mobile)}>

                <Form className={styles.form}
                      onSubmit={() => this.submitAsync()}
                >

                    <OneColumn>

                        <TextInput clearButton name="search"
                                   label={Localizer.mobileSearch}
                                   placeholder={Localizer.inventoryFiltersModalSearchPlaceholder}
                                   value={this.model.search}
                                   onChange={(_, value: string) => this.onSearchChangeAsync(value)}
                        />

                    </OneColumn>

                    <OneColumn>

                        <Dropdown id="inventoryState"
                                  required
                                  label={Localizer.inventoryFiltersModalInventoryState}
                                  items={EnumProvider.getInventoryStateFilterItems()}
                                  onChange={(sender, item, userInteraction: boolean) => this.onInventoriedChangeAsync(parseInt(item!.value), userInteraction)}
                        />

                    </OneColumn>

                    <ButtonContainer className={this.css(styles.buttons, this.mobile && styles.mobile)}>

                        <Button submit
                                id={"submitFilter"}
                                type={ButtonType.Orange}
                                label={Localizer.mobileOrderFilterModalApply}
                        />

                        <Button id={"clearFilter"}
                                type={ButtonType.Blue}
                                label={Localizer.mobileOrderFilterModalClear}
                                onClick={() => this.clearAsync()}
                        />

                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};