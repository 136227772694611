import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {ButtonType, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabRenderType, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import {DataStorageType, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import ProductsSelectionPanel from "@/pages/LabelsManagement/ProductsSelectionPanel/ProductsSelectionPanel";
import LabelsSelectionPanel from "@/pages/LabelsManagement/LabelsSelectionPanel/LabelsSelectionPanel";
import GetProductLabelsTotalCountRequest from "@/models/server/requests/GetProductLabelsTotalCountRequest";
import Localizer from "@/localization/Localizer";

import styles from "./LabelsManagement.module.scss"

export interface ILabelsManagementProps {
}

interface ILabelsManagementState {
    notPrintedLabelCount: number;
}

export default class LabelsManagement extends AuthorizedPage<ILabelsManagementProps, ILabelsManagementState> {

    state: ILabelsManagementState = {
        notPrintedLabelCount: 0
    };

    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    
    private get itemsCount(): number | null {
        return (this.state.notPrintedLabelCount > 0) ? this.state.notPrintedLabelCount : null;
    }
    
    private async createLabelsAsync(newLabelsCount: number): Promise<void>{
        this.state.notPrintedLabelCount += newLabelsCount;
        
        await this.setState(this.state);
        
        await this._tabContainerRef.current!.model.activateTabAsync(1);
    }

    private async getLabelsPendingPrintCountAsync(): Promise<void> {
        const request = new GetProductLabelsTotalCountRequest();
        
        const notPrintedLabelCount: number = await this.postAsync("/api/labelsManagement/getProductLabelsTotalCount", request);

        await this.setState({notPrintedLabelCount});
    }
    
    public getTitle(): string {
        return Localizer.labelsManagementPageTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await this.getLabelsPendingPrintCountAsync();
    }
    
    public render(): React.ReactNode {

        return (
            <PageContainer fullWidth fullHeight className={styles.labelsManagement}>

                <PageHeader withTabs
                            title={this.getTitle()}
                >

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>

                <PageRow>

                    <div className="col">

                        <TabContainer id="labelsManagementTabs" ref={this._tabContainerRef} renderType={TabRenderType.ActiveOnly} dataStorageType={DataStorageType.Page}>
                            
                            <Tab id={"products"} title={Localizer.labelsManagementPageProductsSelection}>
                                
                                <ProductsSelectionPanel createLabels={(newLabelsCount: number) => this.createLabelsAsync(newLabelsCount)}/>
                                
                            </Tab>
                            
                            <Tab id={"print"} title={Localizer.labelsManagementPageLabelsSelection} count={this.itemsCount ?? undefined}>
                                
                                <LabelsSelectionPanel numberOfPrintItemsChange={() => this.getLabelsPendingPrintCountAsync()} />
                                
                            </Tab>
                            
                        </TabContainer>
                    
                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}