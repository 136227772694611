// autogenerated

export default class ListCustomerProductAssortmentsRequest {

    public productId: string = "";

    public readonly isListCustomerProductAssortmentsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListCustomerProductAssortmentsRequest) || ((from as any).isListCustomerProductAssortmentsRequest === true));
    }

    public static as(from?: object | null): ListCustomerProductAssortmentsRequest | null {
        return (ListCustomerProductAssortmentsRequest.is(from)) ? from as ListCustomerProductAssortmentsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}