import {OrderStatus} from "@/models/Enums";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import AittaController from "@/pages/AittaController";

export default class OrdersHistoryToolbarModel {
    public orderStatusFilter: OrderStatus[] = [];

    public customer: Customer | null = null;
    
    public customerGroup: CustomerGroup | null = null;

    public express: boolean | null = null;

    public withReturns: boolean = false;
    
    public search: string | null = null;

    public dataInitialized: boolean = false;

    public static initialized(toolbar: OrdersHistoryToolbarModel): boolean {
        return ((toolbar.dataInitialized) && ((AittaController.user.isMediq) || ((!!toolbar.customer?.id) || (!!toolbar.customerGroup?.id))));
    }
}