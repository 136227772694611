import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ch, IConfirmation, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import {AuthType} from "@/models/Enums";
import PageDefinitions from "../../providers/PageDefinitions";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import SaveUserResponse from "@/models/server/responses/SaveUserResponse";
import SetMediqDeviceRequest from "@/models/server/requests/SetMediqDeviceRequest";
import {
    AddressDivider,
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownSelectType,
    EmailInput,
    Form,
    Inline,
    InlineType,
    Modal,
    ModalSize,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    TextInput,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import AittaController from "@/pages/AittaController";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "../../localization/Localizer";

import styles from "./Account.module.scss";

interface IAccountProps {
}

interface IAccountState {
}

export default class Account extends AuthorizedPage<IAccountProps, IAccountState> {

    state: IAccountState = {
    };

    private readonly _agreementRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _registrationRef: React.RefObject<Checkbox> = React.createRef();

    private get agreementCheckbox(): Checkbox {
        return this._agreementRef.current!;
    }

    private get registrationCheckbox(): Checkbox {
        return this._registrationRef.current!;
    }

    private get fcmToken(): string | null {
        return AittaController.fcmToken;
    }

    private get isMediqDevice(): boolean {
        return AittaController.isMediqDevice;
    }

    private get mediqDeviceRegistering(): boolean {
        return ((ch.mobile) || (ch.mobileApp)) && (!!this.fcmToken) && (this.user.isAdmin);
    }

    public getTitle(): string {
        return Localizer.topNavAccount;
    }

    public get user(): User {
        return this.getUser();
    }

    public get isSiteAdmin(): boolean {
        return this.user.isSiteAdmin;
    }

    public get versionId(): string {
        return ch.versionId || "-";
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {

        if (!this.agreementCheckbox.checked || !this.registrationCheckbox.checked) {
            return await ch.alertErrorAsync(Localizer.myAccountPageAcceptanceRequired);
        }

        const request = new SaveUserRequest();
        request.id = this.user.id;
        request.authType = this.user.authType;

        this.copyTo(data, request, this.user);

        const response: SaveUserResponse = await this.postAsync("/api/account/saveUserAccount", request);

        if (response.userAlreadyExists) {
            await this.alertErrorAsync(Localizer.myAccountPageUserAlreadyExists, true);
            return;
        }

        const message: string = Utility.format(Localizer.myAccountPageDataSaved, TransformProvider.userToString(this.user));

        await this.alertMessageAsync(message, true);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private async redirectToPasswordPage(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.changePasswordRoute);
    }

    private async toggleAsMediqDevice(): Promise<void> {
        const request = new SetMediqDeviceRequest();
        request.fcmToken = this.fcmToken;
        request.isMediqDevice = (!this.isMediqDevice);

        await this.postAsync("/api/account/setMediqDevice", request);
    }

    private async onDeleteAccountAsync(): Promise<void> {
        const confirmation: IConfirmation = {
            title: Localizer.myAccountPageRemoveAccountWarning,
            confirmActionPrompt: true,
            confirmActionPromptWord: Localizer.myAccountPageConfirmActionPromptWord
        } as IConfirmation;

        const confirm: boolean = await ch.confirmAsync(confirmation);

        if (confirm) {
            await this.postAsync("/api/account/deleteMyAccount");
        }
    }

    public render(): React.ReactNode {

        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }

        return (
            <PageContainer className={styles.account}>

                <PageHeader withTabs
                            title={Localizer.myAccountPageTitle}
                />

                <PageRow>

                    <div className="col">
                        {
                            (this.user) &&
                            (
                                <Form id="form" onSubmit={(_, data) => this.handleSubmitAsync(data)}>

                                    {
                                        (this.isSiteAdmin) &&
                                        (
                                            <TwoColumns>

                                                <TextInput id="versionId" readonly
                                                           label={Localizer.myAccountPageVersionId}
                                                           value={this.versionId}
                                                />

                                            </TwoColumns>
                                        )
                                    }

                                    {
                                        (this.mediqDeviceRegistering) &&
                                        (
                                            <TwoColumns>

                                                <TextInput id="fcmToken" readonly
                                                           label={Localizer.myAccountPageDeviceId}
                                                           value={AittaController.fcmToken}
                                                />

                                            </TwoColumns>
                                        )
                                    }

                                    <TwoColumns>

                                        <EmailInput id="email"
                                                    label={Localizer.formInputEmail}
                                                    required={(this.user.authType == AuthType.Email)}
                                                    readonly={!!this.user.email && (this.user.authType == AuthType.Email)}
                                                    value={this.user!.email}
                                        />

                                        <PhoneInput id="phone"
                                                    label={Localizer.formInputPhone}
                                                    value={this.user.phone}
                                                    required={(this.user.authType == AuthType.Phone)}
                                                    readonly={!!this.user.phone && (this.user.authType == AuthType.Phone)}
                                        />

                                    </TwoColumns>

                                    <TwoColumns>
                                        <TextInput id="firstname" required
                                                   label={Localizer.formInputFirstname}
                                                   value={this.user.firstname}
                                        />

                                        <TextInput id="lastName" required
                                                   label={Localizer.formInputLastname}
                                                   value={this.user.lastName}
                                        />

                                    </TwoColumns>

                                    <TwoColumns>

                                        <TextInput id="middleName" label={Localizer.formInputMiddlename} value={this.user.middleName}/>

                                        <Dropdown id="language"
                                                  required
                                                  label={Localizer.formInputLanguage}
                                                  selectType={DropdownSelectType.Checkbox}
                                                  items={Localizer.supportedLanguages}
                                                  selectedItem={Localizer.findLanguage(this.user.language)}
                                        />

                                    </TwoColumns>

                                    <AddressDivider id="formattedAddress"
                                                    location={this.user.homeLocation || undefined}
                                    />

                                    <OneColumn className="mb-3">

                                        <Inline>

                                            <Checkbox ref={this._agreementRef}
                                                      id="agreementAccepted"
                                                      label={Localizer.myAccountPageAcceptance}
                                                      inline
                                                      inlineType={InlineType.Right}
                                                      value={this.user.agreementAccepted}
                                                      readonly={this.user.agreementAccepted}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptanceTerms}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="agreementModal"
                                            />

                                        </Inline>

                                        <Inline>

                                            <Checkbox ref={this._registrationRef}
                                                      id="registrationAccepted"
                                                      label={Localizer.myAccountPageAcceptance}
                                                      inline
                                                      inlineType={InlineType.Right}
                                                      value={this.user.registrationAccepted}
                                                      readonly={this.user.registrationAccepted}
                                            />

                                            <Button className={"ml-n2"}
                                                    label={Localizer.myAccountPageAcceptancePrivacyNotice}
                                                    type={ButtonType.Text}
                                                    toggleModal
                                                    dataTarget="privacyNoticeModal"
                                            />

                                        </Inline>

                                    </OneColumn>

                                    <ButtonContainer>

                                        <Button type={ButtonType.Primary}
                                                label={Localizer.changePasswordButton}
                                                onClick={() => this.redirectToPasswordPage()}
                                        />

                                        {
                                            (this.mediqDeviceRegistering) &&
                                            (
                                                <Button type={ButtonType.Dark}
                                                        label={(this.isMediqDevice) ? Localizer.myAccountPageResetMediqDevice : Localizer.myAccountPageSetMediqDevice}
                                                        onClick={() => this.toggleAsMediqDevice()}
                                                />
                                            )
                                        }

                                        <Button className={styles.delete}
                                                type={ButtonType.Danger}
                                                label={Localizer.genericDelete}
                                                icon={{name: "far trash"}}
                                                onClick={() => this.onDeleteAccountAsync()}
                                        />

                                        <Button submit
                                                type={ButtonType.Orange}
                                                label={Localizer.formSave}
                                                icon={{name: "far save"}}
                                        />

                                    </ButtonContainer>

                                </Form>
                            )
                        }
                    </div>
                </PageRow>

                <Modal id="agreementModal" info keepTextFormatting
                       title={Localizer.myAccountPageAcceptanceTermsTitle}
                       content={Localizer.myAccountPageAcceptanceTermsContent}
                       size={ModalSize.Large}
                />

                <Modal id="privacyNoticeModal" info keepTextFormatting
                       title={Localizer.myAccountPageAcceptancePrivacyNoticeTitle}
                       content={Localizer.myAccountPageAcceptancePrivacyNoticeContent}
                       size={ModalSize.Large}
                />

            </PageContainer>
        );
    }
}