import {CustomerHandler} from "@/models/Enums";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";

export enum LabelsPanelType {
    ProductsSelection,

    LabelsSelection
}

export default class LabelsToolbarModel {
    public customerHandler: CustomerHandler | null = null;

    public customerGroup: CustomerGroup | null = null;
    
    public customer: Customer | null = null;

    public search: string | null = null;
    
    public printedDate: Date | null = null;

    public showPrintedOnly: boolean = false;
    
    public dataInitialized: boolean = false;

    public static initialized(toolbar: LabelsToolbarModel, labelsPanelType: LabelsPanelType): boolean {
        return (labelsPanelType == LabelsPanelType.LabelsSelection) || ((toolbar.dataInitialized) && ((!!toolbar.customer) || (!!toolbar.customerGroup)));
    }
}