// autogenerated
import {ProductAssortmentType} from "@/models/Enums";
import Product from "@/models/server/Product";
import ProductAssortment from "@/models/server/ProductAssortment";

export default class ProductSelection {

    public productId: string = "";

    public product: Product | null = null;

    public customerId: string | null = null;

    public customerGroupId: string | null = null;

    public type: ProductAssortmentType = ProductAssortmentType.Aitta;

    public productAssortmentId: string | null = null;

    public fromProductReplacementId: string | null = null;

    public toProductReplacementIds: string[] | null = null;

    public productReplacementCode: string | null = null;

    public productAssortment: ProductAssortment | null = null;

    public selected: boolean = false;

    public hasBastards: boolean = false;

    public readonly isProductSelection: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ProductSelection) || ((from as any).isProductSelection === true));
    }

    public static as(from?: object | null): ProductSelection | null {
        return (ProductSelection.is(from)) ? from as ProductSelection : null;
    }

    // #region Placeholder

    public static getConsumption(selection: ProductSelection, consumptionInterval?: number | null): number {
        return (selection.productAssortment)
            ? (ProductAssortment.getConsumption(selection.productAssortment, consumptionInterval))
            : 0;
    }

    // #endregion
}