import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {DateInput, Form, Panel, ThreeColumns, TwoColumns,} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerMeetingsPanel.module.scss";

interface ICustomerMeetingsPanelPanelProps {
    customer: Customer;

    onChange(): Promise<void>
}

interface ICustomerMeetingsPanelPanelState {
}

export default class CustomerMeetingsPanel extends BaseComponent<ICustomerMeetingsPanelPanelProps, ICustomerMeetingsPanelPanelState> {

    state: ICustomerMeetingsPanelPanelState = {};

    private get customer(): Customer {
        return this.props.customer;
    }

    private async onSelectionMeetingDateAsync(value: Date): Promise<void> {
        this.customer.selectionMeeting = value

        await this.props.onChange();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <Panel title={Localizer.editCustomerPageCustomerMeetingsPanelTitle} className={styles.customerMeetingsPanel}>

                <div className="col-12">
                    {
                        (this.customer) &&
                        (
                            <div>
                                
                                <Form>

                                    <ThreeColumns>

                                        <DateInput id="selectionMeeting" clearButton
                                                   label={Localizer.editCustomerPageCustomerMeetingsPanelSelectionMeetingDate}
                                                   value={this.customer.selectionMeeting}
                                                   onChange={(value: Date) => this.onSelectionMeetingDateAsync(value)}
                                        />
                                        

                                    </ThreeColumns>

                                </Form>

                            </div>
                        )
                    }
                </div>

            </Panel>
        );
    }
};