import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Accordion, Button, ButtonType, Icon, IconSize, Inline, JustifyContent, PageContainer, PageHeader, PageRow, TextInput, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import {PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import FaqItem from "@/models/server/FaqItem";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/Faq/Faq.module.scss";
import {StringUtility} from "@reapptor-apps/reapptor-toolkit";

interface IFAQProps {
}

interface IFaqState {
    faqItems: FaqItem[];
    displayFaqItems: FaqItem[];
    searchValue: string;
}

export default class Faq extends AuthorizedPage<IFAQProps> {

    state: IFaqState = {
        faqItems: [],
        displayFaqItems: [],
        searchValue: ""
    };

    public getTitle(): string {
        return Localizer.faqPageTitle;
    }

    public get faqItems(): FaqItem[] {
        return this.state.faqItems;
    }

    public get displayFaqItems(): FaqItem[] {
        return this.state.displayFaqItems;
    }

    private async setSearchValueAsync(value: string): Promise<void> {
        await this.setState({searchValue: value});
    }

    private async onSearchValueChangedAsync(value: string, mobile: boolean = false): Promise<void> {
        value = value.toLowerCase();
        this.state.displayFaqItems = [];

        if (mobile) {
            this.state.searchValue = value;
        }

        if (value === "") {
            this.state.displayFaqItems = this.faqItems;
        } else {
            const itemsLength: number = this.faqItems.length;

            for (let i = 0; i < itemsLength; i++) {

                const item: FaqItem = this.faqItems[i];

                const title = FaqItem.getLocalizedTitle(item, Localizer.language).toLowerCase();

                const match: boolean = (title.includes(value)) || (title.startsWith(value));

                if (match) {
                    this.displayFaqItems.push(item);
                }
            }
        }

        await this.setState({});
    }


    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const faq: FaqItem[] = await this.postAsync("/api/faq/listFaq");

        await this.setState({faqItems: faq, displayFaqItems: faq});
    }

    public renderMobileSearch(): React.ReactNode {
        return (
            <div className={this.css(styles.searchBar)}>

                <TextInput clearButton noAutoComplete
                           placeholder={Localizer.mobileOrderDetailsPageSearchCriteria}
                           id={"catalog_search_product"}
                           className={this.css(styles.searchBarInput)}
                           value={this.state.searchValue}
                           onChange={(sender, value) => this.onSearchValueChangedAsync(value, true)}
                           append={<Icon name="far search"/>}
                />

            </div>
        );
    }

    public renderDesktopSearch(): React.ReactNode {
        return (
            <div className={this.css(styles.searchBar)}>

                <Inline>

                    <TextInput clearButton noAutoComplete
                               placeholder={Localizer.faqToolbarSearchPlaceholder}
                               id={"catalog_search_product"}
                               className={this.css(styles.searchBarInput)}
                               value={this.state.searchValue}
                               onChange={(_, value) => this.setSearchValueAsync(value)}
                    />

                    <Button id={"search"}
                            block
                            label={Localizer.genericSearch}
                            icon={{name: "far search", size: IconSize.Large}}
                            type={ButtonType.Primary}
                            onClick={() => this.onSearchValueChangedAsync(this.state.searchValue)}
                    />

                </Inline>

            </div>
        );
    }


    public renderItems(item: FaqItem): React.ReactNode {
        const title: string | null = FaqItem.getLocalizedTitle(item, Localizer.language);
        const description: string | null = FaqItem.getLocalizedDescription(item, Localizer.language);
        
        const shouldRender: boolean = ((title != "") && (description != ""));
        
        return (
            shouldRender &&
            <Accordion key={item.key} header={FaqItem.getLocalizedTitle(item, Localizer.language)}
                       autoCollapse
            >
                
                                <span>
                
                                    {
                                        ReactUtility.toMultiLines(FaqItem.getLocalizedDescription(item, Localizer.language))
                                    }
                
                                </span>

            </Accordion>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight className={this.css(styles.faq, this.mobile && styles.mobile)}>

                {
                    (!this.mobile) &&
                    (
                        <PageHeader title={this.getTitle()}>

                            <ToolbarButton title={Localizer.genericBack}
                                           icon={{name: "fas arrow-alt-circle-left"}}
                                           type={ButtonType.Primary}
                                           onClick={async () => PageRouteProvider.back()}
                            />

                        </PageHeader>
                    )
                }

                <div className={styles.content}>

                    {
                        (this.mobile)
                            ? this.renderMobileSearch()
                            : this.renderDesktopSearch()
                    }

                    {
                        this.state.displayFaqItems.map(item => this.renderItems(item))
                    }

                </div>

            </PageContainer>
        );
    }
}