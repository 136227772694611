import React from "react";
import {ActionType, BaseComponent, ch, PageCacheTtl, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {BorderType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnModel, Grid, GridHoveringType, GridModel, GridOddType, RowModel} from "@reapptor-apps/reapptor-react-components";
import {FileModel, IPagedList, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import ReportProduct from "@/models/server/ReportProduct";
import {AittaReportFilter, ExportEncodingType, ReportType} from "@/models/Enums";
import ReportsToolbar from "@/pages/ReportManagement/ReportsToolbar/ReportsToolbar";
import ReportsToolbarModel, {ReportPageType} from "@/pages/ReportManagement/ReportsToolbar/ReportsToolbarModel";
import ProductModal from "@/components/ProductModal/ProductModal";
import ExportReportToCsvRequest from "@/models/server/requests/ExportReportToCsvRequest";
import ListReportProductsRequest from "@/models/server/requests/ListReportProductsRequest";
import Product from "@/models/server/Product";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./ProductReportPanel.module.scss";

export interface IProductReportPanelProps {
}

interface IProductReportPanelState {
    toolbar: ReportsToolbarModel;
}

export default class ProductReportPanel extends BaseComponent<IProductReportPanelProps, IProductReportPanelState> {

    state: IProductReportPanelState = {
        toolbar: new ReportsToolbarModel(),
    };

    private readonly _productModelRef: React.RefObject<ProductModal> = React.createRef();
    private readonly _reportsPanelGridRef: React.RefObject<Grid<ReportProduct>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            name: "customerName",
            header: Localizer.productReportPanelCustomerLanguageItemName,
            sorting: true,
            accessor: (model: ReportProduct) => model.customerName || model.productAssortment?.customerGroup?.name,
            minWidth: "15rem",
            maxWidth: "15rem",
            settings: {
                infoAccessor: nameof.full<ReportProduct>(o => o.customerCode),
            }
        } as ColumnDefinition,
        {
            name: "groupName",
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<ReportProduct>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            name: "productCode",
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.productAssortment!.product!.code),
            minWidth: "7,5rem",
            maxWidth: "7,5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            name: "productName",
            header: Localizer.productPanelGridNameLanguageItemName,
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.productAssortment!.product!.name),
            minWidth: "25rem",
            stretch: true,
            className: styles.hasImage,
            callback: (cell: CellModel<ReportProduct>) => this.onNameClickAsync(cell),
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridAittaLanguageItemName,
            minWidth: "4rem",
            textAlign: TextAlign.Center,
            init: (cell: CellModel<ReportProduct>) => this.initSelectionColumn(cell),
            actions: [
                {
                    title: "AITTA",
                    type: ActionType.Light,
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            header: ReportProduct.getYearMinus3Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.yearMinus3),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Annual,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getYearMinus2Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.yearMinus2),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Annual,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getYearMinus1Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.yearMinus1),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Annual,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getYearMinus0Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.yearMinus0),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.toolbar.reportType == ReportType.Annual,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus11Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus11),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus10Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus10),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus9Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus9),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus8Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus8),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus7Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus7),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus6Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus6),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus5Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus5),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus4Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus4),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus3Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus3),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus2Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus2),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus1Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus1),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getMonthMinus0Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.monthMinus0),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Monthly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getQuarterMinus3Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.quarterMinus3),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Quarterly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getQuarterMinus2Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.quarterMinus2),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Quarterly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getQuarterMinus1Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.quarterMinus1),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Quarterly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: ReportProduct.getQuarterMinus0Name(),
            sorting: true,
            accessor: nameof.full<ReportProduct>(o => o.quarterMinus0),
            minWidth: "3rem",
            maxWidth: "3rem",
            visible: this.reportType == ReportType.Quarterly,
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
    ];

    private async onToolbarSubmitAsync(toolbar: ReportsToolbarModel): Promise<void> {
        await this.setState({toolbar});

        await this.reloadAsync();
    }

    private async initSelectionColumn(cell: CellModel<ReportProduct>): Promise<void> {
        const model: ReportProduct = cell.row.model;

        const assortmentDeleted = (model.productAssortment != null) && (model.productAssortment.deleted) && (model.productAssortment.customerId != null);
        const selected: boolean =  (model.productAssortment != null) && (!model.productAssortment.deleted) && (model.productAssortment.customerId != null);
        const unselected: boolean = (model.productAssortment != null) && (model.productAssortment.customerGroupId != null);

        cell.className = styles.aitta;
        cell.className = this.cssIf(cell.className, selected, styles.selected);
        cell.className = this.cssIf(cell.className, unselected, styles.unselected);
        cell.className = this.cssIf(cell.className, assortmentDeleted, styles.deleted);

        const selectedAction: CellAction<ReportProduct> = cell.actions[0];

        selectedAction.action.tooltip = (assortmentDeleted)
            ? Localizer.productReportPanelTooltipDeletedAssortment
            : (selected) 
                ? Localizer.productReportPanelTooltipAittaAssortment
                : Localizer.productReportPanelTooltipContractAssortment;

    }

    private async onNameClickAsync(cell: CellModel<ReportProduct>): Promise<void> {
        const model: ReportProduct = cell.row.model;

        if (this._productModelRef.current) {
            const productOrId: Product | string = model.productAssortment?.product ?? model.productAssortmentId;
            const replacementProductId: string | null = model.productReplacementId;

            await this._productModelRef.current.openAsync(productOrId, replacementProductId, null, this.customerId, this.customerGroupId);
        }
    }

    private async fetchAsync(sender: Grid<ReportProduct>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ReportProduct>> {
        if (!ReportsToolbarModel.initialized(this.toolbar, this.hasMediqAccess)) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListReportProductsRequest();
        request.reportType = this.reportType;
        request.customerGroupId = this.customerGroupId;
        request.customerId = this.customerId;
        request.search = this.search;
        request.aittaAppOnly = this.aittaAppOnly;
        request.withSalesOnly = this.productsWithSalesOnly;
        request.aittaReportFilter = this.aittaReportFilter;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;

        return await sender.postAsync("/api/reportManagement/listReportProducts", request, PageCacheTtl._1m);
    }

    private async downloadCsvAsync(encodingType: ExportEncodingType): Promise<void> {
        const request = new ExportReportToCsvRequest();
        request.encodingType = encodingType;
        request.customerGroupId = this.customerGroupId;
        request.customerId = this.customerId;
        request.reportType = this.reportType;
        request.aittaReportFilter = this.aittaReportFilter;
        request.aittaAppOnly = this.aittaAppOnly;
        request.withSalesOnly = this.productsWithSalesOnly;
        request.search = this.search;

        const sortColumnName: string | null = (this.grid.sortColumn)
            ? this.grid.sortColumn.name
                ? this.grid.sortColumn.name
                : (typeof this.grid.sortColumn.accessor === "string")
                    ? this.grid.sortColumn.accessor
                    : null
            : null;

        const sortColumnDirection: SortDirection | null = this.grid.sortDirection;
        
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortColumnDirection;
        
        const file: FileModel = await this.postAsync("/api/reportManagement/exportReportToCsv", request,false, 120);

        await ch.download(file);
    }

    private initRow(row: RowModel<ReportProduct>): void {
        const reportType: ReportType = this.reportType;

        const yearMinus0Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.yearMinus0)).column;
        yearMinus0Column.visible = reportType == ReportType.Annual;
        const yearMinus1Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.yearMinus1)).column;
        yearMinus1Column.visible = reportType == ReportType.Annual;
        const yearMinus2Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.yearMinus2)).column;
        yearMinus2Column.visible = reportType == ReportType.Annual;
        const yearMinus3Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.yearMinus3)).column;
        yearMinus3Column.visible = reportType == ReportType.Annual;

        const monthMinus0Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus0)).column;
        monthMinus0Column.visible = reportType == ReportType.Monthly;
        const monthMinus1Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus1)).column;
        monthMinus1Column.visible = reportType == ReportType.Monthly;
        const monthMinus2Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus2)).column;
        monthMinus2Column.visible = reportType == ReportType.Monthly;
        const monthMinus3Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus3)).column;
        monthMinus3Column.visible = reportType == ReportType.Monthly;
        const monthMinus4Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus4)).column;
        monthMinus4Column.visible = reportType == ReportType.Monthly;
        const monthMinus5Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus5)).column;
        monthMinus5Column.visible = reportType == ReportType.Monthly;
        const monthMinus6Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus6)).column;
        monthMinus6Column.visible = reportType == ReportType.Monthly;
        const monthMinus7Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus7)).column;
        monthMinus7Column.visible = reportType == ReportType.Monthly;
        const monthMinus8Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus8)).column;
        monthMinus8Column.visible = reportType == ReportType.Monthly;
        const monthMinus9Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus9)).column;
        monthMinus9Column.visible = reportType == ReportType.Monthly;
        const monthMinus10Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus10)).column;
        monthMinus10Column.visible = reportType == ReportType.Monthly;
        const monthMinus11Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.monthMinus11)).column;
        monthMinus11Column.visible = reportType == ReportType.Monthly;

        const quarterlyMinus0Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.quarterMinus0)).column;
        quarterlyMinus0Column.visible = reportType == ReportType.Quarterly;
        const quarterlyMinus1Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.quarterMinus1)).column;
        quarterlyMinus1Column.visible = reportType == ReportType.Quarterly;
        const quarterlyMinus2Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.quarterMinus2)).column;
        quarterlyMinus2Column.visible = reportType == ReportType.Quarterly;
        const quarterlyMinus3Column: ColumnModel<ReportProduct> = row.get(nameof<ReportProduct>(o => o.quarterMinus3)).column;
        quarterlyMinus3Column.visible = reportType == ReportType.Quarterly;
    }

    private async onBeforePaginationChangeAsync(): Promise<boolean> {
        window.scrollTo({top: 0});
        
        return true;
    }

    public async reloadAsync(): Promise<void> {
        if (this.grid) {
            await this.grid.reloadAsync();
        }
    }

    public get grid(): GridModel<ReportProduct> {
        return this._reportsPanelGridRef.current!.model;
    }

    public get customerId(): string | null {
        return this.toolbar.customer?.id || null;
    }

    public get customerGroupId(): string | null {
        return this.toolbar.customerGroup?.id || null;
    }

    public get search(): string | null {
        return this.toolbar.search;
    }
    
    public get aittaAppOnly(): boolean {
        return this.toolbar.aittaAppOnly;
    }
    
    public get productsWithSalesOnly(): boolean {
        return this.toolbar.productsWithSalesOnly;
    }

    public get aittaReportFilter(): AittaReportFilter {
        return this.toolbar.aittaReportFilter;
    }

    public get reportType(): ReportType {
        return this.toolbar.reportType;
    }

    public get toolbar(): ReportsToolbarModel {
        return this.state.toolbar;
    }

    public get hasMediqAccess(): boolean {
        return AittaController.hasMediqAccess;
    }

    public renderGroupCell(cell: CellModel<ReportProduct>): React.ReactNode {
        const product: Product = cell.model!.productAssortment!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }

                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }

                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.productReportPanel)}>

                <ReportsToolbar className={styles.stickyToolbar} 
                                model={this.toolbar}
                                type={ReportPageType.Products}
                                hideCustomersInSearch={(AittaController.user.isMaster)}
                                downloadCsv={(sender, encodingType) => this.downloadCsvAsync(encodingType)}
                                onChange={(sender: ReportsToolbar, toolbar: ReportsToolbarModel) => this.onToolbarSubmitAsync(toolbar)}
                />

                <Grid optimization responsive pagination
                      id={"productReportPanelGrid"}
                      ref={this._reportsPanelGridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.Row}
                      className={this.css(styles.reportGrid, styles.stickyHeader)}
                      headerMinHeight={80}
                      odd={GridOddType.None}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      noDataText={Localizer.genericNoData}
                      initRow={(row: RowModel<ReportProduct>) => this.initRow(row)}
                      onBeforePagination={() => this.onBeforePaginationChangeAsync()}
                      fetchData={(sender: Grid<ReportProduct>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                />

                <ProductModal id={"productModal"}
                              ref={this._productModelRef}
                />

            </div>
        )
    }
}