import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BorderType, ButtonType, CellModel, ColumnDefinition, Grid, GridHoveringType, GridModel, GridOddType, PageContainer, PageHeader, PageRow, RowModel, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import InventoriesToolbar from "@/pages/InventoryManagement/InventoriesToolbar/InventoriesToolbar";
import InventoryProduct from "@/models/server/InventoryProduct";
import {FileModel, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import InventoriesToolbarModel from "@/pages/InventoryManagement/InventoriesToolbar/InventoriesToolbarModel";
import ListInventoryProductsRequest from "@/models/server/requests/ListInventoryProductsRequest";
import ProductModal from "@/components/ProductModal/ProductModal";
import {ch, PageRouteProvider, TextAlign, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import ExportInventoryProductsToCsvRequest from "@/models/server/requests/ExportInventoryProductsToCsvRequest";
import Product from "@/models/server/Product";
import {ExportEncodingType} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import Localizer from "@/localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./InventoryManagement.module.scss";

interface IInventoryManagementProps {
}

interface IInventoryManagementState {
    toolbar: InventoriesToolbarModel;
    products: InventoryProduct[];
}

export default class InventoryManagement extends AuthorizedPage<IInventoryManagementProps, IInventoryManagementState> {

    state: IInventoryManagementState = {
        toolbar: this.initializeToolbar(),
        products: [],
    };

    private readonly _toolbarRef: React.RefObject<InventoriesToolbar> = React.createRef();
    private readonly _productsPanelGridRef: React.RefObject<Grid<InventoryProduct>> = React.createRef();
    private readonly _productModalRef: React.RefObject<ProductModal> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            name: "groupName",
            header: Localizer.productPanelGridProductGroupThreeLevelLanguageItemName,
            sorting: true,
            minWidth: "15rem",
            maxWidth: "15rem",
            render: (cell: CellModel<InventoryProduct>) => this.renderGroupCell(cell)
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridMediqNumberLanguageItemName,
            sorting: true,
            accessor: nameof.full<InventoryProduct>(o => o.product!.code),
            minWidth: "7,5rem",
            maxWidth: "7,5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridNameLanguageItemName,
            sorting: true,
            name: nameof.full<InventoryProduct>(o => o.product!.name),
            accessor: (model: InventoryProduct) => Product.getFullName(model.product!),
            minWidth: "25rem",
            stretch: true,
            className: styles.hasImage,
            init: (cell: CellModel<InventoryProduct>) => this.initNameCell(cell),
            callback: (cell: CellModel<InventoryProduct>) => this.onNameClickAsync(cell),
            settings: {
                descriptionAccessor: nameof.full<InventoryProduct>(o => o.productAssortment!.note),
            },
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridManufacturerCodeLanguageItemName,
            sorting: true,
            accessor: nameof.full<InventoryProduct>(o => o.product!.manufactureCode),
            minWidth: "10rem",
            maxWidth: "10rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridWholesalePackageQuantityLanguageItemName,
            title: Localizer.productPanelGridWholesalePackageQuantityTitleLanguageItemName,
            sorting: true,
            accessor: nameof.full<InventoryProduct>(o => o.product!.wholesalePackageQuantity),
            textAlign: TextAlign.Center,
            minWidth: "4rem",
            maxWidth: "4rem",
        } as ColumnDefinition,
        {
            header: Localizer.inventoryManagementGridQuantityLanguageItemName,
            sorting: true,
            accessor: (model: InventoryProduct) => (model.quantity != null) ? model.quantity.format("0.00") : "-",
            textAlign: TextAlign.Center,
            minWidth: "6rem",
            maxWidth: "6rem",
            settings: {
                infoAccessor: (model: InventoryProduct) => ((model.quantity != null) && (model.quantity > 0)) ? Localizer.inventoryManagementGridQuantityInfo.format(model.product!.packageQuantity * model.quantity) : ""
            }
        } as ColumnDefinition
    ];

    private async fetchAsync(sender: Grid<InventoryProduct>, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<InventoryProduct[]> {
        if (!this.initialized) {
            return [];
        }

        const request = new ListInventoryProductsRequest();
        request.search = this.search;
        request.inventoryId = this.inventoryId!;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.includeProductGroups = true;

        const products: InventoryProduct[] = await sender.postAsync("/api/inventoryManagement/listInventoryProducts", request);

        await this.setProductsToToolbarAsync(products);

        return products;
    }

    private initializeToolbar(): InventoriesToolbarModel {
        return UserInteractionDataStorage.get(this.toolbarCacheKey, new InventoriesToolbarModel());
    }

    private initRow(row: RowModel<InventoryProduct>): void {
        const model: InventoryProduct = row.model;

        const notSpecified: boolean = (model.quantity == null);

        row.className = this.cssIf(row.className, notSpecified, styles.notSpecified);
    }

    private initNameCell(cell: CellModel<InventoryProduct>): void {
        const model: InventoryProduct = cell.row.model;

        cell.descriptionAction!.visible = (!!model.productAssortment?.note);
        cell.descriptionReadonly = true;
    }
    
    private async onNameClickAsync(cell: CellModel<InventoryProduct>): Promise<void> {
        const model: InventoryProduct = cell.row.model;

        if (this._productModalRef.current) {
            await this._productModalRef.current.openAsync(model.product ?? model.productAssortmentId, null, null, this.customerId);
        }
    }

    private async onToolbarSubmitAsync(): Promise<void> {
        UserInteractionDataStorage.set(this.toolbarCacheKey, this.toolbar);
        await this.reloadAsync();
    }

    private async setProductsToToolbarAsync(products: InventoryProduct[]): Promise<void> {
        const toolbar: InventoriesToolbar | null = this._toolbarRef.current;
        if (toolbar) {
            await toolbar.setProductsAsync(products);
        }
    }

    private async exportInventoryProductsToCsvAsync(encodingType: ExportEncodingType): Promise<void> {
        const request = new ExportInventoryProductsToCsvRequest();
        request.encodingType = encodingType;
        request.inventoryProductIds = this.grid.data.map(item => item.id);

        const file: FileModel = await this.postAsync("/api/inventoryManagement/exportInventoryProductsToCsv", request);

        ch.download(file);
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get toolbarCacheKey(): string {
        return `inventories.toolbar`;
    }

    public get toolbar(): InventoriesToolbarModel {
        return this.state.toolbar;
    }

    public get customerId(): string | null {
        return this.toolbar.customerId;
    }

    public get inventoryId(): string | null {
        return this.toolbar.inventoryId;
    }

    public get search(): string | null {
        return this.toolbar.search;
    }

    public get initialized(): boolean {
        return InventoriesToolbarModel.initialized(this.toolbar);
    }

    public get grid(): GridModel<InventoryProduct> {
        return this._productsPanelGridRef.current!.model;
    }
    
    public getTitle(): string {
        return Localizer.inventoryManagementPage;
    }

    public renderGroupCell(cell: CellModel<InventoryProduct>): React.ReactNode {
        const product: Product = cell.model!.product!;

        const mainGroup: string = product.mainGroup ?? "";
        const subGroup: string = product.subGroup ?? "";
        const subSubGroup: string = product.subSubGroup ?? "";

        cell.className = this.css(styles.productGroup, styles.threeProductGroups)

        return (
            <div>

                {
                    (mainGroup) && (
                        <span>{mainGroup}</span>
                    )
                }
                
                {
                    (subGroup) && (
                        <span>{subGroup}</span>
                    )
                }
                
                {
                    (subSubGroup) &&
                    (
                        <span>{subSubGroup}</span>
                    )
                }
                
            </div>
        );
    }

    public render(): React.ReactNode {

        return (
            <PageContainer fullWidth fullHeight className={styles.inventoryManagement}>

                <PageHeader withTabs
                            title={this.getTitle()}
                >

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>

                <PageRow>

                    <div className="col">

                        <InventoriesToolbar ref={this._toolbarRef}
                                            model={this.toolbar}
                                            hideCustomersInSearch={(AittaController.user.isMaster)}
                                            onChange={() => this.onToolbarSubmitAsync()}
                                            exportToCsv={(sender: InventoriesToolbar, model: InventoriesToolbarModel, encodingType: ExportEncodingType) => this.exportInventoryProductsToCsvAsync(encodingType)}
                        />

                        <Grid optimization responsive
                              id={"productsPanelGrid"}
                              ref={this._productsPanelGridRef}
                              minWidth="auto"
                              hovering={GridHoveringType.Row}
                              className={styles.productsPanelGrid}
                              headerMinHeight={80}
                              odd={GridOddType.None}
                              borderType={BorderType.NoSeparators}
                              columns={this._columns}
                              noDataText={Localizer.productPanelGridNoProducts}
                              initRow={(row: RowModel<InventoryProduct>) => this.initRow(row)}
                              fetchData={(sender: Grid<InventoryProduct>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, sortColumnName, sortDirection)}
                        />

                    </div>

                </PageRow>

                <ProductModal id={"productModal"}
                              ref={this._productModalRef}
                />

            </PageContainer>
        );
    }
}