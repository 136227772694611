import {Mutex} from "async-mutex";
import {IBaseComponent, PageCacheProvider, PageCacheTtl} from "@reapptor-apps/reapptor-react-common";
import ProductGroupMobileInfo from "@/models/server/ProductGroupMobileInfo";
import ProductAssortment from "@/models/server/ProductAssortment";
import ListProductGroupsRequest from "../models/server/requests/ListProductGroupsRequest";
import ListProductGroupAssortmentsRequest from "@/models/server/requests/ListProductGroupAssortmentsRequest";
import ListProductSelectionsResponse from "@/models/server/responses/ListProductGroupMobileInfoResponse";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import ListProductGroupAssortmentsResponse from "@/models/server/responses/ListProductGroupAssortmentsResponse";
import ListProductGroupsMobileInfoRequest from "../models/server/requests/ListProductGroupsRequest";
import AittaController from "@/pages/AittaController";

export interface ICatalogDataProvider {

    // TODO: remove addedEquipment related methods (UI state handling belongs to React components)

    /**
     * Return added {@link OrderProductMobileInfo} which fulfill the search criteria.
     */
    getAddedOrderProductsAsync(sender: IBaseComponent, search?: string | null): Promise<OrderProductMobileInfo[]>;

    /**
     * Add a new {@link OrderProductMobileInfo} to order, if one does not already exist.
     */
    addOrderProductAsync(orderProduct: OrderProductMobileInfo): Promise<void>;

    /**
     * Get all {@link ProductGroupMobileInfo}s with the specified parent {@link ProductGroupMobileInfo}.
     */
    getProductGroupsAsync(sender: IBaseComponent, parentProductGroupId?: string | null): Promise<ProductGroupMobileInfo[]>;

    /**
     * Get all {@link ProductAssortment}s which belong to the specified {@link ProductGroupMobileInfo} and pass the search criteria.
     */
    getProductAssortmentsAsync(sender: IBaseComponent, productGroupId?: string | null, search?: string | null): Promise<ProductAssortment[]>;

    /**
     * Get all {@link OrderProductMobileInfo}s which belong to the specified {@link ProductGroupMobileInfo} and pass the search criteria.
     */
    getOrderProductsAsync(sender: IBaseComponent, categoryId?: string | null, search?: string | null, exactMatch?: boolean): Promise<OrderProductMobileInfo[]>;

    /**
     * Sets all {@link OrderProductMobileInfo}'s quantity to zero.
     */
    resetOrderProductsAsync(sender: IBaseComponent): Promise<void>;

    /**
     * Set a {@link Product} as a user favorite.
     */
    setFavoriteAsync(sender: IBaseComponent, productId: string, favorite: boolean): Promise<void>;

    isCategoriesOrProductsDataProvider: true;
}

export default class CatalogDataProvider implements ICatalogDataProvider {
    private _initialized: boolean = false;
    // private _productAssortments: ProductAssortment[] = [];
    private readonly _orderProducts: OrderProductMobileInfo[] = [];
    private readonly _customerId: string = "";
    private readonly _customerGroupId: string = "";
    private readonly _allProductGroupsLock: Mutex = new Mutex();
    private readonly _favorite: ProductGroupMobileInfo = ProductGroupMobileInfo.createFavorite();
    private readonly _assumed: ProductGroupMobileInfo = ProductGroupMobileInfo.createAssumed();
    private readonly _top30: ProductGroupMobileInfo = ProductGroupMobileInfo.createTop30();
    private readonly _includeFavoriteProductGroup: boolean;
    private readonly _includeAssumedProductGroup: boolean;
    private readonly _includeTop30ProductGroup: boolean;
    private _allProductGroups: ProductGroupMobileInfo[] | null = null;

    protected async initializeAsync(sender: IBaseComponent): Promise<void> {
        if (!this._initialized) {
            this._initialized = true;
        }
    }

    protected static productAssortmentToOrderProduct(productAssortment: ProductAssortment): OrderProductMobileInfo {
        const orderProduct = new OrderProductMobileInfo();

        orderProduct.productAssortment = productAssortment;
        orderProduct.productAssortmentId = productAssortment.id;
        orderProduct.productId = productAssortment.productId;
        orderProduct.toProductReplacementIds = productAssortment.toProductReplacementIds;
        orderProduct.productReplacementId = productAssortment.fromProductReplacementId;

        return orderProduct;
    }

    constructor(customerGroupId: string, customerId: string, orderProducts: OrderProductMobileInfo[], includeFavoriteProductGroup: boolean, includeAssumedProductGroup: boolean, includeTop30ProductGroup: boolean) {
        this._customerId = customerId;
        this._customerGroupId = customerGroupId;
        this._orderProducts = orderProducts;
        this._includeFavoriteProductGroup = includeFavoriteProductGroup;
        this._includeAssumedProductGroup = includeAssumedProductGroup;
        this._includeTop30ProductGroup = includeTop30ProductGroup;
    }

    public async addOrderProductAsync(orderProduct: OrderProductMobileInfo): Promise<void> {
    }

    public async getAddedOrderProductsAsync(sender: IBaseComponent, search?: string | null): Promise<OrderProductMobileInfo[]> {
        await this.initializeAsync(sender)

        let orderProducts: OrderProductMobileInfo[] = this._orderProducts;
        orderProducts = orderProducts.where((orderProduct: OrderProductMobileInfo) => (orderProduct.quantity > 0));

        if (search) {
            search = search.toLowerCase();
            orderProducts = orderProducts.where(item => this.isMatch(item, search!));
        }

        return orderProducts;
    }

    public isMatch(item: OrderProductMobileInfo, search: string): boolean {
        return (!!item.productAssortment?.product?.name?.toLowerCase().includes(search)) ||
            (!!item.productAssortment?.product?.code?.toLowerCase().includes(search));
    }

    // Groups

    private async fetchProductGroupsAsync(sender: IBaseComponent, request: ListProductGroupsRequest): Promise<ProductGroupMobileInfo[]> {
        const key: string = `listMobileProductGroups:customerId:${request.customerId};customerGroupId:${request.customerGroupId}`;

        return PageCacheProvider.getAsync(key, async () => {
            const response: ListProductSelectionsResponse = await sender.postAsync("/api/mobileApp/listMobileProductGroups", request);

            ListProductSelectionsResponse.restore(response);

            return response.productGroups ?? [];
        }, PageCacheTtl._1h);
    }

    private async listProductGroupsAsync(sender: IBaseComponent, customerGroupId: string, customerId: string): Promise<ProductGroupMobileInfo[]> {
        return await this._allProductGroupsLock.runExclusive(async () => {

            if ((this._allProductGroups == null) || (this._allProductGroups.length == 0)) {
                const request = new ListProductGroupsMobileInfoRequest();
                request.customerGroupId = customerGroupId;
                request.customerId = customerId;

                this._allProductGroups = await this.fetchProductGroupsAsync(sender, request);
            }

            return this._allProductGroups!;
        });
    }

    public async getProductGroupsAsync(sender: IBaseComponent, parentProductGroupId?: string | null): Promise<ProductGroupMobileInfo[]> {
        await this.initializeAsync(sender);

        const allProductGroups: ProductGroupMobileInfo[] = await this.listProductGroupsAsync(sender, this.customerGroupId, this.customerId);

        const favoriteGroup: boolean = (parentProductGroupId === this._favorite.id);
        const assumedGroup: boolean = (parentProductGroupId === this._assumed.id);
        const top30Group: boolean = (parentProductGroupId === this._top30.id);

        if (favoriteGroup || assumedGroup || top30Group) {
            return [];
        }

        const needAllProductGroups: boolean = (parentProductGroupId === undefined);
        const needRootGroups: boolean = (!needAllProductGroups) && (parentProductGroupId === null);

        const productGroups: ProductGroupMobileInfo[] = ((needAllProductGroups)
            ? allProductGroups
            : allProductGroups.where(group => group.parentId == parentProductGroupId))
            .sort((a: ProductGroupMobileInfo, b: ProductGroupMobileInfo) => a.name.localeCompare(b.name));

        let result: ProductGroupMobileInfo[] = ((this._includeFavoriteProductGroup) && ((needRootGroups) || (needAllProductGroups)))
            ? [this._favorite, ...productGroups]
            : productGroups;

        result = (this._includeTop30ProductGroup && ((needRootGroups) || (needAllProductGroups)))
            ? [this._top30, ...result]
            : result;

        return ((this._includeAssumedProductGroup) && ((needRootGroups) || (needAllProductGroups)))
            ? [this._assumed, ...result]
            : result;
    }

    // Order Products

    public async getProductAssortmentsAsync(sender: IBaseComponent, productGroupId?: string | null, search?: string | null): Promise<ProductAssortment[]> {
        await this.initializeAsync(sender);

        const favoriteGroup: boolean = (productGroupId === this._favorite.id);
        const assumedGroup: boolean = (productGroupId === this._assumed.id);

        const request = new ListProductGroupAssortmentsRequest();
        request.productGroupId = (!favoriteGroup && !assumedGroup) ? productGroupId || null : null;
        request.customerId = this.customerId;
        request.favorite = favoriteGroup;
        request.search = search || null;
        request.assumed = assumedGroup;

        const response: ListProductGroupAssortmentsResponse = await sender.postAsync("/api/mobileApp/listProductGroupAssortments", request);

        return response.productAssortments ?? [];
    }

    public async getOrderProductsAsync(sender: IBaseComponent, productGroupId?: string | null, search?: string | null, exactMatch: boolean = false): Promise<OrderProductMobileInfo[]> {
        await this.initializeAsync(sender);

        const favoriteGroup: boolean = (productGroupId === this._favorite.id);
        const assumedGroup: boolean = (productGroupId === this._assumed.id);
        const top30Group: boolean = (productGroupId === this._top30.id);

        const request = new ListProductGroupAssortmentsRequest();
        request.productGroupId = (!favoriteGroup && !assumedGroup && !top30Group) ? productGroupId || null : null;
        request.customerId = this.customerId;
        request.customerGroupId = this.customerGroupId;
        request.favorite = favoriteGroup;
        request.assumed = assumedGroup;
        request.top30 = top30Group;
        request.search = search || null;
        request.exactMatch = exactMatch;

        const response: ListProductGroupAssortmentsResponse = await sender.postAsync("/api/mobileApp/listProductGroupAssortments", request);

        const productAssortments: ProductAssortment[] = response.productAssortments ?? [];

        const orderProducts: OrderProductMobileInfo[] = this._orderProducts;

        // create order products for all products, unless if in initial equipment
        const items: OrderProductMobileInfo[] = [];
        productAssortments.forEach((productAssortment: ProductAssortment) => {
            const initialOrderProductItem: OrderProductMobileInfo | undefined = orderProducts.find((orderProduct: OrderProductMobileInfo) => orderProduct.productAssortmentId === productAssortment.id);
            if (initialOrderProductItem) {
                initialOrderProductItem.productAssortment = productAssortment;
                items.push(initialOrderProductItem);
            } else {
                const orderProduct: OrderProductMobileInfo = CatalogDataProvider.productAssortmentToOrderProduct(productAssortment);
                orderProducts.push(orderProduct);
                items.push(orderProduct);
            }
        });

        return items;
    }

    public async resetOrderProductsAsync(sender: IBaseComponent): Promise<void> {
        let orderProducts: OrderProductMobileInfo[] = this._orderProducts;
        orderProducts = orderProducts.where((orderProduct: OrderProductMobileInfo) => (orderProduct.quantity > 0));

        orderProducts.map(item => item.quantity = 0);
    }

    // Favorites

    public async setFavoriteAsync(sender: IBaseComponent, productId: string, favorite: boolean): Promise<void> {
        await this.initializeAsync(sender);

        AittaController.setFavorite(productId, favorite);
    }

    // Other

    public get customerGroupId(): string {
        return this._customerGroupId;
    }

    public get customerId(): string {
        return this._customerId;
    }

    public get isCategoriesOrProductsDataProvider(): true {
        return true;
    }

    public get orderProducts(): OrderProductMobileInfo[] {
        return this._orderProducts;
    }

    public get orderProductsCount(): number {
        return this.orderProducts.count(item => (item.quantity != null) && (item.quantity > 0));
    }
}
