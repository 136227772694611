// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {ProductType} from "@/models/Enums";
import ProductGroup from "@/models/server/ProductGroup";
import ProductAttribute from "@/models/server/ProductAttribute";
import ProductAccessory from "@/models/server/ProductAccessory";
import AittaConstants from "@/helpers/AittaConstants";
import Localizer from "@/localization/Localizer";

export default class Product {

    public id: string = "";

    public productGroupId: string | null = null;

    public productGroup: ProductGroup | null = null;

    public name: string = "";

    public code: string = "";

    public e6Code: string | null = null;

    public manufactureCode: string | null = null;

    public gtinCode: string | null = null;

    public salesUnit: string | null = null;

    public size: string | null = null;

    public unitOfMeasure: string | null = null;

    public packageQuantity: number = 0;

    public wholesalePackageQuantity: number = 0;

    public type: ProductType = ProductType.Unknown;

    public priority: number = 0;

    public favorite: boolean = false;

    public comment: string | null = null;

    public mainGroup: string | null = null;

    public subGroup: string | null = null;

    public subSubGroup: string | null = null;

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public images: FileModel[] | null = null;

    public attributes: ProductAttribute[] | null = null;

    public accessories: ProductAccessory[] | null = null;

    public externalCreatedAt: Date = new Date();

    public externalModifiedAt: Date = new Date();

    public readonly isProduct: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Product) || ((from as any).isProduct === true));
    }

    public static as(from?: object | null): Product | null {
        return (Product.is(from)) ? from as Product : null;
    }

    // #region Placeholder

    public static getProductOfUnit(product: Product): string {
        const unitOfMeasure: string = product.unitOfMeasure || "";
        const code: string = `UnitOfMeasure.${unitOfMeasure}`;
        return Localizer.contains(code)
            ? Localizer.get(code)
            : unitOfMeasure;
    }

    public static getSalesUnit(product: Product): string {
        const unitOfMeasure: string = this.getProductOfUnit(product);
        return ((unitOfMeasure) && (product.packageQuantity))
            ? `${product.packageQuantity} ${unitOfMeasure}`
            : product.salesUnit || "";
    }

    public static getFullName(product: Product): string {
        const salesUnit: string = this.getSalesUnit(product);
        return (salesUnit)
            ? "{0} <mark>{1}</mark>".format(product.name, salesUnit)
            : product.name;
    }

    public static getImage(product: Product): FileModel | null {
        return ((product.images) && (product.images.length > 0))
            ? product.images[0]
            : null;
    }

    public static getTitle(product: Product): string {
        return "{0} {1}".format(product.code, product.name);
    }

    public static getSubtitle(product: Product): string {
        return product.productGroup?.parent?.name ?? product.manufactureCode ?? "";
    }

    public static getName(product: Product): string {
        return product.name.trim();
    }

    public static getSalesUnitAndSize(product: Product): string {
        const salesUnit: string = this.getSalesUnit(product);
        const size: string = product.size || "";

        let productName: string = (salesUnit)
            ? "<mark>{0}</mark>".format(salesUnit)
            : "";

        productName = (!!size)
            ? (size.length > AittaConstants.maxProductSizeLength)
                ? "{0}, <mark>{1}</mark>...".format(productName, size.substring(0, AittaConstants.maxProductSizeLength).trim())
                : "{0}, <mark>{1}</mark>".format(productName, size)
            : productName;

        return productName;
    }

    public static hasData(product: Product | null): boolean {
        return (product != null) && (product.images != null) && (product.attributes != null) && (product.accessories != null);
    }

    public static getLowerCase(product: Product, propertyName: keyof Product): string {
        const key: string = "__" + propertyName;
        const container = product as any;
        let value: string | undefined | null = container[key];
        if (value == null) {
            value = container[propertyName];
            value = (value != null) ? value.toLowerCase() : "";
            container[key] = value;
        }
        return value;
    }

    public static searchPredicate(product: Product | null, value: string): boolean {
        return (
            (product != null) &&
            (
                (this.getLowerCase(product, "name").includes(value)) ||
                (this.getLowerCase(product, "code").startsWith(value)) ||
                (this.getLowerCase(product, "e6Code").startsWith(value)) ||
                (this.getLowerCase(product, "manufactureCode").startsWith(value))
            )
        );
    }

    public static getAttributes(product: Product | null): ProductAttribute[] {
        let attributes: ProductAttribute[] = product?.attributes ?? [];
        attributes = attributes.where(item => !item.key.startsWith("Raw"));
        return attributes;
    }

    // #endregion
}