import React from "react";
import {BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Checkbox, DateInput, Dropdown, DropdownOrderBy, Form, IconSize, InlineType, OneColumn, PageContainer, PageHeader, PageRow, TextAreaInput, ThreeColumns} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import Banner from "@/models/server/Banner";
import ListBannersRequest from "@/models/server/requests/ListBannersRequest";
import ListBannersResponse from "@/models/server/responses/ListBannersResponse";
import BannerMessage from "@/models/server/BannerMessage";
import AittaConstants from "@/helpers/AittaConstants";
import Localization from "@/models/server/Localization";
import {LocalizationLanguage} from "@/models/Enums";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "../../localization/Localizer";

import styles from "./BannerManagement.module.scss";

export interface IBannerManagementProps extends BasePageParameters {
}

interface IBannerManagementState {
    banners: Banner[];
    banner: Banner | null;
    newBanner: Banner,
}

export default class BannerManagement extends AuthorizedPage<IBannerManagementProps, IBannerManagementState> {
    
    state: IBannerManagementState = {
        banners: [],
        banner: null,
        newBanner: Banner.create(),
    };
    
    private async selectAsync(banner: Banner): Promise<void> {
        await this.setState({ banner });
    }
    
    private async setTextAsync(banner: Banner, language: LocalizationLanguage, value: string): Promise<void> {
        const text: Localization = banner.text ?? (banner.text = new Localization());
        Localization.setLocalizedItem(text, language, value);
        
        await this.reRenderAsync();
    }
    
    private async setShowOnceAsync(banner: Banner, showOnce: boolean): Promise<void> {
        banner.showOnce = showOnce;
        
        await this.reRenderAsync();
    }
    
    private async setDismissibleAsync(banner: Banner, dismissible: boolean): Promise<void> {
        banner.dismissible = dismissible;
        
        await this.reRenderAsync();
    }
    
    private async setRoleNamesAsync(banner: Banner, roleNames: string[]): Promise<void> {
        banner.roleNames = roleNames;
        
        await this.reRenderAsync();
    }
    
    private async setPageNamesAsync(banner: Banner, pageNames: string[]): Promise<void> {
        banner.pageNames = pageNames;
        
        await this.reRenderAsync();
    }
    
    private async setActivateAtAsync(banner: Banner, date: Date | null): Promise<void> {
        banner.activateAt = date;
        
        await this.reRenderAsync();
    }
    
    private async setDeactivateAtAsync(banner: Banner, date: Date | null): Promise<void> {
        banner.deactivateAt = date;
        
        await this.reRenderAsync();
    }
    
    private async activateAsync(banner: Banner, active: boolean): Promise<void> {
        banner.active = active;
        
        if (active) {
            banner.activateAt = null;
        } else {
            banner.deactivateAt = null;
        }
        
        await this.reRenderAsync();
    }
    
    private async cancelAsync(banner: Banner): Promise<void> {
        const banners: Banner[] = this.banners;

        const index: number = banners.indexOf(banner);
        
        const isNew: boolean = (this.newBanner == banner);
        
        if (isNew) {
            banner = Banner.create();

            this.state.newBanner = banner;
        } else {
            banner = await this.postAsync("/api/banner/getBanner", banner.id);
        }

        banners[index] = banner;

        this.state.banner = banner;

        await this.reRenderAsync();
    }
    
    private async deleteBannerAsync(banner: Banner): Promise<void> {
        const banners: Banner[] = this.banners;

        const index: number = this.banners.indexOf(banner);

        await this.postAsync("/api/banner/deleteBanner", banner.id);

        banners.removeAt(index);
        
        banner = banners.first();
        
        this.state.banner = banner;

        await this.reRenderAsync();
        
        await this.alertMessageAsync(Localizer.bannerManagementPageAlertDeleted, true, true);
    }
    
    private async saveAsync(banner: Banner): Promise<void> {
        const banners: Banner[] = this.banners;
        
        const isNew: boolean = (this.newBanner == banner);
        
        const index: number = banners.indexOf(banner);
        
        banner = await this.postAsync("/api/banner/saveBanner", banner);

        banners[index] = banner;
        
        this.state.banner = banner;
        
        if (isNew) {
            this.state.newBanner = Banner.create();
            banners.insert(this.state.newBanner);
        }
        
        await this.reRenderAsync();

        await this.alertMessageAsync(Localizer.bannerManagementPageAlertSaved, true, true);
    }
     
    public async initializeAsync(): Promise<void> {
        const request = new ListBannersRequest();
        const response: ListBannersResponse = await this.postAsync("/api/banner/listBanners", request);

        const banners: Banner[] = [...response.banners ?? []];
        
        let banner: Banner | null = banners.firstOrDefault();

        banners.insert(this.newBanner);

        banner = banner ?? this.newBanner;

        await this.setState({banners, banner});
    }

    public getTitle(): string {
        return Localizer.bannerManagementPageTitle;
    }

    public getSubTitle(): string {
        return (this.banner)
            ? (this.banner == this.newBanner)
                ? Localizer.bannerManagementPageSubtitleNewBanner
                : this.getHeader(this.banner)
            : Localizer.genericLoading;
    }
    
    public get banners(): Banner[] {
        return this.state.banners;
    }

    public get newBanner(): Banner {
        return this.state.newBanner;
    }
    
    public get banner(): Banner | null {
        return this.state.banner;
    }
    
    public get allUserRoles(): string[] {
        return [
            AittaConstants.adminRole,
            AittaConstants.masterRole,
            AittaConstants.managerRole,
            AittaConstants.shelverRole,
            AittaConstants.shelverPartnerRole,
        ];
    }
    
    public get allPageNames(): string[] {
        return [
            PageDefinitions.homeRouteName,
            PageDefinitions.loginRouteName,
            PageDefinitions.dashboardRouteName,
            PageDefinitions.orderManagementRouteName,
            PageDefinitions.mobileDashboardRouteName,
            PageDefinitions.mobileRegularOrderRouteName,
            PageDefinitions.mobileExpressOrderRouteName,
        ];
    }
    
    private getHeader(banner: Banner): string {
        let header: string = BannerMessage.getMessage(banner) || Localizer.bannerManagementPageNewBanner;
        
        if (header.length > 40) {
            header = header.substring(0, 40) + "...";
        }
        
        return header;
    }

    private renderBanner(banner: Banner): React.ReactNode {
        const isNew: boolean = (banner == this.newBanner);
        const isSelected: boolean = (banner == this.banner);
        const active: boolean = banner.active;
        const header: string = this.getHeader(banner);

        return (
            <div key={banner.id}
                 className={this.css(styles.banner, isNew && styles.new, isSelected && styles.selected, active && styles.active)}
                 onClick={() => this.selectAsync(banner)}
            >

                <div className={styles.header}>{header}</div>

                <div className={styles.settings}>
                    <span>
                        {
                            (banner.active)
                                ? Localizer.bannerManagementPageVisible
                                : Localizer.bannerManagementPageHidden
                        }
                    </span>
                    <span>
                        {
                            (banner.dismissible)
                                ? Localizer.bannerManagementPageDismissible
                                : Localizer.bannerManagementPageUnclosable
                        }
                    </span>
                    <span>
                        {
                            (banner.showOnce)
                                ? Localizer.bannerManagementPageOneTime
                                : Localizer.bannerManagementPagePermanent
                        }
                    </span>
                </div>

                <div className={styles.names}>
                    <span>
                        {
                            (banner.roleNames.length > 0)
                                ? Localizer.bannerManagementPageRoles.format(banner.roleNames.length)
                                : Localizer.bannerManagementPageAnyRole
                        }
                    </span>
                    <span>
                        {
                            (banner.pageNames.length > 0)
                                ? Localizer.bannerManagementPagePages.format(banner.pageNames.length)
                                : Localizer.bannerManagementPageAnyPage
                        }
                    </span>
                </div>

                <div className={styles.activation}>
                    <span>{(banner.activateAt ? banner.activateAt.format("D") : "*")}</span>
                    <span>/</span>
                    <span>{(banner.deactivateAt ? banner.deactivateAt.format("D") : "*")}</span>
                </div>

            </div>
        )
    }
    
    private renderBanners(): React.ReactNode {
        const banners: Banner[] = this.banners;
        
        return (
            <div className={styles.banners}>

                { banners.map((banner) => this.renderBanner(banner)) }

            </div>
        )
    }
    
    private renderBannerForm(): React.ReactNode {
        const banner: Banner | null = this.banner;
        const isNew: boolean = (banner == this.newBanner);
        
        return (
            <div className={styles.bannerForm}>

                {
                    (banner) &&
                    (
                        <Form onSubmit={() => this.saveAsync(banner)}>

                            <OneColumn>
                                
                                <TextAreaInput required autoFocus
                                               id={"text_finnish"}
                                               rows={3}
                                               className={styles.text}
                                               maxLength={AittaConstants.keyLength}
                                               label={Localizer.bannerManagementPageTextFi}
                                               value={banner.text!.finnish}
                                               onChange={(_, value) => this.setTextAsync(banner, LocalizationLanguage.Finnish, value)}
                                />

                            </OneColumn>

                            <OneColumn>
                                
                                <TextAreaInput id={"text_english"}
                                               rows={3}
                                               className={styles.text}
                                               maxLength={AittaConstants.keyLength}
                                               label={Localizer.bannerManagementPageTextEn}
                                               value={banner.text!.english}
                                               onChange={(_, value) => this.setTextAsync(banner, LocalizationLanguage.English, value)}
                                />

                            </OneColumn>

                            <OneColumn>
                                
                                <TextAreaInput id={"text_swedish"}
                                               rows={3}
                                               className={styles.text}
                                               maxLength={AittaConstants.keyLength}
                                               label={Localizer.bannerManagementPageTextSe}
                                               value={banner.text!.swedish}
                                               onChange={(_, value) => this.setTextAsync(banner, LocalizationLanguage.Swedish, value)}
                                />

                            </OneColumn>
                            
                            <ThreeColumns className={styles.settingsPanel}>
                                
                                <Checkbox id={"showOnce"} inline
                                          inlineType={InlineType.Right}
                                          label={Localizer.bannerManagementPageShowOnceLabel}
                                          value={banner.showOnce}
                                          onChange={(_, value) => this.setShowOnceAsync(banner, value)}
                                />

                                <Checkbox id={"dismissible"} inline
                                          inlineType={InlineType.Right}
                                          label={Localizer.bannerManagementPageDismissibleLabel}
                                          value={banner.dismissible}
                                          onChange={(_, value) => this.setDismissibleAsync(banner, value)}
                                />
                                
                            </ThreeColumns>
                            
                            <div className={styles.namesPanel}>
                                
                                <Dropdown multiple clearButton autoCollapse
                                          id={"roleNames"}
                                          label={Localizer.bannerManagementPageRolesLabel}
                                          orderBy={DropdownOrderBy.None}
                                          nothingSelectedText={Localizer.bannerManagementPageAnyRole}
                                          selectedTextFormat={2}
                                          items={this.allUserRoles}
                                          selectedItems={banner.roleNames}
                                          onChange={(sender) => this.setRoleNamesAsync(banner, sender.selectedItems)}
                                />
                                
                                <Dropdown multiple clearButton autoCollapse
                                          id={"pageNames"}
                                          label={Localizer.bannerManagementPagePagesLabel}
                                          orderBy={DropdownOrderBy.None}
                                          nothingSelectedText={Localizer.bannerManagementPageAnyPage}
                                          selectedTextFormat={1}
                                          items={this.allPageNames}
                                          selectedItems={banner.pageNames}
                                          onChange={(sender) => this.setPageNamesAsync(banner, sender.selectedItems)}
                                />
                                
                            </div>
                            
                            <div className={styles.activationPanel}>
                                
                                <DateInput clearButton 
                                           id={"activateAt"}
                                           label={Localizer.bannerManagementPageActivateAtLabel}
                                           readonly={banner.active}
                                           minDate={Utility.today()}
                                           value={banner.activateAt}
                                           onChange={(value) => this.setActivateAtAsync(banner, value)}
                                />
                                
                                <DateInput clearButton 
                                           id={"deactivateAt"}
                                           label={Localizer.bannerManagementPageDeactivateAtLabel}
                                           minDate={(banner.activateAt ?? Utility.today()).addDays(1)}
                                           value={banner.deactivateAt}
                                           onChange={(value) => this.setDeactivateAtAsync(banner, value)}
                                />

                                {
                                    (!banner.active) &&
                                    (
                                        <Button label={Localizer.genericActivate}
                                                type={ButtonType.Blue}
                                                icon={{name: "far play"}}
                                                onClick={() => this.activateAsync(banner, true)}
                                        />
                                    )
                                }

                                {
                                    (banner.active) &&
                                    (
                                        <Button label={Localizer.genericDeactivate}
                                                type={ButtonType.Blue}
                                                icon={{name: "far pause"}}
                                                onClick={() => this.activateAsync(banner, false)}
                                        />
                                    )
                                }
                                
                            </div>
                            
                            <ButtonContainer className={styles.buttonsPanel}>

                                <Button label={Localizer.genericCancel}
                                        type={ButtonType.Primary}
                                        icon={{name: "far ban", size: IconSize.Large}}
                                        confirm={Localizer.bannerManagementPageConfirmCancel}
                                        onClick={() => this.cancelAsync(banner)}
                                />

                                {
                                    (!isNew) &&
                                    (
                                        <Button label={Localizer.genericDelete}
                                                icon={{name: "trash", size: IconSize.Large}}
                                                type={ButtonType.Primary}
                                                confirm={Localizer.bannerManagementPageConfirmDelete}
                                                onClick={() => this.deleteBannerAsync(banner)}
                                        />
                                    )
                                }

                                <Button submit right
                                        label={Localizer.genericSave}
                                        type={ButtonType.Orange}
                                        icon={{name: "save"}}
                                />

                            </ButtonContainer>

                        </Form>
                    )
                }
                
            </div>
        )
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.bannerManagement}>
                
                <PageHeader title={this.getTitle()}
                            subtitle={this.getSubTitle()}
                />
                
                <PageRow>
                    
                    <div className={styles.container}>

                        { this.renderBanners() }
                        
                        { this.renderBannerForm() }
                        
                    </div>
                    
                </PageRow>
                
            </PageContainer>
        );
    }
}