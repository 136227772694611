import React from "react";
import {Button, ButtonType, Icon, Layout, PageContainer, Scroller, TextInput} from "@reapptor-apps/reapptor-react-components";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import ListProductLabelOrdersResponse from "@/models/server/responses/ListProductLabelOrdersResponse";
import ListProductLabelOrdersRequest from "@/models/server/requests/ListProductLabelOrdersRequest";
import ProductAssortmentCard from "@/components/ProductAssortmentCard/ProductAssortmentCard";
import ProductAssortmentMobileInfo from "@/models/server/ProductAssortmentMobileInfo";
import ScanQrCodeModal from "@/components/Catalog/ScanQrCodeModal/ScanQrCodeModal";
import CreateProductLabelsRequest from "@/models/server/requests/CreateProductLabelsRequest";
import Customer from "@/models/server/Customer";
import {IConfirmation, IManualProps, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Product from "@/models/server/Product";
import Localizer from "@/localization/Localizer";

import styles from "./OrderLabels.module.scss";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";

const VISIBLE_DEFAULT: number = 100;

export interface IOrderLabelsProps {
}

interface IOrderLabelsState {
    productAssortment: ProductAssortmentMobileInfo[];
    filteredProductAssortments: ProductAssortmentMobileInfo[];
    displayedProductAssortments: ProductAssortmentMobileInfo[];
    search: string | null;
    visible: number;
}

export default class OrderLabels extends MobileAuthorizedPage<IOrderLabelsProps, IOrderLabelsState> {

    state: IOrderLabelsState = {
        productAssortment: [],
        filteredProductAssortments: [],
        displayedProductAssortments: [],
        search: null,
        visible: VISIBLE_DEFAULT
    };

    private readonly _qrModalRef: React.RefObject<ScanQrCodeModal> = React.createRef();
    private readonly _qrScrollerRef: React.RefObject<Scroller> = React.createRef();

    private getDisplayedProductAssortments(assortments: ProductAssortmentMobileInfo[], visible: number): ProductAssortmentMobileInfo[] {
        return (assortments.length > visible)
            ? assortments.take(visible)
            : assortments;
    }

    private async fetchProductLabelOrdersAsync(): Promise<void> {
        const request = new ListProductLabelOrdersRequest();

        request.customerId = this.customer.id;
        request.search = this.search;

        const productLabelOrdersResponse: ListProductLabelOrdersResponse = await this.postAsync("/api/mobileApp/listProductLabelSelections", request)

        const assortments: ProductAssortmentMobileInfo[] = productLabelOrdersResponse.productAssortments ?? [];
        
        const displayedProductAssortments: ProductAssortmentMobileInfo[] = this.getDisplayedProductAssortments(assortments, this.state.visible);
        
        await this.setState({productAssortment: assortments, filteredProductAssortments: assortments, displayedProductAssortments});
    }

    private getConfirmation(): IConfirmation {
        const minLength: number = 10;

        return {
            title: Localizer.mobileOrderLabelsPagePrintAllSure.format(this.labelsReadyForPrint.length),
            comment: true,
            minLength: minLength,
            placeholder: Localizer.mobileOrderLabelsPageConfirmPrintAll.format(minLength)
        } as IConfirmation;
    }

    private async createLabelsAsync(items: ProductAssortmentMobileInfo[], comment: string | null = null): Promise<void> {
        const request = new CreateProductLabelsRequest();

        const ids: string[] = items.map(item => item.id);
        request.productAssortmentIds = [...ids];
        request.comment = comment;

        await this.postAsync("/api/mobileApp/createProductLabels", request);

        items.map(item => item.hasOpenLabel = true);

        await this.reRenderAsync();
    }

    private async onSearchValueChangedAsync(value: string): Promise<void> {

        this._qrScrollerRef.current!.scrollTop();

        const visible: number = VISIBLE_DEFAULT;

        if (this.state.visible != visible) {
            await this.setState({visible});
        }

        value = value.toLowerCase();

        const assortments: ProductAssortmentMobileInfo[] = (value.length == 0)
            ? this.productAssortments
            : this.productAssortments.where(item => Product.searchPredicate(item.product, value));

        const displayedProductAssortments: ProductAssortmentMobileInfo[] = this.getDisplayedProductAssortments(assortments, visible);

        await this.setState({search: value, filteredProductAssortments: assortments, displayedProductAssortments});
    }
    
    private async showMoreAsync(): Promise<void> {
        const visible: number = this.state.visible + VISIBLE_DEFAULT;

        const displayedProductAssortments: ProductAssortmentMobileInfo[] = this.getDisplayedProductAssortments(this.state.filteredProductAssortments, visible);

        await this.setState({visible, displayedProductAssortments});
    }

    private async onQrAsync(code: string): Promise<void> {
        await this.qrModal.closeAsync();

        await this.onSearchValueChangedAsync(code);
    }

    private get qrModal(): ScanQrCodeModal {
        return this._qrModalRef.current!;
    }

    private get search(): string | null {
        return this.state.search;
    }

    private get searchResult(): ProductAssortmentMobileInfo[] {
        return this.state.displayedProductAssortments;
    }

    private get noItemsText(): string {
        const isSearch: boolean = (this.search != null) && (this.search.length > 0);
        return (isSearch)
            ? Localizer.mobileOrderLabelsPageProductsNotFound
            : Localizer.mobileOrderLabelsPageNoProducts;
    }

    private get productAssortments(): ProductAssortmentMobileInfo[] {
        return this.state.productAssortment;
    }

    private get labelsReadyForPrint(): ProductAssortmentMobileInfo[] {
        return this.state.productAssortment.where(item => !item.hasOpenLabel);
    }
    
    private async onContainerScrollAsync(position: number, height: number): Promise<void> {
        if (position > 0.75 * height) {
            await this.showMoreAsync();
        }
    }

    public override async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        //this.subscribeLayoutEvents();

        await this.fetchProductLabelOrdersAsync();
    }
    
    // public override async componentWillUnmount(): Promise<void> {
    //     await super.componentWillUnmount();
    //
    //     this.unSubscribeLayoutEvents();
    // }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileOrderLabelsPageInfo.toUpperCase(),
            render: () => this.renderManual(),
        };
    }

    public renderManual(): React.ReactNode {
        const selectedCustomer: CustomerMobileInfo = this.customer;
        
        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(selectedCustomer.codeInfo)} </mark> - {selectedCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{this.customer?.customerGroupName}</b></span>

                <span>{Localizer.genericAddress}: <b>{Customer.deliveryAddress(this.customer)}</b></span>

                <span>{Localizer.mobileOrderLabelsPageInfoTotalNumberProducts}: <b>{this.productAssortments.length}</b></span>

                <span>{Localizer.mobileOrderLabelsPageInfoAvailableForPrinting}: <b>{this.labelsReadyForPrint.length}</b></span>

                <span>{Localizer.mobileOrderLabelsPageInfoInPrinting}: <b>{this.productAssortments.length - this.labelsReadyForPrint.length}</b></span>

            </div>
        );
    }

    public renderFooter(sender: ProductAssortmentCard): React.ReactNode {
        const productAssortment: ProductAssortmentMobileInfo = sender.productAssortment;

        const renderOrderButton: boolean = !(productAssortment.hasOpenLabel);

        return (
            <div className={styles.orderButton}>
                {
                    (renderOrderButton)
                        ?
                        <Button id={"createLabel"}
                                key={sender.productAssortment.id}
                                label={Localizer.mobileOrderLabelsPagePrintLabel}
                                type={ButtonType.Primary}
                                onClick={() => this.createLabelsAsync([productAssortment])}
                        />
                        :
                        <span className={styles.labelRequested}>{Localizer.mobileOrderLabelsPageRequested}</span>
                }
            </div>
        );
    }

    private renderSearchInput(): React.ReactNode {
        return (
            <div className={this.css(styles.searchBar)}>
                <TextInput clearButton noAutoComplete
                           placeholder={Localizer.mobileOrderDetailsPageSearchCriteria}
                           id={"catalog_search_product"}
                           className={this.css(styles.searchBarInput)}
                           append={<Icon name="far search"/>}
                           value={this.search}
                           onChange={(sender, value) => this.onSearchValueChangedAsync(value)}
                />
            </div>
        );
    }
    
    private renderProductAssortmentCard(productAssortment: ProductAssortmentMobileInfo): React.ReactNode {
        return (
            <div key={productAssortment.id} className={this.css(styles.cardContainer)}>
                <ProductAssortmentCard showOrderQuantity
                                       className={styles.card}
                                       productAssortment={productAssortment}
                                       renderFooter={(sender: ProductAssortmentCard) => this.renderFooter(sender)}
                />
            </div>
        )
    }

    public render(): React.ReactNode {
        const searchResult: ProductAssortmentMobileInfo[] = this.searchResult;

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.orderLabels, this.mobile && styles.mobile)}
            >
                <div className={this.css(styles.stickyHeader)}>
                    
                    {
                        this.renderSearchInput()
                    }

                    <div className={styles.buttons}>

                        <Button id={"scan"}
                                type={ButtonType.Primary}
                                label={Localizer.mobileOrderLabelsPageQR}
                                icon={{name: "fas fa-qrcode"}}
                                onClick={() => this.qrModal.openAsync()}
                        />

                        <Button id={"requestPrintAll"}
                                type={ButtonType.Primary}
                                label={Localizer.mobileOrderLabelsPagePrintAllButtonLabel.format(this.labelsReadyForPrint.length)}
                                onClick={(_, comment) => this.createLabelsAsync(this.labelsReadyForPrint, comment)}
                                confirm={this.getConfirmation()}
                        />

                    </div>

                </div>

                {
                    searchResult.map((item: ProductAssortmentMobileInfo) => this.renderProductAssortmentCard(item))
                }

                {
                    (searchResult.length == 0) &&
                    (
                        <div className={this.css(styles.cardContainer, styles.customItem, styles.noItems)}>
                            {this.noItemsText}
                        </div>
                    )
                }

                {
                    (this.state.filteredProductAssortments.length > this.state.visible) &&
                    (
                        <div className={this.css(styles.cardContainer, styles.customItem, styles.showMore)} onClick={() => this.showMoreAsync()}>
                            {Localizer.mobileOrderLabelsPageShowMore}
                        </div>
                    )
                }

                <ScanQrCodeModal ref={this._qrModalRef}
                                 onQr={(sender, code: string) => this.onQrAsync(code)}
                />
                
                <Layout.Scroller ref={this._qrScrollerRef}
                                 onScroll={(_, position: number, height: number) => this.onContainerScrollAsync(position, height)}
                />

            </PageContainer>
        );
    }
}