// autogenerated
import {IIconProps, AddressHelper} from "@reapptor-apps/reapptor-react-components";
import {CalendarDateMark, OrderStatus} from "@/models/Enums";
import Customer from "@/models/server/Customer";
import OrderProduct from "@/models/server/OrderProduct";
import OrderInfo from "@/models/server/OrderInfo";
import Localizer from "@/localization/Localizer";

export default class Order extends OrderInfo {

    public visualCreatedAt: Date = new Date();

    public customer: Customer | null = null;

    public products: OrderProduct[] | null = null;

    public plannedCloseMark: CalendarDateMark | null = null;

    public plannedDeliveryMark: CalendarDateMark | null = null;

    public readonly isOrder: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Order) || ((from as any).isOrder === true));
    }

    public static as(from?: object | null): Order | null {
        return (Order.is(from)) ? from as Order : null;
    }

    // #region Placeholder

    public static getExpressIcon(model: Order): IIconProps {
        return (model.express)
            ? {name: "fas shipping-fast", className: "text-danger", tooltip: Localizer.orderHistoryToolbarExpress}
            : {name: "fas fa-calendar-alt", className: "text-mediq-blue", tooltip: Localizer.orderHistoryToolbarScheduled};
    }

    public static getStatusIcon(model: Order): IIconProps {
        const open: boolean = model.status == OrderStatus.Open;
        const closed: boolean = model.status == OrderStatus.Closed;
        const delivered: boolean = model.status == OrderStatus.Delivered;
        const submitted: boolean = model.status == OrderStatus.Submitted;

        return (delivered)
            ? {name: "fas check-circle", className: "text-grey", tooltip: Localizer.enumOrderStatusDelivered}
            : (open)
                ? {name: "fas lock-open-alt", className: "text-success", tooltip: Localizer.enumOrderStatusOpen}
                : (closed)
                    ? {name: "fas fa-clock", className: "text-orange", tooltip: Localizer.enumOrderStatusClosed}
                    : (submitted)
                        ? {name: "fas fa-truck", className: "text-orange", tooltip: Localizer.enumOrderStatusSubmitted}
                        : {name: "fas spinner", className: "text-chocolate", tooltip: Localizer.enumOrderStatusPartiallyDelivered};
    }

    public static deliveryAddress(model: Order): string | null {
        return (model.customer)
            ? (model.customer.location)
                ? AddressHelper.removeLatLon(model.customer.location.formattedAddress)
                : model.customer.virtualAddress
            : "";
    }

    // #endregion
}