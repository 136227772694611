import React from "react";
import {ActionType, BaseComponent, ch, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {BorderType, CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, RowModel} from "@reapptor-apps/reapptor-react-components";
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import FaqToolbar from "@/pages/FaqManagement/FaqToolbar/FaqToolbar";
import {LocalizationLanguage} from "@/models/Enums";
import SaveFaqRequest from "@/models/server/requests/SaveFaqRequest";
import SaveFaqResponse from "@/models/server/responses/SaveFaqResponse";
import ListFaqRequest from "@/models/server/requests/ListFaqRequest";
import FaqItem from "@/models/server/FaqItem";
import Localizer from "@/localization/Localizer";

import styles from "./FaqPanel.module.scss";

export interface IFaqPanelProps {
    language: LocalizationLanguage
}

interface IFaqPanelState {
    search: string | null;
}

export default class  FaqPanel extends BaseComponent<IFaqPanelProps, IFaqPanelState> {

    state: IFaqPanelState = {
        search: null
    };

    private readonly _faqPanelGridRef: React.RefObject<Grid<FaqItem>> = React.createRef();
    private readonly _toolbarRef: React.RefObject<FaqToolbar> = React.createRef();
    
    public async fetchAsync(sender: Grid<FaqItem>, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<FaqItem[]> {
        const request = new ListFaqRequest();
        request.search = this.state.search;
        request.sortDirection = sortDirection;
        request.sortColumnName = sortColumnName;
        
        return await this.postAsync("/api/faq/listFaq", request);
    }
    
    private readonly _columns: ColumnDefinition[] = [
        {
            accessor: nameof<FaqItem>(o => o.key),
            name: "key",
            header: Localizer.faqPanelKeyLanguageItemName,
            editable: true,
            type: ColumnType.Text,
            sorting: SortDirection.Desc,
            minWidth: "12rem",
        } as ColumnDefinition,
        {
            name: "title",
            header: Localizer.faqPanelTitleLanguageItemName,
            editable: true,
            type: ColumnType.Text,
            sorting: SortDirection.Desc,
            accessor: (model: FaqItem) => FaqItem.getLocalizedTitle(model, this.props.language),
            minWidth: "12rem",
            callback: (cell) => this.setTitleAsync(cell),
        } as ColumnDefinition,
        {
            name: "description",
            header: Localizer.faqPanelDescriptionLanguageItemName,
            editable: true,
            type: ColumnType.Text,
            stretch: true,
            sorting: SortDirection.Desc,
            callback: (cell) => this.setDescriptionAsync(cell),
            accessor: (model: FaqItem) => this.getLocalizedDescription(model),
            minWidth: "22rem",
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: 120,
            removable: false,
            textAlign: TextAlign.Center,
            init: (cell) => this.initFaqOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.faqPanelSaveTitle,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: (cell, action) => this.processFaqOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "cancel",
                    title: Localizer.genericCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Grey,
                    callback: (cell, action) => this.processFaqOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "delete",
                    title: Localizer.genericDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    callback: (cell, action) => this.processFaqOperationAsync(cell, action)
                } as ColumnActionDefinition,
            ]

        } as ColumnDefinition,
    ];
    
    private async setTitleAsync(cell: CellModel<FaqItem>): Promise<void> {
        const model: FaqItem = cell.model;
        FaqItem.setTitle(model, this.props.language, cell.value);
    } 
    private async setDescriptionAsync(cell: CellModel<FaqItem>): Promise<void> {
        const model: FaqItem = cell.model;
        FaqItem.setDescription(model, this.props.language, cell.value);
    }

    private getLocalizedDescription(model: FaqItem): string {
        return FaqItem.getLocalizedDescription(model, this.props.language)
    }
    
    private initFaqOperations(cell: CellModel<FaqItem>): void {
        const model: FaqItem = cell.model;

        const applyAction: CellAction<FaqItem> = cell.actions[0];
        const cancelAction: CellAction<FaqItem> = cell.actions[1];
        const deleteAction: CellAction<FaqItem> = cell.actions[2];

        const isValidKey = (!!model.key);
        const isValidTitle = (!!FaqItem.getLocalizedTitle(model, this.props.language));
        const isValidDescription = (!!FaqItem.getLocalizedDescription(model, this.props.language));
        
        const isValidModel: boolean = (isValidKey && isValidTitle && isValidDescription)

        applyAction.visible = (isValidModel) && (cell.row.modified);
        cancelAction.visible = (cell.row.modified);
        deleteAction.visible = (!cell.row.modified) && (!cell.row.deleted);
    }
    
    private async processFaqOperationAsync(cell: CellModel<FaqItem>, action: CellAction<FaqItem>): Promise<void> {
        const model: FaqItem = cell.model!;

        if (action.action.name === "save") {

            const saveFaqRequest = new SaveFaqRequest();
            saveFaqRequest.id = model.id ?? null;
            saveFaqRequest.key = model.key;
            saveFaqRequest.title = FaqItem.getLocalizedTitle(model, this.props.language);
            saveFaqRequest.description = FaqItem.getLocalizedDescription(model, this.props.language);
            saveFaqRequest.language = this.props.language;

            const response: SaveFaqResponse = await cell.grid.postAsync("/api/faq/saveFaq", saveFaqRequest);
            
            if (response.alreadyExists) {
                await ch.alertErrorAsync(Localizer.faqPanelAlreadyExists.format(model.key), true, true);
            }
            
            if (response.faqItem) {
                cell.row.model = response.faqItem;

                await ch.alertMessageAsync(Localizer.faqPanelSaved, true, true);
            }
        } else if (action.action.name === "cancel") {
            await cell.row.cancelAsync();

            await this.grid.reRenderAsync();
        } else if (action.action.name === "delete") {
            if (model.id == "") {
                this.grid.delete(model);
                
                await this.grid.reRenderAsync();
            } else {
                await cell.grid.postAsync("/api/faq/deleteFaq", model.id);

                await ch.alertMessageAsync(Localizer.faqPanelDeleted, true, true);

                await this.grid.reloadAsync();
            }
        }

        await cell.row.bindAsync(true);
    }

    private get newRowAlreadyExists(): boolean {
        return (this.grid.rows.some(row => !row.deleted && !row.model.id));
    }
    
    public get grid(): GridModel<FaqItem> {
        return this._faqPanelGridRef.current!.model;
    }
    
    public get toolbar(): FaqToolbar {
        return this._toolbarRef!.current!;
    }
    
    public async onAddAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const newRows: RowModel<FaqItem>[] = await this.grid.insertAsync(0, new FaqItem());

            const newRow: RowModel<FaqItem> = newRows[0];
            const nameCell: CellModel<FaqItem> = newRow.get("key");

            await nameCell.editAsync(true);
        }
    }
    
    public async onSearchAsync(search: string): Promise<void> {
        this.state.search = search;
        
        await this.grid.reloadAsync();
    }
    
    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.productsPanel)}>
                
                <FaqToolbar ref= {this._toolbarRef}
                            onAdd= {() => this.onAddAsync()}
                            onSearch= {(_, search: string) => this.onSearchAsync(search)}
                />
                
                <Grid autoToggle 
                      id={"productsPanelGrid"}
                      minWidth="auto"
                      ref={this._faqPanelGridRef}
                      hovering={GridHoveringType.Row}
                      className={styles.productsPanelGrid}
                      noDataText={Localizer.genericNoData}
                      headerMinHeight={80}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns} 
                      fetchData={(sender: Grid<FaqItem>, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(sender, sortColumnName, sortDirection)}
                />

            </div>
        )
    }
}