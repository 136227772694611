import React from "react";
import {BaseComponent, IBaseContainerComponentProps} from "@reapptor-apps/reapptor-react-common";
import {Icon, IconSize, IconStyle} from "@reapptor-apps/reapptor-react-components";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import ImageProvider from "@/providers/ImageProvider";
import noCatalogImage from "@/components/Images/NoCatalogImage.png";

import styles from "./CatalogItem.module.scss";

export interface ICatalogItemProps extends IBaseContainerComponentProps {
    imageOrIcon?: string | FileModel | null;

    onClick?(): Promise<void>;
}

export default class CatalogItem extends BaseComponent<ICatalogItemProps> {

    private get className(): string {
        return this.css(
            styles.catalogItem,
            this.props.className,
        );
    }

    private get hasImageOrIcon(): boolean {
        return (this.props.imageOrIcon !== undefined);
    }

    private get iconName(): string {
        return (this.isIcon)
            ? (this.props.imageOrIcon as string).replace("fa-faucet", "fa-expand").replace("fal", "fas")
            : "";
    }

    private get image(): FileModel | string | null {
        return (!this.isIcon)
            ? (this.props.imageOrIcon as (FileModel | string | null))
            : null;
    }

    private get isIcon(): boolean {
        return (this.props.imageOrIcon != null) && (typeof this.props.imageOrIcon === "string") && (Icon.isIconName(this.props.imageOrIcon));
    }

    private async onClickAsync(): Promise<void> {
        (this.props.onClick) && await this.props.onClick();
    }

    private getImage(): string {
        return ImageProvider.getRequiredImageSrc(this.image, noCatalogImage);
    }

    public render(): JSX.Element {
        return (
                <div className={this.className}
                     onClick={() => this.onClickAsync()}
                >

                    {
                        (this.hasImageOrIcon) &&
                        (
                            <React.Fragment>
                                {
                                    (this.isIcon)
                                        ?
                                        (
                                            <div className={styles.icon}>
                                                <Icon name={this.iconName}
                                                      size={IconSize.X3}
                                                      style={IconStyle.Solid}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            <div className={styles.image}>
                                                <img src={this.getImage()}
                                                     alt={""}
                                                />
                                            </div>
                                        )
                                }
                            </React.Fragment>
                        )
                    }

                    {
                        this.props.children
                    }

                </div>
        );
    }
}