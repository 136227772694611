import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import InventoryProduct from "@/models/server/InventoryProduct";
import {BorderType, ColumnDefinition, Grid, GridHoveringType, GridModel} from "@reapptor-apps/reapptor-react-components";
import OrderProduct from "@/models/server/OrderProduct";
import Localizer from "@/localization/Localizer";

import styles from "./InventoriesPanel.module.scss";

export interface IInventoriesPanelProps {
    productAssortmentId: string;
}

interface IInventoriesPanelState {
    showApplyTemplates: boolean;
}

export default class InventoriesPanel extends BaseComponent<IInventoriesPanelProps, IInventoriesPanelState> {

    state: IInventoriesPanelState = {
        showApplyTemplates: false
    };

    private readonly _gridRef: React.RefObject<Grid<InventoryProduct>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: "3rem",
            maxWidth: "3rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalInventoriesPanelInventedAtLanguageItemName,
            accessor: nameof.full<InventoryProduct>(o => o.createdAt),
            format: "D",
            minWidth: "8rem",
            maxWidth: "8rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalInventoriesPanelInventedByLanguageItemName,
            accessor: nameof.full<InventoryProduct>(o => o.createdBy),
            minWidth: "14rem",
            maxWidth: "14rem",
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<OrderProduct>(o => o.createdBy!.email),
                infoHideEqual: true,
            }
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalInventoriesPanelQuantityLanguageItemName,
            accessor: nameof.full<InventoryProduct>(o => o.quantity),
            format: "0",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition
    ];
    
    private get productAssortmentId(): string {
        return this.props.productAssortmentId;
    }

    private async fetchAsync(sender: Grid<InventoryProduct>): Promise<InventoryProduct[]> {
        return await sender.postAsync("/api/productManagement/listProductInventories", this.productAssortmentId);
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get grid(): GridModel<InventoryProduct> {
        return this._gridRef.current!.model;
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.inventoriesPanel)}>

                <Grid id={`inventoriesPanelGrid_${this.productAssortmentId}`}
                      key={`inventoriesPanelGrid_${this.productAssortmentId}`}
                      ref={this._gridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.None}
                      className={styles.inventoriesPanelGrid}
                      noDataText={Localizer.statisticsModalInventoriesPanelNoInventoriesLanguageItemName}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      fetchData={(sender: Grid<InventoryProduct>) => this.fetchAsync(sender)}
                />

            </div>
        )
    }
}