import CustomerGroup from "@/models/server/CustomerGroup";
import Customer from "@/models/server/Customer";
import UserRole from "@/models/server/UserRole";
import User from "@/models/server/User";

export default class CustomerRole {
    public customerGroup: CustomerGroup | null = null;

    public customer: Customer | null = null;

    public isCustomerGroup: boolean = false;

    public isCustomer: boolean = false;

    public parent: CustomerRole | null = null;

    public children: CustomerRole[] = [];

    public codeOrName: string = "";

    public id: string = "";

    public name: string = "";

    public active: boolean = false;
    
    public get deleted(): boolean {
        return (
            ((this.isCustomer) && (this.customer != null) && (this.customer.deleted)) ||
            ((this.isCustomerGroup) && (this.customerGroup != null) && (this.customerGroup.deleted))
        );
    }

    public get partiallyActive(): boolean {
        return (
            (!this.active) &&
            (
                (this.children.some(child => child.active)) ||
                (
                    ((this.parent != null) && (this.parent.active))
                )
            )
        );
    }

    public toggleActive(forceSet: boolean = false): void {
        const value: boolean = (forceSet) || !((this.active) || (this.partiallyActive));

        this.active = value;

        if (value) {
            // Set
            if (this.parent != null) {
                const all: boolean = this.parent.children.every(item => item.active);
                this.parent.active = all;
                if (all) {
                    this.parent.children.forEach(item => item.active = false);
                }
            } else {
                this.children.forEach(item => item.active = false);
            }
        } else {
            // Unset
            if (this.parent != null) {
                if (this.parent.active) {
                    this.parent.children.where(item => item != this).forEach(item => item.active = true);
                    this.parent.active = false;
                }
            } else {
                this.children.forEach(item => item.active = false);
            }
        }
    }

    public setParent(parent: CustomerRole): void {
        parent.children.push(this);
        this.parent = parent;
    }

    public static create(model: CustomerGroup | Customer, roles: UserRole[], roleName: string): CustomerRole {
        const customerRole = new CustomerRole();

        let contractId: string;

        if (Customer.is(model)) {
            const customer = model as Customer;
            customerRole.isCustomer = true;
            customerRole.customer = customer;
            customerRole.customerGroup = customer.customerGroup;
            customerRole.id = customer.id;
            customerRole.codeOrName = customer.code;
            customerRole.name = customer.name;
            contractId = customer.id;
        } else {
            const customerGroup = model as CustomerGroup;
            customerRole.isCustomerGroup = true;
            customerRole.customerGroup = customerGroup;
            customerRole.id = customerGroup.id;
            customerRole.codeOrName = customerGroup.name;
            customerRole.name = customerGroup.name;
            contractId = customerGroup.id;
        }

        const customerGroupRoles: UserRole[] = roles.where(item => item.customerId == contractId || item.customerGroupId == contractId);

        const roleNames = customerGroupRoles
            .map(item => item.roleName)
            .distinct(item => item);

        const hasCustomerRole: boolean = customerGroupRoles.some(item => !!item.customerId);

        customerRole.id = contractId;
        customerRole.active = (customerRole.isCustomerGroup)
            ? (!hasCustomerRole) && roleNames.includes(roleName)
            : roleNames.includes(roleName);

        return customerRole;
    }

    public static getCustomerRoles(customers: Customer[], user: User, roleName: string): CustomerRole[] {
        customers.sortBy(item => item.customerGroup?.name, item => item.code);

        let customerRoles: CustomerRole[] = [];

        let parentRole: CustomerRole | null = null;
        for (let i: number = 0; i < customers.length; i++) {
            const customer: Customer = customers[i];
            const customerGroup: CustomerGroup = customer.customerGroup!;
            if ((parentRole == null) || (customerGroup.id != parentRole.id)) {
                parentRole = customerRoles.firstOrDefault(item => (item.isCustomerGroup) && (item.id == customerGroup.id));
                if (parentRole == null) {
                    parentRole = CustomerRole.create(customerGroup, user.roles, roleName);
                    customerRoles.push(parentRole);
                }
            }

            const customerRole: CustomerRole = CustomerRole.create(customer, user.roles, roleName);

            customerRole.setParent(parentRole);

            customerRoles.push(customerRole);
        }

        return customerRoles;
    }

    public static reset(customerRoles: CustomerRole[]): void {
        for (let i: number = 0; i < customerRoles.length; i++) {
            const customerRole: CustomerRole = customerRoles[i];
            customerRoles[i].active = false;
            CustomerRole.reset(customerRole.children);
        }
    }
    
    public static getUserRoles(customerRoles: CustomerRole[], primaryRole: UserRole, primaryRoleByDefault: boolean = true): UserRole[] {
        const roleName: string = primaryRole.roleName;

        const userRoles: UserRole[] = [];

        customerRoles = customerRoles.where(item => item.active || item.partiallyActive);

        for (let i: number = 0; i < customerRoles.length; i++) {
            const customerRole: CustomerRole = customerRoles[i];
            
            if (customerRole.partiallyActive) {
                const childrenUserRoles: UserRole[] = CustomerRole.getUserRoles(customerRole.children, primaryRole, false);

                userRoles.push(...childrenUserRoles);

                continue;
            }
            
            const userRole = new UserRole();
            userRole.roleName = roleName;

            if (customerRole.isCustomer) {
                userRole.customerId = customerRole.id;
                userRole.customerGroupId = customerRole.parent!.id;
            } else {
                userRole.customerGroupId = customerRole.id;
            }
            
            userRoles.push(userRole);
        }
        
        if ((primaryRoleByDefault) && (userRoles.length == 0)) {
            primaryRole.customerGroupId = null;
            primaryRole.customerId = null;
            userRoles.push(primaryRole);
        }

        return userRoles;
    }
}