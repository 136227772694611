// autogenerated
import Localization from "@/models/server/Localization";
import Localizer from "@/localization/Localizer";

export default class BannerMessage {

    public id: string = "";

    public text: Localization | null = null;

    public dismissible: boolean = false;

    public activatedAt: Date | null = null;

    public roleNames: string[] = [];

    public pageNames: string[] = [];

    public readonly isBannerMessage: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof BannerMessage) || ((from as any).isBannerMessage === true));
    }

    public static as(from?: object | null): BannerMessage | null {
        return (BannerMessage.is(from)) ? from as BannerMessage : null;
    }

    // #region Placeholder

    public static getMessage(model: BannerMessage): string {
        return (model.text)
            ? (
                Localization.getLocalizedItem(model.text, Localizer.language) ||
                Localization.getLocalizedItem(model.text, Localizer.defaultLanguage) ||
                model.text.english || ""
            )
            : "";
    }

    // #endregion
}