import React from "react";
import BreadCrumb from "@/components/Catalog/BreadCrumb/BreadCrumb";
import CatalogModal from "./CatalogModal/CatalogModal";
import ProductGroupItem from "./ProductGroupItem/ProductGroupItem";
import {BaseAsyncComponent, BasePageParameters, IBaseAsyncComponentState, IBasePage, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {ICatalogDataProvider} from "@/providers/CatalogDataProvider";
import ProductGroupMobileInfo from "@/models/server/ProductGroupMobileInfo";
import {Button, ConfirmationDialog, Icon, IconSize, Spinner, TextInput} from "@reapptor-apps/reapptor-react-components";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import OrderProductItem from "@/components/Catalog/OrderProductItem/OrderProductItem";
import ScanQrCodeModal from "@/components/Catalog/ScanQrCodeModal/ScanQrCodeModal";
import Localizer from "@/localization/Localizer";

import styles from "./Catalog.module.scss";

export enum CatalogMode {

    /**
     * Display navigable {@link ProductGroupMobileInfo}s, and {@link OrderProductMobileInfo}s which can be added to "shopping cart".
     */
    AddProduct = 0,

    /**
     * Display a search bar, list of added {@link OrderProductMobileInfo}, "add product" and "scan qr-code" buttons.
     */
    Order = 1
}

export class CatalogParameters extends BasePageParameters {
    public search: string | null = null;
    public exactMatch: boolean = false;
    public categoryId: string | null = null;
}

export interface ICatalogProps {
    dataProvider: ICatalogDataProvider;
    mode: CatalogMode;
    className?: string;
    express: boolean;
    keepRouting?: boolean;

    onChange?(sender: OrderProductItem, item: OrderProductMobileInfo): Promise<void>;

    onScanQr?(sender: Catalog, code: string): Promise<void>;

    sendExpressOrder?(sender: Catalog): Promise<boolean>;

    onFavoriteChange?(sender: Catalog, productId: string, favorite: boolean): Promise<void>;

    onResetSearch?(sender: Catalog): Promise<void>;
}

interface ICatalogState extends IBaseAsyncComponentState<{}> {
    allProductGroups: ProductGroupMobileInfo[];
    displayedProductGroups: ProductGroupMobileInfo[];
    displayedProducts: OrderProductMobileInfo[];
    editing: boolean;
    mode: InternalCatalogMode;
    orderProducts: OrderProductMobileInfo[];
    search: string;
    searchValue: string;
    exactMatch: boolean;
    selectedProductGroupId: string | null;
    contractLevelWarning: boolean;
}

enum InternalCatalogMode {

    /**
     * Display a search bar, list of added {@link OrderProductMobileInfo}s, "add order product" and "scan qr-code" buttons.
     */
    ShoppingCart,

    /**
     * Display a list of {@link ProductGroupMobileInfo}s.
     */
    ProductGroups,

    /**
     * Display a list of {@link Product}s.
     */
    Products
}

export default class Catalog extends BaseAsyncComponent<ICatalogProps, ICatalogState, {}> {

    public state: ICatalogState = {
        allProductGroups: [],
        data: null,
        displayedProductGroups: [],
        displayedProducts: [],
        editing: false,
        isLoading: false,
        mode: (this.props.mode === CatalogMode.AddProduct)
            ? InternalCatalogMode.ProductGroups
            : InternalCatalogMode.ShoppingCart,
        orderProducts: [],
        search: "",
        searchValue: "",
        exactMatch: false,
        selectedProductGroupId: null,
        contractLevelWarning: false,
    };

    // Fields

    private readonly _productGroupsModalRef: React.RefObject<CatalogModal> = React.createRef();
    private readonly _qrModalRef: React.RefObject<ScanQrCodeModal> = React.createRef();
    private readonly _confirmationDialogRef: React.RefObject<ConfirmationDialog> = React.createRef();
    private readonly _params: CatalogParameters = new CatalogParameters();
    private _onScanQrClickInvoking: boolean = false;
    private _onCatalogClickInvoking: boolean = false;

    // Inherited / implemented

    protected async fetchDataAsync(): Promise<{}> {
        const shoppingCard: boolean = (this.props.mode !== CatalogMode.AddProduct);
        const productsOnly: boolean = (!shoppingCard) && (!!this.search);

        const allProductGroups: ProductGroupMobileInfo[] = await this.dataProvider.getProductGroupsAsync(this);

        const displayedProductGroups: ProductGroupMobileInfo[] = ((!shoppingCard) && (!productsOnly))
            ? await this.dataProvider.getProductGroupsAsync(this, this.selectedProductGroupId)
            : [];
        
        const displayedProducts: OrderProductMobileInfo[] = (shoppingCard)
            ? await this.dataProvider.getAddedOrderProductsAsync(this, this.search)
            : (displayedProductGroups.length == 0)
                ? await this.dataProvider.getOrderProductsAsync(this, this.selectedProductGroupId, this.search, this.exactMatch)
                : [];
        
        const mode: InternalCatalogMode = (shoppingCard)
            ? InternalCatalogMode.ShoppingCart
            : (displayedProductGroups.length > 0)
                ? InternalCatalogMode.ProductGroups
                : InternalCatalogMode.Products;

        const contractLevelWarning: boolean =
            (
                (!!this.search) && (this.exactMatch) && (!shoppingCard) &&
                (displayedProducts.length == 1) &&
                (displayedProducts[0].productAssortment?.customerId == null)
            );
        
        await this.setState({
            contractLevelWarning,
            allProductGroups: allProductGroups,
            displayedProductGroups: displayedProductGroups,
            displayedProducts: displayedProducts,
            mode
        });
        
        return {};
    }

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    public async reloadAsync(search: string | null = null, productGroupId: string | null = null, exactMatch: boolean = false, push: boolean = true): Promise<void> {
        this.state.selectedProductGroupId = productGroupId;
        this.state.search = search || "";
        this.state.searchValue = "";
        this.state.exactMatch = exactMatch;
        
        if ((push) && (this.keepRouting)) {
            this.pushRoute();
        }

        await super.reloadAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        if (this.displayingShoppingCart) {
            const modal: CatalogModal | null = this._productGroupsModalRef.current;

            if (modal) {
                const params = this.getPage().route.parameters as (CatalogParameters | null);
                if (params) {
                    // ((params.search != this._params.search) || (params.categoryId !== this._params.categoryId))
                    const isSearchEqual: boolean = ((params.search || "") == (this._params.search || ""));
                    const isProductGroupIdEqual: boolean = (params.categoryId == this._params.categoryId);
                    const isExactMatchEqual: boolean = (params.exactMatch == this._params.exactMatch);

                    if ((!isSearchEqual) || (!isProductGroupIdEqual) || (!isExactMatchEqual)) {
                        await modal.reloadAsync(params.search, params.categoryId, params.exactMatch, false);
                        await modal.openAsync(false, false);
                    }
                }
            }
        }
    }

    private async onQrAsync(code: string): Promise<void> {
        await this._qrModalRef.current?.closeAsync();

        await this.openSearchModalAsync(true);
       
        if (this._productGroupsModalRef.current) {
            await this._productGroupsModalRef.current.reloadAsync(code, null, true, true);
        }

        if (this.props.onScanQr) {
            await this.props.onScanQr(this, code);
        }
    }

    public async onScanQrClickAsync(): Promise<void> {
        if (this._onScanQrClickInvoking) {
            return;
        }

        try {
            this._onScanQrClickInvoking = true;

            await this._qrModalRef.current?.openAsync();
        } finally {
            this._onScanQrClickInvoking = false;
        }
    }

    // Getters

    private get allProductGroups(): ProductGroupMobileInfo[] {
        return this.state.allProductGroups;
    }

    private get dataProvider(): ICatalogDataProvider {
        return this.props.dataProvider;
    }

    private get mode(): InternalCatalogMode {
        return this.state.mode;
    }

    private get search(): string {
        return this.state.search;
    }

    private get exactMatch(): boolean {
        return this.state.exactMatch;
    }

    private get searchValue(): string {
        return this.state.searchValue;
    }

    private get selectedProductGroupId(): string | null {
        return this.state.selectedProductGroupId;
    }

    private get selectedProductGroup(): ProductGroupMobileInfo | null {
        return (this.selectedProductGroupId)
            ? this.allProductGroups.find((group: ProductGroupMobileInfo) => group.id === this.selectedProductGroupId) || null
            : null;
    }

    private get breadCrumbs(): ProductGroupMobileInfo[] {
        return (this.selectedProductGroup)
            ? ProductGroupMobileInfo.toBreadCrumbs(this.selectedProductGroup)
            : [];
    }

    private get displayedProductGroups(): ProductGroupMobileInfo[] {
        return this.state.displayedProductGroups;
    }


    private get displayedProducts(): OrderProductMobileInfo[] {
        return this.state.displayedProducts;
    }

    private get displayingShoppingCart(): boolean {
        return (this.mode === InternalCatalogMode.ShoppingCart);
    }

    private get displayingProducts(): boolean {
        return (this.mode === InternalCatalogMode.Products);
    }

    private get displayingCategories(): boolean {
        return (this.mode === InternalCatalogMode.ProductGroups);
    }

    private get needsBreadCrumbs(): boolean {
        return (!this.displayingShoppingCart);
    }

    private get searchResultCount(): number {
        return (this.displayingCategories)
            ? this.displayedProductGroups.length
            : this.displayedProducts.length;
    }

    private get selectedProductGroupName(): string | null {
        const group: ProductGroupMobileInfo | null = (this.selectedProductGroup)
            ? this.breadCrumbs.find(group => group.id === this.selectedProductGroupId) || null
            : null;
        return (group?.name)
            ? group.name
            : null;
    }

    private get searchResult(): string {
        return (this.isLoading)
            ? Localizer.mobileCatalogSearching
            : (this.searchResultCount > 0)
                ? Localizer.mobileCatalogProductsFound.format(this.searchResultCount)
                : Localizer.mobileCatalogNoProductsFound
    }

    private get title(): string {
        const page: IBasePage = this.getPage();
        let title: string = page.getTitle();
        const selectedProductGroupName: string | null = this.selectedProductGroupName;
        if (selectedProductGroupName) {
            title = title + "/" + selectedProductGroupName;
        }
        if (this.search) {
            title = title + "/" + this.search;
        }
        return title;
    }

    // Sync-methods

    private pushRoute(): void {
        if (!this.displayingShoppingCart) {
            const params: CatalogParameters = this._params;
            if ((params.categoryId != this.selectedProductGroupId) || (params.search != this.search) || (params.exactMatch != this.exactMatch)) {
                params.categoryId = this.selectedProductGroupId;
                params.search = this.search;
                params.exactMatch = this.exactMatch;

                const route: PageRoute = this.getPage().route;
                route.parameters = params;

                const title: string = this.title;

                PageRouteProvider.push(route, title);
            }
        }
    }

    // Async-methods

    private async onCloseCatalogModal(scanNext: boolean): Promise<void> {
        if (scanNext) {
            await this._qrModalRef?.current?.openAsync();
        } else {
            await this.resetSearchAsync();
        }
    }

    private async onChangeItemAsync(sender: OrderProductItem, item: OrderProductMobileInfo): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(sender, item);
        }

        if (this.displayingShoppingCart) {
            this.state.displayedProducts = await this.dataProvider.getAddedOrderProductsAsync(this, this.search);
            await this.reRenderAsync();
        }
    }

    private async onFavoriteChangeAsync(productId: string, favorite: boolean): Promise<void> {
        if (productId) {

            if (this.displayingShoppingCart) {
                await this.dataProvider.setFavoriteAsync(this, productId, favorite);
            }

            if (this.props.onFavoriteChange) {
                await this.props.onFavoriteChange(this, productId, favorite);
            }

        }
    }

    private async onSearchValueChangedAsync(value: string, done: boolean): Promise<void> {
        if (done) {
            await this.searchAsync();
        } else if (this.searchValue !== value) {
            await this.setState({searchValue: value});
        }
    }

    private async onSelectProductGroupAsync(group: ProductGroupMobileInfo): Promise<void> {
        if ((this.selectedProductGroupId !== group.id) || (!!this.search)) {
            await this.reloadAsync("", group.id);
        }
    }

    private async onHomeAsync(): Promise<void> {
        if (this.props.onResetSearch) {
            await this.props.onResetSearch(this);
        }

        await this.reloadAsync();
    }

    private async searchAsync(): Promise<void> {
        await this.reloadAsync(this.searchValue, this.selectedProductGroupId);
    }

    private async openSearchModalAsync(showQrScanResultButtons: boolean = false): Promise<void> {
        if (this._onCatalogClickInvoking) {
            return;
        }

        try {
            this._onCatalogClickInvoking = true;

            if (this._productGroupsModalRef.current) {
                await this._productGroupsModalRef.current.openAsync(true, showQrScanResultButtons);
            }
        } finally {
            this._onCatalogClickInvoking = false;
        }
    }

    private async sendExpressOrderAsync(): Promise<void> {
        if (this.props.sendExpressOrder) {
            const orderSent: boolean = await this.props.sendExpressOrder(this);

            if (orderSent) {
                await this.dataProvider.resetOrderProductsAsync(this);

                await this.reloadAsync(this.search, null, this.exactMatch, true);
            }
        }
    }

    public async resetSearchAsync(): Promise<void> {
        if (this.props.onResetSearch) {
            await this.props.onResetSearch(this);
        }

        await this.reloadAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }
    
    public get keepRouting(): boolean {
        return (this.props.keepRouting === true);
    }

    private get renderList(): React.ReactNode {
        return (
            <div className={styles.list}>
                {
                    (this.displayingCategories)
                        ?
                        (
                            this.displayedProductGroups.map((group: ProductGroupMobileInfo, index: number) =>
                                <ProductGroupItem key={group.id + index}
                                                  group={group}
                                                  onClick={() => this.onSelectProductGroupAsync(group)}
                                />
                            )
                        ) :
                        (
                            this.displayedProducts.map((product: OrderProductMobileInfo, index: number) =>
                                <OrderProductItem key={product.id + index}
                                                  orderProduct={product}
                                                  express={this.props.express}
                                                  shoppingCart={this.displayingShoppingCart}
                                                  onChange={(sender: OrderProductItem) => this.onChangeItemAsync(sender, product)}
                                                  onFavoriteChange={(_, productId: string, favorite: boolean) => this.onFavoriteChangeAsync(productId, favorite)}
                                />
                            )
                        )
                }
            </div>
        );
    }

    public render(): React.ReactNode {
        const shoppingCardStyle: any = (this.displayingShoppingCart) && styles.shoppingCard;

        return (
            <div className={this.css(styles.catalog, shoppingCardStyle, this.props.className)}>

                <ConfirmationDialog ref={this._confirmationDialogRef}/>

                {
                    (this.needsBreadCrumbs) &&
                    (
                        <BreadCrumb items={this.breadCrumbs}
                                    className={styles.breadCrumbs}
                                    onClick={(sender, group) => this.onSelectProductGroupAsync(group)}
                                    onHome={() => this.onHomeAsync()}
                        />
                    )
                }

                <div className={this.css(styles.searchBar)}>
                    <TextInput clearButton noAutoComplete
                               placeholder={Localizer.genericSearch}
                               id={"catalog_search_product"}
                               className={this.css(styles.searchBarInput)}
                               value={this.searchValue}
                               onChange={(sender, value, userInteraction, done) => this.onSearchValueChangedAsync(value, done)}
                               append={
                                   <Icon name="far search"
                                         onClick={() => this.searchAsync()}
                                   />
                               }
                    />
                </div>

                {
                    (this.hasData) && (this.displayingShoppingCart) &&
                    (
                        <React.Fragment>

                            <div className={styles.buttonsContainer}>

                                <div className={styles.add} onClick={() => this.openSearchModalAsync()}>

                                    <Icon name={"far fa-sitemap"} size={IconSize.Large}/>
                                    <span>{Localizer.mobileCatalogAdd}</span>
                                </div>

                                <div className={styles.add} onClick={() => this.onScanQrClickAsync()}>
                                    <Icon name={"far fa-camera"} size={IconSize.Large}/>
                                    <span>{Localizer.mobileCatalogScanQr}</span>
                                </div>


                            </div>

                            {
                                ((this.props.express) && (this.displayedProducts.length > 0)) &&
                                (
                                    <div className={styles.sendOrderButton} onClick={() => this.sendExpressOrderAsync()}>

                                        <Icon name={"fas fa-paper-plane"} size={IconSize.Large}/>

                                        <span>{Localizer.mobileCatalogSendExpressOrder}</span>

                                    </div>
                                )
                            }

                        </React.Fragment>
                    )
                }

                {
                    (this.search) &&
                    (
                        <div className={styles.searchResult}>

                            <div className={styles.title}>

                                <h3>
                                    {Localizer.mobileCatalogSearchResults.format(this.search)}
                                </h3>

                                <Button id="resetSearch"
                                        icon={{name:"far fa-eraser", size:IconSize.X2 }}
                                        className={styles.icon}
                                        onClick={() => this.resetSearchAsync()}
                                />

                            </div>

                            <p className={styles.result}>
                                
                                {this.searchResult}
                                
                            </p>

                            {
                                (this.state.contractLevelWarning) &&
                                (
                                    <span className={styles.warning}> {Localizer.mobileCatalogSearchWarning} </span>
                                )
                            }

                        </div>
                    )
                }

                {
                    (this.hasData) && (this.displayingProducts || this.displayingShoppingCart) && (this.displayedProducts.length == 0) && (!this.search) &&
                    (
                        <div className={this.css(styles.noProducts, this.props.express && styles.express)}>
                            {Localizer.mobileCatalogNoProducts}
                        </div>
                    )
                }

                {
                    (this.hasData) &&
                    (
                        this.renderList
                    )
                }

                {
                    (this.isLoading) &&
                    (
                        <Spinner/>
                    )
                }

                {
                    (this.displayingShoppingCart) &&
                    (
                        <React.Fragment>

                            <CatalogModal ref={this._productGroupsModalRef}
                                          express={this.props.express}
                                          dataProvider={this.dataProvider}
                                          onChange={(sender: OrderProductItem, item: OrderProductMobileInfo) => this.onChangeItemAsync(sender, item)}
                                          onClose={(_: CatalogModal, scanNext: boolean) => this.onCloseCatalogModal(scanNext)}
                                          onFavoriteChange={(sender: CatalogModal, productId: string, favorite: boolean) => this.onFavoriteChangeAsync(productId, favorite)}
                            />

                            <ScanQrCodeModal ref={this._qrModalRef}
                                             onQr={(sender, code: string) => this.onQrAsync(code)}
                            />

                        </React.Fragment>
                    )
                }

            </div>
        );
    }
};