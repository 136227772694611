import Customer from "@/models/server/Customer";

export default class MyCalendarToolbarModel {

    public customer: Customer | null = null;

    public dataInitialized: boolean = false;

    public initialized(): boolean {
        return (!!this.customer?.id);
    }
}