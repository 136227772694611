import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Form, OneColumn, Panel, TextAreaInput, TextInput, TwoColumns,} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";

import styles from "./CustomerNotesPanel.module.scss";
import Localizer from "@/localization/Localizer";

interface ICustomerNotesPanelProps {
    customer: Customer;

    onChange(): Promise<void>
}

interface ICustomerNotesPanelState {
}

export default class CustomerNotesPanel extends BaseComponent<ICustomerNotesPanelProps, ICustomerNotesPanelState> {

    state: ICustomerNotesPanelState = {};

    private get customer(): Customer {
        return this.props.customer;
    }

    private async onChangeNotesAsync(value: string): Promise<void> {
        this.customer.notes = value

        await this.props.onChange();
    }

    private async onChangeAittaAdditionalInfoAsync(value: string): Promise<void> {
        this.customer.info = value

        await this.props.onChange();
    }

    private async onChangeCommentsAsync(value: string): Promise<void> {
        this.customer!.comments = value

        await this.props.onChange();
    }

    private async onChangeSpacesAsync(value: string): Promise<void> {
        this.customer!.spaces = value

        await this.props.onChange();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public render(): React.ReactNode {
        return (
            <Panel title={Localizer.editCustomerPageCustomerNotesPanelComments} className={styles.customerAdditionalInformationPanel}>

                <div className="col-12">
                    {
                        (this.customer) &&
                        (
                            <div>
                                <Form>

                                    <TwoColumns>

                                        <TextInput noAutoComplete
                                                   id="spaces"
                                                   label={Localizer.editCustomerPageCustomerNotesPanelAdditionalSpaces}
                                                   value={this.customer.spaces}
                                                   onChange={(_, value) => this.onChangeSpacesAsync(value)}
                                        />
                                        
                                        <TextInput noAutoComplete
                                                   id="comments"
                                                   label={Localizer.editCustomerPageCustomerNotesPanelComments}
                                                   value={this.customer.comments}
                                                   onChange={(_, value) => this.onChangeCommentsAsync(value)}
                                        />

                                    </TwoColumns>
                                    
                                    <OneColumn>

                                        <TextAreaInput id="info"
                                                       label={Localizer.editCustomerPageCustomerNotesPanelAittaAdditionalnfo}
                                                       value={this.customer.info}
                                                       onChange={(_, value) => this.onChangeAittaAdditionalInfoAsync(value)}
                                        />

                                    </OneColumn>
                                    
                                    <OneColumn>

                                        <TextAreaInput id="notes"
                                                       label={Localizer.editCustomerPageCustomerNotesPanelNotes}
                                                       value={this.customer.notes}
                                                       onChange={(_, value) => this.onChangeNotesAsync(value)}
                                        />

                                    </OneColumn>

                                </Form>

                            </div>
                        )
                    }
                </div>

            </Panel>
        );
    }
};