// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {AddressHelper} from "@reapptor-apps/reapptor-react-components";
import {CustomerHandler, CustomerLabelHandler, CustomerServiceType, DeliveryDayOfWeek} from "@/models/Enums";
import Customer from "@/models/server/Customer";

export default class CustomerMobileInfo {

    public id: string = "";

    public name: string = "";

    public code: string = "";

    public codeInfo: string = "";

    public customerGroupId: string = "";

    public customerGroupName: string | null = null;

    public handler: CustomerHandler = CustomerHandler.Customer;

    public labelHandler: CustomerLabelHandler = CustomerLabelHandler.Customer;

    public serviceType: CustomerServiceType = CustomerServiceType.Aitta;

    public spaces: string | null = null;

    public deliveryInterval: number = 0;

    public deliveryDay: DeliveryDayOfWeek = DeliveryDayOfWeek.Monday;

    public closeDay: DeliveryDayOfWeek = DeliveryDayOfWeek.Monday;

    public location: GeoLocation | null = null;

    public virtualAddress: string | null = null;

    public addressDetails: string | null = null;

    public favorite: boolean = false;

    public nextCloseDate: Date | null = null;

    public nextDeliveryDate: Date | null = null;

    public readonly isCustomerMobileInfo: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CustomerMobileInfo) || ((from as any).isCustomerMobileInfo === true));
    }

    public static as(from?: object | null): CustomerMobileInfo | null {
        return (CustomerMobileInfo.is(from)) ? from as CustomerMobileInfo : null;
    }

    // #region Placeholder

    public static deliveryAddress(model: CustomerMobileInfo): string | null {
        return (model.location)
            ? (model.addressDetails)
                ? `${AddressHelper.removeLatLon(model.location.formattedAddress)} (${model.addressDetails})`
                : AddressHelper.removeLatLon(model.location.formattedAddress)
            : model.virtualAddress;
    }
    
    public static maxDeliveryInterval(customer: Customer): number {
        return (customer.serviceType == CustomerServiceType.HomeDelivery)
            ? 13
            : 4;
    }
    
    public static maxCloseInterval(customer: Customer): number {
        return 5;
    }

    // #endregion
}