import React from "react";
import {BaseComponent, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, DateInput, Dropdown, Form, Inline, Modal, OneColumn, SelectListItem, TextInput} from "@reapptor-apps/reapptor-react-components";
import {OrderStatus} from "@/models/Enums";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./OrderFiltersModal.module.scss";

export class OrderFilterModel {
    public statuses: OrderStatus[] = [];
    public createdFrom: Date | null = null;
    public createdTo: Date | null = null;
    public orderNumber: string | null = null;

    public clear(): void {
        this.statuses = [];
        this.createdFrom = null;
        this.createdTo = null;
        this.orderNumber = null;
    }

    public static isEmpty(model: OrderFilterModel): boolean {
        return (
            (model.createdTo == null) &&
            (model.createdFrom == null) &&
            (model.statuses.length == 0) &&
            (!model.orderNumber)
        );
    }
}

interface IOrderFiltersModalProps {
    model: OrderFilterModel;
    title: string;
    onSubmit(sender: IBaseComponent, model: OrderFilterModel): Promise<void>;
}

interface IOrderFiltersModalState {
    model: OrderFilterModel;
}

export default class OrderFiltersModal extends BaseComponent<IOrderFiltersModalProps, IOrderFiltersModalState> {
    state: IOrderFiltersModalState = {
        model: this.props.model
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private async clearAsync(): Promise<void> {
        this.state.model.clear();
        
        await this.reRenderAsync();
    }

    private async statusChangedAsync(items: SelectListItem[]): Promise<void> {
        this.state.model.statuses = items.map(item => parseInt(item.value));
        await this.setState({model: this.state.model});
    }

    private async createdFromChangedAsync(date: Date | null): Promise<void> {
        this.state.model.createdFrom = date;
        await this.setState({model: this.state.model});
    }

    private async onOrderNumberChangeAsync(orderNumber: string | null): Promise<void> {
        this.state.model.orderNumber = orderNumber;
        await this.setState({model: this.state.model});
    }
    
    private async createdToChangedAsync(date: Date | null): Promise<void> {
        this.state.model.createdTo = date;
        await this.setState({model: this.state.model});
    }

    private async submitAsync(): Promise<void> {
        await this.props.onSubmit(this, this.state.model);

        await this._modalRef.current!.closeAsync();
    }
    
    private get model(): OrderFilterModel {
        return this.state.model;
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {
        return (
            <Modal id={this.id}
                   title={this.props.title}
                   ref={this._modalRef}
                   className={this.css(styles.devicesFiltersModal, this.mobile && styles.mobile)}>

                <Form className={styles.form}
                      onSubmit={() => this.submitAsync()}
                >

                    <OneColumn>

                        <Dropdown multiple clearButton
                                  name="status"
                                  label={Localizer.mobileOrderFilterModalOrderStatus}
                                  nothingSelectedText={"-"}
                                  items={EnumProvider.getOrderStatusItems()}
                                  selectedItems={this.model.statuses}
                                  onChange={(sender) => this.statusChangedAsync(sender.selectedItems)}
                        />

                    </OneColumn>


                    <Inline className={styles.inline}>

                        <DateInput popup clearButton
                                   name="from"
                                   maxDate={this.model.createdTo || Utility.today()}
                                   label={Localizer.mobileOrderFilterModalCreatedFrom}
                                   value={this.model.createdFrom}
                                   onChange={(value) => this.createdFromChangedAsync(value)}
                        />

                        <DateInput popup clearButton
                                   name="to"
                                   minDate={this.model.createdFrom}
                                   maxDate={Utility.today()}
                                   label={Localizer.mobileOrderFilterModalCreatedTo}
                                   value={this.model.createdTo}
                                   onChange={(value) => this.createdToChangedAsync(value)}
                        />

                    </Inline>

                    <OneColumn>

                        <TextInput name="orderNumber"
                                   label={Localizer.mobileOrderFilterModalOrderNumber}
                                   placeholder={Localizer.mobileOrderFilterModalSearchOrderNumber}
                                   value={this.model.orderNumber}
                                   onChange={(_, value: string) => this.onOrderNumberChangeAsync(value)}
                        />

                    </OneColumn>

                    <ButtonContainer className={this.css(styles.buttons, this.mobile && styles.mobile)}>

                        <Button submit
                                id={"oderFilterSubmit"}
                                type={ButtonType.Orange}
                                label={Localizer.mobileOrderFilterModalApply}
                        />

                        <Button id={"orderFilterClear"}
                                type={ButtonType.Blue}
                                label={Localizer.mobileOrderFilterModalClear}
                                onClick={() => this.clearAsync()}
                        />

                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};