import React from "react";
import {ITabContainerClassNames, PageContainer, Tab, TabContainer, TabRenderType} from "@reapptor-apps/reapptor-react-components";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import Inventory from "@/models/server/Inventory";
import InventoryProductList from "@/pages/Mobile/InventoryDetails/InventoryProductList/InventoryProductList";
import GetCurrentUserInventoryResponse from "@/models/server/responses/GetCurrentUserInventoryResponse";
import {IManualProps, PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import InventoryProductItem from "@/pages/Mobile/InventoryDetails/InventoryProductItem/InventoryProductItem";
import InventoryProductMobileInfo from "@/models/server/InventoryProductMobileInfo";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";

import Localizer from "@/localization/Localizer";

import styles from "./InventoryDetails.module.scss";

export interface IInventoryDetailsProps {
}

interface IInventoryDetailsState {
    inventory: Inventory | null;
    inventoryCloseDate: Date | null;
    pendingCount: number;
    modifiedProductIds: string[];
}

export default class InventoryDetails extends MobileAuthorizedPage<IInventoryDetailsProps, IInventoryDetailsState> {

    state: IInventoryDetailsState = {
        inventory: null,
        inventoryCloseDate: null,
        pendingCount: 0,
        modifiedProductIds: []
    };

    private get pendingCount(): number {
        return this.state.pendingCount;
    }

    private get modifiedProductIds(): string[] {
        return this.state.modifiedProductIds;
    }
    
    private async onDoneAsync(productId: string, isPendingTab: boolean = true): Promise<void> {
        if (isPendingTab) {
            await this.setState({pendingCount: this.state.pendingCount - 1});
        }

        const index: number = this.modifiedProductIds.indexOf(productId, 0);
        
        this.modifiedProductIds.splice(index, 1);
        
        await this.setState({modifiedProductIds: this.state.modifiedProductIds});
    }
    
    private async onModifyAsync(modifiedProductId: string): Promise<void> {
        if (!this.modifiedProductIds.includes(modifiedProductId)) {
            this.state.modifiedProductIds.push(modifiedProductId);
            await this.setState({modifiedProductIds: this.state.modifiedProductIds});
        }
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: styles.tabContainerNavTabs,
            headerLink: styles.tabHeaderLink,
            navigationContainer: styles.stickyNavigationContainer
        };
    }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileInventoryPageTitle.toUpperCase(),
            render: () => this.renderManual(),
        };
    }

    public async beforeRedirectAsync(): Promise<boolean> {

        let confirmed: boolean = true;

        const hasUnconfirmedChanges: boolean = (this.state.modifiedProductIds.length > 0);

        if (hasUnconfirmedChanges) {
            confirmed = await this.confirmAsync(Localizer.mobileInventoryDetailsPageUnsavedChages);
        }

        return confirmed;
    }
    
    public renderManual(): React.ReactNode {
        const orderCustomer: CustomerMobileInfo = this.customer;
        
        return (
            <div className={styles.manual}>

                <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(orderCustomer.codeInfo)} </mark> - {orderCustomer.name} </b></span>

                <span>{Localizer.mobileInfoGroup}: <b>{this.customer.customerGroupName}</b></span>

                <span>{this.toMultiLines(Localizer.mobileInventoryDetailsPageInventoryStartedDate.format(this.inventory?.date))}</span>

                <span>{this.toMultiLines(Localizer.mobileInventoryDetailsPageInventoryClosesDate.format(this.state.inventoryCloseDate))}</span>

                <span>{this.toMultiLines(Localizer.mobileInventoryDetailsPageProductsPendingInventory.format(this.pendingCount))}</span>

            </div>
        )
    }

    public async initializeAsync(): Promise<void> {
        const inventoryId: string = this.routeId ?? "";

        if (!inventoryId) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileInventoryRoute, true, true);
            return;
        }

        const response: GetCurrentUserInventoryResponse = await this.postAsync("/api/mobileApp/GetCurrentUserInventory", inventoryId);

        await this.setState({inventory: response.inventory, inventoryCloseDate: response.inventoryCloseDate, pendingCount: response.pendingInventoryCount});

        await super.initializeAsync();
    }

    private get inventory(): Inventory | null {
        return this.state.inventory;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.inventoryDetails, this.mobile && styles.mobile)}
            >

                {
                    (this.inventory) &&
                    (
                        <TabContainer id="inventoryContainer"
                                      renderType={TabRenderType.ActiveOnly}
                                      className={styles.inventoriesTabs}
                                      classNames={this.tabContainerClassNames}
                        >
                
                            <Tab id="pending"
                                 title={Localizer.mobileInventoryDetailsPagePending}
                                 className={styles.inventoryTab}
                                 count={this.pendingCount}
                            >
                                <InventoryProductList title={Localizer.inventoryProductListTitle.format(this.inventory.date)}
                                                      inventoryId={this.inventory!.id}
                                                      customer={this.customer}
                                                      showInventoried={false}
                                                      noDataText={Localizer.mobileOrderHistoryPageNoOrderHistory}
                                                      modifiedProductIds={this.modifiedProductIds}
                                                      onModify={(sender: InventoryProductItem, productId: string) => this.onModifyAsync(productId)}
                                                      onDone={(sender: InventoryProductItem, product: InventoryProductMobileInfo) => this.onDoneAsync(product.id, false)}
                                />
                
                            </Tab>
                
                            <Tab id="completed"
                                 title={Localizer.mobileInventoryDetailsPageCompleted}
                                 className={styles.inventoryTab}
                            >
                                <InventoryProductList showInventoried
                                                      title={Localizer.inventoryProductListTitle.format(this.inventory.date)}
                                                      inventoryId={this.inventory!.id}
                                                      customer={this.customer}
                                                      noDataText={Localizer.mobileOrderHistoryPageNoExpressOrderHistory}
                                                      modifiedProductIds={this.modifiedProductIds}
                                                      onModify={(sender: InventoryProductItem, productId: string) => this.onModifyAsync(productId)}
                                                      onDone={(sender: InventoryProductItem, product: InventoryProductMobileInfo) => this.onDoneAsync(product.id, false)}
                                />
                            </Tab>
                
                        </TabContainer>
                
                    )
                }

            </PageContainer>
        );
    }
}