import {ApiProvider, ch, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import AittaConstants from "@/helpers/AittaConstants";
import ApplicationContext from "@/models/server/ApplicationContext";

export default class UserActivityHelper {
    private static _initialized: boolean = false;
    private static _lastActivityAt: Date = new Date();
    private static _lastActivities: number = 100;

    private static onUserInteraction(): void {
        this._lastActivityAt = new Date();
        this._lastActivities++;
    }

    private static async pollAsync(): Promise<void> {
        let pollIntervalInSeconds: number = AittaConstants.pollUserActivityInSeconds;

        try {
            if (ch.isAuthenticated) {
                const context: ApplicationContext = ch.getContext();

                const userActivityTtlInMinutes: number = context.settings.userActivityTtlInMinutes;
                
                if (userActivityTtlInMinutes > 0) {
                    const userActivityActionsPerInterval: number = context.settings.userActivityActions;
                    const userActivityActionsPerPoll: number = (pollIntervalInSeconds / 60) * (userActivityActionsPerInterval / userActivityTtlInMinutes);
                    
                    const active: boolean = (
                        (!document.hidden) &&
                        (this._lastActivities > userActivityActionsPerPoll) &&
                        (Utility.diff(new Date(), this._lastActivityAt).totalMinutes < userActivityTtlInMinutes)
                    );

                    this._lastActivities = 0;

                    if (active) {
                        await ApiProvider.postAsync("/api/application/confirmSessionActivity");
                    }
                }
            }
        } catch (error) {
            await PageRouteProvider.exception(error as any);
        } finally {
            await Utility.wait(1000 * pollIntervalInSeconds);

            await this.pollAsync();
        }
    }

    public static initialize(): void {
        if (!this._initialized) {
            setTimeout(() => this.pollAsync(), 0);

            window.onscroll = () => this.onUserInteraction();
            window.onkeyup = () => this.onUserInteraction();
            window.onmouseup = () => this.onUserInteraction();

            this._initialized = true;
        }
    }
}