import {ch, DataStorageType, PageRoute, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import User from "@/models/server/User";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import Customer from "@/models/server/Customer";
import {Button, Icon, Tab} from "@reapptor-apps/reapptor-react-components";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import OrderProduct from "@/models/server/OrderProduct";
import OrderMobileInfo from "@/models/server/OrderMobileInfo";
import Order from "@/models/server/Order";
import {CustomerHandler, CustomerLabelHandler, CustomerServiceType} from "@/models/Enums";
import ApplicationContext from "@/models/server/ApplicationContext";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import TagManager, {TagManagerArgs} from "react-gtm-module";
import Localizer from "@/localization/Localizer";
import EnumProvider from "@/providers/EnumProvider";
import styles from "@/mediq.module.scss";

export interface IGoogleAnalyticsSettings {

    googleAnalyticsEnabled: boolean;

    googleAnalyticsGtmContainerId: string;

    googleAnalyticsTestMode: boolean;
}

type TPlatform = "mobile" | "desktop";
type TLoginMethod = "username" | "office365";

const SelectedCustomerKey: string = "GoogleAnalyticsHelper.selectedCustomer";
const DefaultCurrency: string = "EUR";

const cookieBotId: string = "CybotCookiebotDialog";
const consentAllowAllButtonId: string = "CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll";
const consentAllowSelectedButtonId: string = "CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection";
const consentDeclineButtonId: string = "CybotCookiebotDialogBodyButtonDecline";
const consentStatisticsCheckboxId: string = "CybotCookiebotDialogBodyLevelButtonStatistics";
const consentBackdropId: string = "CybotCookiebotBackdrop";

enum ECommerceEventActions {
    add_to_cart,

    remove_from_cart,

    cart_quantity_changed,

    purchase
}

enum UserDataEventActions {
    push_button,

    login,

    customer_selected,

    customer_created
}

enum DataEventActions {
    push_button
}

interface ISelectedCustomer {
    code: string;

    serviceType: string;

    shelvingHandler: string;

    labelHandler: string;
}

interface ILoginParameters {
    method: TLoginMethod;
}

interface ICustomerSelectedParameters {
    customerCode: string;

    customerServiceType: string;

    shelvingHandler: string;

    labelHandler: string;
}

interface ICustomerCreatedParameters {

    customerCode: string;

    serviceType: string;

    shelvingHandler: string;

    labelHandler: string;
}

interface IPurchaseItem {
    transaction_id: string;

    currency: string;

    items: IEcommerceItem[];
}

interface IEcommerceItem {
    item_id: string;

    item_name: string;

    index: number;

    item_brand: string;

    item_express: boolean | null;

    item_category: string;

    item_category2: string;

    item_category3: string;

    item_list_id: string;

    item_list_name: string;

    item_variant: string;

    assumed_quantity: number;

    quantity: number;
}

interface IEcommerce {

    currency: string;

    items: IEcommerceItem[];
}

interface IAddToCartParameters extends IEcommerce {
}

interface IRemoveFromCartParameters extends IEcommerce {
}

interface ICartQuantityChangedParameters {
    items: IEcommerceItem[];
}

interface IPurchaseParameters extends IEcommerce, IPurchaseItem {
    // ecommerce: IPurchaseItem;
}

interface IPushButtonParameters {
    buttonName: string;

    buttonType?: string;

    buttonIcon?: string;

    buttonData?: string;
}

interface IDataLayerEvent {
    platform?: TPlatform,
    browserId?: string;
    roleName?: string;
    language?: string;
    event?: string;
    page?: string;
    title?: string;
    customerCode?: string;
    customerServiceType?: string;
    pageName?: string;
    tabName?: string;
    category?: string;
    label?: string;
}

interface IDataLayerEcommerceEvent extends IDataLayerEvent {
    ecommerce: IAddToCartParameters | IRemoveFromCartParameters | ICartQuantityChangedParameters | IPurchaseParameters;
}

interface IDataLayerUserDataEvent extends IDataLayerEvent {
    userData: ILoginParameters | ICustomerSelectedParameters | ICustomerCreatedParameters | ICartQuantityChangedParameters | IPurchaseParameters;
}

interface IDataLayerDataEvent extends IDataLayerEvent {
    data: IPushButtonParameters;
}

interface IDataLayer {
    push(args: IDataLayerEvent): void;
}

export default class GoogleAnalyticsHelper {
    private static _initialized: boolean = false;
    private static _statisticsCollectionAllowed: boolean = false;
    private static _dataLayer: IDataLayer | null = null;
    private static _platform: TPlatform | null = null;

    private static get dataLayer(): IDataLayer {
        return this._dataLayer ?? (this._dataLayer = (window as any).dataLayer);
    }

    private static get platform(): TPlatform {
        return this._platform ?? (this._platform = ((ch.mobile || ch.mobileApp) ? "mobile" : "desktop"));
    }

    private static get language(): string {
        return Localizer.language;
    }

    private static get pageName(): string {
        return ch.findRouteName();
    }

    private static get tabName(): string | undefined {
        return (Tab.activeTab?.id || undefined);
    }

    private static get browserId(): string | undefined {
        return (ch.findContext()?.browserId || undefined);
    }

    private static get roleName(): string | undefined {
        return (ch.findUser<User>()?.role?.roleName || undefined);
    }

    private static toEcommerceItem(product: OrderProductMobileInfo | OrderProduct, order: OrderMobileInfo | Order | null, index: number): IEcommerceItem {
        return {
            index: index,
            item_id: product.productAssortment?.product?.code,
            item_name: product.productAssortment?.product?.name,
            quantity: product.quantity,
            assumed_quantity: product.productAssortment?.orderQuantity,
            item_category: product.productAssortment?.product?.mainGroup,
            item_category2: product.productAssortment?.product?.subGroup,
            item_category3: product.productAssortment?.product?.subSubGroup,
            item_list_id: order?.id,
            item_list_name: order?.number?.toString(),
            item_express: (order) ? order.express : null
        } as IEcommerceItem
    }

    private static getCustomerServiceType(value: CustomerServiceType): string {
        const name: string = EnumProvider.getCustomerServiceTypeName(value);
        return name.split(".").last();
    }

    private static getCustomerLabelHandler(value: CustomerLabelHandler): string {
        const name: string = EnumProvider.getCustomerLabelHandlerName(value);
        return name.split(".").last();
    }

    private static getCustomerHandler(value: CustomerHandler): string {
        const name: string = EnumProvider.getCustomerHandlerName(value);
        return name.split(".").last();
    }

    private static pushEvent(event: IDataLayerEvent): void {

        const selectedCustomer: ISelectedCustomer | null = UserInteractionDataStorage.get(SelectedCustomerKey, null, DataStorageType.Session);

        if (this._initialized && GoogleAnalyticsHelper._statisticsCollectionAllowed) {
            event.platform = this.platform;
            event.roleName = this.roleName;
            event.browserId = this.browserId;
            event.language = this.language;
            event.pageName = this.pageName;
            event.tabName = this.tabName;
            event.customerCode = selectedCustomer?.code;
            event.customerServiceType = selectedCustomer?.serviceType;

            setTimeout(() => this.dataLayer.push(event), 0);
        }
    }

    private static async onRedirectCallbackAsync(route: PageRoute): Promise<void> {
        const page: string = "/" + route.name;
        const title: string = document.title;

        const event: IDataLayerEvent = {
            event: "pageview",
            page: page,
            title: title
        };

        this.pushEvent(event);
    }

    private static async initializeCookieBotDialogAsync(): Promise<void> {
        let cybotCookieBotDialog: HTMLElement | null = document.getElementById(cookieBotId);

        if (cybotCookieBotDialog == null) {
            const delayMs: number = 100;
            let attempts: number = 30;

            while ((cybotCookieBotDialog == null) && (attempts > 0)) {
                await Utility.wait(delayMs);

                cybotCookieBotDialog = document.getElementById(cookieBotId);

                attempts--;
            }
        }

        if (cybotCookieBotDialog != null) {
            let allowAllButton: HTMLElement | null = document.getElementById(consentAllowAllButtonId);
            let allowSelectedButton: HTMLElement | null = document.getElementById(consentAllowSelectedButtonId);
            let declineButton: HTMLElement | null = document.getElementById(consentDeclineButtonId);

            if (allowAllButton || allowSelectedButton || declineButton) {
                document.body.classList.add(styles.cookieBot);

                let backdrop:HTMLDivElement = document.createElement("div");
                backdrop.id = consentBackdropId;
                backdrop.classList.add(styles.backdrop);
                document.body.appendChild(backdrop);
            }

            if (allowAllButton) {
                allowAllButton.addEventListener("click", async () =>  {
                    GoogleAnalyticsHelper.onConsentButtonClick(true);
                });
            }

            if (allowSelectedButton) {
                let statisticsCheckbox: HTMLInputElement | null = document.getElementById(consentStatisticsCheckboxId) as HTMLInputElement;
                allowSelectedButton.addEventListener("click", async () => {
                    const checked: boolean = (statisticsCheckbox) ? statisticsCheckbox.checked : false;
                    GoogleAnalyticsHelper.onConsentButtonClick(checked);
                });
            }

            if (declineButton) {
                declineButton.addEventListener("click", async () => {
                    GoogleAnalyticsHelper.onConsentButtonClick(false);
                });
            }
        }
    }

    private static onConsentButtonClick(accepted: boolean): void {
        GoogleAnalyticsHelper._statisticsCollectionAllowed = accepted;
        UserInteractionDataStorage.set("GoogleAnalytics.statisticsCollectionAllowed", accepted, DataStorageType.Session);

        const backdrop: HTMLElement | null = document.getElementById(consentBackdropId);

        if (backdrop) {
            backdrop.remove();
        }

        document.body.classList.remove(styles.cookieBot);
    }

    public static async initializeAsync(): Promise<void> {
        if (!this._initialized) {

            const context: ApplicationContext = ch.getContext();
            const settings = context.settings as IGoogleAnalyticsSettings;

            const enabled: boolean = (settings.googleAnalyticsEnabled) && (!!settings.googleAnalyticsGtmContainerId);

            if (enabled) {
                const tagManagerArgs: TagManagerArgs = {
                    gtmId: settings.googleAnalyticsGtmContainerId,
                    auth: this.platform,
                    dataLayer: {
                        platform: this.platform,
                        platform2: this.platform
                    }
                }

                TagManager.initialize(tagManagerArgs);

                Button.registerOnClickCallback(async (button, buttonData) => this.buttonPushed(button, buttonData));

                this._initialized = true;

                ch.registerRedirectCallback((route: PageRoute) => GoogleAnalyticsHelper.onRedirectCallbackAsync(route));

                if (!GoogleAnalyticsHelper._statisticsCollectionAllowed) {
                    GoogleAnalyticsHelper._statisticsCollectionAllowed = UserInteractionDataStorage.get("GoogleAnalytics.statisticsCollectionAllowed", true, DataStorageType.Session);

                    setTimeout(() => GoogleAnalyticsHelper.initializeCookieBotDialogAsync(), 0);
                }
            }
        }
    }

    public static eCommerceEvent(action: ECommerceEventActions, params: IAddToCartParameters | IRemoveFromCartParameters | ICartQuantityChangedParameters | IPurchaseParameters): void {
        const eventAction: string = ECommerceEventActions[action];

        const event: IDataLayerEcommerceEvent = {
            event: eventAction,
            category: this.pageName,
            ecommerce: params
        };

        this.pushEvent(event);
    }

    public static userDataEvent(action: UserDataEventActions, params: ILoginParameters | ICustomerSelectedParameters | ICustomerCreatedParameters): void {
        const eventAction: string = UserDataEventActions[action];

        const event: IDataLayerUserDataEvent = {
            event: eventAction,
            category: this.pageName,
            userData: params
        };

        this.pushEvent(event);
    }

    public static dataEvent(action: UserDataEventActions, params: IPushButtonParameters): void {
        const eventAction: string = DataEventActions[action];

        const event: IDataLayerDataEvent = {
            event: eventAction,
            category: this.pageName,
            data: params
        };

        this.pushEvent(event);
    }

    public static login(method: TLoginMethod = "username"): void {
        const parameters: ILoginParameters = {
            method: method,
        };

        GoogleAnalyticsHelper.userDataEvent(UserDataEventActions.login, parameters);
    }

    public static customerSelected(customer: CustomerMobileInfo | Customer | null): void {
        if (customer) {

            const selectedCustomer: ISelectedCustomer = {
                code: customer.code,
                serviceType: this.getCustomerServiceType(customer.serviceType),
                labelHandler: this.getCustomerLabelHandler(customer.labelHandler),
                shelvingHandler: this.getCustomerHandler(customer.handler)
            };

            UserInteractionDataStorage.set(SelectedCustomerKey, selectedCustomer, DataStorageType.Session);

            const parameters: ICustomerSelectedParameters = {
                customerCode: customer.code,
                customerServiceType: this.getCustomerServiceType(customer.serviceType),
                labelHandler: this.getCustomerLabelHandler(customer.labelHandler),
                shelvingHandler: this.getCustomerHandler(customer.handler)
            };

            GoogleAnalyticsHelper.userDataEvent(UserDataEventActions.customer_selected, parameters);

        } else {

            UserInteractionDataStorage.set(SelectedCustomerKey, null, DataStorageType.Session);

        }
    }

    public static customerCreated(customer: Customer): void {
        const parameters: ICustomerCreatedParameters = {
            customerCode: customer.code,
            shelvingHandler: this.getCustomerHandler(customer.handler),
            labelHandler: this.getCustomerLabelHandler(customer.labelHandler),
            serviceType: this.getCustomerServiceType(customer.serviceType),
        };

        GoogleAnalyticsHelper.userDataEvent(UserDataEventActions.customer_created, parameters);
    }

    public static buttonPushed(button: Button, buttonData?: string | null): void {
        const parameters: IPushButtonParameters = {
            buttonName: button.id,
            buttonType: button.buttonTypeName,
            buttonIcon: Icon.getIconName(button.iconName) || undefined,
            buttonData: buttonData || undefined,
        };

        GoogleAnalyticsHelper.dataEvent(UserDataEventActions.push_button, parameters);
    }

    public static onCartChange(order: OrderMobileInfo | Order | null, product: OrderProductMobileInfo | OrderProduct): void {
        if (product.quantity == 0) {
            GoogleAnalyticsHelper.removeFromCart(order, product);
        } else {
            const productAlreadyExists: boolean = (order != null && order.products != null) && (product.productAssortment?.product != null) && (order.products.any(item => item.productAssortment?.product?.code == product.productAssortment!.product!.code));

            if (productAlreadyExists) {
                GoogleAnalyticsHelper.cartQuantityChanged(order, product);
            } else {
                GoogleAnalyticsHelper.addToCart(order, product);
            }
        }
    }

    public static addToCart(order: OrderMobileInfo | Order | null, product: OrderProductMobileInfo | OrderProduct): void;

    public static addToCart(order: OrderMobileInfo | Order | null, products: OrderProductMobileInfo[] | OrderProduct[]): void;
    public static addToCart(order: OrderMobileInfo | Order | null, products: OrderProductMobileInfo | OrderProduct | OrderProductMobileInfo[] | OrderProduct[]): void {
        const items: (OrderProductMobileInfo | OrderProduct)[] = (Array.isArray(products))
            ? products
            : [products];

        const parameters: IAddToCartParameters = {
            currency: DefaultCurrency,
            items: items.map((product: OrderProductMobileInfo | OrderProduct, index: number) => GoogleAnalyticsHelper.toEcommerceItem(product, order, index))
        };

        GoogleAnalyticsHelper.eCommerceEvent(ECommerceEventActions.add_to_cart, parameters);
    }

    public static cartQuantityChanged(order: OrderMobileInfo | Order | null, product: OrderProductMobileInfo | OrderProduct): void {
        const parameters: ICartQuantityChangedParameters = {
            items: [GoogleAnalyticsHelper.toEcommerceItem(product, order, 0)]
        };

        GoogleAnalyticsHelper.eCommerceEvent(ECommerceEventActions.cart_quantity_changed, parameters);
    }

    public static removeFromCart(order: OrderMobileInfo | Order | null, product: OrderProductMobileInfo | OrderProduct): void;

    public static removeFromCart(order: OrderMobileInfo | Order | null, products: OrderProductMobileInfo[] | OrderProduct[]): void;
    public static removeFromCart(order: OrderMobileInfo | Order | null, products: OrderProductMobileInfo | OrderProduct | OrderProductMobileInfo[] | OrderProduct[]): void {
        let items: (OrderProductMobileInfo | OrderProduct)[] = (Array.isArray(products))
            ? products
            : [products];

        const parameters: IRemoveFromCartParameters = {
            currency: DefaultCurrency,
            items: items.map((product: OrderProductMobileInfo | OrderProduct, index: number) => GoogleAnalyticsHelper.toEcommerceItem(product, order, index))
        };

        GoogleAnalyticsHelper.eCommerceEvent(ECommerceEventActions.remove_from_cart, parameters);
    }

    public static purchase(order: OrderMobileInfo | Order, products: OrderProductMobileInfo[] | OrderProduct[]): void {
        const parameters: IPurchaseParameters = {
            transaction_id: order.id,
            currency: DefaultCurrency,
            items: products.map((product: OrderProductMobileInfo | OrderProduct, index: number) => GoogleAnalyticsHelper.toEcommerceItem(product, order, index))
        };

        GoogleAnalyticsHelper.eCommerceEvent(ECommerceEventActions.purchase, parameters);
    }
}
