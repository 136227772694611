// autogenerated
import OrderingRule from "@/models/server/OrderingRule";

export default class SaveOrderingRuleRequest {

    public orderingRules: OrderingRule[] | null = null;

    public deletedProductAssortmentIds: string[] | null = null;

    public readonly isSaveOrderingRuleRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveOrderingRuleRequest) || ((from as any).isSaveOrderingRuleRequest === true));
    }

    public static as(from?: object | null): SaveOrderingRuleRequest | null {
        return (SaveOrderingRuleRequest.is(from)) ? from as SaveOrderingRuleRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}