import Customer from "@/models/server/Customer";
import Product from "@/models/server/Product";
import ProductReplacement from "@/models/server/ProductReplacement";
import {IPagedList} from "@reapptor-apps/reapptor-toolkit";
import CustomerGroup from "@/models/server/CustomerGroup";

export default class ProductReplacementItem {
    public id: string = "";

    public customerId: string = "";

    public customer: Customer | null = null;

    public customerGroupId: string = "";

    public customerGroup: CustomerGroup | null = null;
    
    public productId: string = "";

    public product: Product | null = null;
    
    public isFrom: boolean = false;
    
    public isTo: boolean = false;

    public owner: ProductReplacement = new ProductReplacement();

    public isProductReplacementItem: true = true;
    
    public static create(from: ProductReplacement, isFrom: boolean): ProductReplacementItem {
        const to = new ProductReplacementItem();
        to.owner = from;
        to.id = from.id;
        to.customer = from.customer;
        to.customerId = from.customerId ?? "";        
        to.customerGroup = from.customerGroup;
        to.customerGroupId = from.customerGroupId ?? "";
        to.isFrom = isFrom;
        to.isTo = !isFrom;
        to.product = (isFrom) ? from.fromProduct : from.toProduct;
        to.productId = (isFrom) ? from.fromProductId : from.toProductId;

        return to;
    }
    
    public static transform(from: IPagedList<ProductReplacement>): IPagedList<ProductReplacementItem> {
        const result: ProductReplacementItem[] = [];
        const items: ProductReplacement[] = from.items;
        for (let i: number = 0; i < items.length; i++) {
            result.push(ProductReplacementItem.create(items[i], true));
            result.push(ProductReplacementItem.create(items[i], false));
        }
        return {
            items: result,
            pageCount: from.pageCount,
            pageNumber: from.pageNumber,
            pageSize: from.pageSize,
            totalItemCount: from.totalItemCount,
            hasPreviousPage: from.hasPreviousPage,
            hasNextPage: from.hasNextPage,
            isLastPage: from.isLastPage,
            isFirstPage: from.isFirstPage,
            lastItemIndex: from.lastItemIndex,
            firstItemIndex: from.firstItemIndex
        };
    }
}