import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {Link, PageContainer, PageHeader, PageRow} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./Admin.module.scss"

export default class Admin extends AuthorizedPage {

    public get isAdmin(): boolean {
        return this.userContext.isAdmin;
    }

    public get isSiteAdmin(): boolean {
        return this.userContext.isSiteAdmin;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.admin}>
                <PageHeader title={Localizer.topNavAdminManagement} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">
                        <div className={styles.menu}>

                            <Link className={styles.menuLink} route={PageDefinitions.userManagementRoute}>{Localizer.userManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.productManagementRoute}>{Localizer.productManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.customerManagementRoute}>{Localizer.customerManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.labelsManagementRoute}>{Localizer.labelsManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.reportsManagementRoute}>{Localizer.reportsManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.inventoryManagementRoute}>{Localizer.inventoryManagementPage}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.orderManagementRoute}>{Localizer.orderManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.FaqManagementRoute}>{Localizer.faqManagementPageTitle}</Link>
                            <Link className={styles.menuLink} route={PageDefinitions.ProductAuditRoute}>{Localizer.auditPageTitle}</Link>

                            {
                                ((this.isAdmin) || (this.isSiteAdmin)) &&
                                (
                                    <Link className={styles.menuLink} route={PageDefinitions.bannerManagementRoute}>{Localizer.bannerManagementPageTitle}</Link>
                                )
                            }

                            {
                                (this.isSiteAdmin) &&
                                (
                                    <React.Fragment>
                                        <Link className={styles.menuLink} route={PageDefinitions.IfsAuditRoute}>{Localizer.ifsAuditPageTitle}</Link>
                                        <Link className={styles.menuLink} route={PageDefinitions.applicationSettingsRoute}>{Localizer.applicationSettingsPageTitle}</Link>
                                        <Link className={styles.menuLink} route={PageDefinitions.adminConsoleRoute}>{Localizer.adminConsolePageTitle}</Link>
                                    </React.Fragment>
                                )
                            }

                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}