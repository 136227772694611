import {AittaReportFilter, ReportType} from "@/models/Enums";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";

export enum ReportPageType {
    Products,
    
    Revenue
}

export default class ReportsToolbarModel {
    public reportType: ReportType = ReportType.Annual;

    public customerGroup: CustomerGroup | null = null;
    
    public customer: Customer | null = null;

    public search: string | null = null;

    public aittaReportFilter: AittaReportFilter = AittaReportFilter.All;
    
    public aittaAppOnly: boolean = false;
    
    public productsWithSalesOnly: boolean = false;
    
    public dataInitialized: boolean = false;

    public static initialized(toolbar: ReportsToolbarModel, hasMediqAccess: boolean): boolean {
        return (
            (toolbar.dataInitialized) &&
            (
                (hasMediqAccess) ||
                ((!!toolbar.customer?.id) || (!!toolbar.customerGroup?.id))
            )
        );
    }
}