import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import OrderProduct from "@/models/server/OrderProduct";
import {BorderType, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel} from "@reapptor-apps/reapptor-react-components";
import {OrderProductStatus} from "@/models/Enums";
import Order from "@/models/server/Order";
import Localizer from "@/localization/Localizer";

import styles from "./OrdersPanel.module.scss";

export interface IOrdersPanelProps {
    productAssortmentId: string;
}

interface IOrdersPanelState {
    showApplyTemplates: boolean;
}

export default class OrdersPanel extends BaseComponent<IOrdersPanelProps, IOrdersPanelState> {

    state: IOrdersPanelState = {
        showApplyTemplates: false
    };

    private readonly _gridRef: React.RefObject<Grid<OrderProduct>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: "3rem",
            maxWidth: "3rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalOrdersPanelOrderNumberLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.order!.number),
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalOrdersPanelOrderTypeLanguageItemName,
            type: ColumnType.Icon,
            accessor: (model: Order) => Order.getExpressIcon(model),
            minWidth: "7rem",
            maxWidth: "7rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.genericCreatedAtLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.order!.createdAt),
            format: "D",
            minWidth: "8rem",
            maxWidth: "8rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalOrdersPanelOrderedByLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.createdBy),
            minWidth: "14rem",
            maxWidth: "14rem",
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: nameof.full<OrderProduct>(o => o.createdBy!.email),
                infoHideEqual: true,
            }
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalOrdersPanelQuantityLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.quantity),
            format: "0",
            minWidth: "5rem",
            maxWidth: "5rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalOrdersPanelStatusLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.status),
            format: nameof(OrderProductStatus),
            minWidth: "8rem",
            maxWidth: "8rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.statisticsModalOrdersPanelOrderedAtLanguageItemName,
            accessor: nameof.full<OrderProduct>(o => o.deliveryDate),
            format: "D",
            minWidth: "8rem",
            maxWidth: "8rem",
            textAlign: TextAlign.Center,
        } as ColumnDefinition
    ];
    
    private get productAssortmentId(): string {
        return this.props.productAssortmentId;
    }

    private async fetchAsync(sender: Grid<OrderProduct>): Promise<OrderProduct[]> {
        return sender.postAsync("/api/productManagement/listProductOrders", this.productAssortmentId);
    }

    public async reloadAsync(): Promise<void> {
        await this.grid.reloadAsync();
    }

    public get grid(): GridModel<OrderProduct> {
        return this._gridRef.current!.model;
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.ordersPanel)}>

                <Grid id={`ordersPanelGrid_${this.productAssortmentId}`}
                      key={`ordersPanelGrid_${this.productAssortmentId}`}
                      ref={this._gridRef}
                      minWidth="auto"
                      hovering={GridHoveringType.None}
                      className={styles.ordersPanelGrid}
                      noDataText={Localizer.statisticsModalOrdersPanelNoOrdersLanguageItemName}
                      borderType={BorderType.NoSeparators}
                      columns={this._columns}
                      fetchData={(sender: Grid<OrderProduct>) => this.fetchAsync(sender)}
                />

            </div>
        )
    }
}