import React from "react";
import AittaConstants from "@/helpers/AittaConstants";
import Customer from "@/models/server/Customer";
import User from "@/models/server/User";
import {ActionType, BaseComponent, ch, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    CellAction,
    CellModel,
    Checkbox,
    ColumnActionDefinition,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownAlign,
    Grid,
    InlineType,
    JustifyContent,
    RowModel,
    TextInput,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import CustomerGroup from "@/models/server/CustomerGroup";
import UserRole from "@/models/server/UserRole";
import CustomerRole from "@/pages/UserManagement/UserRolesPanel/CustomerRole";
import NotificationsPanel from "@/pages/UserManagement/UserRolesPanel/NotificationsPanel/NotificationsPanel";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./UserRolesPanel.module.scss";

interface IUserRolesPanelProps {
    className?: string;
    customers: Customer[];
    user: User;
    isModified: boolean;
    readonly: boolean;
    onChange: (sender: UserRolesPanel, user: User) => Promise<void>;

    onChangeNotificationSettings: (sender: UserRolesPanel, user: User) => Promise<void>;
}

interface IUserRolesPanelState {
    customerGroups: CustomerGroup[];
    customerGroupId: string | null,
    codeOrName: string;
    activeRolesOnly: boolean;
    showDeletedCustomers: boolean;
    hasGroupLevelReportsAccess: boolean;
    canSetGroupLevelReportsAccess: boolean;
    roleName: string,
    customerGroupRoles: CustomerRole[];
}

type RefBinder = () => Promise<void>;

export default class UserRolesPanel extends BaseComponent<IUserRolesPanelProps, IUserRolesPanelState> {

    state: IUserRolesPanelState = {
        customerGroups: [],
        customerGroupId: null,
        codeOrName: "",
        activeRolesOnly: false,
        showDeletedCustomers: true,
        hasGroupLevelReportsAccess: false,
        canSetGroupLevelReportsAccess: (this.user.isManager && this.user.roles.any()),
        roleName: "",
        customerGroupRoles: [],
    };

    private readonly _customerGroupRolesGrid: React.RefObject<Grid<CustomerRole>> = React.createRef();

    private readonly _customerGroupRoleColumns: ColumnDefinition[] = [
        {
            header: Localizer.userRolesPanelGridNameLanguageItemName,
            accessor: (model: CustomerRole) => model.name,
            minWidth: 200,
            stretch: true,
            className: styles.code,
            init: (cell: CellModel<CustomerRole>) => this.initCode(cell),
            actions: [
                {
                    name: "toggle",
                    type: ActionType.Blue,
                    icon: {name: ""},
                    callback: (cell: CellModel<CustomerRole>) => this.toggleDetailsAsync(cell),
                } as ColumnActionDefinition,
            ],
            callback: (cell: CellModel<CustomerRole>) => this.toggleDetailsAsync(cell),
        } as ColumnDefinition,
        {
            header: Localizer.userRolesPanelGridOrderingLanguageItemName,
            accessor: (model: CustomerRole) => model.active || model.partiallyActive,
            init: (cell: CellModel<CustomerRole>) => this.initCustomerGroupRole(cell),
            minWidth: 110,
            type: ColumnType.Boolean,
            editable: true,
            textAlign: TextAlign.Center,
            callback: (cell: CellModel<CustomerRole>) => this.toggleRoleAsync(cell),
        } as ColumnDefinition
    ];

    private readonly _customerRoleColumns: ColumnDefinition[] = [
        {
            accessor: (model: CustomerRole) => model.codeOrName,
            init: (cell: CellModel<CustomerRole>) => this.initCode(cell),
            minWidth: 50,
        } as ColumnDefinition,
        {
            accessor: (model: CustomerRole) => model.name,
            init: (cell: CellModel<CustomerRole>) => this.initCustomerName(cell),
            minWidth: 100,
            stretch: true,
        } as ColumnDefinition,
        {
            accessor: (model: CustomerRole) => model.active || model.partiallyActive,
            init: (cell: CellModel<CustomerRole>) => this.initCustomerRole(cell),
            minWidth: 110,
            maxWidth: 110,
            type: ColumnType.Boolean,
            editable: true,
            textAlign: TextAlign.Center,
            callback: (cell: CellModel<CustomerRole>) => this.toggleRoleAsync(cell),
        } as ColumnDefinition
    ];

    private initCode(cell: CellModel<CustomerRole>): void {
        const toggleButton: CellAction<CustomerRole> = cell.actions[0];

        if (toggleButton) {
            toggleButton.action.icon!.name = (cell.row.expanded) ? "far fa-angle-up" : "far fa-angle-down";
        }

        cell.className = this.cssIf(cell.className, cell.model.deleted, styles.deleted);
    }

    private initCustomerGroupRole(cell: CellModel<CustomerRole>): void {
        const model: CustomerRole = cell.model;
        cell.className = this.cssIf(cell.className, model.partiallyActive, styles.partial);
        cell.className = this.cssIf(cell.className, model.deleted, styles.deleted);
    }

    private initCustomerName(cell: CellModel<CustomerRole>): void {
        const model: CustomerRole = cell.model;
        cell.className = this.cssIf(cell.className, model.deleted, styles.deleted);
    }

    private initCustomerRole(cell: CellModel<CustomerRole>): void {
        const model: CustomerRole = cell.model;
        cell.className = this.cssIf(cell.className, model.partiallyActive, styles.partial);
        cell.className = this.cssIf(cell.className, model.deleted, styles.deleted);
    }

    private async toggleRoleAsync(cell: CellModel<CustomerRole>): Promise<void> {
        const model: CustomerRole = cell.model;

        model.toggleActive();

        await cell.reRenderAsync();

        const refBinder: RefBinder | null = cell.row.tag as RefBinder | null;

        if (refBinder) {
            await refBinder();
        }

        this.user.roles = CustomerRole.getUserRoles(this.state.customerGroupRoles, this.primaryRole);

        await this.invokeOnChange();

        if ((this.roleName == AittaConstants.managerRole)) {

            if (this.user.roles.length == 1) {
                const role: UserRole = this.user.roles.first();
                
                const wholeGroupSelected: boolean = ((role.customerGroupId != null) && (role.customerId == null));
                const nothingSelected: boolean = ((role.customerGroupId == null) && (role.customerId == null));

                if (wholeGroupSelected) {
                    this.user.hasGroupLevelReportsAccess = true;
                } else if (nothingSelected) {
                    this.user.hasGroupLevelReportsAccess = false;
                }
            }

            const canSetGroupLevelReportsAccess = (!this.user.roles.all(item => (item.customerId == null)));
          
            await this.setState({canSetGroupLevelReportsAccess});
        }
    }

    private async toggleDetailsAsync(cell: CellModel<CustomerRole>): Promise<void> {
        await cell.row.toggleAsync();
    }

    private async invokeOnChange(): Promise<void> {
        await this.props.onChange(this, this.user);
    }

    private async setActiveRolesOnlyAsync(value: boolean): Promise<void> {
        await this.setState({activeRolesOnly: value});

        await this.toggleActiveRolesAsync();
    }

    private async setShowDeletedCustomersAsync(value: boolean): Promise<void> {
        await this.setState({showDeletedCustomers: value});
    }

    private async setHasGroupLevelReportsAccessAsync(value: boolean): Promise<void> {
        this.user.hasGroupLevelReportsAccess = value;
    }

    private async setCodeOrNameAsync(value: string): Promise<void> {
        await this.setState({codeOrName: value});
    }

    private async setCustomerGroupAsync(customerGroup: CustomerGroup, userInteraction: boolean): Promise<void> {
        this.state.customerGroupId = customerGroup.id;

        if (userInteraction) {
            this.resetCustomerRoles();
        }

        await this.reRenderAsync();

        await this.toggleActiveRolesAsync();

        if (userInteraction) {
            await this.invokeOnChange();
        }
    }

    private async toggleActiveRolesAsync(): Promise<void> {
        const grid: Grid<CustomerRole> | null = this._customerGroupRolesGrid.current;
        if (grid) {
            const forceExpanding: boolean = (!this.multipleCustomerGroups) || (this.activeRolesOnly);
            for (let i: number = 0; i < grid.rows.length; i++) {
                const row: RowModel<CustomerRole> = grid.rows[i];
                if ((!row.expanded) && ((row.model.partiallyActive) || ((forceExpanding) && (!row.model.active)))) {
                    await row.toggleAsync();
                }
            }
        }
    }

    private async setPrimaryRoleAsync(roleName: string): Promise<void> {
        const customerGroupSpecificRole: boolean = ((AittaConstants.customerRoles.includes(roleName)) && (roleName != AittaConstants.shelverPartnerRole));

        if (!customerGroupSpecificRole) {
            this.state.customerGroupId = null;
        }

        User.setPrimaryRole(this.user, roleName);

        this.resetCustomerRoles();

        this.state.activeRolesOnly = false;

        await this.reRenderAsync();

        await this.toggleActiveRolesAsync();

        await this.invokeOnChange();
    }

    private resetCustomerRoles(): void {
        this.primaryRole.customerGroupId = null;
        CustomerRole.reset(this.state.customerGroupRoles);
    }

    private get customers(): Customer[] {
        return this.props.customers;
    }

    private get readonly(): boolean {
        return this.props.readonly || false;
    }

    private get isSiteAdmin(): boolean {
        const user: User = ch.getUser();
        
        return user.isSiteAdmin;
    }

    private get customerGroupRoles(): CustomerRole[] {
        let roles: CustomerRole[] = this.state.customerGroupRoles;
        const customerGroupId: string | null = this.customerGroupId;
        if (customerGroupId) {
            roles = roles.where(item => item.id == customerGroupId || item.children.some(child => child.id == customerGroupId));
        }
        if (this.activeRolesOnly) {
            roles = roles.where(item => item.active || item.partiallyActive);
        }
        if (!this.showDeletedCustomers) {
            roles = roles.where(item => !item.deleted);
        }
        if (this.codeOrName) {
            const search: string = this.codeOrName.toLowerCase();
            roles = roles.where(item => item.name.toLowerCase().includes(search) || item.children.some(child => child.name.toLowerCase().includes(search)));
        }
        return roles;
    }

    private getCustomerRoles(customerRole: CustomerRole): CustomerRole[] {

        let roles: CustomerRole[] = customerRole.children;
        const customerGroupId: string | null = this.customerGroupId;
        if (customerGroupId) {
            roles = roles.where(item => item.id == customerGroupId || item.parent?.id == customerGroupId);
        }
        if (this.activeRolesOnly) {
            roles = roles.where(item => item.active || item.partiallyActive);
        }
        if (!this.showDeletedCustomers) {
            roles = roles.where(item => !item.deleted);
        }
        if (this.codeOrName) {
            const search: string = this.codeOrName.toLowerCase();
            roles = roles.where(item => item.name.toLowerCase().includes(search));
        }
        return roles;
    }

    private get user(): User {
        return this.props.user;
    }

    private get primaryRole(): UserRole {
        return User.getPrimaryRole(this.user);
    }

    private get codeOrName(): string {
        return this.state.codeOrName;
    }

    private get roleName(): string {
        return this.primaryRole.roleName;
    }

    private get hasSubRoles(): boolean {
        return AittaConstants.customerRoles.includes(this.roleName);
    }

    private get multipleCustomerGroups(): boolean {
        return (this.roleName == AittaConstants.shelverPartnerRole);
    }

    private get customerGroups(): CustomerGroup[] {
        return (this.showDeletedCustomers)
            ? this.state.customerGroups
            : this.state.customerGroups.where(item => !item.deleted);
    }

    private get customerGroupId(): string | null {
        return this.state.customerGroupId;
    }

    private get activeRolesOnly(): boolean {
        return this.state.activeRolesOnly;
    }

    private get hasGroupLevelReportsAccess(): boolean {
        return this.state.hasGroupLevelReportsAccess;
    }

    private get showDeletedCustomers(): boolean {
        return this.state.showDeletedCustomers;
    }

    private async onChangeAdminDailyNotificationAsync(): Promise<void> {
        await this.props.onChangeNotificationSettings(this, this.user);
    }

    private async reloadAsync(): Promise<void> {

        this.state.activeRolesOnly = false;

        this.state.customerGroups = Customer.getCustomerGroups(this.customers);

        this.state.customerGroupId = (!this.multipleCustomerGroups)
            ? this.primaryRole.customerGroupId
            : null;

        const customerRoles: CustomerRole[] = CustomerRole.getCustomerRoles(this.customers, this.user, this.primaryRole.roleName);

        this.state.customerGroupRoles = customerRoles.where(item => item.isCustomerGroup);

        await this.reRenderAsync();

        await this.toggleActiveRolesAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.reloadAsync();
    }

    public async componentWillReceiveProps(nextProps: IUserRolesPanelProps): Promise<void> {

        const isNewUser: boolean = (nextProps.user.id != this.props.user.id);
        const isUserCanceled: boolean = (!nextProps.isModified) && (this.props.isModified);
        const isNewCustomers: boolean = (nextProps.customers != this.props.customers);

        await super.componentWillReceiveProps(nextProps);

        if ((isNewCustomers) || (isNewUser) || (isUserCanceled)) {
            await this.reloadAsync();
        }
    }

    private initDetailsRow(row: RowModel<CustomerRole>, parentRow: RowModel<CustomerRole>): void {
        row.tag = () => parentRow.bindAsync();
    }

    public renderDetails(row: RowModel<CustomerRole>): React.ReactNode {
        const ref: React.RefObject<Grid<CustomerRole>> = React.createRef();
        row.tag = () => ref.current?.reloadAsync();
        return (
            <Grid noHeader
                  id={`grid_customerRoles_${row.index}`}
                  ref={ref}
                  noDataText={Localizer.userRolesPanelGridNoRolesFoundLanguageItemName}
                  className={styles.customerRolesGrid}
                  columns={this._customerRoleColumns}
                  data={this.getCustomerRoles(row.model)}
                  initRow={(innerRow: RowModel<CustomerRole>) => this.initDetailsRow(innerRow, row)}
            />
        );
    }

    public render(): React.ReactNode {
        const isManagerRolesTab: boolean = (AittaConstants.managerRole == this.roleName);

        return (
            <div id={this.id} className={this.css(styles.userRolesPanel)}>

                <ToolbarContainer className={this.css(styles.toolbar)}>

                    <ToolbarRow justify={JustifyContent.Start} className={styles.primaryRoles}>

                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  id={"admin"}
                                  label={Localizer.roleNameSiteAdmin}
                                  readonly={(this.readonly) || (!this.isSiteAdmin)}
                                  value={this.roleName == AittaConstants.siteAdminRole}
                                  onChange={() => this.setPrimaryRoleAsync(AittaConstants.siteAdminRole)}
                        />

                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  id={"admin"}
                                  label={Localizer.roleNameAdmin}
                                  readonly={this.readonly}
                                  value={this.roleName == AittaConstants.adminRole}
                                  onChange={() => this.setPrimaryRoleAsync(AittaConstants.adminRole)}
                        />

                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  id={"master"}
                                  label={Localizer.roleNameMaster}
                                  readonly={this.readonly}
                                  value={this.roleName == AittaConstants.masterRole}
                                  onChange={() => this.setPrimaryRoleAsync(AittaConstants.masterRole)}
                        />

                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  id={"shelver"}
                                  label={Localizer.roleNameShelver}
                                  readonly={this.readonly}
                                  value={this.roleName == AittaConstants.shelverRole}
                                  onChange={() => this.setPrimaryRoleAsync(AittaConstants.shelverRole)}
                        />

                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  id={"manager"}
                                  label={Localizer.roleNameManager}
                                  readonly={this.readonly}
                                  value={this.roleName == AittaConstants.managerRole}
                                  onChange={() => this.setPrimaryRoleAsync(AittaConstants.managerRole)}
                        />

                        <Checkbox inline
                                  inlineType={InlineType.Right}
                                  id={"shelverPartner"}
                                  label={Localizer.roleNameShelverPartner}
                                  readonly={this.readonly}
                                  value={this.roleName == AittaConstants.shelverPartnerRole}
                                  onChange={() => this.setPrimaryRoleAsync(AittaConstants.shelverPartnerRole)}
                        />

                    </ToolbarRow>

                    <NotificationsPanel primaryRole={this.roleName}
                                        user={this.user}
                                        onChangeDailyNotifications={() => this.onChangeAdminDailyNotificationAsync()}
                    />

                    {
                        (this.hasSubRoles) &&
                        (
                            <ToolbarRow justify={JustifyContent.Start} className={styles.subRoles}>

                                {
                                    (!this.multipleCustomerGroups) ?
                                        (
                                            <Dropdown id="customerGroups" noWrap noGrouping required
                                                      className={styles.customerGroups}
                                                      align={DropdownAlign.Left}
                                                      width={AittaConstants.customersDropdownMinWidth}
                                                      nothingSelectedText={Localizer.userRolesPanelFiltersChooseCustomerGroup}
                                                      label={Localizer.userRolesPanelFiltersCustomerGroup}
                                                      items={this.customerGroups}
                                                      transform={(item: CustomerGroup) => TransformProvider.toCustomerGroupListItem(item)}
                                                      selectedItem={this.customerGroupId}
                                                      onChange={(sender: Dropdown<CustomerGroup>, item: CustomerGroup, userInteraction) => this.setCustomerGroupAsync(item, userInteraction)}
                                            />
                                        ) :
                                        (

                                            <TextInput clearButton
                                                       id={"codeOrName"}
                                                       label={Localizer.userRolesPanelFiltersCodeOrNameLabel}
                                                       placeholder={Localizer.userRolesPanelFiltersCodeOrName}
                                                       value={this.codeOrName}
                                                       width={AittaConstants.customersDropdownMinWidth}
                                                       onChange={(sender, value) => this.setCodeOrNameAsync(value)}
                                            />
                                        )
                                }

                                <Checkbox inline
                                          inlineType={InlineType.Left}
                                          id={"activeRolesOnly"}
                                          label={Localizer.userRolesPanelFiltersActiveOnly}
                                          value={this.activeRolesOnly}
                                          onChange={(sender, value) => this.setActiveRolesOnlyAsync(value)}
                                />

                                <Checkbox inline
                                          inlineType={InlineType.Left}
                                          id={"showDeletedCustomers"}
                                          label={Localizer.userRolesPanelFiltersShowDeletedCustomers}
                                          value={this.showDeletedCustomers}
                                          onChange={(sender, value) => this.setShowDeletedCustomersAsync(value)}
                                />


                                {
                                    (isManagerRolesTab) &&
                                    (
                                        <Checkbox inline
                                                  inlineType={InlineType.Left}
                                                  id={"showDeletedCustomers"}
                                                  label={Localizer.userRolesPanelFiltersHasGroupLevelReportsAccess}
                                                  value={this.user.hasGroupLevelReportsAccess}
                                                  readonly={!this.state.canSetGroupLevelReportsAccess}
                                                  onChange={(sender, value) => this.setHasGroupLevelReportsAccessAsync(value)}
                                        />
                                    )
                                }

                            </ToolbarRow>
                        )
                    }

                </ToolbarContainer>

                {
                    (this.hasSubRoles) &&
                    (
                        <Grid responsive autoToggle
                              ref={this._customerGroupRolesGrid}
                              id={"customerGroupRolesGrid"}
                              className={styles.customerGroupRolesGrid}
                              columns={this._customerGroupRoleColumns}
                              noDataText={Localizer.userRolesPanelGridNoRolesFoundLanguageItemName}
                              data={this.customerGroupRoles}
                              renderDetails={(row: RowModel<CustomerRole>) => this.renderDetails(row)}
                        />
                    )
                }

            </div>
        )
    }
}