import {ProductAssortmentFilter, ProductAssortmentType, ProductReplacementLevel, ProductTemplateFilter} from "@/models/Enums";
import CustomerGroup from "@/models/server/CustomerGroup";
import Customer from "@/models/server/Customer";

export enum ProductPanelType {
    ProductsSelection,

    Templates,

    OrderingRules,

    Replacements
}

export default class ProductsToolbarModel {
    public assortmentFilter: ProductAssortmentFilter = ProductAssortmentFilter.All;

    public templateFilter: ProductTemplateFilter = ProductTemplateFilter.All;

    public replacementLevelFilter: ProductReplacementLevel = ProductReplacementLevel.Customer;

    public showHistory: boolean = false;

    public showAllReplacements: boolean = false;

    public showAllCustomers: boolean = false;

    public customerGroup: CustomerGroup | null = null;

    public customer: Customer | null = null;

    public productAssortmentType: ProductAssortmentType = ProductAssortmentType.Customer;

    public templateId: string | null = null;

    public search: string | null = null;

    public dataInitialized: boolean = false;

    public initialized(type: ProductPanelType): boolean {
        return (
            (
                (type == ProductPanelType.Replacements) &&
                (this.replacementLevelFilter == ProductReplacementLevel.Global)
            ) ||
            (
                ((this.dataInitialized) &&
                    (
                        (!!this.customerGroup?.id) ||
                        (!!this.customer?.id) ||
                        (this.customerGroup == null && this.customer == null && this.showAllCustomers)
                    ) ||
                    (type == ProductPanelType.Templates)
                )
            )
        );
    }
}