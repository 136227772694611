import React, {ReactElement} from "react";
import {AddressHelper, DropdownOrderBy, DropdownRequiredType, DropdownWidget, Icon, IconSize, PageContainer, RouteWidget, WidgetContainer} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import {ch, IManualProps, PageCacheProvider, PageCacheTtl, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import {CustomerServiceType, DeliveryDayOfWeek} from "@/models/Enums";
import User from "@/models/server/User";
import ScanQrCodeModal from "@/components/Catalog/ScanQrCodeModal/ScanQrCodeModal";
import ListMobileCustomersResponse from "@/models/server/responses/ListMobileCustomersResponse";
import ListMobileCustomersRequest from "@/models/server/requests/ListMobileCustomersRequest";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./Dashboard.module.scss";

export interface IDashboardProps {
}

interface IDashboardState {
    expanded: boolean;
    customers: CustomerMobileInfo[];
    hasCustomers: boolean;
    initialized: boolean;
}

export default class Dashboard extends MobileAuthorizedPage<IDashboardProps, IDashboardState> {

    state: IDashboardState = {
        expanded: (this.selectedCustomer == null),
        customers: [],
        hasCustomers: false,
        initialized: false
    };

    private readonly _dropdownRef: React.RefObject<DropdownWidget<CustomerMobileInfo>> = React.createRef();
    private readonly _qrModalRef: React.RefObject<ScanQrCodeModal> = React.createRef();
    private _onScanQrClickInvoking: boolean = false;
    
    private async getLocationAsync(): Promise<GeoCoordinate | null> {
        return await Utility.getLocationAsync({ maximumAge: 60000, timeout: 1000 });
    }

    private async fetchCustomersAsync(sender: DropdownWidget<CustomerMobileInfo>): Promise<CustomerMobileInfo[]> {
        const request = new ListMobileCustomersRequest();

        const response: ListMobileCustomersResponse = await PageCacheProvider.getAsync(this.getListCustomersCacheKey(), () => sender.postAsync("/api/mobileApp/listMobileCustomers", request), PageCacheTtl._1h);

        const customers: CustomerMobileInfo[] = response.customers ?? [];

        const hasCustomers: boolean = (customers.length > 0);

        let expanded: boolean = this.state.expanded;

        if (hasCustomers) {

            if (customers.length == 1) {

                expanded = false;

                await this.selectCustomerAsync(customers.single());

            } else {

                const currentLocation: GeoCoordinate | null = await this.getLocationAsync();
                
                if (currentLocation != null) {
                    customers.sortBy((item: CustomerMobileInfo) => (item.location) ? AddressHelper.distance(currentLocation, item.location) : Number.MAX_VALUE);
                }

            }

        } else {

            expanded = false;

            await ch.alertWarningAsync(Localizer.mobileDashboardNoCustomerAccess, true, false);

        }

        await this.setState({customers, hasCustomers, expanded, initialized: true});

        if (expanded) {
            setTimeout(() => this.focusAsync(), 0);
        }

        return customers;
    }

    private async findClosestCustomerInRangeAsync(rangeMeters: number): Promise<void> {
        const currentLocation: GeoCoordinate | null = await this.getLocationAsync();
        
        if (currentLocation != null) {

            const actualDistanceMeters: number = ((this.selectedCustomer) && (this.selectedCustomer.location))
                ? 1000 * AddressHelper.distance(currentLocation, this.selectedCustomer.location)
                : Number.MAX_VALUE;

            const selectedCustomerInTheRadius: boolean = (actualDistanceMeters < rangeMeters);

            if (!selectedCustomerInTheRadius) {

                const customersInRange: CustomerMobileInfo[] = this
                    .customers
                    .where(item => (item.location != null) && (1000 * AddressHelper.distance(currentLocation, item.location) < rangeMeters));

                const closestCustomer: CustomerMobileInfo | null = customersInRange.firstOrDefault();

                if (closestCustomer) {
                    const actualDistanceMeters: number = 1000 * AddressHelper.distance(currentLocation, closestCustomer.location!);

                    const closestCustomerInTheRadius = ((actualDistanceMeters) < rangeMeters);

                    if ((closestCustomerInTheRadius) && (this.selectedCustomer?.code != closestCustomer.code)) {
                        await this.selectCustomerAsync(closestCustomer);
                    }
                } else {
                    await this.alertWarningAsync(Localizer.mobileDashboardNoClosestCustomer, true);
                }
            }

        } else {
            await this.alertWarningAsync(Localizer.mobileDashboardNoLocation, true);
        }
    }

    private getListCustomersCacheKey(): string {
        return `listCustomers_${this.username}`;
    }

    public getManualProps(): IManualProps {
        return {
            icon: "info-circle",
            title: Localizer.mobileDashboardTitle.toUpperCase(),
            render: () => this.renderManual()
        };
    }

    private async onQrAsync(code: string): Promise<void> {

        await this._qrModalRef.current?.closeAsync();

        const customer: CustomerMobileInfo | null = this.customers.firstOrDefault(item => (item.code == code) || (item.codeInfo.includes(code)));

        const customerFound: boolean = (customer != null);

        if ((customerFound) && (this._dropdownRef.current)) {
            await this._dropdownRef.current.selectItemAsync(customer);
        }

        if (!customerFound) {
            await this.alertWarningAsync(Localizer.mobileDashboardNoCustomer, true);
        }
    }

    private async selectCustomerAsync(customer: CustomerMobileInfo): Promise<void> {
        AittaController.setDefaultCustomerGroupOrCustomer(customer);
    }

    private async onSelectCustomerAsync(customer: CustomerMobileInfo): Promise<void> {
        await this.selectCustomerAsync(customer);
    }

    private async setCustomerFavoriteAsync(customer: CustomerMobileInfo, favorite: boolean): Promise<void> {
        customer.favorite = favorite;

        AittaController.setFavorite(customer.id, favorite);

        PageCacheProvider.clear(this.getListCustomersCacheKey());
    }

    private async onToggleAsync(expanded: boolean): Promise<void> {
        await this.setState({expanded});

        await this.focusAsync();
    }

    private async onScanQrClickAsync(): Promise<void> {
        if (this._onScanQrClickInvoking) {
            return;
        }

        try {
            this._onScanQrClickInvoking = true;
            await this._qrModalRef.current?.openAsync();
        } finally {
            this._onScanQrClickInvoking = false;
        }
    }

    private async focusAsync(): Promise<void> {
        if ((this.state.expanded) && (this._dropdownRef.current)) {

            await Utility.wait(100);

            const input = this.JQuery(`#${this._dropdownRef.current.id} input`);

            input.focus();
        }
    }

    protected get hasCustomers(): boolean {
        return this.state.hasCustomers;
    }

    public get selectedCustomer(): CustomerMobileInfo | null {
        return AittaController.currentCustomer;
    }

    protected get customerRequired(): boolean {
        return false;
    }

    public get expanded(): boolean {
        return this.state.expanded;
    }

    public get initialized(): boolean {
        return this.state.initialized;
    }

    public get customers(): CustomerMobileInfo[] {
        return this.state.customers;
    }

    public get handler(): string {
        return (this.selectedCustomer)
            ? Localizer.get(EnumProvider.getCustomerHandlerName(this.selectedCustomer.handler))
            : "";
    }

    public get labelHandler(): string {
        return (this.selectedCustomer)
            ? Localizer.get(EnumProvider.getCustomerLabelHandlerName(this.selectedCustomer.labelHandler))
            : "";
    }

    public get service(): string {
        return (this.selectedCustomer)
            ? Localizer.get(EnumProvider.getCustomerServiceTypeName(this.selectedCustomer.serviceType))
            : "";
    }

    public get deliveryDay(): DeliveryDayOfWeek | null {
        return this.selectedCustomer?.deliveryDay ?? null;
    }

    public get closeDay(): DeliveryDayOfWeek | null {
        return this.selectedCustomer?.closeDay ?? null;
    }

    public get hasCalendar(): boolean {
        return (
            (this.selectedCustomer != null) &&
            (this.selectedCustomer.serviceType != CustomerServiceType.Subscription) &&
            (this.selectedCustomer.serviceType != CustomerServiceType.MiniAitta)
        );
    }

    public get isMasterOrAdmin(): boolean {
        const user: User = this.getUser();
        return (user.isMaster || user.isAdmin);
    }

    public get canRequestLabels(): boolean {
        return (
            (this.selectedCustomer != null) &&
            (this.selectedCustomer.serviceType != CustomerServiceType.Shelving) &&
            (this.selectedCustomer.serviceType != CustomerServiceType.Subscription)
        );
    }

    public get deliveryInterval(): (ReactElement | string)[] {
        return (this.selectedCustomer)
            ? (this.selectedCustomer.deliveryInterval > 1)
                ? this.toMultiLines(Localizer.mobileDashboardDeliveryIntervalWeeks.format(this.selectedCustomer.deliveryInterval))
                : this.toMultiLines(Localizer.mobileDashboardDeliveryIntervalWeek.format(this.selectedCustomer.deliveryInterval))
            : [];
    }

    public get header(): string {
        return (this.selectedCustomer) ? this.selectedCustomer.name : "";
    }
    
    public renderManual(): React.ReactNode {

        const selectedCustomer: CustomerMobileInfo | null = this.selectedCustomer;
        
        return (
            <div className={styles.manual}>

                {
                    (selectedCustomer) &&
                    (
                        <React.Fragment>

                            <span>{Localizer.mobileInfoCustomer}: <b> <mark> {ReactUtility.toTags(selectedCustomer.codeInfo)} </mark> - {selectedCustomer.name} </b></span>


                            <span>{Localizer.mobileInfoGroup}: <b>{selectedCustomer.customerGroupName}</b></span>

                            {
                                (selectedCustomer.spaces) &&
                                (
                                    <span>{Localizer.mobileDashboardAdditionalAittaStorage}: <b>{selectedCustomer.spaces}</b></span>
                                )
                            }

                            <span>{Localizer.mobileDashboardShelvingHandler}: <b>{this.handler}</b></span>

                            <span>{Localizer.mobileDashboardLabelHandler}: <b>{this.labelHandler}</b></span>

                            <span>{Localizer.mobileDashboardServiceType}: <b>{this.service}</b></span>

                            {
                                (!this.hasCalendar) &&
                                (
                                    <span>{this.toMultiLines(Localizer.mobileDashboardMidnightCloseDate)}</span>
                                )
                            }

                            {
                                (this.hasCalendar) &&
                                (
                                    <React.Fragment>
                                        <span>{this.deliveryInterval}</span>

                                        <span>{this.toMultiLines(Localizer.mobileDashboardCloseDay.format(this.closeDay))}</span>

                                        {
                                            (selectedCustomer.nextCloseDate) &&
                                            (
                                                <span>{this.toMultiLines(Localizer.mobileDashboardNextCloseDate.format(selectedCustomer.nextCloseDate))}</span>
                                            )
                                        }

                                        <span>{this.toMultiLines(Localizer.mobileDashboardDeliveryDay.format(this.deliveryDay))}</span>

                                        {
                                            (selectedCustomer.nextDeliveryDate) &&
                                            (
                                                <span>{this.toMultiLines(Localizer.mobileDashboardNextDeliveryDate.format(selectedCustomer.nextDeliveryDate))}</span>
                                            )
                                        }

                                        <span>{Localizer.genericAddress}: <b>{CustomerMobileInfo.deliveryAddress(selectedCustomer)}</b></span>

                                    </React.Fragment>
                                )
                            }

                        </React.Fragment>
                    )
                }

                {
                    (!selectedCustomer && this.state.initialized) &&
                    (
                        <span>{Localizer.mobileDashboardNoCustomerSelected}</span>
                    )
                }

                {
                    (this.customers.length == 0 && this.state.initialized) &&
                    (
                        <span>{Localizer.mobileDashboardNoCustomerAccess}</span>
                    )
                }

            </div>
        );
    }

    public render(): React.ReactNode {
        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.dashboard, this.mobile && styles.mobile)}
            >

                <WidgetContainer>

                    <BannerMessageAlert className={styles.banner} />

                    {
                        ((!this.initialized) || (this.customers.length > 1)) &&
                        (
                            <React.Fragment>

                                <div className={styles.buttonsContainer}>

                                    <div className={styles.add}
                                         onClick={() => this.onScanQrClickAsync()}
                                    >
                                        <Icon name={"far fa-camera"} size={IconSize.X2}/>
                                    </div>

                                    <div className={styles.add} onClick={() => this.findClosestCustomerInRangeAsync(100)}>
                                        <Icon name={"far fa-location"} size={IconSize.X2}/>
                                    </div>

                                </div>

                            </React.Fragment>
                        )
                    }

                    {
                        ((!this.initialized) || (this.hasCustomers)) &&
                        (
                            <DropdownWidget wide required favorite stretchContent autoCollapse
                                            ref={this._dropdownRef}
                                            id={"customers"}
                                            minimized={!this.expanded}
                                            expanded={this.expanded}
                                            requiredType={DropdownRequiredType.Restricted}
                                            className={styles.widget}
                                            label={Localizer.mobileDashboardCustomer}
                                            icon={{name: "far fa-hospital"}}
                                            description={(this.initialized) ? Localizer.mobileDashboardSelectCustomer : Localizer.genericLoading}
                                            orderBy={DropdownOrderBy.None}
                                            selectedItem={AittaController.currentCustomer}
                                            fetchDataAsync={(sender: DropdownWidget<CustomerMobileInfo>) => this.fetchCustomersAsync(sender)}
                                            transform={(item: CustomerMobileInfo) => TransformProvider.toCustomerMobileInfoListItem(item)}
                                            onChange={(_, item: CustomerMobileInfo) => this.onSelectCustomerAsync(item)}
                                            onToggle={(_, expanded: boolean) => this.onToggleAsync(expanded)}
                                            onFavoriteChange={(_: DropdownWidget<CustomerMobileInfo>, item: CustomerMobileInfo, favorite: boolean) => this.setCustomerFavoriteAsync(item, favorite)}
                            />
                        )
                    }

                    {
                        ((this.selectedCustomer) && (!this.expanded)) &&
                        (
                            <>

                                <RouteWidget className={this.css(styles.widget, styles.flex)} minimized icon={{name: "far fa-cart-plus"}} route={PageDefinitions.mobileRegularOrderRoute} label={Localizer.mobileDashboardOrder}/>

                                {
                                    (!this.userContext.isShelverPartner) &&
                                    (
                                        <RouteWidget className={this.css(styles.widget, styles.flex)} minimized icon={{name: "far fa-shipping-fast"}} route={PageDefinitions.mobileExpressOrderRoute} label={Localizer.mobileDashboardExpressOrder}/>
                                    )
                                }

                                <RouteWidget minimized
                                             className={this.css(styles.widget, styles.flex)}
                                             icon={{name: "fal fa-truck-loading"}}
                                             route={PageDefinitions.mobilePostDeliveryRoute}
                                             label={Localizer.mobilePostDeliveryPageTitle}
                                />
                                
                                <RouteWidget className={this.css(styles.widget, styles.flex)} minimized icon={{name: "far fa-history"}} route={PageDefinitions.mobileOrderHistoryRoute} label={Localizer.mobileDashboardOrderHistory}/>

                                {
                                    (this.canRequestLabels) &&
                                    (
                                        <RouteWidget className={this.css(styles.widget, styles.flex)} minimized icon={{name: "far fa-scanner"}} route={PageDefinitions.mobileOrderLabelsRoute} label={Localizer.mobileDashboardOrderLabels}/>
                                    )
                                }

                                {
                                    (AittaController.canDoInventory) &&
                                    (
                                        <RouteWidget className={this.css(styles.widget, styles.flex)} minimized icon={{name: "far fa-inventory"}} route={PageDefinitions.mobileInventoryRoute} label={Localizer.mobileDashboardInventory}/>
                                    )
                                }

                                {
                                    (this.isMasterOrAdmin) &&
                                    (

                                        <RouteWidget className={this.css(styles.widget, styles.flex)} minimized icon={{name: "far fa-warehouse-alt"}} route={PageDefinitions.mobileInventoryManagementRoute}
                                                     label={Localizer.mobileDashboardInventoryManagement}/>

                                    )
                                }
                            </>
                        )
                    }

                    {
                        ((this.initialized) && (!this.hasCustomers)) &&
                        (
                            <RouteWidget icon={{name: "users"}}
                                         route={PageDefinitions.contactSupportRoute}
                                         label={Localizer.topNavContactSupport}
                                         description={Localizer.dashboardPageContactSupportDescription}
                            />
                        )
                    }

                </WidgetContainer>

                <ScanQrCodeModal ref={this._qrModalRef}
                                 title={Localizer.mobileDashboardScanCustomer}
                                 onQr={(_, code: string) => this.onQrAsync(code)}
                />

            </PageContainer>
        );
    }
}