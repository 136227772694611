import React from "react";
import LabelsToolbarModel, {LabelsPanelType} from "@/pages/LabelsManagement/LabelsToolbar/LabelsToolbarModel";
import Customer from "@/models/server/Customer";
import {BaseComponent, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Checkbox, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, IconSize, Inline, JustifyContent, SelectListItem, Spinner, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import CustomerGroup from "@/models/server/CustomerGroup";
import ProductAssortment from "@/models/server/ProductAssortment";
import ProductLabel from "@/models/server/ProductLabel";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import AittaConstants from "@/helpers/AittaConstants";
import {ExportEncodingType} from "@/models/Enums";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import AittaController from "@/pages/AittaController";

import styles from "./LabelsToolbar.module.scss";

export interface ILabelsToolbarProps {
    type: LabelsPanelType;
    model: LabelsToolbarModel;
    hideCustomersInSearch?: boolean;
    onChange?(sender: LabelsToolbar, model: LabelsToolbarModel): Promise<void>;
    createLabels?(): Promise<void>;
    printLabels?(all: boolean, except: boolean): Promise<void>;
    deleteLabels?(all: boolean, except: boolean): Promise<void>;
    downloadCsv?(encodingType: ExportEncodingType): Promise<void>;
    downloadPdf?(all: boolean, except: boolean): Promise<void>;
}

interface ILabelsToolbarState {
    model: LabelsToolbarModel;
    isLoading: boolean;
    selectedCustomerOrGroup: Customer[] | CustomerGroup | null;
    customers: Customer[];
    assortments: ProductAssortment[];
    selectedLabels: ProductLabel[];
}

export default class LabelsToolbar extends BaseComponent<ILabelsToolbarProps, ILabelsToolbarState> {

    state: ILabelsToolbarState = {
        model: this.props.model,
        customers: [],
        isLoading: true,
        selectedCustomerOrGroup: null,
        assortments: [],
        selectedLabels: []
    };

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/labelsManagement/listCustomers"));
    }

    private async selectCustomerHandlerFilterAsync(item: SelectListItem | null): Promise<void> {
        this.model.customerHandler = (item != null) ? parseInt(item.value) : null;
        await this.processAsync(true);
    }

    private async setSearchAsync(search: string): Promise<void> {
        this.model.search = search;

        await this.processAsync(true);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this, this.state.model);
        }
    }

    private async selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer: CustomerGroup | Customer | null, userInteraction: boolean): Promise<void> {
        this.model.customerGroup = CustomerGroup.is(customerGroupOrCustomer) ? customerGroupOrCustomer as CustomerGroup : null;
        this.model.customer = Customer.is(customerGroupOrCustomer) ? customerGroupOrCustomer as Customer : null;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customerGroupOrCustomer);
        }

        await this.processAsync(true);
    }

    private async setShowPrintedAsync(value: boolean): Promise<void> {
        this.model.showPrintedOnly = value;
        this.state.selectedLabels = [];

        await this.processAsync(true);
    }

    private async setPrintedDateFilterAsync(value: Date | null): Promise<void> {
        this.model.printedDate = value?.date() ?? null;

        await this.processAsync(true);
    }

    public async createLabelsAsync(): Promise<void> {
        if (this.props.createLabels) {
            await this.props.createLabels();
        }
    }

    public async printLabelsAsync(all: boolean, except: boolean): Promise<void> {
        if (this.props.printLabels) {
            await this.props.printLabels(all, except);
        }
    }

    public async deleteLabelsAsync(all: boolean, except: boolean): Promise<void> {
        if (this.props.deleteLabels) {
            await this.props.deleteLabels(all, except);
        }
    }

    public async downloadPdfAsync(all: boolean, except: boolean): Promise<void> {
        if (this.props.downloadPdf) {
            await this.props.downloadPdf(all, except);
        }
    }

    public async downloadCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {
        if (this.props.downloadCsv) {
            await this.props.downloadCsv(encodingType);
        }
    }

    public async selectProductAssortments(assortments: ProductAssortment[]): Promise<void> {
        await this.setState({assortments});
    }

    public async selectProductLabelsAsync(labels: ProductLabel[]): Promise<void> {
        this.state.selectedLabels = labels;
        await this.setState({selectedLabels: labels});
    }

    public get items(): (CustomerGroup | Customer)[] {
        return (this.type == LabelsPanelType.ProductsSelection)
            ? this.state.customers
            : Customer.group(this.state.customers);
    }

    public get selectedLabelsCount(): number {
        return this.state.selectedLabels.length;
    }

    public get showCustomerHandlerFilter(): boolean {
        return (this.type == LabelsPanelType.LabelsSelection) && (this.model.customer == null)
    }

    private get hideCustomersInSearch(): boolean {
        return this.props.hideCustomersInSearch ?? false;
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get type(): LabelsPanelType {
        return this.props.type;
    }

    public get model(): LabelsToolbarModel {
        return this.state.model;
    }

    public get customerGroupId(): string | null {
        return this.model.customerGroup?.id || null;
    }

    public get customerId(): string | null {
        return this.model.customer?.id || null;
    }

    public get search(): string | null {
        return this.model.search || null;
    }

    public hasSpinner(): boolean {
        return true;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const customers: Customer[] = await this.fetchCustomersAsync();

        const restoreCustomerSelection: boolean = (this.type == LabelsPanelType.ProductsSelection);

        this.state.customers = customers;
        this.state.model.customerGroup = null;
        this.state.model.customer = restoreCustomerSelection ? AittaController.getDefaultCustomer(customers) : null;

        this.model.dataInitialized = true;

        await this.processAsync(true);

        await this.setState({isLoading: false});
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={this.css(styles.labelsToolbar)}>

                <Form onSubmit={() => this.processAsync(true)}>

                    <ToolbarRow justify={JustifyContent.SpaceBetween}>

                        <Inline>

                            <Dropdown id="customerGroupsOrCustomers" noWrap inline noGrouping clearButton
                                      required={this.props.type == LabelsPanelType.ProductsSelection}
                                      className={styles.customers}
                                      align={DropdownAlign.Left}
                                      disabled={this.isLoading}
                                      width={AittaConstants.customersDropdownMinWidth}
                                      items={this.items}
                                      filterMinSymbols={this.hideCustomersInSearch ? AittaConstants.minimumSymbolsToDisplayCustomersInDropDown : undefined}
                                      selectedItem={this.customerGroupId || this.customerId}
                                      nothingSelectedText={Localizer.labelsToolbarAllCustomers}
                                      orderBy={DropdownOrderBy.None}
                                      transform={(item: Customer | CustomerGroup) => TransformProvider.toCustomerOrCustomerGroupSelectItem(item, styles.customerGroupItem, styles.customerItem)}
                                      onChange={(sender, item: CustomerGroup | Customer | null, userInteraction: boolean) => this.selectCustomerGroupOrCustomerAsync(item, userInteraction)}
                            />

                            {
                                (this.showCustomerHandlerFilter) &&
                                (
                                    <Dropdown id="customerHandlerFilter" noWrap inline clearButton
                                              align={DropdownAlign.Left}
                                              orderBy={DropdownOrderBy.None}
                                              width={"20rem"}
                                              disabled={this.isLoading}
                                              nothingSelectedText={Localizer.labelsToolbarAllCustomersHandlers}
                                              items={EnumProvider.getCustomerHandlerItems()}
                                              selectedItem={this.model.customerHandler}
                                              onChange={(_, item: SelectListItem) => this.selectCustomerHandlerFilterAsync(item)}
                                    />
                                )
                            }

                            <TextInput id="productSearch" inline clearButton
                                       width={"20rem"}
                                       readonly={this.isLoading}
                                       placeholder={Localizer.labelsToolbarSearch}
                                       value={this.search}
                                       onChange={(_, value: string) => this.setSearchAsync(value)}
                            />

                            <Button submit right
                                    disabled={this.isLoading}
                                    label={Localizer.genericSearch}
                                    icon={{name: "fas search"}}
                                    type={ButtonType.Primary}
                            />

                        </Inline>

                        {
                            (this.type == LabelsPanelType.ProductsSelection) &&
                            (
                                <Inline>

                                    <Button right label={Localizer.labelsToolbarCreateLabels.format(this.state.assortments.length)}
                                            icon={{name: "plus", size: IconSize.Large}}
                                            type={ButtonType.Orange}
                                            disabled={this.isLoading || this.state.assortments.length == 0}
                                            onClick={() => this.createLabelsAsync()}
                                    />

                                </Inline>
                            )
                        }


                    </ToolbarRow>

                    {
                        (this.type == LabelsPanelType.LabelsSelection) &&
                        (
                            <ToolbarRow className={styles.toolbar} justify={JustifyContent.SpaceBetween}>

                                <Inline>

                                    <Checkbox inline
                                              id="showPrinted"
                                              label={Localizer.labelsToolbarShowPrinted}
                                              value={this.model.showPrintedOnly}
                                              onChange={(_, value) => this.setShowPrintedAsync(value)}
                                    />

                                    {
                                        (this.model.showPrintedOnly) &&
                                        (

                                            <DateInput showYearDropdown showMonthDropdown inline clearButton
                                                       id={"printedDateFilter"}
                                                       label={Localizer.labelsToolbarPrintedDate}
                                                       value={this.model.printedDate}
                                                       maxDate={Utility.today()}
                                                       onChange={(date: Date) => this.setPrintedDateFilterAsync(date)}
                                            />
                                        )
                                    }

                                </Inline>

                                {
                                    (this.type == LabelsPanelType.LabelsSelection) &&
                                    (
                                        <Inline>

                                            <Button id={"exportToCsv"} right
                                                    className={styles.button}
                                                    label={Localizer.labelsToolbarExcel}
                                                    icon={{name: "fa-file-download fas", size: IconSize.Large}}
                                                    type={ButtonType.Primary}
                                                    disabled={this.isLoading}
                                                    onClick={() => this.downloadCsvAsync()}
                                            >

                                                <Button.Action id={"utf8"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Utf8)}
                                                />

                                                <Button.Action id={"unicode"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Unicode)}
                                                />

                                                <Button.Action id={"latin1"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Latin1)}
                                                />

                                                <Button.Action id={"openXml"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.OpenXml)}
                                                />

                                            </Button>

                                            <Button right label={Localizer.labelsToolbarPdf}
                                                    className={styles.button}
                                                    icon={{name: "fa-file-download fas", size: IconSize.Large}}
                                                    type={ButtonType.Primary}
                                                    disabled={this.isLoading}
                                            >

                                                <Button.Action title={Localizer.labelsToolbarPdfSelected.format(this.selectedLabelsCount)}
                                                               onClick={() => this.downloadPdfAsync(false, false)}
                                                />

                                                <Button.Action title={Localizer.labelsToolbarPdfAll}
                                                               onClick={() => this.downloadPdfAsync(true, false)}
                                                />

                                            </Button>

                                            <Button right label={Localizer.labelsToolbarPrint}
                                                    className={styles.button}
                                                    icon={{name: "fas print", size: IconSize.Large}}
                                                    type={ButtonType.Primary}
                                                    disabled={this.isLoading}
                                            >

                                                <Button.Action title={Localizer.labelsToolbarPrintSelected.format(this.selectedLabelsCount)}
                                                               onClick={() => this.printLabelsAsync(false, false)}
                                                />

                                                <Button.Action title={Localizer.labelsToolbarPrintAllButSelected}
                                                               onClick={() => this.printLabelsAsync(false, true)}
                                                />

                                                <Button.Action title={Localizer.labelsToolbarPrintAll}
                                                               onClick={() => this.printLabelsAsync(true, false)}
                                                />

                                            </Button>

                                            <Button right
                                                    className={this.css(styles.button, styles.delete)}
                                                    icon={{name: "far trash-alt", size: IconSize.Large}}
                                                    type={ButtonType.Danger}
                                                    disabled={this.isLoading}
                                            >

                                                <Button.Action title={Localizer.labelsToolbarDeleteSelected.format(this.selectedLabelsCount)}
                                                               onClick={() => this.deleteLabelsAsync(false, false)}
                                                />

                                                <Button.Action title={Localizer.labelsToolbarDeleteAllButSelected}
                                                               onClick={() => this.deleteLabelsAsync(false, true)}
                                                />

                                                <Button.Action title={Localizer.labelsToolbarDeleteAll}
                                                               onClick={() => this.deleteLabelsAsync(true, false)}
                                                />

                                            </Button>

                                        </Inline>
                                    )
                                }

                            </ToolbarRow>
                        )
                    }
                </Form>

                {(this.isLoading) && (<Spinner/>)}

            </ToolbarContainer>
        )
    }
}