// autogenerated

export default class DeleteProductReplacementRequest {

    public id: string = "";

    public readonly isDeleteProductReplacementRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof DeleteProductReplacementRequest) || ((from as any).isDeleteProductReplacementRequest === true));
    }

    public static as(from?: object | null): DeleteProductReplacementRequest | null {
        return (DeleteProductReplacementRequest.is(from)) ? from as DeleteProductReplacementRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}