// autogenerated
import BaseConcurrencyRequest from "./BaseConcurrencyRequest";

export default class ClearOrderRequest extends BaseConcurrencyRequest {

    public orderId: string = "";

    public readonly isClearOrderRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ClearOrderRequest) || ((from as any).isClearOrderRequest === true));
    }

    public static as(from?: object | null): ClearOrderRequest | null {
        return (ClearOrderRequest.is(from)) ? from as ClearOrderRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}